import React from 'react'

import NamedBlock from 'components/blocks/NamedBlock'
import List from 'components/blocks/List'
import {PARAMETERS_FIELD} from 'constants/constants'

function OrbitMapVal(props) {
    const {data, spacecraftsCollection, orbitsCollection, collectionsDict} = props;
    const spacecrafts = collectionsDict[spacecraftsCollection];
    const orbits = collectionsDict[orbitsCollection];

    const onAction = (action, localId) => {
        const fullSpacecraftField = `${PARAMETERS_FIELD}.spacecrafts.id:${localId}.orbitLocalId`;

        switch (action) {
            case "add": {
                const s = spacecrafts.find(s => s.localId === localId);
                s.orbitLocalId = collectionsDict.parameters.orbitId;
                props.onChange(data, fullSpacecraftField, s.orbitLocalId);
                break;
            }
            case "remove": {
                const s = spacecrafts.find(s => s.localId === localId);
                s.orbitLocalId = null;
                props.onChange(data, fullSpacecraftField, s.orbitLocalId);
                break;
            }
            default:
                break;
        }
    };

    let spacecraftsSplitted = {own: [], free: [], others: []};

    spacecrafts.forEach((s) => {
        if (s.orbitLocalId === collectionsDict.parameters.orbitId) {
            spacecraftsSplitted.own.push(s);
        } else if (!s.orbitLocalId) {
            spacecraftsSplitted.free.push(s);
        } else {
            spacecraftsSplitted.others.push(s);
        }
    });

    ["own", "free", "others"].forEach((partition) => {
        spacecraftsSplitted[partition] = spacecraftsSplitted[partition].map(s => ({
            ...s,
            notSelectable: true,
            name: <span className="spacecraft-name">{
                    (partition === "others") ?
                        s.name + " (" + orbits.filter(o => o.localId === s.orbitLocalId)[0].name + ")" :
                        s.name
                }</span>,
            actions: (partition === "own") ?
                {"remove": () => onAction('remove', s.localId)} :
                {"add": () => onAction('add', s.localId)}
        }));
    });

    return (
        <div className="form-group row g-0 d-flex flex-row">
            <NamedBlock name={"In this orbit"} className="flex-grow">
                <List rows={spacecraftsSplitted.own}/>
            </NamedBlock>
            <NamedBlock name={"Without orbit"} className="flex-grow">
                <List rows={spacecraftsSplitted.free}/>
            </NamedBlock>
            <NamedBlock name={"In other orbits"} className="flex-grow">
                <List rows={spacecraftsSplitted.others}/>
            </NamedBlock>
        </div>);
}

export default OrbitMapVal;
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import dateUtils from 'utils/dateUtils'
import useInterval from 'hooks/useInterval'

const UTCTimerSpan = (props) => {
    const {startedAtUTC, endsAtUTC, onTimerEnd} = props;
    const [isRunning, setIsRunning] = useState(true);
    const [nowUTCDate, setNowUTCDate] = useState(dateUtils.getCurrentServerUTCDate());
    const {t} = useTranslation();

    useInterval(() => {
        const newNowUTCDate = dateUtils.getCurrentServerUTCDate();
        setNowUTCDate(newNowUTCDate);
        // new.Date converts any date type (string/Date/Number of millis) to Date
        if (endsAtUTC && newNowUTCDate >= new Date(endsAtUTC)) {
            setIsRunning(false);
            onTimerEnd && onTimerEnd();
        }
    }, isRunning ? 1000 : null);

    const result =
        startedAtUTC ? dateUtils.getLocaleStringTimeSpanBetween(startedAtUTC, nowUTCDate, t) :
            endsAtUTC ? dateUtils.getLocaleStringTimeSpanBetween(nowUTCDate, endsAtUTC, t) :
                t('components.elements.UTCTimerSpan.notDefined')
    return <span> {result}</span>;
};

export default UTCTimerSpan;
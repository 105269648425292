import React from 'react'
import {useTranslation} from 'react-i18next'

import Block from './Block'
import entityUtils from 'utils/entityUtils'
import {calculateParamsFromLiveData} from 'utils/parametersUtils'
import {translateObjOrArray} from 'utils/translationUtils'
import {staticAllReferenceData} from 'constants/staticReferenceData'


export default function DynamicSectionBlock(props) {
    const {newSection, newSectionName, sectionNameType, itemIdField, sectionItemComponents, field, sourceField,
           childField, disabled, data, fieldsInfo, onChange, onValidate, collectionsDict, editLanguage,
           wide, children} = props;

    const {t} = useTranslation();

    const parameters = calculateParamsFromLiveData(props.parameters, field, data, fieldsInfo, collectionsDict);
    // copy of data - we can safely make changes to ids and names (as long as we don't add/remove sections)
    const fullSourceField = field + (field && sourceField ? '.' : '') + sourceField;
    const arr = data[fullSourceField] && Array.isArray(data[fullSourceField]) ? data[fullSourceField].slice() : [];
    if (itemIdField === 'localId') {
        entityUtils.ensureArrayLocalIds(arr);
    }

    const addNewSection = () => {
        const newSectionCallback = parameters.newSectionCallback;
        const newItem = {};
        if (newSectionCallback) {
            newSectionCallback(newItem, arr);
        }
        arr.push(newItem);
        props.onChange(data, fullSourceField, arr);
    };

    const removeSection = (i) => {
        const removedItem = arr.splice(i, 1)[0];

        const removeSectionCallback = parameters.removeSectionCallback;
        if (removeSectionCallback) {
            removeSectionCallback(removedItem, arr);
        }

        props.onChange(data, fullSourceField, arr);
    };

    let components = [];
    let sections = [];

    // adding existing sections

    if (children) {
        React.Children.forEach(
            children,
            (child, i) => {
                const sectionKey = "s" + i;
                components.push(
                    React.cloneElement(child, {
                        section: sectionKey,
                        disabled: child.props.disabled || disabled,
                        data: data,
                        fieldsInfo: fieldsInfo,
                        onChange: onChange,
                        onValidate: onValidate,
                        collectionsDict: collectionsDict,
                        editLanguage: editLanguage,
                        wide: (child.props && child.props.wide) || wide
                    }));
                sections.push({
                    key: sectionKey,
                    name: child.props.name,
                    className: child.props.className,
                    title: child.props.title
                });
            });
    }

    // adding dynamic sections

    const fieldsToUpdate = ['field', 'choiceField'];

    // const sectionTypesCountByName = {};
    if (sectionNameType === "typeTypeName") {
        const sectionNameTypes = parameters.sectionNameTypes;
        const typeInfoArr = (sectionItemComponents && translateObjOrArray(staticAllReferenceData[sectionNameTypes], t)) || [];
        entityUtils.ensureNamesUniqueness(arr, '$name', 'type._type', typeInfoArr);
    }

    arr.forEach((item, i) => {
        const sectionKey = "d" + i;
        const itemId = itemIdField === '$index' ? i : item[itemIdField];
        let sectionName = sectionNameType ? item.$name : item.name;
        if (sectionNameType === 'pattern') {
            const sectionNamePattern = parameters.sectionNamePattern || "$index";
            sectionName = sectionNamePattern.replace('$index', i + 1);
        }

        const itemBlockChildren = React.Children.map(sectionItemComponents, (c, j) => React.cloneElement(c));

        // creating props for every CollectionItemBlock
        const itemBlockProps = {
            name: sectionName,
            field: childField
        };
        const el = React.createElement(
            // DynamicSectionItemBlock,
            Block,
            {
                ...itemBlockProps,
                // for "Block", to draw as regular section:
                section: sectionKey,
                disabled: disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                // onRemove: () => removeSection(i)
            },
            itemBlockChildren);
        // recursively updating $index
        const recursiveCloneWithUpdatedField = (el) => {
            const updatedProps = {};
            fieldsToUpdate
                .filter(f => el.props[f])
                .forEach(f => updatedProps[f] = el.props[f].replace('$itemId', String(itemId)));
            return React.cloneElement(
                el, updatedProps,
                React.Children.map(
                    el.props.children,
                    (child, i) => typeof(child.type) !== 'string' ? recursiveCloneWithUpdatedField(child) : child));
        };
        components.push(recursiveCloneWithUpdatedField(el));
        // if (i < arr.length - 1) {
        //     components.push(<hr/>);
        // }

        sections.push({
            key: sectionKey,
            name: sectionName,
            title: `${sectionName}, ${t('components.fieldBlocks.DynamicSectionBlock.params')}`,
            onRemoveSection: !disabled && newSection && (() => removeSection(i))
        });
    });

    // adding new section
    if (!disabled && newSection) {
        sections.push({
            key: '$new',
            name: <span>
                    <i className="fa fa-plus"/>
                    <span>{` ${newSectionName ? newSectionName : "New section"}`}</span>
                </span>,
            onClick: addNewSection
        });
    }

    // adding key
    components = React.Children.map(components, c => React.cloneElement(c));

    const block = React.createElement(
        Block,
        {
            field: field,
            sections: sections,
            disabled: disabled,
            data: data,
            fieldsInfo: fieldsInfo,
            onChange: onChange,
            onValidate: onValidate,
            collectionsDict: collectionsDict,
            editLanguage: editLanguage,
            shouldOpenNewSection: true
        },
        components);


    return (
        block
    );
}

import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useDebug from 'hooks/useDebug'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useSelectVersion from '../hooks/useSelectVersion'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import NamedBlock from 'components/blocks/NamedBlock'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import EventView from 'appEvent/views/EventView'
import EventCard from 'appEvent/views/components/EventCard'

import {queryBrowserCatalogEntryVersion, queryBrowserFlatCatalogsAndEntries} from 'appCatalog/data/catalogQueries'
import entityUtils from 'utils/entityUtils'


const IndexMaterialsView = compose(
    withUriParams([
        ['catalogEntryId', Number],
        ['catalogEntryVersionId', Number]]),
    queryBrowserFlatCatalogsAndEntries,
    queryBrowserCatalogEntryVersion,
    withInitialDataLoadWaiting(['browserFlatCatalogsAndEntries'])
)((props) => {
    useDebug("MaterialApp", props);
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const {catalogEntryId, catalogEntryVersionId, browserFlatCatalogsAndEntries, browserCatalogEntryVersion, loading} = props;
    const {catalogs, browserCatalogEntries} = browserFlatCatalogsAndEntries;

    const browserCatalogEntry = catalogEntryId &&
        browserCatalogEntries.find(bce => bce.catalogEntry.id === catalogEntryId);
    const {selectVersionDropdownAction} = useSelectVersion(
        browserCatalogEntry && browserCatalogEntry.versionHeaders,
        browserCatalogEntryVersion,
        useCallback((versionId) =>
            `${props.uri}/${catalogEntryId}/${versionId}${props.location.search}`,
            [props.uri, catalogEntryId, props.location.search]));

    const selectedEventId =
        browserCatalogEntryVersion &&
        browserCatalogEntryVersion.event &&
        browserCatalogEntryVersion.event.id;

    // creating blocks of event cards (1 block for every root catalog)
    const blocks = useMemo(() => {
        const findCatalogRootId = (catalogId) => {
            while (catalogId) {
                const currentCatalogId = catalogId;
                const catalog = catalogs.find(c => c.id === currentCatalogId);
                if (!catalog) {
                    return null;
                }
                if (!catalog.parentId) {
                    return currentCatalogId;
                }
                catalogId = catalog.parentId;
            }
        };

        const rootCatalogsWithBrowserCatalogEntriesById = {};
        catalogs.filter(c => c.parentId === null)
            .forEach(c => rootCatalogsWithBrowserCatalogEntriesById[c.id] = {catalog: c, browserCatalogEntries: []});

        browserCatalogEntries.forEach(bce => {
            const catalogRootId = findCatalogRootId(bce.catalogEntry.catalogId);
            if (catalogRootId && bce.lastVersion && bce.lastVersion.event) {
                rootCatalogsWithBrowserCatalogEntriesById[catalogRootId].browserCatalogEntries.push(bce);
            }
        });

        return Object.values(rootCatalogsWithBrowserCatalogEntriesById)
            .sort(entityUtils.sortByOrder(rootCatalogWithLastVersions => rootCatalogWithLastVersions.catalog))
            .filter(rootCatalogWithLastVersions => rootCatalogWithLastVersions.browserCatalogEntries.length > 0)
            .map((rootCatalogWithLastVersions, iCatalog) => {
                const {catalog, browserCatalogEntries} = rootCatalogWithLastVersions;
                const rows = [];
                const makeOnClick = (bce) => (e) => navigate(`${props.uri}/${bce.catalogEntry.id}/${bce.lastVersion.id}`)

                for (let j = 0; j < Math.ceil(browserCatalogEntries.length / 2); j++) {
                    rows.push(
                        <div className="row mb-4" key={j}>
                            {}
                            <EventCard allowUnpublished
                                       event={browserCatalogEntries[2 * j].lastVersion.event} uri={props.uri}
                                       isLarge={browserCatalogEntries.length === 1}
                                       onClick={makeOnClick(browserCatalogEntries[2 * j])} />
                            {browserCatalogEntries[2 * j + 1] &&
                                <EventCard allowUnpublished
                                           event={browserCatalogEntries[2 * j + 1].lastVersion.event} uri={props.uri}
                                           onClick={makeOnClick(browserCatalogEntries[2 * j + 1])} />}
                        </div>
                    );
                }
                return (
                    <NamedBlock key={iCatalog} name={catalog.name}>
                        {rows}
                    </NamedBlock>
                );
            });
        },
        [catalogs, browserCatalogEntries, navigate, props.uri]);

    // if catalogEntry without version:
    if (browserCatalogEntry && !catalogEntryVersionId && browserCatalogEntry.lastVersion) {
        navigate(`${props.uri}/${catalogEntryId}/${browserCatalogEntry.lastVersion.id}${props.location.search}`);
        return null;
    }

    // invalid catalogEntryVersionId - no event id found
    if (catalogEntryVersionId && !loading.browserCatalogEntryVersion && !selectedEventId) {
        navigate(`${props.uri}${props.location.search}`);
        return null;
    }

    // render
    return (
        <>
            {!catalogEntryVersionId &&
            <div className="flex-grow scroll">
                <h5 className="text-center mt-3">{t('appCatalog.views.IndexMaterialsView.materials')}</h5>
                <div className="container">

                    {blocks.length > 0 && blocks}

                    {blocks.length === 0 &&
                    <p>{t('appCatalog.views.IndexMaterialsView.noAccess')}</p>}
                </div>
            </div>}

            {catalogEntryVersionId &&
            <>
                <SaveButtonsBlock withBack
                                  actions={[selectVersionDropdownAction]}
                                  executeWithLock={props.executeWithLock} />

                {selectedEventId &&
                <EventView eventId={selectedEventId}
                           user={props.user} errors={props.errors} loading={props.loading}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation}
                           uri={`${props.uri}/${catalogEntryId}/${catalogEntryVersionId}`}
                           uriParams={props.uriParams} location={props.location} />}
            </>}
        </>
    );
});

export default IndexMaterialsView;
import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {useLocation} from '@reach/router'
import {Button} from 'reactstrap'
import classnames from "classnames"

import useSmartNavigate from 'hooks/useSmartNavigate'

import PanelModal from 'components/modals/PanelModal'
import Messages from 'components/elements/Messages'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'

import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import {queryEventTeamsWithStudents} from '../data/eventQueries'
import {createUserTeam, joinUserTeam, exitFromUserTeam} from '../data/eventMutations'

import {toggleSearchParam} from 'utils/urlUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {TEAM_VIEW} from 'constants/constants'

const TeamModal = compose(
    queryEventTeamsWithStudents,
    createUserTeam, joinUserTeam, exitFromUserTeam,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['eventTeamsWithStudents']))
((props) => {
    const {user, event, eventTeamsWithStudents, createUserTeam, joinUserTeam, exitFromUserTeam,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;

    if (!user || !user.id) {
        return null;
    }

    const [showOtherTeams, setShowOtherTeams] = useState(false);
    const [error, setError] = useState("");

    const teamData = {};
    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const userTeamWithStudents = eventTeamsWithStudents.find(team => team.studentsWithUsers.find(u => u.user && u.user.id === user.id));
    const otherTeamsWithStudents = userTeamWithStudents ?
                                       eventTeamsWithStudents.filter(team => team.studentTeam.id !== userTeamWithStudents.studentTeam.id):
                                       eventTeamsWithStudents;


    useEffect(() => {
        setDataSource(
            teamData,
            (data) => {
                createUserTeam({
                    eventId: event.id,
                    name: data.name})
                .then(({data: {createUserTeam: {error}}}) => {
                    setError({createUserTeam: parseBackendMessage(error, t)});
                });
        });

    }, [user, event, userTeamWithStudents, t, createUserTeam, setDataSource, teamData]);

    return (
        <PanelModal show title={userTeamWithStudents && userTeamWithStudents.studentTeam ? userTeamWithStudents.studentTeam.name: t('appEvent.modals.TeamModal.joinTeam')}
                    onClose={() => {
                            navigate(
                                `${location.pathname}${toggleSearchParam(location.search, TEAM_VIEW)}`,
                                {state: location.state});
                        }}>

            <Messages errors={error} />


            {!userTeamWithStudents &&
            <p>{t('appEvent.modals.TeamModal.noTeam')}</p>}


            {userTeamWithStudents &&
            <>
                <p>{userTeamWithStudents.studentTeam.name}</p>
                {userTeamWithStudents.studentsWithUsers &&
                <ul>
                    {userTeamWithStudents.studentsWithUsers.map(({student, user}, i) =>
                    <li key={i} className={classnames(!user && "text-danger")}>
                        {user && user.name ? `${user.name} (${student.email})`: student.email}
                        {userTeamWithStudents.captainStudent && userTeamWithStudents.captainStudent.id &&
                             userTeamWithStudents.captainStudent.id === student.id && t('appEvent.modals.TeamModal.captain')}
                    </li>)}
                </ul>}
            </>}


            {event.isUserTeams && userTeamWithStudents &&
            <Button color="primary" onClick={() => {
                    exitFromUserTeam(userTeamWithStudents.studentTeam.id);
                }}>
                   {t('appEvent.modals.TeamModal.exitFromTeam')}
            </Button>}


            <div>
                {/* TODO: заменить на чекбокс */}
                <a href="#/" onClick={() => setShowOtherTeams(!showOtherTeams)}>{showOtherTeams ? t('appEvent.modals.TeamModal.hideOtherTeams'): t('appEvent.modals.TeamModal.showOtherTeams')}</a>
            </div>


            {showOtherTeams && otherTeamsWithStudents && otherTeamsWithStudents.map((team, i) =>
            <div key={i}>
                {team.studentTeam &&
                <p>
                    {team.studentTeam.name}

                    {!userTeamWithStudents &&
                    <Button color="primary" onClick={() => {
                        joinUserTeam(team.studentTeam.id)
                        .then(({data: {joinUserTeam: {error}}}) => {
                              setError({joinUserTeam: parseBackendMessage(error, t)});
                        });
                    }}>
                        {t('appEvent.modals.TeamModal.joinTeam')}
                    </Button>}
                </p>}
                {team.studentsWithUsers &&
                <ul>
                    {team.studentsWithUsers.map(({student, user}, i) =>
                    <li key={i} className={classnames(!user && "text-danger")}>
                        {user && user.name ? `${user.name} (${student.email})`: student.email}
                        {team.captainStudent && team.captainStudent.id === student.id && t('appEvent.modals.TeamModal.captain')}
                    </li>)}
                </ul>}
            </div>)}


            {showOtherTeams && !otherTeamsWithStudents &&
            <p>{t('appEvent.modals.TeamModal.noOtherTeams')}</p>}


            {event && event.isUserTeams && showOtherTeams && !userTeamWithStudents &&
            <>
                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={saveAndReset} />
                <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate}
                                items={[{
                                    name: t('appEvent.modals.TeamModal.createTeam'),
                                    type: "string",
                                    field: "name"
                                }]} />
            </>}

        </PanelModal>);
});

export default TeamModal;
import {staticPrecisionByUnit} from 'constants/staticReferenceData'
import {translateObjOrArray} from 'utils/translationUtils'


export function toPrecision(x, unitOrPrecision, t) {
    const translatedStaticPrecisionByUnit = translateObjOrArray(staticPrecisionByUnit, t);
    let precision = 2;
    if (typeof(unitOrPrecision) === 'number') {
        precision = unitOrPrecision;
    } else if (typeof(unitOrPrecision) === 'string') {
        if (translatedStaticPrecisionByUnit.hasOwnProperty(unitOrPrecision)) {
            precision = translatedStaticPrecisionByUnit[unitOrPrecision];
        } else {
            console.warn(`mathUtils: Unknown unit ${unitOrPrecision}`);
        }
    }
    const precisionMultiplier = Math.pow(10, precision);
    return Math.round(x * precisionMultiplier) / precisionMultiplier;
}


const mathUtils = {
    toPrecision
};

export default mathUtils;
/**
 * Created by Yuri on 02.07.2017
 * Part of frontend
 */
import React from 'react'
import {withTranslation} from 'react-i18next'
import entityUtils from 'utils/entityUtils'

const sourceObjectSymbol = Symbol('sourceObject');

export const UPDATE_ITERATION_FIELD = "_updateIterationNumber";


export default function withDataSource(WrappedComponent) {
    class WithDataSource extends React.Component {
        static getEmptyState = () => ({
            data: null,
            onSave: null,
            virtualPropsOriginalValues: {},
            isChanged: false,
            validationValues: {},
            hasErrors: false
        });

        static aggregateErrors = (validationValues) => {
            let hasErrors = false;
            Object.keys(validationValues).forEach(key => {
                hasErrors = hasErrors || validationValues[key] === false;
            });
            return hasErrors;
        };

        static updateActionLock = (props, isChanged, hasErrors, setDataSource, saveAndReset) => {
            if (isChanged) {
                if (hasErrors) {
                    props.setActionLockWithMessageAndActions &&
                    props.setActionLockWithMessageAndActions(
                        <span>{props.t('hocs.withGeneratorDataSource.unsavedChanges')}<br />{props.t('hocs.withGeneratorDataSource.cancelOrReturn')}</span>, {
                        discard: () => setDataSource(null),
                        cancel: () => {}
                    });
                } else {
                    props.setActionLockWithMessageAndActions &&
                    props.setActionLockWithMessageAndActions(
                        <span>{props.t('hocs.withGeneratorDataSource.unsavedChanges')}<br />{props.t('hocs.withGeneratorDataSource.saveCancelOrReturn')}</span>, {
                        discard: () => setDataSource(null),
                        save: saveAndReset,
                        cancel: () => {}
                    });
                }
            } else {
                props.resetActionLock &&
                props.resetActionLock();
            }
        };

        constructor(props) {
            super(props);
            //console.info("WithDataSource constructor");
            this.state = {...WithDataSource.getEmptyState()};
            this.setDataSource = this.setDataSource.bind(this);
            this.saveAndReset = this.saveAndReset.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onValidate = this.onValidate.bind(this);
        }

        // data should be stable object (should not change if underlying data object has not changed)
        setDataSource(data, onSave) {
            // no previous data or new data (proxy is of other object)
            if (!this.state.data || this.state.data[sourceObjectSymbol] !== data) {
                // proxificating copy of object
                const proxy = data ?
                    entityUtils.getExistigProxyOrProxificateObj(JSON.parse(JSON.stringify(data)), true) :
                    null;
                if (proxy) {
                    proxy[sourceObjectSymbol] = data;
                    proxy[UPDATE_ITERATION_FIELD] = 1;
                }
                // we totaly reset everything including onSave
                this.setState((prevState) => ({
                    ...WithDataSource.getEmptyState(),
                    data: proxy,
                    onSave: onSave
                }));
                // reset action lock if saving allowed
                onSave && this.props.resetActionLock && this.props.resetActionLock();
            } // if dataSource was not changed, we can change only onSave (if it was changed this was already done)
            else if (this.state.onSave !== onSave) {
                this.setState((prevState) => ({
                    onSave: onSave
                }));
            }
        }

        saveAndReset() {
            const discardVirtualProps = (data, virtualPropsOriginalValues) => {
                Object.keys(virtualPropsOriginalValues).forEach(k => {
                    if (entityUtils.doesFieldExist(data, k)) {
                        data[k] = virtualPropsOriginalValues[k];
                    }
                    delete virtualPropsOriginalValues[k];
                });
                delete data[UPDATE_ITERATION_FIELD];
            };

            const {data, virtualPropsOriginalValues} = this.state;
            discardVirtualProps(data, virtualPropsOriginalValues);
            this.state.onSave && this.state.onSave(data);
            // resetting data source with same onSave - will reset action lock if it was set
            this.setDataSource(null, this.state.onSave);
            return data;
        }

        onChange(externalData, field, value, isVirtual, noRerender) {
            const {data, virtualPropsOriginalValues, validationValues} = this.state;
            let {isChanged} = this.state;

            // is it the same data as we have?
            if (externalData !== data) {
                return;
            }

            if (isVirtual && !virtualPropsOriginalValues.hasOwnProperty(field)) {
                virtualPropsOriginalValues[field] = data[field];
            } else if (!isVirtual && virtualPropsOriginalValues.hasOwnProperty(field)) {
                delete virtualPropsOriginalValues[field];
            }
            data[field] = value;

            data[UPDATE_ITERATION_FIELD] += 1;
            isChanged = isVirtual ? isChanged : true;

            // update information about fields validation:

            // deleting errors in non-active branch of enum-block
            if (field.endsWith("_type")) {
                const commonPrefix = field.substring(0, field.length - "_type".length);
                const validPrefix = commonPrefix + value;

                Object.keys(validationValues).forEach(key => {
                    if ((key !== field) && (key.startsWith(commonPrefix)) && (!key.startsWith(validPrefix))){
                        delete validationValues[key];
                    }
                });
            }

            //deleting errors from embedded fields if they has been deleted
            Object.keys(validationValues).forEach(key => {
                if (key.startsWith(field + '.') && !entityUtils.doesFieldExist(data, key)) {
                    delete validationValues[key];
                }
            });

            const hasErrors = WithDataSource.aggregateErrors(validationValues);

            // update the state and redraw - setState will call render()

            if (!noRerender || this.state.isChanged !== isChanged || this.state.hasErrors !== hasErrors) {
                // console.log('withDataSource.onChange: setState & updateActionLock->');
                this.setState((prevState) => ({
                    isChanged,
                    hasErrors
                }));
            }

            if (this.state.isChanged !== isChanged || this.state.hasErrors !== hasErrors) {
                this.state.onSave &&
                WithDataSource.updateActionLock(this.props, isChanged, hasErrors, this.setDataSource, this.saveAndReset);
            }
            // console.log('withDataSource.onChange: setState & updateActionLock<-');
        }

        onValidate(externalData, field, isValid) {
            // ***************************************************************
            // VALIDATION STRUCTURE
            // First stream (up): user changes field, data is sending through onChange
            // Rendering will start the second stream (down), ValidatorBlock cathes
            // and starts third atream (up), data is sending through onValidate
            // and fourth stream (down) is responsible for button state
            // and will be start only when we need to change the button
            // ***************************************************************
            const {data, validationValues} = this.state;

            // is it the same data as we have?
            if (externalData !== data) {
                return;
            }

            // no changes?
            if (validationValues[field] === isValid) {
                return;
            }

            validationValues[field] = isValid;

            const hasErrors = WithDataSource.aggregateErrors(validationValues);

            // if hasErrors has been changed, we update the state and redraw

            if (this.state.hasErrors !== hasErrors) {
                // console.log('withDataSource.validate: seState & updateActionLock->');
                this.setState((prevState) => ({
                    hasErrors
                }));
                this.state.onSave &&
                WithDataSource.updateActionLock(this.props, this.state.isChanged, hasErrors, this.setDataSource, this.saveAndReset);
                // console.log('withDataSource.validate: seState & updateActionLock<-');
            }
        }


        render() {
            const {data, isChanged, hasErrors} = this.state;

            return (<WrappedComponent {...this.props}
                                      dataSource={data}
                                      isChanged={isChanged} hasErrors={hasErrors}
                                      setDataSource={this.setDataSource} saveAndReset={this.saveAndReset}
                                      onChange={this.onChange} onValidate={this.onValidate} />);
        }
    }

    WithDataSource.displayName = `WithDataSource(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return withTranslation()(WithDataSource);
};
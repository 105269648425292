import React from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import {Badge} from 'reactstrap'

import {queryLicenseStatistics, queryEventStatistics} from '../../data/adminQueries'

const LicenseAndEventStatisticsView = compose(
    queryEventStatistics,
    queryLicenseStatistics
)((props) => {
    const {licenseStatistics, eventStatistics, eventId, withEvents, withStudents, withDataSize} = props;
    const {t} = useTranslation();

    let {activeStudentsCount, activeEventsCount, studentsLimit, eventsLimit, dataSize: licenseDataSize, dataLimit} = licenseStatistics || {};
    let {dataSize: eventDataSize} = eventStatistics || {};

    activeStudentsCount = activeStudentsCount || 0;
    activeEventsCount = activeEventsCount || 0;

    const statistics =  [
        {
            active: withEvents && eventsLimit !== null && eventsLimit !== undefined,
            header: t('appAdmin.views.components.LicenseAndEventStatisticsView.events'),
            size: activeEventsCount,
            limit: eventsLimit,
        },
        {
            active: withStudents && studentsLimit !== null && studentsLimit !== undefined,
            header: t('appAdmin.views.components.LicenseAndEventStatisticsView.students'),
            size: activeStudentsCount,
            limit: studentsLimit,
        },
        {
            active: eventId && withDataSize && dataLimit !== null && dataLimit !== undefined,
            header: t('appAdmin.views.components.LicenseAndEventStatisticsView.eventSize'),
            size: eventId && eventDataSize,
            shownSize: eventId && (eventDataSize / (1024 * 1024)).toFixed(1),
            limit: null
        },
        {
            active: withDataSize && dataLimit !== null && dataLimit !== undefined,
            header: t('appAdmin.views.components.LicenseAndEventStatisticsView.licenseSize'),
            size: licenseDataSize,
            shownSize: (licenseDataSize / (1024 * 1024)).toFixed(1),
            limit: dataLimit,
            shownLimit: Math.max(0.1, dataLimit / (1024 * 1024)).toFixed(1)
        }
    ];

    return (
        statistics.filter(e => e.active).length > 0 &&
        <div className="bg-light border-dark border-bottom text-end">
            {statistics.filter(e => e.active)
                       .map((e, i, arr) =>
                            <React.Fragment key={i}>
                                {e.header}
                                <Badge className="ms-1" color={e.limit && e.size >= e.limit ? "danger": e.limit && e.size >= e.limit * 0.75 ? "warning": "success"}>
                                      {e.shownSize !== undefined ? e.shownSize: e.size} {e.limit && "/"} {e.shownLimit !== undefined ? e.shownLimit: e.limit}
                                </Badge>
                                {i < arr.length-1 && <span className="me-3">{' '}</span>}
                            </React.Fragment>)}
        </div>);
});

export default LicenseAndEventStatisticsView;
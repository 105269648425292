export const extractPrototypeParameters = (prototypeParameters) => {
    const fields = [];
    const recurse = (items, parentField) => {
        items
            .forEach(item => {
                let currentField = null;
                if (item.type === 'choice') {
                    currentField = item.value;
                } else {
                    currentField = item.field;
                }
                if (!currentField) {
                    return;
                }
                const itemField = parentField ? `${parentField}.${currentField}` : currentField;
                if (item.items) {
                    recurse(item.items, itemField);
                } else {
                    fields.push(itemField);
                }
            });
    };

    const items = Array.isArray(prototypeParameters) ? prototypeParameters : ((prototypeParameters && prototypeParameters.items) || []);
    recurse(items, '$$solution');
    return fields;
};

import React, {Component} from 'react'

import CollectionItemBlock from '../fieldBlocks/CollectionItemBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import List from 'components/blocks/List'

import {DEVICES_FIELD} from 'constants/constants'


export default class RadioMapVal extends Component {
    constructor(props) {
        super(props);
        this.state = {expandedIndex: 0};
        this.toggle = this.toggle.bind(this);
        this.onAction = this.onAction.bind(this);
    }

    toggle(i) {
        this.setState((prevState, props) => {
            prevState.expandedIndex = i;
            return prevState;
        });
    }

    onAction(action, pair) {
        const {data, field} = this.props;
        const value = data[field] ? data[field].slice() : [];

        switch (action) {
            case "add": {
                value.push(pair);
                break;
            }
            case "remove": {
                value.splice(value.indexOf(pair), 1);
                break;
            }
            default:
                throw new Error(`Unknown action ${action}`);
        }
        this.props.onChange(data, field, value);
    }

    render() {
        const {data, field, disabled, fieldsInfo, onChange, onValidate, collectionsDict} = this.props;
        const {expandedIndex} = this.state;
        const value = data[field] || [];

        const devices = data[DEVICES_FIELD];
        const antennas = [];
        const transceivers = [];
        devices && devices.forEach(d => {
            const dLocId = d.localId;
            (d.functions || []).forEach(f => {
                const type = f.type && f.type._type;
                const fLocId = f.localId;
                const funcInfo = {
                    name: <span>{d.$name}<em>{` (${f.$name})`}</em></span>,
                    indices: [dLocId, fLocId]
                };
                if (type === 'antenna') {
                    antennas.push(funcInfo);
                } else if (['receiver', 'transmitter'].indexOf(type) >= 0) {
                    transceivers.push(funcInfo);
                }
            })
        });

        let components;

        components = transceivers.map((t,i) => {
            const itemBlockProps = {
                name: t.name,
                disabled: disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                index: i,
                expand: i === expandedIndex,
                onToggle: this.toggle
            };

            const rowsConnected = [];
            const rowsOther = [];
            antennas.forEach(a => {
                const pair = JSON.stringify([t.indices[0], t.indices[1], a.indices[0], a.indices[1]]);
                const isConnected = value.indexOf(pair) >= 0;
                const row = {
                    notSelectable: true,
                    name: a.name,
                    actions: isConnected ?
                        {"remove": () => this.onAction('remove', pair)} :
                        {"add": () => this.onAction('add', pair)}
                };
                if (isConnected) {
                    rowsConnected.push(row);
                } else {
                    rowsOther.push(row);
                }
            });

            return (
                <CollectionItemBlock key={i}
                                     containerClassName="d-flex flex-row"
                                     {...itemBlockProps}>
                    <NamedBlock name={"Connected"} className="flex-grow">
                        <List rows={rowsConnected}/>
                    </NamedBlock>
                    <NamedBlock name={"Other"} className="flex-grow">
                        <List rows={rowsOther}/>
                    </NamedBlock>
                </CollectionItemBlock>);
        });

        return (
            <div className="form-group">
                {components}
            </div>);
    }
}
import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from 'reactstrap'
import Recaptcha from 'react-recaptcha'
import compose from 'lodash/flowRight'
import {useLocation, useMatch} from '@reach/router'

import useDebug from "hooks/useDebug";
import useSmartNavigate from 'hooks/useSmartNavigate'
import PanelModal from 'components/modals/PanelModal'
import BlockingLink from 'components/links/BlockingLink'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import withUriSearchParam from "hocs/withUriSearchParam"
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withConfirmation from 'hocs/withConfirmation'

import {unregisterTalentId} from './data/baseMutations'
import {registerLicense} from 'appLicense/data/licenseMutations'

import dateUtils from 'utils/dateUtils'
import {toggleSearchParam, removeSearchParam} from 'utils/urlUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {PROFILE_VIEW} from 'constants/constants'

const ProfileView = (props) => {
    const location = useLocation();

    const modes = location.search.substring(1).split('&');    // location.search starts with '?'
    const showProfile = modes.includes(PROFILE_VIEW);

    return showProfile ? <ProfileContents {...props} /> : null;
};


const ProfileContents = compose(
    withUriSearchParam('addTalent', 'isAddTalent'),
    withGeneratorDataSource,
    withConfirmation,
    registerLicense,
    unregisterTalentId
)((props) => {
    useDebug("ProfileView", props);

    const {
        user, isAddTalent,
        dataSource, setDataSource, hasErrors, onChange, onValidate,
        registerLicense, unregisterTalentId,
        executeWithConfirmation, executeWithConfirmationPromise} = props;

    const uriMatch = useMatch('/:lang/*');
    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    const [isAddLicense, setIsAddLicense] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState(props.location.hostname === "localhost");
    const [isRequesting, setIsRequesting] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => setDataSource({}), [setDataSource]);

    // ask to add talent

    useEffect(() => {
        if (isAddTalent && !user.talentId) {
            executeWithConfirmationPromise(t('appBase.Profile.addTalent'))
            .then(
                () => {
                    navigate(`/${uriMatch.lang}/auth/initiateTalentOauth`, {
                        state: {
                            goBackLinkData: {
                                to:`${location.pathname}${removeSearchParam(removeSearchParam(location.search, 'profile'), 'addTalent')}`,
                                state: location.state,
                                isAuthorized: true}}})
                },
                () => navigate(`${location.pathname}${removeSearchParam(removeSearchParam(location.search, 'profile'), 'addTalent')}`, {state: location.state}));
        }
    }, [isAddTalent, user.talentId, executeWithConfirmationPromise, t, uriMatch.lang, location.pathname, location.search, location.state, navigate]);

    //access: registered users
    const hasAccess = user && user.id;
    if (!hasAccess) {
        navigate(`${props.uri}/..`);
        return null;
    }

    // rendering

    return (
        <PanelModal show={!isAddTalent} title={user ? user.name : t('appBase.Profile.loading')}
                    onClose={() => {
                            navigate(
                                `${location.pathname}${toggleSearchParam(location.search, PROFILE_VIEW)}`,
                                {state: location.state});
                        }}>
            {props.loading['user'] && t('appBase.Profile.dataLoading')}
            {!props.loading['user'] &&
            <>
            <h6>{t('appBase.Profile.basicInformation')}</h6>
            <p>{t('appBase.Profile.name')}<span className="font-weight-bold">{user.name}</span></p>
            <p>{t('appBase.Profile.email')}<span className="font-weight-bold">{user.email}</span></p>
            <p>{t('appBase.Profile.talentId')}
                {user.talentId &&
                <span className="font-weight-bold"> {user.talentId}
                    <span className="controlelement float-end"
                          onClick={() => executeWithConfirmation(() => unregisterTalentId(user.talentId)
                            .then(({data: {unregisterTalentId: {error}}}) => {
                                setMessage(error ? {text: parseBackendMessage(error, t), type: 'danger'}: null);
                            }))}>
                        {t('appBase.Profile.removeTalent')}
                    </span>
                </span>}
                {!user.talentId &&
                <BlockingLink to={`/${uriMatch.lang}/auth/initiateTalentOauth`}
                              state={{
                                  goBackLinkData: {
                                      to:`${location.pathname}${location.search}`,
                                      state: location.state,
                                      isAuthorized: true},
                              }}
                              executeWithLock={props.executeWithLock}>{t('appBase.Profile.pointTalent')}</BlockingLink>}
            </p>
            <p>
                <BlockingLink to={`/${uriMatch.lang}/auth/set`}
                              state={{
                                  goBackLinkData: {
                                      to:`${location.pathname}${location.search}`,
                                      state: location.state,
                                      isAuthorized: true}
                              }}
                              executeWithLock={props.executeWithLock}>{t('appBase.Profile.changePassword')}</BlockingLink>
            </p>
            {location.state && location.state.info &&
            <p className="text-success"> {location.state.info} </p>}
            {location.state && location.state.error &&
            <p className="text-danger"> {location.state.error} </p>}

            {['region', 'city', 'birth_year'].some(f => f in user.parameters) &&
            <>
            <h6>{t('appBase.Profile.additionalInformation')}</h6>
            {user.parameters.region &&
            <p>{t('appBase.Profile.region')} <span className="fw-bold">{user.parameters.region}</span></p>}
            {user.parameters.city &&
            <p>{t('appBase.Profile.city')} <span className="fw-bold">{user.parameters.city}</span></p>}
            {user.parameters.birth_year &&
            <p>{t('appBase.Profile.birthYear')} <span className="fw-bold">{user.parameters.birth_year}</span></p>}
            </>}

            <h6>{t('appBase.Profile.licensesInformation')}</h6>
            {(!user.licenses || user.licenses.length === 0) &&
            <p>{t('appBase.Profile.noLicenses')}</p>}
            {user.licenses && user.licenses.length > 0 && user.licenses.map((l,i) =>
                <p key={i}>
                    <span className="fw-bold">{l.name}</span>
                    {!l.startsAt && !l.endsAt && t('appBase.Profile.unlimited')}
                    {(l.startsAt || l.endsAt) &&
                    ` (${l.startsAt && t('appBase.Profile.from') +
                         dateUtils.dateToLocaleDateString(l.startsAt, i18n)
                      }${l.startsAt && l.endsAt && ', '
                      }${l.startsAt && t('appBase.Profile.to') +
                         dateUtils.dateToLocaleDateString(l.endsAt, i18n)})`}
                </p>)}
            </>}
            <span className="controlelement" onClick={() => setIsAddLicense(!isAddLicense)}>{t('appBase.Profile.addLicense')}</span>
            {isAddLicense &&
            <>
            <GeneratorBlock data={dataSource}
                            items={[{
                                name: t('appBase.Profile.licenseCode'),
                                type: "serialNumber",
                                field: "key",
                                mask: "XXXX-XXXX-XXXX-XXXX",
                                required: true
                            }]}
                            onChange={onChange} onValidate={onValidate} />
            {!isCaptcha &&
            <Recaptcha
                sitekey="6LfrzcMUAAAAAMG9ILDeR6wtuTVRfrS9zU-o45uo"
                size="compact"
                render="explicit"
                onloadCallback={() => console.log('rendered')}
                verifyCallback={(e) => setIsCaptcha(true)}
            />}
            <Button disabled={hasErrors || !isCaptcha || isRequesting}
                    onClick={() => {
                        setIsRequesting(true);
                        registerLicense(dataSource.key)
                            .then(({data: {registerLicense: {error}}}) => {
                                setIsRequesting(false);
                                if (error) {
                                    setMessage({text: parseBackendMessage(error, t), type: 'danger'});
                                } else {
                                    setDataSource({});
                                    setMessage({text: t('appBase.Profile.licenseIsActivated'), type: 'success'});
                                }
                            });
                    }}>{isRequesting ? t('appBase.Profile.searching') : t('appBase.Profile.getAccess')}</Button>
            {message &&
            <p className={`text-${message.type}`}>{message.text}</p>}
            </>}
        </PanelModal>);
});


export default ProfileView;
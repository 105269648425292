import React from 'react';
import {Container, Row, Col} from 'reactstrap';


export default function SimulationResultsBlock(props) {
    const components = React.Children.map(props.children, c => c);

    return (
        <Container>
            <Row noGutters>
                <Col sm="4" style={{minWidth: "33.3%"}}>
                    {components.length > 0 && components[0]}
                </Col>
                <Col sm="8" style={{"borderLeft": "1px solid #ccc"}}>
                    {components.length > 1 && components[1]}
                </Col>
           </Row>
        </Container>);
};
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Row} from 'reactstrap'

import LabelWithInfo from './elements/LabelWithInfo'
import XYChart from './elements/XYChart'

import {calculateParamsFromLiveData} from 'utils/parametersUtils'
import {translateObjOrArray} from 'utils/translationUtils'
import {staticResultsHierarchy} from 'constants/staticReferenceData'


class ChartVal extends Component {

    static substNames(field) {
        const subst = function(k) {
            const d = {
                "time": "t",
                "julian-date-utc": "jd",
                //####
                "environment": "e",
                "sun": "s",
                "luna": "l",
                "position": "p",
                //####
                "spacecrafts": "s",
                //##
                "construction": "cn",
                "mass": "m",
                //##
                "thermodynamics": "t",
                "temperature": "t",
                "power-dissipation": "pd",
                //##
                "motion": "m",
                "linear-velocity-magnitude": "lvm",
                "linear-velocity": "lv",
                "linear-acceleration-magnitude": "lam",
                "linear-acceleration": "la",
                "angular-velocity-magnitude": "avm",
                "angular-velocity": "av",
                "angular-acceleration-magnitude": "aam",
                "angular-acceleration": "aa",
                "orientation": "o",
                "projection": "p",
                "altitude": "al",
                "longitude": "lo",
                "latitude": "la",
                //##
                "electrics": "e",
                "accumulator-charge": "ach",
                "accumulator-capacity": "aca",
                "power-production": "pp",
                "power-consumption": "pc",
                "power-balance": "pb",
                //##
                "control": "cl",
                "sleep": "s",
                //##
                "devices": "d",
                "functions": "f",
                "charge": "ch",
                "capacity": "ca",
                "area": "ar",
                "efficiency": "ef",
                "enabled": "en",
                "failed": "f",
                "location": "l",
                //####
                "stations": "st"
            };
            return d[k] ? d[k] : k;
        };
        return field.split('.')
            .map(part => part.startsWith('index:') ? part.substring(6) : subst(part))
            .join('.');
    }

    static toSeries(seriesTemplate, root, collectionsDict, t) {
        let seriesArr = [{name: "", field: "", collection: null}];
        const startCollection = (collection) => {
            const name = seriesArr.length === 1 ? seriesArr[0].name : "";
            const field = seriesArr.length === 1 ? seriesArr[0].field : "";
            seriesArr = [{name: name, field: field, collection: collection}];
        };
        const recurseCollection = (field, resultsField, filterFunc) => {
            if (!field) {
                seriesArr.splice(0, seriesArr.length);
            } else if (seriesArr.length > 0) {
                const newSeriesArr = [];
                seriesArr.forEach(a => {
                    if (a.collection && a.collection.hasOwnProperty(field)) {
                        const subCollection = a.collection[field];
                        if (Array.isArray(subCollection)) {
                            let derivedSeriesArr = subCollection.map((sc, i) => ({
                                name: a.name,
                                field: a.field + (a.field ? '.' : '') + `${resultsField}.index:${i}`,
                                collection: sc
                            }));
                            if (filterFunc) {
                                derivedSeriesArr = derivedSeriesArr.filter((s,i) => filterFunc(s.collection, i));
                            }
                            newSeriesArr.push(...derivedSeriesArr);
                        } else if (subCollection) {
                            newSeriesArr.push({
                                name: a.name,
                                field: a.field + (a.field ? '.' : '') + resultsField,
                                collection: subCollection
                            });
                        }
                    }
                });
                seriesArr.splice(0, seriesArr.length, ...newSeriesArr);
            }
        };
        const addToNames = (newPart) => {
            newPart = newPart ? newPart : ' ';
            seriesArr.forEach(a => a.name += (a.name && newPart ? ' - ' : '') + newPart);
        };
        const addCollectionFieldToNames = (nameField) => {
            seriesArr.forEach(a => a.name += (a.name && a.collection[nameField] ? ' - ' : '') + a.collection[nameField]);
        };
        const setUnit = (unit) => {
            seriesArr.forEach(a => a.unit = unit);
        };
        const setMultiplier = (multiplier) => {
            seriesArr.forEach(a => a.multiplier = multiplier);
        };
        // const setExpression = (expression) => {
        //     seriesArr.forEach(a => a.expression = expression);
        // };
        const addToFields = (newPart) => {
            if (!newPart) {
                seriesArr.splice(0, seriesArr.length);
            } else {
                seriesArr.forEach(a => a.field += (a.field && newPart ? '.' : '') + newPart);
            }
        };
        let accumulatedGeneralName = "";
        const accumulateWithName = (newPart) => {
            newPart = newPart ? newPart : ' ';
            accumulatedGeneralName += (accumulatedGeneralName && newPart ? ' - ' : '') + newPart;
        };
        const accumulateWithCollectionFieldOrDefault = (nameField, newPart) => {
            newPart =
                seriesArr.length === 1 && seriesArr[0].collection[nameField]
                    ? seriesArr[0].collection[nameField]
                    : newPart;
            accumulatedGeneralName += (accumulatedGeneralName && newPart ? ' - ' : '') + newPart;
        };
        // == fields: ==
        // field_name
        // spacecrafts.id:1.field_name
        // spacecrafts.*.field_name
        // spacecrafts.id:1.devices.id:1.field_name
        // spacecrafts.*.devices.*.functions.id:1.field_name
        // spacecrafts.*.devices.*.functions.type:accumulator.field_name
        // spacecrafts.*.devices.*.functions.*.field_name
        if (seriesTemplate && seriesTemplate.startsWith('*')) {
            seriesTemplate = (root ? root : "") + seriesTemplate.substring(1);
        }
        if (!seriesTemplate || (root && !seriesTemplate.startsWith(root))) {
            return [];
        }
        let arr = seriesTemplate.split('.');
        let hierItems = translateObjOrArray(staticResultsHierarchy, t);
        let i = 0;
        while (i < arr.length) {
            let part = arr[i];
            const hierItem =
                hierItems.find(hi => hi.field === part) ||
                (i === arr.length - 1 && hierItems.find(hi => hi.field === ""));
            if (!hierItem) {
                break;
            }
            if (hierItem.fields) {
                if (hierItem.field) {
                    recurseCollection(hierItem.field, hierItem.resultsField || hierItem.field);
                }
                let fieldName;
                if (part === hierItem.field && i < arr.length - 1) {
                    fieldName = arr[i + 1];
                } else {
                    fieldName = part;
                }
                addToNames(fieldName);
                const fieldItem = hierItem.fields.find(f => f.name === fieldName);
                setUnit(fieldItem && fieldItem.unit);
                setMultiplier(fieldItem && fieldItem.sourceUnit);
                addToFields(fieldItem && fieldItem.field && (fieldItem.field + (fieldItem.expression ? `#${fieldItem.expression}` : '')));
                accumulateWithName(fieldName);
                break;
            } else if (hierItem.items) {
                if (hierItem.collection) {
                    startCollection({
                        [hierItem.field]: collectionsDict[hierItem.collection] || []
                    });
                }
                // index part:
                if (i < arr.length - 1) {
                    let indexPart = arr[i + 1];
                    const resultsField = hierItem.resultsField || hierItem.field;
                    if (hierItem.index === "index" && indexPart.startsWith('index:') && isFinite(indexPart.substring(indexPart.indexOf(':') + 1))) {
                        const number = Number(indexPart.substring(indexPart.indexOf(':') + 1));
                        recurseCollection(hierItem.field, resultsField, (x,i) => i === number);
                        addCollectionFieldToNames(
                            hierItem.nameField ? hierItem.nameField : 'name');
                        accumulateWithCollectionFieldOrDefault(
                            hierItem.nameField ? hierItem.nameField : 'name',
                            hierItem.name + ' # ' + number);
                    } else if (hierItem.index === "id" && indexPart.startsWith('id:') && isFinite(indexPart.substring(indexPart.indexOf(':') + 1))) {
                        let number = Number(indexPart.substring(indexPart.indexOf(':') + 1));
                        recurseCollection(hierItem.field, resultsField, (x,i) => x.localId === number);
                        addCollectionFieldToNames(
                            hierItem.nameField ? hierItem.nameField : 'name');
                        accumulateWithCollectionFieldOrDefault(
                            hierItem.nameField ? hierItem.nameField : 'name',
                            hierItem.name + ' ID ' + number);
                    } else if (hierItem.types && indexPart.startsWith('type:')) {
                        const type = indexPart.substring(indexPart.indexOf(':') + 1);
                        const typeItem = hierItem.types.find(t => t.type === type);
                        recurseCollection(hierItem.field, resultsField, (x,i) => typeItem && (hierItem.typeFieldType === 'typeType' ? x.type._type === type : x._type === type));
                        addCollectionFieldToNames(
                            hierItem.nameField ? hierItem.nameField : 'name');
                        accumulateWithCollectionFieldOrDefault(
                            hierItem.nameField ? hierItem.nameField : 'name',
                            typeItem ? typeItem.plural : t('components.fields.ChartVal.unknownType'));
                    } else if (indexPart === '*') {
                        recurseCollection(hierItem.field, resultsField);
                        addCollectionFieldToNames(
                            hierItem.nameField ? hierItem.nameField : 'name');
                        accumulateWithCollectionFieldOrDefault(
                            hierItem.nameField ? hierItem.nameField : 'name',
                            hierItem.plural);
                    } else {
                        throw new Error(`Bad results hierarchy found: ${indexPart} for ${seriesTemplate} with index: ${hierItem.index}`);
                    }
                    // next
                    hierItems = hierItem.items;
                    i += 2;
                }
            } else {
                throw new Error(`Bad results hierarchy found: ${part} for ${seriesTemplate}`);
            }
        }
        // returning
        if (seriesArr.length === 0) {
            seriesArr = [{name: '(-) ' + accumulatedGeneralName}];
        }
        return seriesArr;
    }


    static getNewStateFromProps(props) {
        // values are passed by reference, we need to update them
        const {field, data, fieldsInfo, collectionsDict, t} = props;
        const value = (data && data[field]) || {};
        let {root, x, y} = value;
        // root
        const parameters = calculateParamsFromLiveData(props.parameters, field, data, fieldsInfo, collectionsDict);
        if (root === "spacecrafts.id") {
            root += ':' + (parameters.spacecraftId || -1);
        } else if (root === "stations.id") {
            root += ':' + (parameters.stationId || -1);
        }

        let xSeries;
        const xSeriesArr = ChartVal.toSeries(x, root, collectionsDict, t);
        if (!xSeriesArr || xSeriesArr.length !== 1 || !xSeriesArr[0].field) {
            if (!xSeriesArr || xSeriesArr.length > 1) {
                console.warn(`Bad xseries ${x}, returned length ${xSeriesArr && xSeriesArr.length}`);
            } else if (xSeriesArr.length === 1 && !xSeriesArr[0].field) {
                console.warn(`Bad xseries ${x}, returned void aggregate ${xSeriesArr[0].name}`);
            }
            xSeries = {name: "Time", unit: "sec", field: "time"};
        } else {
            xSeries = xSeriesArr[0];
        }

        const ySeriesArr = y ? [].concat.apply([], y.map(y => ChartVal.toSeries(y, root, collectionsDict, t))) : [];

        console.log('xSeries: ');
        console.log('  ' + JSON.stringify({name: xSeries.name, unit: xSeries.unit, field: xSeries.field}));
        xSeries.field = xSeries.field && ChartVal.substNames(xSeries.field);
        console.log('  ' + JSON.stringify({name: xSeries.name, unit: xSeries.unit, field: xSeries.field}));
        console.log('ySeries: ');
        ySeriesArr.forEach(ySeries => {
            console.log('  ' + JSON.stringify({name: ySeries.name, unit: ySeries.unit, field: ySeries.field}));
            ySeries.field = ySeries.field && ChartVal.substNames(ySeries.field);
            console.log('  ' + JSON.stringify({name: ySeries.name, unit: ySeries.unit, field: ySeries.field}))
        });

        return {
            xSeries: xSeries,
            ySeriesArr: ySeriesArr
        };
    }


    static getValueHash(props) {
        const {field, data, collectionsDict} = props;
        const value = (data && data[field]) || {};
        let {root, x, y} = value;
        const parameters = calculateParamsFromLiveData(props.parameters, field, data, collectionsDict);
        return [root, x, y && y.join(';'), parameters.spacecraftId, parameters.stationId].join(';');
    }


    constructor(props) {
        super(props);
        this.state = {
            valueHash: ChartVal.getValueHash(props),
            ...ChartVal.getNewStateFromProps(props),
            key: 1
        };
    }

    componentWillReceiveProps(nextProps) {
        const newValueHash = ChartVal.getValueHash(nextProps);
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field || this.state.valueHash !== newValueHash) {
            this.setState((prevState) => {
                // we do not close graph if underlying data updates.
                // parsing chart series parameters
                return {
                    valueHash: newValueHash,
                    ...ChartVal.getNewStateFromProps(nextProps),
                    key: prevState.key + 1
                }
            });
        }
    }

    // onAction(action) {
    //     this.setState((prevState) => {
    //         switch (action) {
    //             case "openGraph":
    //                 prevState.showGraph = true;
    //                 break;
    //             case "closeGraph":
    //                 prevState.showGraph = false;
    //                 break;
    //             default:
    //                 throw new Error(`Unknown action ${action}!`);
    //         }
    //         return prevState;
    //     });
    // }


    render() {
        const {name, info, infoImage, noLabel, style, field, resultsDataCollection,
            //parametersCollection,
            collectionsDict} = this.props;
        let {dataZoom, legendInside} = this.props;

        const {xSeries, ySeriesArr} = this.state;

        //dataZoom = dataZoom ? dataZoom : false;
        dataZoom = (xSeries.field === 'time' || xSeries.field === 't');
        legendInside = true; //legendInside ? legendInside : false;

        const resultsData = collectionsDict[resultsDataCollection];
        // const simulationId = collectionsDict[parametersCollection].simulationId;
        // const isRunning = collectionsDict[parametersCollection].isRunning;

        // const {series, showGraph, key} = this.state;

        return (
            // <React.Fragment>
            <div className="form-group">
                {!noLabel &&
                <Row noGutters>
                    <Col>
                        <LabelWithInfo name={name} info={info} infoImage={infoImage} />
                    </Col>
                </Row>}
                <Row noGutters className="d-flex flex-row flex-nowrap">
                    <div className="flex-grow">
                        {/*{!isRunning && resultsData &&*/}
                        {/*<a onClick={() => this.onAction('openGraph')} key="A" href="#">Open in a new tab </a>}*/}
                        {/*<XYChart key={key}*/}
                        <XYChart
                                 xSeries={xSeries} ySeriesArr={ySeriesArr} data={resultsData}
                                 dataZoom={dataZoom} legendInside={legendInside}
                                 style={style} />
                    </div>
                </Row>
                {/*<SimpleModal key="M" show={showGraph} title={dynamicSectionName} onClose={() => this.onAction('closeGraph')}>*/}
                    {/*{showGraph &&*/}
                    {/*<this.TimeLineChartWithData simulationId={simulationId} series={series} dataZoom timeField={timeField} legendInside={legendInside} />}*/}
                {/*</SimpleModal>*/}
            </div>
            // </React.Fragment>
        );
    }
}

export default withTranslation()(ChartVal);

import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import classnames from "classnames";


export default class PanelModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: props.hasOwnProperty("show") ? props.show : true
        };
    }

    componentWillReceiveProps(nextProps) {
        // don't reset current state if the "show" was not changed. just do a rerender with current state saved.
        if (this.state.show !== nextProps.show) {
            this.setState((prevState) => ({
                show: nextProps.hasOwnProperty("show") ? nextProps.show : prevState.show
            }));
        }
    }

    render() {
        const {title, titleComponents, noPadding, children, scrollable, size, onClose} = this.props;
        const {show} = this.state;

        return (
            <Modal isOpen={show} size={size || "xl"} aria-labelledby="contained-modal-title-lg"
                   toggle={() => {this.setState(ps => ({show: !ps.show})); onClose();}}
                   backdrop keyboard
                   className={classnames(scrollable && "modal-dialog-scrollable")}>
                <ModalHeader id="contained-modal-title-lg"
                             toggle={() => {this.setState(ps => ({show: !ps.show})); onClose();}}>
                    <div className="float-end">{titleComponents}</div>
                    <div>{title}</div>
                </ModalHeader>

                <ModalBody style={noPadding && {padding: 0}} className={classnames(scrollable && "d-flex flex-column")}>
                    {children}
                </ModalBody>

            </Modal>);

    }
}

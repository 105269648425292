import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {InputGroup, InputGroupText} from 'reactstrap'
import {graphql} from '@apollo/client/react/hoc'
import compose from 'lodash/flowRight'
import classnames from 'classnames'

import List from 'components/blocks/List'
import DataBlock from 'components/blocks/DataBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import DialogModal from 'components/modals/DialogModal'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {DevicesQuery} from '../../data/eventQueries'

import {breadcrumbsStore} from 'appBase/TopNav'
import entityUtils from 'utils/entityUtils'
import prototypeUtils from 'utils/prototypeUtils'
import mathUtils from 'utils/mathUtils'
import {translateObjOrArray} from 'utils/translationUtils'
import {ensureDevicesConsistency} from 'utils/devicesUtils'
import staticPrototypes from 'constants/_@_staticPrototypes'
import {staticSpacecraftFunctionsTypes, staticStationFunctionsTypes, staticAllFunctionsTypes, staticFunctionsAggregateFields} from 'constants/staticReferenceData'
import {SPACECRAFT, STATION, DEVICE, INITIAL_DB_DEVICES, DEVICES_FIELD, FULL_SCREEN} from 'constants/constants'

const DB_DEVICE = 'device-db';
const USER_DEVICE = 'device-user';


class ComposeDevicesView extends Component {

    static dbDeviceDataMapper(d) {
        return {id: d.id, name: d.name, description: d.description, ...JSON.parse(d.parameters)};
    }

    static addDeviceFunctionsVariableNames(newDevice, devices) {
        const variableNames = devices.map(f => f['variable-name']);
        newDevice['variable-name'] = entityUtils.nextVariableName(variableNames, 'dev');
        newDevice.functions.forEach((f, i) => f['variable-name'] = `fun${i + 1}`);
    }

    static addFunctionVariableNameAndIndex(newFunc, functions) {
        const variableNames = functions.map(f => f['variable-name']);
        newFunc['variable-name'] = entityUtils.nextVariableName(variableNames, 'fun');
        newFunc['index'] = functions.length;
    }

    static removeFunctionIndex(index, functions) {
        functions.forEach((f, i) => f.index = i);
    }

    static getDevicesExpandedWithDbDevices(container, initialDbDevices) {
        return container[DEVICES_FIELD]
            ? container[DEVICES_FIELD].map(d => {
                const dbD = initialDbDevices && initialDbDevices.find(dbD => dbD.id === d.id);
                return d.id && dbD
                    ? {
                        ...d,
                        ...dbD,
                        functions: dbD.functions.map((dbF,i) => ({
                            ...(d.functions && d.functions[i]),
                            ...dbF
                        }))
                    }
                    : d;
              })
            : []
    }

    static getMaxSpacecraftVolume(props) {
        const spacecraftFieldsInfo = prototypeUtils.getFieldsInfo(staticPrototypes.spacecraft);
        // maxVolume
        const spacecraft = props.dataSource[props.containerRootField];
        // proxy for reading
        const spacecraftProxy = entityUtils.getExistigProxyOrProxificateObj(spacecraft);
        let maxVolume;
        const massInertiaType = prototypeUtils.get(spacecraftProxy, "mass-inertia._type", spacecraftFieldsInfo);
        switch (massInertiaType) {
            case "spherical":
                const r = prototypeUtils.get(spacecraftProxy, "mass-inertia.spherical.radius", spacecraftFieldsInfo);
                maxVolume = (4 / 3) * 3.1416 * r * r * r;
                break;
            case "cubical":
                const e = prototypeUtils.get(spacecraftProxy, "mass-inertia.cubical.edge", spacecraftFieldsInfo);
                maxVolume = e * e * e;
                break;
            case "custom":
                maxVolume = prototypeUtils.get(spacecraftProxy, "mass-inertia.custom.volume", spacecraftFieldsInfo);
                break;
            default:
                maxVolume = 0;
                console.warn(`Unknown massInertiaType type ${massInertiaType}!`);
        }
        return maxVolume;
    }

    constructor(props){
        // props: configuration, onAction
        super(props);

        const container = props.dataSource[props.containerRootField];

        // enhance devices field
        const initialDbDevices = props[INITIAL_DB_DEVICES];
        container[DEVICES_FIELD] = ComposeDevicesView.getDevicesExpandedWithDbDevices(container, initialDbDevices);
        ensureDevicesConsistency(container[DEVICES_FIELD], props.t);
        this.maxVolume =
            props.containerType === SPACECRAFT ? ComposeDevicesView.getMaxSpacecraftVolume(props) : null;
        this.staticFunctionsTypes =
            props.containerType === SPACECRAFT ? translateObjOrArray(staticSpacecraftFunctionsTypes, props.t) :
            props.containerType === STATION ? translateObjOrArray(staticStationFunctionsTypes, props.t) : null;

        // state:
        this.state = {
            selectedDeviceLocalId: null,
            showModal: false
        };
        this.onAction = this.onAction.bind(this);
        this.onChange = this.onChange.bind(this);

        this.SelectDeviceFromDBWithData = compose(
            graphql(DevicesQuery, {
                skip: ({show}) => !show,
                props: ({ loading, errors, data }) => ({
                    loading: {...loading, allDevices: data.loading},
                    errors: {...errors, allDevices: data.error && data.error.message },
                    allDevices: data.devices && data.devices.map(d => ComposeDevicesView.dbDeviceDataMapper(d))
                }),
            }),
        )(SelectDeviceFromDB);

        // links

        const containerId = DEVICE;
        breadcrumbsStore.register(containerId);
        breadcrumbsStore.set(containerId, {name: props.t('appEvent.views.solutionViews.ComposeDevicesView.devices'), back: 1, state: {[FULL_SCREEN]: true}, nonBlocking: true}, true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.containerRootField !== this.props.containerRootField || nextProps.dataSource !== this.props.dataSource) {
            let newSelectedDeviceId = this.state.selectedDeviceLocalId;
            const thisPropsContainer = this.props.dataSource[this.props.containerRootField];
            const nextPropsContainer = nextProps.dataSource[nextProps.containerRootField];
            if (!nextPropsContainer) {
                newSelectedDeviceId = null;
            } else if ((thisPropsContainer && nextPropsContainer.localId !== thisPropsContainer.localId) || !thisPropsContainer) {

                newSelectedDeviceId = null;
                const container = nextProps.dataSource[nextProps.containerRootField];

                // enhance devices field
                const initialDbDevices = nextProps[INITIAL_DB_DEVICES];
                container[DEVICES_FIELD] = ComposeDevicesView.getDevicesExpandedWithDbDevices(container, initialDbDevices);
                ensureDevicesConsistency(container[DEVICES_FIELD], nextProps.t);
                this.maxVolume =
                    nextProps.containerType === SPACECRAFT ? ComposeDevicesView.getMaxSpacecraftVolume(nextProps) : null;
                this.staticFunctionsTypes =
                    nextProps.containerType === SPACECRAFT ? translateObjOrArray(staticSpacecraftFunctionsTypes, nextProps.t) :
                    nextProps.containerType === STATION ? translateObjOrArray(staticStationFunctionsTypes, nextProps.t) : null;
            }
            this.setState((prevState, props) => ({
                showModal: false
            }));
            this.onAction('select', {deviceLocalId: newSelectedDeviceId});
        }
    }

    onAction(action, data) {
        const onParentAction = this.props.onAction;
        if (['select', 'create', 'clone', 'remove', 'open', 'close'].indexOf(action) === -1) {
            onParentAction(action, data);
        } else {
            const {dataSource, containerRootField, onChange} = this.props;
            const container = dataSource[containerRootField];
            if (!container) {
                return null;
            }
            const devices = container[DEVICES_FIELD];

            let type, deviceLocalId, device, newDevice;
            switch (action) {
                case "select":
                    deviceLocalId = data.deviceLocalId;
                    if (deviceLocalId === -1) {
                        this.setState((prevState) => ({
                            selectedDeviceLocalId: deviceLocalId
                        }));
                        return;
                    }
                    device = deviceLocalId && devices.find(d => d.localId === deviceLocalId);
                    // rebuilding prototype (costy operation, and it also can disrupt sensitive by-pointer checks (like values in preset):
                    // device: disabling all but [variable-name, orientation, enabled] if from db
                    // function: disabling all but [variable-name, enabled] if from db
                    // filtering off volume (for db devices) and size (for abstract devices)
                    if (device) {
                        // limiting allowed function types
                        const functionsTypes = this.staticFunctionsTypes.map(funcType => funcType.type);
                        const baseStaticPrototype = JSON.parse(JSON.stringify(staticPrototypes[DEVICE]));
                        const baseStaticPrototypesFunctionsChoiceItem =
                            baseStaticPrototype.find(itemProto => itemProto.type === "dynamicSection")
                            .dynamicItems[0].items.find(itemProto => itemProto.type === "choices");
                        baseStaticPrototypesFunctionsChoiceItem.stuff =
                            baseStaticPrototypesFunctionsChoiceItem.stuff
                            .filter(x => functionsTypes.includes(x.value));
                        this.setState((prevState, props) => {
                            prevState.selectedDeviceLocalId = deviceLocalId;
                            prevState.modifiedPrototypeItems = baseStaticPrototype
                                .map(itemProto => {
                                    if (itemProto.type === "dynamicSection") {
                                        return {
                                            ...itemProto,
                                            newSection: !device.id,
                                            items: [{
                                                ...itemProto.items[0],
                                                items: itemProto.items[0].items
                                                    .filter(subItemProto => device.id ? subItemProto.field !== "volume" : subItemProto.field !== "size")
        // .filter(subItemProto => subItemProto.field === 'orientation')
                                                    .map(subItemProto => ({
                                                        ...subItemProto,
                                                        disabled: subItemProto.disabled || (!["variable-name", "orientation", "enabled"].includes(subItemProto.field) && device.id)
                                                    }))
                                            }],
                                            dynamicItems: [{
                                                ...itemProto.dynamicItems[0],
                                                items: itemProto.dynamicItems[0].items
                                                    .map(subItemProto => ({
                                                        ...subItemProto,
                                                        disabled: subItemProto.disabled || (!["variable-name", "enabled"].includes(subItemProto.field) && device.id)
                                                    }))
                                            }],
                                        }
                                    } else {
                                        return {...itemProto, disabled: device.id};
                                    }
                                });
                            return prevState;
                        });
                    }
                    break;
                case "create":
                    type = data.type;
                    switch (type) {
                        case DB_DEVICE:
                            newDevice = JSON.parse(JSON.stringify(data.device));
                            ComposeDevicesView.addDeviceFunctionsVariableNames(newDevice, devices);
                            devices.push(newDevice);
                            ensureDevicesConsistency(devices, this.props.t);
                            //this.onAction('select', {deviceLocalId: newDevice.localId});
                            break;
                        case USER_DEVICE:
                            newDevice = {name: this.props.t('appEvent.views.solutionViews.ComposeDevicesView.newDevice'), functions: []};
                            ComposeDevicesView.addDeviceFunctionsVariableNames(newDevice, devices);
                            devices.push(newDevice);
                            ensureDevicesConsistency(devices, this.props.t);
                            //this.onAction('select', {deviceLocalId: newDevice.localId});
                            break;
                        default:
                            throw new Error(`Unknown type ${type}`);
                    }
                    onChange(dataSource, `${containerRootField}.${DEVICES_FIELD}`, devices);
                    break;
                case "clone":
                    deviceLocalId = data.deviceLocalId;
                    device = deviceLocalId && devices.find(d => d.localId === deviceLocalId);
                    newDevice = JSON.parse(JSON.stringify(device));
                    devices.splice(devices.indexOf(device) + 1, 0, newDevice);
                    ensureDevicesConsistency(devices, this.props.t);
                    //this.onAction('select', {deviceLocalId: newDevice.localId});
                    onChange(dataSource, `${containerRootField}.${DEVICES_FIELD}`, devices, this.props.t);
                    break;
                case "remove":
                    type = data.type;
                    deviceLocalId = data.deviceLocalId;
                    device = deviceLocalId && devices.find(d => d.localId === deviceLocalId);
                    switch (type) {
                        case DEVICE:
                            devices.splice(devices.indexOf(device), 1);
                            ensureDevicesConsistency(devices, this.props.t);
                            if (this.state.selectedDeviceLocalId === deviceLocalId) {
                                //this.onAction('select', {deviceLocalId: null});
                            }
                            break;
                        default:
                            throw new Error(`Unknown type ${type}`);
                    }
                    onChange(dataSource, `${containerRootField}.${DEVICES_FIELD}`, devices);
                    break;
                case "open":
                    this.setState((prevState, props) => {
                        prevState.showModal = true;
                        return prevState;
                    });
                    break;
                case 'close':
                    this.setState((prevState, props) => {
                        prevState.showModal = false;
                        return prevState;
                    });
                    break;
                default:
                    throw new Error('Unknown action ' + action + ' with data ' + JSON.stringify(data));
            }
        }
    }

    onChange(data, field, value, noChanges) {
        const {dataSource, containerRootField, onChange, t} = this.props;
        const devices = dataSource[containerRootField][DEVICES_FIELD];

        onChange(data, field, value, noChanges);

        if (field.endsWith('.name')) {
            ensureDevicesConsistency(devices, t);
        }
    }

    render() {
        const {dataSource, containerRootField, disabled, className, t} = this.props;
        let {selectedDeviceLocalId, showModal, modifiedPrototypeItems} = this.state;

        const container = dataSource[containerRootField];
        if (!container) {
            return null;
        }
        if (!container[DEVICES_FIELD]) {
            container[DEVICES_FIELD] = [];
        }
        const devices = container[DEVICES_FIELD];

        const selectedIndex = devices.map(x => x.localId).indexOf(selectedDeviceLocalId) + 1;

        const device = selectedDeviceLocalId && devices.find(d => d.localId === selectedDeviceLocalId);
        selectedDeviceLocalId = device ? selectedDeviceLocalId : null;
        const deviceRootField = `${containerRootField}.${DEVICES_FIELD}.id:${selectedDeviceLocalId}`;

        const nameClassNames = {[SPACECRAFT]: 'spacecraft-name', [STATION]: 'gc-name'};

        let rows = [];
        rows.push({
            name: <span className={nameClassNames[this.containerType]}>{container.name}</span>,
            actions: {
                "select": () => this.onAction('select', {deviceLocalId: -1})
            }});
        devices.forEach( (d, i) => {
            rows.push({
                name: <span className="device-name ms-4">{d.$name}</span>,
                actions: {
                    "clone": !disabled && (() => this.onAction('clone',  {deviceLocalId: d.localId})),
                    "removeConfirmation": !disabled && (() => this.onAction('remove', {type: DEVICE, deviceLocalId: d.localId})),
                    "select": () => this.onAction('select', {deviceLocalId: d.localId})
                }});
        });
        if (!disabled) {
            rows.push({
                name: t('appEvent.views.solutionViews.ComposeDevicesView.addDeviceFromDB'),
                className: "text-end",
                notSelectable: true,
                actions: {"click": () => this.onAction("open")}
            });
            rows.push({
                name: t('appEvent.views.solutionViews.ComposeDevicesView.newDevice2'),
                className: "text-end",
                notSelectable: true,
                actions: {"click": () => this.onAction("create", {type: USER_DEVICE})}
            });
        }

        // aggrregates
        const deviceFieldsInfo = prototypeUtils.getFieldsInfo(staticPrototypes[DEVICE]);

        const mass = devices.map((d) => prototypeUtils.get(d, "mass", deviceFieldsInfo)).reduce((acc, m) => acc + (m ? m : 0), 0);
        const volume = devices.map((d) => d.id
                ? prototypeUtils.get(d, "size", deviceFieldsInfo).reduce((acc, v) => acc * (v ? v : 0), 1)
                : prototypeUtils.get(d, "volume", deviceFieldsInfo)
        ).reduce((acc, v) => acc + (v ? v : 0), 0);
        const volumePercent = 100*volume/this.maxVolume;
        const powerConsumption = devices.map((d) =>
            prototypeUtils.get(d, "power-consumption", deviceFieldsInfo) +
            d.functions
                .map((f,i) => prototypeUtils.get(d, `functions.index:${i}.power-consumption`, deviceFieldsInfo))
                .reduce((acc, m) => acc + (m ? m : 0), 0)
        ).reduce((acc, m) => acc + (m ? m : 0), 0);
        const powerDissipation = devices.map((d) =>
            prototypeUtils.get(d, "power-dissipation", deviceFieldsInfo) +
            d.functions
                .map((f,i) => prototypeUtils.get(d, `functions.index:${i}.power-dissipation`, deviceFieldsInfo))
                .reduce((acc, m) => acc + (m ? m : 0), 0)
        ).reduce((acc, m) => acc + (m ? m : 0), 0);

        const deviceSummaryParams = [
            {
                name: t('appEvent.views.solutionViews.ComposeDevicesView.mass'),
                unit: t('appEvent.views.solutionViews.ComposeDevicesView.massUnit'),
                value: mathUtils.toPrecision(mass, t('appEvent.views.solutionViews.ComposeDevicesView.massUnit'), t)},
            {
                name: t('appEvent.views.solutionViews.ComposeDevicesView.volume'),
                unit: t('appEvent.views.solutionViews.ComposeDevicesView.volumeUnit'),
                value: [
                    <span key={1}>{mathUtils.toPrecision(volume, t('appEvent.views.solutionViews.ComposeDevicesView.volumeUnit'), t)}</span>,
                    <span key={2} className={classnames({'text-danger': volumePercent > 100})}>
                        ({mathUtils.toPrecision(volumePercent, 2, t)} %)
                    </span>
                ]
            },
            {
                name: t('appEvent.views.solutionViews.ComposeDevicesView.powerConsumption'),
                unit: t('appEvent.views.solutionViews.ComposeDevicesView.powerConsumptionUnit'),
                value: mathUtils.toPrecision(powerConsumption, t('appEvent.views.solutionViews.ComposeDevicesView.powerConsumptionUnit'), t)
            },
            {
                name: t('appEvent.views.solutionViews.ComposeDevicesView.powerDissipation'),
                unit: t('appEvent.views.solutionViews.ComposeDevicesView.powerDissipationUnit'),
                value: mathUtils.toPrecision(powerDissipation, t('appEvent.views.solutionViews.ComposeDevicesView.powerDissipationUnit'), t)
            }
        ];

        // container aggregates
        let funcSummaryArr = null;
        if (selectedIndex === 0) {
            funcSummaryArr = [];
            // known functions
            this.staticFunctionsTypes.forEach(funcType => {
                funcSummaryArr.push({
                    name: funcType.name,
                    params: translateObjOrArray(staticFunctionsAggregateFields, t)
                        .filter(af => af.type === funcType.type)
                        .map(af => {
                            const value = devices.map((d) => {
                                if (af.field.startsWith('functions.')) {
                                    return d.functions
                                        .map((f,i) => (f.type && f.type._type) === funcType.type
                                            ? prototypeUtils.get(d, `functions.index:${i}.${af.field.substring(10)}`, deviceFieldsInfo)
                                            : 0
                                        ).reduce((acc, v) => acc + (v ? v : 0), 0);
                                } else {
                                    return prototypeUtils.get(d, af.field, deviceFieldsInfo);
                                }
                            }).reduce((acc, v) => acc + (v ? v : 0), 0);
                            return {name: af.name, unit: af.unit, value: mathUtils.toPrecision(value, af.unit, t)};
                        }),
                    rows: devices
                        .filter(d => d.functions.some(f => (f.type && f.type._type) === funcType.type))
                        .map(d => ({
                            name: <span className="device-name">{d.$name}</span>,
                            actions: {
                                "select": () => this.onAction('select', {deviceLocalId: d.localId})
                            }
                        }))
                });
            });
            // unknown functions:
            const functionsTypes = this.staticFunctionsTypes.map(funcType => funcType.type);
            const unknownFunctionDevices = devices
                .filter(d => d.functions.some(f => functionsTypes.indexOf(f.type && f.type._type) === -1));
            if (unknownFunctionDevices.length > 0) {
                funcSummaryArr.push({
                    name: <span className='text-danger'>{t('appEvent.views.solutionViews.ComposeDevicesView.unknown')}</span>,
                    rows: unknownFunctionDevices
                        .map(d => ({
                            name: <span className="device-name">{d.$name}</span>,
                            actions: {
                                "select": () => this.onAction('select', {deviceLocalId: d.localId})
                            }
                        }))
                });
            }
        }

        return (
            // <React.Fragment>
            <div className={classnames("flex-grow d-flex", className)}>
                <div style={{width: "25%"}} className="scroll">
                    <DataBlock name={t('appEvent.views.solutionViews.ComposeDevicesView.info')}
                               parameters={deviceSummaryParams} />
                    {devices &&
                    <List rows={rows} selectedIndex={selectedIndex} />}
                </div>
                {device &&
                <div className="flex-grow scroll d-flex border border-dark border-top-0 border-end-0 border-bottom-0">
                    <GeneratorBlock className="flex-grow mt-4"
                                    disabled={disabled}
                                    data={dataSource}
                                    field={deviceRootField}
                                    items={modifiedPrototypeItems}
                                    collectionsDict={{parameters: {
                                        newSectionCallback: ComposeDevicesView.addFunctionVariableNameAndIndex,
                                        removeSectionCallback: ComposeDevicesView.removeFunctionIndex
                                    }}}
                                    onChange={this.onChange}
                                    onValidate={this.props.onValidate} />
                </div>}
                {funcSummaryArr &&
                <div className="flex-grow scroll border border-dark border-top-0 border-end-0 border-bottom-0">
                    <div className="d-flex flex-wrap align-items-stretch">
                        {funcSummaryArr.map((fs, i) =>
                            <DataBlock key={i} className="flex-grow-auto" style={{maxWidth: "25%"}}
                                       name={fs.name}
                                       parameters={fs.params}
                                       rows={fs.rows} />)}
                    </div>
                </div>}

                <this.SelectDeviceFromDBWithData key="M" show={showModal} t={t}
                                                 onSelect={(device) => {
                                                     //this.onAction('close');
                                                     this.onAction('create', {type: DB_DEVICE, device: device});
                                                 }}
                                                 onClose={() => this.onAction('close')} />
            </div>
            // </React.Fragment>
        );
    }
}


class SelectDeviceFromDB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null,
            selectedDevice: null,
            search: null
        };
        this.onSelect = this.onSelect.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);

        this.ListWithLoadWaiting = compose(
            withInitialDataLoadWaiting(["allDevices"])
        )(List);

        // device: filtering off ["variable-name", "orientation", "enabled", "volume"]
        // function: filtering off ["variable-name", "enabled"]
        this.modifiedPrototypeItems = staticPrototypes[DEVICE]
            .map(itemProto => {
                if (itemProto.type === "dynamicSection") {
                    return {
                        ...itemProto,
                        items: [{
                            ...itemProto.items[0],
                            items: itemProto.items[0].items
                                .filter(subItemProto => ["variable-name", "orientation", "enabled", "volume"].indexOf(subItemProto.field) === -1)
                        }],
                        dynamicItems: [{
                            ...itemProto.dynamicItems[0],
                            items: itemProto.dynamicItems[0].items
                                .filter(subItemProto => ["variable-name", "enabled"].indexOf(subItemProto.field) === -1)
                        }]
                    }
                } else {
                    return itemProto;
                }
            });
    }

    onSelect(index, device) {
        ensureDevicesConsistency([device], this.props.t);
        this.setState(prevState => ({
            selectedIndex: index,
            selectedDevice: device
        }));
    }

    onSearchChange(e) {
        const value = e.nativeEvent.target.value;
        this.setState(prevState => ({
            selectedIndex: null,
            selectedDevice: null,
            search: value
        }));
    }

    render() {
        const {show, onClose, allDevices, loading, errors, t} = this.props;
        const parentOnSelect = this.props.onSelect;
        const {selectedIndex, search} = this.state;
        let  {selectedDevice} = this.state;

        const rows = [];
        if (allDevices) {
            const devices = search
                ? allDevices
                    .filter(d =>
                        (d.name && d.name.indexOf(search) >= 0) ||
                        (d.description && d.description.indexOf(search) >= 0) ||
                        (d.functions && d.functions.some(f => f.type && f.type._type && f.type._type.indexOf(search) >= 0)))
                : allDevices;
            // group by type
            const groups = {};
            devices.forEach((d, i) => {
                const distinctNames = [];
                (d.functions || []).forEach(f => {
                    const functionInfo = translateObjOrArray(staticAllFunctionsTypes, t).find(s => s.type === (f.type && f.type._type));
                    const name = functionInfo ? functionInfo.name : t('appEvent.views.solutionViews.ComposeDevicesView.SelectDeviceFromDB.unknown');
                    if (distinctNames.indexOf(name) === -1) {
                        distinctNames.push(name);
                        if (!groups.hasOwnProperty(name)) {
                            groups[name] = [];
                        }
                        groups[name].push(d);
                    }
                });
            });
            Object.keys(groups).slice().sort()
                .forEach(name => {
                    rows.push({
                        name: name,
                        notSelectable: true
                    });
                    groups[name].forEach(d => {
                        const currentIndex = rows.length;
                        rows.push({
                            name: <span className="device-name ms-4">{d.name}</span>,
                            actions: {
                                "select": () => this.onSelect(currentIndex, d)
                            }
                        });
                    });
                });
        }

        // proxy for reading
        const selectedDeviceProxy = selectedDevice && entityUtils.getExistigProxyOrProxificateObj(selectedDevice);

        return (
            <DialogModal key="M" show={show}
                          title={t('appEvent.views.solutionViews.ComposeDevicesView.SelectDeviceFromDB.addDeviceFromDB')}
                          titleComponents= {
                              <InputGroup>
                                  <input type="text" value={search ? search : ""} onChange={this.onSearchChange} />
                                  <InputGroupText>
                                      <span className="input-group-text"><i className="fa fa-search" /></span>
                                  </InputGroupText>
                              </InputGroup>
                          }
                          actions={[
                              {
                                  key: "select", name: t('appEvent.views.solutionViews.ComposeDevicesView.SelectDeviceFromDB.select'),
                                  disabled: !selectedDevice,
                                  action: () => {
                                      parentOnSelect(selectedDevice);
                                  }
                              },
                              {
                                  key: "cancel", name: t('appEvent.views.solutionViews.ComposeDevicesView.SelectDeviceFromDB.cancel'),
                                  action: onClose
                              },
                              {
                                  key: "close",
                                  action: onClose
                              }
                          ]}
                          noPadding>

                <div className="d-flex">
                    <div style={{width: "25%"}} className="scroll">
                        <this.ListWithLoadWaiting loading={loading} errors={errors}
                                                  rows={rows} selectedIndex={selectedIndex} />
                    </div>
                    <div className="flex-grow scroll d-flex border border-dark border-top-0 border-end-0 border-bottom-0 pt-4">
                        {selectedDevice &&
                        <GeneratorBlock className="flex-grow"
                                        disabled
                                        data={selectedDeviceProxy}
                                        items={this.modifiedPrototypeItems}
                                        collectionsDict={{}} />}
                    </div>
                </div>
            </DialogModal>
        );
    }
}

export default withTranslation()(ComposeDevicesView);
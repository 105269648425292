import React, {useEffect, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import List from 'components/blocks/List'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import Error from 'components/fields/elements/Error'
import withUriParams from 'hocs/withUriParams'
import ComposeDevicesView from './ComposeDevicesView'

import {breadcrumbsStore} from 'appBase/TopNav'

import staticPrototypes from 'constants/_@_staticPrototypes'
import {SPACECRAFTS, STATIONS, SPACECRAFT, STATION, DEVICE, FULL_SCREEN} from 'constants/constants'


const ConstellationSolutionItemView = compose(
    withUriParams([
        ['itemLocalId', Number],
        ['itemMode', String]])
)(props => {

    const {itemType, itemLocalId, itemMode, dataSource, field, onChange, onValidate} = props;

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const fromRoot = useCallback((relativeField) => field ? field + "." + relativeField : relativeField, [field]);

    // some calculations

    const arr = dataSource[fromRoot(itemType)];

    const item = arr &&
        arr.find(item => item.localId === itemLocalId);

    // preparing links

    const containerId = SPACECRAFTS + STATIONS;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    if (itemType === SPACECRAFTS) {
        breadcrumbs.push({name: t('appEvent.views.solutionViews.ConstellationSolutionItemView.spacecrafts'), to: `${props.uri}${props.location.search}`, back: 1, state: {[FULL_SCREEN]: true}, nonBlocking: true});
    }
    if (itemType === STATIONS) {
        breadcrumbs.push({name: t('appEvent.views.solutionViews.ConstellationSolutionItemView.stations'), to: `${props.uri}${props.location.search}`, back: 1, state: {[FULL_SCREEN]: true}, nonBlocking: true});
    }
    if (item) {
        breadcrumbs.push({name: item.name, to: `${props.uri}/${itemLocalId}${props.location.search}`, back: 2, state: {[FULL_SCREEN]: true}, nonBlocking: true});
    }

    useEffect(() => {
        const isTerminal = !itemMode;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
    });

    // subnavigation

    if (itemLocalId && !item) {// item not found
        navigate(`${props.uri}${props.location.search}`, {state: {[FULL_SCREEN]: true}});
        return null;
    }

    if (itemMode && itemMode !== DEVICE) {
        navigate(`${props.uri}/${itemLocalId}${props.location.search}`, {state: {[FULL_SCREEN]: true}});
        return null;
    }

    // rendering

    const {disabled, rows, listSizeError} = props;

    const selectedIndex = item && arr.indexOf(item);

    const rootField = fromRoot(`${itemType}.id:${itemLocalId}`);

    const externalSections = [{
        key: "compose",
        name: t('appEvent.views.solutionViews.ConstellationSolutionItemView.devices'),
        className: "tab-icon functions-tab-icon",
        onClick: () => navigate(`${props.uri}/${itemLocalId}/${DEVICE}${props.location.search}`, {state: {[FULL_SCREEN]: true}})
    }];

    const prototypeItemType = {[SPACECRAFTS]: 'spacecraft', [STATIONS]: 'station'}[itemType];
    const devicesContainerType = {[SPACECRAFTS]: SPACECRAFT, [STATIONS]: STATION}[itemType];

    return (
        <div className="flex-grow d-flex flex-row scroll-parent">
            <div className="w-25 scroll border border-dark border-top-0 border-start-0 border-bottom-0">
                <List rows={rows} selectedIndex={selectedIndex} />
                {listSizeError &&  <Error error={listSizeError}/>}
            </div>
            {!itemMode && item &&
            <GeneratorBlock className="scroll w-75"
                            disabled={disabled}
                            data={dataSource}
                            field={rootField}
                            items={staticPrototypes[prototypeItemType]}
                            onChange={onChange}
                            onValidate={onValidate}
                            collectionsDict={{
                                spacecrafts: dataSource[fromRoot("spacecrafts")],
                                //orbits: dataSource[PARAMETERS_FIELD].orbits,
                                //parameters: viewType === ORBIT && {orbitId: itemLocalId}
                            }}
                            externalSections={externalSections} />}
            {itemMode === DEVICE &&
            <ComposeDevicesView className="w-75 scroll"
                                disabled={disabled}
                                containerType={devicesContainerType}
                                dataSource={dataSource}
                                containerRootField={rootField}
                                onChange={onChange} onValidate={onValidate} />}
        </div>);
});

export default ConstellationSolutionItemView;
import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import {licenseTemplateFields, deviceFields} from './serviceQueries'

const CreateRoleUser = gql`
  mutation createRoleUser($data: CreateRoleUserMutationInput!) {
    createRoleUser(data: $data) {
      id
      error
    }
  }
`;


const DeleteRoleUser = gql`
  mutation deleteRoleUser($data: DeleteRoleUserMutationInput!) {
    deleteRoleUser(data: $data) {
      id
      error
    }
  }
`;


const CreateLicenseTemplate = gql`
  mutation createLicenseTemplate($data: CreateLicenseTemplateMutationInput!) {
    createLicenseTemplate(data: $data) {
      id
    }
  }
`;


const UpdateLicenseTemplate = gql`
  mutation updateLicenseTemplate($data: UpdateLicenseTemplateMutationInput!) {
    updateLicenseTemplate(data: $data) {
        licenseTemplate {
          ${licenseTemplateFields}
        }
        error
    }
  }
`;


const DeleteLicenseTemplate = gql`
  mutation deleteLicenseTemplate($id: ID!) {
    deleteLicenseTemplate(id: $id) {
      id
    }
  }
`;


const AddCatalogToLicenseTemplate = gql`
  mutation addCatalogToLicenseTemplate($data: AddCatalogToLicenseTemplateMutationInput!) {
    addCatalogToLicenseTemplate(data: $data) {
        error
    }
  }
`;


const DeleteCatalogFromLicenseTemplate = gql`
  mutation deleteCatalogFromLicenseTemplate($data: DeleteCatalogFromLicenseTemplateMutationInput!) {
    deleteCatalogFromLicenseTemplate(data: $data) {
      error
    }
  }
`;


const CreateLicensesFromLicenseTemplate = gql`
  mutation createLicensesFromLicenseTemplate($data: CreateLicensesFromLicenseTemplateMutationInput!) {
    createLicensesFromLicenseTemplate(data: $data) {
      error
    }
  }
`;


const CreateDevice = gql`
  mutation createDevice($data: CreateDeviceMutationInput!) {
    createDevice(data: $data) {
      id
    }
  }
`;


const UpdateDevice = gql`
  mutation updateDevice($data: UpdateDeviceMutationInput!) {
    updateDevice(data: $data) {
      serviceDevice {
        ${deviceFields}
      }
    }
  }
`;


const DeleteDevice = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      id
    }
  }
`;


const UploadUsers = gql`
  mutation uploadData($data: UploadUsersMutationInput!) {
    uploadUsers(data: $data) {
        result
        infos
        errors
    }
  }
`;


const CreateUser = gql`
  mutation createUser($data: CreateUserMutationInput!) {
    createUser(data: $data) {
      id
      error
    }
  }
`;


const UpdateUser = gql`
  mutation updateUser($data: UpdateUserMutationInput!) {
    updateUser(data: $data) {
      id
      error
    }
  }
`;


const DeleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      error
    }
  }
`;


// user roles
export const createRoleUser = graphql(CreateRoleUser, {
    props: ({ mutate }) => ({ createRoleUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['RolesWithUsersQuery']}
});
export const deleteRoleUser = graphql(DeleteRoleUser, {
    props: ({ mutate }) => ({ deleteRoleUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['RolesWithUsersQuery']}
});

// license templates
export const createLicenseTemplate = graphql(CreateLicenseTemplate, {
    props: ({ mutate }) => ({ createLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});
export const updateLicenseTemplate = graphql(UpdateLicenseTemplate, {
    props: ({ mutate }) => ({ updateLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});
export const deleteLicenseTemplate = graphql(DeleteLicenseTemplate, {
    props: ({ mutate }) => ({ deleteLicenseTemplate: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});
export const addCatalogToLicenseTemplate = graphql(AddCatalogToLicenseTemplate, {
    props: ({ mutate }) => ({ addCatalogToLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});
export const deleteCatalogFromLicenseTemplate = graphql(DeleteCatalogFromLicenseTemplate, {
    props: ({ mutate }) => ({ deleteCatalogFromLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseTemplatesQuery']}
});
export const createLicensesFromLicenseTemplate = graphql(CreateLicensesFromLicenseTemplate, {
    props: ({ mutate }) => ({ createLicensesFromLicenseTemplate: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});

// devices
export const createDevice = graphql(CreateDevice, {
    props: ({ mutate }) => ({ createDevice: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});
export const updateDevice = graphql(UpdateDevice, {
    props: ({ mutate }) => ({ updateDevice: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});
export const deleteDevice = graphql(DeleteDevice, {
    props: ({ mutate }) => ({ deleteDevice: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['ServiceDevicesQuery']}
});

// users
export const uploadUsers = graphql(UploadUsers, {
    props: ({ mutate }) => ({ uploadUsers: (data) => mutate({ variables: { data } }) }),
});
export const createUser = graphql(CreateUser, {
    props: ({ mutate }) => ({ createUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['UsersQuery']}
});
export const updateUser = graphql(UpdateUser, {
    props: ({ mutate }) => ({ updateUser: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['UsersQuery']}
});
export const deleteUser = graphql(DeleteUser, {
    props: ({ mutate }) => ({ deleteUser: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['UsersQuery']}
});
import entityUtils from 'utils/entityUtils'
import calculateExpression from 'constants/expressions'


const prototypeUtils = {

    fieldsInfoArr: [],

    getFieldsInfo(protoItems, rootField) {
        if (!protoItems || !Array.isArray(protoItems)) {
            console.warn(`null/bad protoItems supplied!`);
            return null;
        }

        const processPrototypesRecursive = function(proto, parentField, fieldsInfo) {
            let field = proto.field ? proto.field : "";
            field = parentField + (parentField && field ? "." : "") + field;
            // if choice, take value as "field"
            if (proto.type === "choice" && proto.hasOwnProperty("value")) {
                field += (field && proto.value !== undefined ? "." : "") + proto.value;
            }
            // maybe some info is already there?
            const info = fieldsInfo[field] || {};
            // set type:
            if (["presets", "choices", "enum", "collection", "collectionSelect"].indexOf(proto.type) >= 0) {
                info.type = proto.type;
            }
            // values:
            if (proto.type === "choices" || proto.type === "enum") {
                const stuff = proto.stuff || [];
                if (proto.type === "choices") {
                    // let's put stuff into '_type'
                    if (proto.type === "choices") {
                        const child_proto = proto.items && proto.items.find(i => i.field === '_type');
                        if (!child_proto) {
                            throw Error(`bad protos - 0 items with "_type"!`)
                        }
                        child_proto.stuff = stuff;
                    }
                } else if (proto.type === "enum") {
                    // store in 'values'
                    info.values = stuff.map(item => item.value);
                }
            }
            // presets:
            if (proto.type === "presets" && proto.hasOwnProperty("stuff")) {
                const stuff = proto.stuff ? proto.stuff : [];
                // let's put stuff into '_preset'
                const child_proto = proto.items && proto.items.find(i => i.field === '_preset');
                if (!child_proto) {
                    throw Error(`bad protos - 0 items with "_preset"!`)
                }
                child_proto.stuff = stuff;
                // store in presets
                info.presets = {};
                stuff.forEach(item => {
                    info.presets[item.value !== undefined ? item.value : ""] = item.values || {};
                });
            }
            // collections:
            if ((proto.type === "collection" || proto.type === "collectionSelect") && proto.hasOwnProperty("collection")) {
                info.collection = proto.collection;
            }
            if ((proto.type === "collection" || proto.type === "collectionSelect") && proto.hasOwnProperty("itemValueField")) {
                info.itemValueField = proto.itemValueField;
            }
            // info_fields:
            const defFields = ["defaultValue", "defaultExpression", "expression"];
            defFields
                .filter(df => proto.hasOwnProperty(df))
                .forEach(df => {
                    info[df] = proto[df];
                });
            if (Object.keys(info).length > 0) {
                fieldsInfo[field] = info;
            }
            // recursing
            if (proto.items) {
                proto.items.forEach(item => processPrototypesRecursive(item, field, fieldsInfo));
            }
            if (proto.dynamicItems) {
                const dynamicField = field + (field && proto.sourceField ? "." : "") + proto.sourceField;
                proto.dynamicItems.forEach(item => processPrototypesRecursive(item, dynamicField, fieldsInfo));
            }
        };

        const withRootField = function(fieldsInfo) {
            if (!rootField) {
                return fieldsInfo;
            }
            const newFieldsInfo = {};
            Object.entries(fieldsInfo).forEach(([key, value]) =>
                newFieldsInfo[rootField + (rootField && key ? '.' : '') + key] = value);
            return newFieldsInfo;
        };

        // processing

        const fieldsInfoEntry = prototypeUtils.fieldsInfoArr.find(fi => fi.protoItems === protoItems);
        if (fieldsInfoEntry) {
            return withRootField(fieldsInfoEntry.fieldsInfo);
        }

        const fieldsInfo = {};
        console.log('processing new fieldsInfo for: ' + JSON.stringify(protoItems).substring(0, 100));
        protoItems.forEach(item => processPrototypesRecursive(item, "", fieldsInfo));
        prototypeUtils.fieldsInfoArr.push({protoItems: protoItems, fieldsInfo: fieldsInfo});
        return withRootField(fieldsInfo);
    },

    get(target, prop, fieldsInfo, collectionsDict) {
        if (!target) {
            console.warn('prototypeUtils.get: empty target given!');
            return null;
        }
        let originalTarget = target;

        const getValueOrDefault = (o, f, parentFieldInfoPath, parentObjectPath, valuesField, additionalValues) => {
            valuesField = valuesField || 'values';
            const fFieldInfoPath = parentFieldInfoPath + (parentFieldInfoPath && f ? '.' : '') + f;
            const fObjectPath = parentObjectPath + (parentObjectPath && f ? '.' : '') + f;
            const fFieldInfo = fieldsInfo[fFieldInfoPath] || {};
            const expr = fFieldInfo.expression;
            let v = expr
                ? calculateExpression(expr, fObjectPath, originalTarget, fieldsInfo, collectionsDict)
                : o[f];
            let vals = fFieldInfo[valuesField];
            if (!vals) {
                vals = additionalValues;
            } else if (additionalValues) {
                vals = vals.concat(additionalValues);
            }
            // choice has bad value? try take default:
            if ((vals && vals.indexOf(v) === -1) ||
                    (!vals && (v === null || v === undefined || v === ''))) {
                if (vals && vals.indexOf(v) === -1) {
                    console.warn(`   value ${v} at ${fFieldInfoPath} not in 'values'! Taking default...`);
                }
                v = null;
                if (fFieldInfo.hasOwnProperty('defaultValue') || fFieldInfo.hasOwnProperty('defaultExpression')) {
                    if (fFieldInfo.hasOwnProperty('defaultValue')) {
                        v = fFieldInfo.defaultValue;
                    } else if (fFieldInfo.hasOwnProperty('defaultExpression')) {
                        v = calculateExpression(fFieldInfo.defaultExpression, fObjectPath, originalTarget, fieldsInfo, collectionsDict)
                    }
                    if (vals && vals.indexOf(v) === -1) {
                        console.warn(`   default value ${v} for ${fFieldInfoPath} not in values! Taking 'None'.`);
                        v = null;
                    }
                // } else {
                //     console.warn(`   default for enum ${fFieldInfoPath} does not exist! Taking 'None'.`);
                }
            }
            return v;
        };

        let arr = typeof(prop) === 'string' ? prop.split('.') : String(prop) ? [String(prop)] : [];
        let currentFieldInfoPath = "";
        let currentObjectPath = "";
        let currentPathInfo;
        let currentPathInfoType;
        // running
        for (let i = 0; i < arr.length; i++) {
            let part = arr[i];
            let idPart;

            // evaluate result - taking next object corresponding to 'part'
            // 'target' is a previous object, 'currentFieldInfoPath' previous path without 'part'.

            // skip empty paths, they match everything. any meaningful preset or choice should have non-empty "field"
            currentPathInfo = currentFieldInfoPath && fieldsInfo[currentFieldInfoPath];
            currentPathInfoType = currentPathInfo && currentPathInfo.type;
            // need to advance it one step forward

            // we have 'part' pointing to next object for taking next step:
            if (currentPathInfoType === "choices") {
                // like 'environment.type.|near-earth|', we are at |near-earth| now
                const typeValue = getValueOrDefault(target, '_type', currentFieldInfoPath, currentObjectPath);
                if (part === typeValue) {
                    // some property of some choice requested. descending into that choice
                    target =
                        target && target.hasOwnProperty(part)
                            ? target[part]
                            : i < arr.length - 1 ? {} : null;
                } else if (part === '_type') {
                    target = typeValue;
                } else {
                    // should stop and return None (no defaults or expressions)
                    return null;
                }
            } else if (currentPathInfo && currentPathInfo.type === "presets") {
                const presetValue = getValueOrDefault(target, '_preset', currentFieldInfoPath, currentObjectPath, 'presets', ['_user']);
                const presets = currentPathInfo.presets;
                let values;
                if (presetValue === '_user' || !presetValue) {
                    values = target;
                } else {
                    values = presets.find(p => p.value === presetValue);
                }
                target =
                    values && values.hasOwnProperty(part)
                        ? values[part]
                        : i < arr.length - 1 ? {} : null;
            } else {
                // normal field
                // undefined regular sections of configuration: if null but not leaf node we should return {}
                target = getValueOrDefault(target, part, currentFieldInfoPath, currentObjectPath);
                if ((target === null || target === undefined) && i < arr.length - 1) {
                    target = {};
                }
            }

            // 'obj' was advanced forward
            // update current_field_info_path to match 'target'
            currentFieldInfoPath += (currentFieldInfoPath ? "." : "") + part;
            currentPathInfo = currentFieldInfoPath && fieldsInfo[currentFieldInfoPath];
            currentPathInfoType = currentPathInfo && currentPathInfo.type;

            // we have 'part' pointing to current object for additional processing
            if (currentPathInfoType === 'collection') {
                target = Array.isArray(target) ? target : [];
                // 'part' has name of collection
                const collection = currentPathInfo.collection;
                if (collection !== part) {
                    console.warn(`part ${part} not equal to 'collection' ${collection}`)
                }
                let collectionItems = collectionsDict[collection];
                if (!collectionItems) {
                    console.warn(`CollectionItems is absent! ${part}`);
                    collectionItems = target;
                }
                if (i < arr.length - 1) {
                    // some item requested
                    idPart = arr[i + 1];
                    idPart = entityUtils.isNumericPart(idPart) ? idPart : null;
                    if (!idPart) {
                        console.warn(`idPart ${idPart} is bad!`);
                        return null;
                    }
                    const number = Number(idPart.substring(idPart.indexOf(':') + 1));
                    // special select from array of objects
                    if (idPart.startsWith("id:")) {
                        target = target.find(x => x.localId === number);
                        if (!target) {
                            const colItem = collectionItems.find(x => x.localId === number);
                            if (colItem) {
                                target = {localId: colItem.localId}
                            } else {
                                return null;
                            }
                        }
                    } else if (idPart.startsWith("index:")) {
                        throw new Error(`not allowed 'index:' in type='collection'! ${idPart}`);
                    }
                    i += 1;
                } else {
                    // all items requested
                    const newTarget = [];//eslint-disable-next-line
                    collectionItems.forEach(item => {
                        const targetItem = target.find(x => x.localId === item.localId);
                        newTarget.push(targetItem ? targetItem : {localId: item.localId});
                    });
                    target = newTarget;
                }
            } else if (Array.isArray(target)) {
                // simple array
                if (i < arr.length - 1) {
                    idPart = arr[i + 1];
                    idPart = entityUtils.isNumericPart(idPart) ? idPart : null;
                    if (!idPart) {
                        console.warn(`got array without numeric part for ${part} in ${prop}!`);
                    } else {
                        const number = Number(idPart.substring(idPart.indexOf(':') + 1));
                        // special select from array of objects
                        if (idPart.startsWith("id:")) {
                            target = target.find(x => x.localId === number);
                        } else if (idPart.startsWith("index:")) {
                            target = target[number];
                        }
                        i += 1;
                    }
                }
            }

            // update currentObjectPath (recreate it back)
            // we do it here because only here we have processed id_part (if any)
            currentObjectPath += (currentObjectPath ? "." : "") + part + (idPart ? '.' + idPart : '');
        }

        // leaf
        if (currentPathInfoType === 'collectionSelect' && target !== null && target !== undefined) {
            const collection = currentPathInfo.collection;
            let collectionItems = collectionsDict[collection] || [];
            let matchingItem;
            if (currentPathInfo.itemValueField === '$index') {
                matchingItem = collectionItems[target];
            } else {
                matchingItem = collectionItems.find(i => i.localId === target);
            }
            if (!matchingItem) {
                console.warn(`   collectionSelect value ${target} at ${currentObjectPath} does not match any item for collection ${collection}!`);
                target = null;
            }
        }

        return target;
    },


    discardFieldsFromPrototype(protoItems, fieldsToDiscard) {
        return JSON.parse(JSON.stringify(protoItems)).filter(item => !fieldsToDiscard.includes(item.field));
    }
};

export default prototypeUtils;
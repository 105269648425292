import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Nav} from 'reactstrap'
import DownloadLink from 'react-download-link'
import useSmartNavigate from 'hooks/useSmartNavigate'

import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import catalogPrototypes from '../data/catalogPrototypes'
import {queryCatalog} from '../data/catalogQueries'
import {updateCatalog, exportCatalog, importCatalog} from '../data/catalogMutations'

import {breadcrumbsStore} from 'appBase/TopNav'

import entityUtils from 'utils/entityUtils'
import dateUtils from 'utils/dateUtils'

import FileUpload from 'appAdmin/views/components/FileUpload'
import {CATALOG_VIEW, DB_ROLE_NAME_SUPERADMIN} from 'constants/constants'

const CatalogView = compose(
    withUriParams([
        ['mode', String]]),
    queryCatalog,
    updateCatalog,
    exportCatalog,
    importCatalog,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['catalog'])
)(props => {

    const {catalogId, catalog, mode, user, setDataSource, updateCatalog, exportCatalog, importCatalog, loading} = props;
    const [isExporting, setIsExporting] = useState(false);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // set data source

    useEffect(() => {
        setDataSource(
            catalog,
            (data) =>
                updateCatalog(entityUtils.filterFields(
                    data,
                    ['id', 'isArchived', 'nameMl', 'descriptionMl'])));
    }, [catalog, setDataSource, updateCatalog]);

    // links

    const containerId = CATALOG_VIEW;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    if (catalog) {
        breadcrumbs.push({name: t('appCatalog.section') + catalog.name, to: `${props.uri}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
    });

    // subnavigation

    if (catalogId && !catalog && !loading.catalog) {
        navigate(`${props.uri}/..`);
        return null;
    }

    // rendering

    const {
        dataSource, saveAndReset, onChange, onValidate, isChanged, hasErrors } = props;

    return (
        <>
        <SaveButtonsBlock withEditMode
                          isChanged={isChanged}
                          hasErrors={hasErrors}
                          onSave={mode === 'edit' && (() => {
                              saveAndReset();
                              navigate(`${props.uri}${props.location.search}`);
                          })}
                          onCancel={mode === 'edit' && (() => {
                              setDataSource(null);
                              navigate(`${props.uri}${props.location.search}`);
                          })}
                          actions={[mode !== 'edit' && {
                              name: t('appCatalog.edit'),
                              allowOnErrors: true,
                              action: () => navigate(`${props.uri}/edit${props.location.search}`)}]} />
        <div className="flex-grow d-flex scroll">
            {dataSource &&
            <div className="workpanel-gray flex-grow">
                <GeneratorBlock data={dataSource}
                                items={catalogPrototypes.catalog}
                                onChange={onChange} onValidate={onValidate}
                                collectionsDict={{}}
                                disabled={mode !== 'edit'} />

                        {user && user.id && user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN) &&
                        <div className="mt-3">
                            {!isExporting &&
                            <Nav>
                                <DownloadLink label={t('appCatalog.views.CatalogView.export')} filename={`${dataSource.name}-${dateUtils.dateToFilenameDateString(new Date())}.json`}
                                              className="nav-link" style={{}}
                                              exportFile={() => {
                                                  setIsExporting(true);
                                                  return exportCatalog(catalogId).then(({data: {exportCatalog: {json}}}) => {setIsExporting(false); return json;})
                                              }}/>
                            </Nav>}

                            {isExporting && <>{t('appCatalog.views.CatalogView.archive')}</>}
                            <FileUpload name={t('appCatalog.views.CatalogView.import')} noReplaceCurrentData
                                        uploadConfirmation={{header: t('appCatalog.views.CatalogView.import2'), text: t('appCatalog.views.CatalogView.importConfirmation')}}
                                        extraMutationData={[{"field": "catalogId", "value": catalogId}]} validExtensionsList={["json"]}
                                        uploadMutation={(data) => importCatalog(data)} mutationResultsName="importCatalog" />
                        </div>}
            </div>}
        </div>
        </>);
});

export default CatalogView;
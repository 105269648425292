import React, {Component} from 'react';

import List from "components/blocks/List";
import withSort from "components/blocks/withSort";

class ListWithSort extends Component {
    constructor(props) {
        super(props);
        this.ListSort = withSort(List);
    }

    render() {
        const ListSort =  this.ListSort;
        return (<ListSort {...this.props} />);
    }

}
export default ListWithSort;
import React from 'react'
import {Col} from 'reactstrap'

import LabelWithInfo from './elements/LabelWithInfo'
import NumberInput from './elements/NumberInput'
import Error from './elements/Error'

function DoubleArrayVal(props) {

    //the length must come separately, because. data can be partially initialized
    const {isNoInput, wide, name, unit, info, infoImage, disabled, data, field, size, error} = props;

    // copy of data
    const arr = data[field] && Array.isArray(data[field]) ? data[field].slice() : [];

    const onChange = (i, e) => {
        arr[i] = e.nativeEvent.target.value;
        // converting back
        let isAllNull = true;
        for (let i=0; i < size; i++) {
            arr[i] = arr[i] !== "" ? Number(arr[i]) : null;
            if (arr[i] !== null) {
                isAllNull = false;
            }
        }
        // saving
        if (isAllNull) {
            props.onChange(data, field, null);
        } else {
            props.onChange(data, field, arr);
        }
    };

    for (let i=0; i < size; i++) {
        arr[i] = (arr[i] || arr[i] === 0) ? String(arr[i]) : "";
    }

    return (
        <div className="form-group row g-0">
            <Col sm={wide ? 2 : 3}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
            </Col>
            <Col sm={wide ? 10 : 9}>
                <div className="g-0 parameters-multibox d-flex flex-row">
                    {arr.map( (v, i) =>
                        <NumberInput key={i} className="form-control flex-shrink-grow"
                                     value={!isNoInput ? v : ""}
                                     placeholder={isNoInput ? v : ""}
                                     disabled={disabled} onChange={onChange.bind(null, i)} />
                    )}
                </div>
                {error &&  <Error id={field} error={error}/>}
            </Col>
        </div>);
}
export default DoubleArrayVal;
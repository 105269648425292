import React from 'react'
import {useTranslation} from 'react-i18next'
import {Col} from 'reactstrap'
import FileSaver from 'file-saver'

import Error from './elements/Error'
import LabelWithInfo from './elements/LabelWithInfo'

function FileUploadVal(props) {
    const {noLabel, data, field, name, unit, info, infoImage, error, wide, disabled, allowUpload} = props;
    const value = data && field && data[field];
    const {t} = useTranslation();

    const onChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const matches = /data:(.+);base64,(.+)/.exec(reader.result);
                props.onChange(data, field, {name: file.name, type: matches[1], contents: matches[2]});
            };
            reader.readAsDataURL(file);
        }
    };

    const download = () => {
        fetch("data:" + value.type + ";base64," + value.contents)
            .then(res => res.blob())
            .then(res => FileSaver.saveAs(res, value.name));
    };

    const drop = () => {
        props.onChange(data, field, null);
    };

    let content;
    if (value) { //1-4 cases from 8 (3 variables: disabled, allowUpload, value)
        content =
            <span>
                {value.type.substring(0,5) !== "image" &&
                <span className="controlelement" onClick={download}>{value.name}</span>}

                {value.type.substring(0,5) === "image" &&
                <img style={{maxWidth: "100px", maxHeight: "100px"}} src={`data:${value.type};base64,${value.contents}`} onClick={download} alt="Preview" />}

                {allowUpload && !disabled &&
                <i className="fa fa-times" onClick={drop} style={{"cursor": "pointer", "float": "right"}}/>}
            </span>;
    } else if (disabled || !allowUpload) { //5-7 cases
        content = <span className="text-muted">{t('components.fields.FileVal.notUploaded')}</span>;
    } else { //8 case: !disabled && allowUpload
        content =
            <label className="py-1 px-2 form-label btn btn-primary">
                {t('components.fields.FileVal.upload')}
                <input className="d-none" type="file" onChange={onChange} />
            </label>;
    }

    if (noLabel) {
        return (
            // padding accounts for 2-column gap
            <Col sm={6} className="form-group row g-0 pe-2">
                {content}
                {error &&
                <Error id={field} error={error}/>}
            </Col>);
    } else {
        return (
            <Col sm={6} className="form-group row g-0">
                <Col sm={wide ? 4 : 6}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                {/* padding accounts for 2-column gap */}
                <Col sm={wide ? 8 : 6} className="pe-2">
                    {content}
                    {error &&
                    <Error id={field} error={error}/>}
                </Col>
            </Col>);
    }
}

export default FileUploadVal;
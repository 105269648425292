import React from 'react';
import {NavLink} from "reactstrap";
import useSmartNavigate from 'hooks/useSmartNavigate'


const Link = props => {
    const {to, state, children} = props;
    const navigate = useSmartNavigate();

    const onClick = (e) => {
        e.preventDefault();
        navigate(to, {state: state});
        return false;
    };
    return (
        <NavLink href={to} onClick={onClick}>{children}</NavLink>);
};

export default Link;
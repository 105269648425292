/**
 * Created by Yuri on 03.08.2020
 * Part of frontend
 */
import React from 'react';


export default function withLocationStateParam(statePropName, propName) {
    return function withLocationStateParam(WrappedComponent) {
        class WithLocationStateParam extends React.Component {
            render() {
                const value = this.props.location.state && this.props.location.state[statePropName];

                // console.log(`#withLocationState: ${propName}: ${value}`);

                const newProps = {...this.props, [propName]: value};
                return <WrappedComponent {...newProps} />;
            }
        }

        WithLocationStateParam.displayName = `WithLocationStateParam(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
        return WithLocationStateParam;
    }
};
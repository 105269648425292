import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import FileUpload from 'appAdmin/views/components/FileUpload'
import {breadcrumbsStore} from 'appBase/TopNav'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryLicenses} from 'appLicense/data/licenseQueries'
import {uploadUsers} from '../data/serviceMutations'

import {SERVICE_REGISTRATION_VIEW} from 'constants/constants'

const RegistrationView = compose(
     queryLicenses,
     uploadUsers,
     withInitialDataLoadWaiting(['licenses'])
)((props) => {
    const {licenses, uploadUsers} = props;
    const {t} = useTranslation();
    const [licenseId, setLicenseId] = useState(0);

    // preparing links
    breadcrumbsStore.register(SERVICE_REGISTRATION_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_REGISTRATION_VIEW, {name: t('appService.registration'), to: `${props.uri}`}, true);
    });

    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">

                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.registration')}</h5>
                        <div className="container">
                            <div className="text-muted">
                                {t('appService.views.RegistrationView.chooseLicense')}
                            </div>

                            <select value={licenseId} onChange={(e) => setLicenseId(Number(e.nativeEvent.target.value))}>
                                <option value="0">
                                    {t('appService.views.RegistrationView.notSelected')}
                                </option>
                                {licenses.map(l =>
                                    (<option key={l.id} value={l.id}>
                                        {l.name}
                                     </option>))}
                            </select>

                            <div className="text-muted">
                                {t('appService.views.RegistrationView.fileFormat')}
                            </div>

                            <FileUpload name={t('appService.views.RegistrationView.import')}
                                        uploadMutation={(data) => uploadUsers({licenseId: licenseId, ...data})}
                                        mutationResultsName="uploadUsers"
                                        validExtensionsList={["xlsx"]}
                                        disabled={!licenseId} noReplaceCurrentData />

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
});

export default RegistrationView;
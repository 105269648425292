import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col} from 'reactstrap'
import {Alert} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from '../fields/elements/LabelWithInfo'

import {calculateParamsFromLiveData} from 'utils/parametersUtils'
import {translateObjOrArray} from 'utils/translationUtils'
import {staticResultsHierarchy} from 'constants/staticReferenceData'


class ResultsSeriesEditor extends Component {

    // TODO: Migrate to ChartVal.toSeries
    static toChoices(series, root, collectionsDict, t) {
        // == fields: ==
        // field_name
        // spacecrafts.id:1.field_name
        // spacecrafts.*.field_name
        // spacecrafts.id:1.devices.id:1.field_name
        // spacecrafts.*.devices.*.functions.id:1.field_name
        // spacecrafts.*.devices.*.functions.type:accumulator.field_name
        // spacecrafts.*.devices.*.functions.*.field_name
        if (!series || (root && !series.startsWith(root))) {
            return [];
        }
        let arr = series.split('.');
        let hierItems = translateObjOrArray(staticResultsHierarchy, t);
        let collection = {};
        const choices = [];
        let i = 0;
        while (i < arr.length) {
            let part = arr[i];
            const hierItem =
                hierItems.find(hi => hi.field === part) ||
                (i === arr.length - 1 && arr[i] !== '_empty' && hierItems.find(hi => hi.field === ""));
            if (!hierItem) {
                break;
            }
            if (hierItem.fields) {
                if (hierItems.length > 1) {
                    choices.push(hierItem.field);
                }
                let fieldName;
                if (part === hierItem.field && i < arr.length - 1) {
                    fieldName = arr[i + 1];
                } else {
                    fieldName = part;
                }
                const fieldItem = hierItem.fields.find(f => f.name === fieldName);
                if (fieldItem) {
                    if (hierItem.groups) {
                        const groupItem = hierItem.groups.find(g => g.group === fieldItem.group);
                        if (groupItem) {
                            choices.push(groupItem.group);
                        }
                    }
                    choices.push(fieldName);
                }
                break;
            } else if (hierItem.items) {
                let subCollection;
                if (hierItem.collection) {
                    subCollection = collectionsDict[hierItem.collection];
                } else if (collection) {
                    subCollection = collection[part] || [];
                }
                // check if exists (only if concrete collection)
                if (collection && (!subCollection || subCollection.length === 0)) {
                    break;
                }
                collection = subCollection;
                // index part:
                if (i < arr.length - 1) {
                    const indexPart = arr[i + 1];
                    if (hierItem.index === "index" && indexPart.startsWith('index:') && isFinite(indexPart.substring(indexPart.indexOf(':') + 1))) {
                        const number = Number(indexPart.substring(indexPart.indexOf(':') + 1));
                        // if not exists concrete collection, no possible to take specific index
                        // if not exists specific index, no possible to take it
                        if (!collection || !collection[number]) {
                            break;
                        }
                        collection = collection[number];
                    } else if (hierItem.index === "id" && indexPart.startsWith('id:') && isFinite(indexPart.substring(indexPart.indexOf(':') + 1))) {
                        const number = Number(indexPart.substring(indexPart.indexOf(':') + 1));
                        // if not exists concrete collection, no possible to take specific id
                        // if not exists specific id, no possible to take it
                        if (!collection || !collection.find(x => x.localId === number)) {
                            break;
                        }
                        collection = collection.find(x => x.localId === number);
                    } else if (hierItem.types && indexPart.startsWith('type:')) {
                        const type = indexPart.substring(indexPart.indexOf(':') + 1);
                        const typeItem = hierItem.types.find(t => t.type === type);
                        if (!typeItem) {
                            break;
                        }
                        // if not exists concrete collection, possible to take everything
                        // if exists specific collection, but no such types, no possible to take them
                        if (collection && !collection.some(x => hierItem.typeFieldType === 'typeType' ? x.type._type === type : x._type === type)) {
                            break;
                        }
                        collection = null;
                    } else if (indexPart === '*') {
                        collection = null;
                    } else {
                        throw new Error(`Bad results hierarchy found: ${indexPart} for ${series} with index: ${hierItem.index}`);
                    }
                    choices.push(part + '.' + indexPart);
                    // next
                    hierItems = hierItem.items;
                    i += 2;
                }
            } else {
                throw new Error(`Bad results hierarchy found: ${part} for ${series}`);
            }
        }
        return choices;
    }


    static toSeries(choices) {
        // no series?
        if (choices.length === 0) {
            return null;
        }
        // excluding group (like in ["spacecrafts.*", "lin", "Linear Velocity"])
        if (choices.length > 1 && choices[choices.length - 2] && choices[choices.length - 2].indexOf('.') === -1) {
            choices = choices.slice();
            choices.splice(choices.length - 2, 1);
        }
        return choices
            .filter(o => o)
            .join('.');
    }


    static getNewStateFromProps(props) {
        // values are passed by reference, we need to update them
        if (!props.values) {
            throw new Error('values need to be passed by reference to editor!');
        }
        if (!props.values.parameters) {
            props.values.parameters = {};
        }
        const {field, data, collectionsDict} = props;
        const {x, y} = props.values.parameters;
        // root, alert
        let {root} = props.values.parameters;
        let infoAlertData = null;
        const parameters = calculateParamsFromLiveData(props.parameters, field, data, props.fieldsInfo, collectionsDict);
        if (root === "spacecrafts.id") {
            root += ':' + (parameters.spacecraftId || -1);
            if (!parameters.spacecraftId) {
                infoAlertData = {
                    type: "info",
                    info: "No spacecraft selected.",
                };
            }
        } else if (root === "stations.id") {
            root += ':' + (parameters.stationId || -1);
            if (!parameters.stationId) {
                infoAlertData = {
                    type: "info",
                    info: "No station selected.",
                };
            }
        }

        return {
            root: root,
            xChoices: ResultsSeriesEditor.toChoices(x, root, collectionsDict, props.t),
            yChoicesArr: y ? y.map(y => ResultsSeriesEditor.toChoices(y, root, collectionsDict, props.t)) : [],
            infoAlertData: infoAlertData
        };
    }


    constructor(props) {
        super(props);
        this.state = ResultsSeriesEditor.getNewStateFromProps(props);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.values !== this.props.values) {
            this.setState((prevState) => ResultsSeriesEditor.getNewStateFromProps(nextProps));
        }
    }

    onChange() {
        const {xChoices, yChoicesArr} = this.state;
        this.props.values.parameters.x = ResultsSeriesEditor.toSeries(xChoices);
        this.props.values.parameters.y = yChoicesArr.map(yChoices => ResultsSeriesEditor.toSeries(yChoices));
        this.forceUpdate();
    }

    onRemove(choices) {
        const {yChoicesArr} = this.state;
        const ind = yChoicesArr.indexOf(choices);
        if (ind >= 0) {
            yChoicesArr.splice(ind, 1);
        }
        this.onChange();
    }

    onCreate(choices) {
        const {yChoicesArr} = this.state;
        yChoicesArr.push([]);
        this.onChange();
    }

    render() {
        const {root, xChoices, yChoicesArr, infoAlertData} = this.state;
        const {collectionsDict, t} = this.props;

        const matchRoot = (parent, field) => {
            field = parent + (parent && field ? '.' : '') + field;
            return !root || field.startsWith(root) || (field && root.startsWith(field));
        };

        // TODO: Migrate to ChartVal.toSeries
        const getRowElements = (choices, persistentEmptyValueName, hasRemove, singleColumnOnly) => {
            const EMPTY_CONST = '_empty';
            if (choices.length === 0) {
                choices.push(EMPTY_CONST);
            }
            let hierItems = translateObjOrArray(staticResultsHierarchy, t);
            let collection = {};
            let selectedGroupType = null;
            let accumulatedField = '';
            let i = 0;
            const selectOptionsArr = [];
            let isLastOfSelects = false;
            while (i < choices.length) {
                /*eslint no-loop-func: 0*/
                // correcting choices[i], valid values: "", "_empty", real values
                if (choices[i] === undefined || choices[i] === null) {
                    choices[i] = EMPTY_CONST;
                }
                let choice = choices[i];
                // construct
                // terminating one?
                if (hierItems.length === 1 && hierItems[0].fields) {
                    const hierItem = hierItems[0];
                    // has groups?
                    if (hierItem.groups) {
                        selectOptionsArr.push(hierItem.groups.map(g => ({value: g.group, name: g.name})));
                        const groupItem = hierItem.groups.find(g => g.group === choice);
                        if (groupItem) {
                            selectOptionsArr.push(hierItem.fields
                                .filter(f => f.group === choice)
                                .map(f => ({value: f.name, name: f.name})));
                            // should add new empty select?
                            if (i === choices.length - 1) {
                                choices.push(EMPTY_CONST);
                            }
                            isLastOfSelects = true;
                        }
                    } else if (hierItem.typed) {
                        selectOptionsArr.push(hierItem.fields
                            .filter(f => f.type === selectedGroupType || !f.type)
                            .map(f => ({value: f.name, name: f.name})));
                        isLastOfSelects = true;
                    } else {
                        selectOptionsArr.push(hierItem.fields.map(f => ({value: f.name, name: f.name})));
                        isLastOfSelects = true;
                    }
                    break;
                }
                // multiple choices
                const selectOptions = [];
                selectOptionsArr.push(selectOptions);
                const availableNextChoicesWithData = [];
                hierItems
                    .filter(hierItem => matchRoot(accumulatedField, hierItem.field))
                    .forEach(hierItem => {
                        if (hierItem.fields) {
                            selectOptions.push({value: hierItem.field, name: hierItem.name});
                            availableNextChoicesWithData.push({choice: hierItem.field, hierItem: hierItem});
                        } else if (hierItem.items) {
                            let subCollection;
                            if (hierItem.collection) {
                                subCollection = collectionsDict[hierItem.collection];
                            } else if (collection) {
                                subCollection = collection[hierItem.field] || [];
                            }
                            // check if exists (only if concrete collection)
                            if (collection && (!subCollection || subCollection.length === 0)) {
                                return;
                            }
                            // add options
                            if (subCollection) {
                                subCollection.forEach((x, i) => {
                                    let field;
                                    if (hierItem.index === "index") {
                                        field = `${hierItem.field}.index:${i}`;
                                    } else if (hierItem.index === "id") {
                                        field = `${hierItem.field}.id:${x.localId}`;
                                    } else {
                                        throw new Error(`Bad results hierarchy found: ${choice} for ${choices} with index: ${hierItem.index}`);
                                    }
                                    if (matchRoot(accumulatedField, field)) {
                                        selectOptions.push({value: field, name: hierItem.nameField ? x[hierItem.nameField] : x.name});
                                        availableNextChoicesWithData.push({choice: field, type: hierItem.types ? (hierItem.typeFieldType === 'typeType' ? x.type._type : x._type) : null, collection: x, hierItem: hierItem});
                                    }
                                });
                            }
                            if (hierItem.types && !singleColumnOnly) {
                                hierItem.types
                                    .filter(typeItem => !subCollection || subCollection.some(x => hierItem.typeFieldType === 'typeType' ? x.type._type === typeItem.type : x._type === typeItem.type))
                                    .forEach(typeItem => {
                                        const field = `${hierItem.field}.type:${typeItem.type}`;
                                        if (matchRoot(accumulatedField, field)) {
                                            selectOptions.push({value: field, name: typeItem.plural});
                                            availableNextChoicesWithData.push({choice: field, type: typeItem.type, hierItem: hierItem});
                                        }
                                    });
                            }
                            if (!singleColumnOnly) {
                                const field = `${hierItem.field}.*`;
                                if (matchRoot(accumulatedField, field)) {
                                    selectOptions.push({value: field, name: hierItem.plural});
                                    availableNextChoicesWithData.push({choice: field, hierItem: hierItem});
                                }
                            }
                        } else {
                            throw new Error(`Bad results hierarchy found: ${choice} for ${choices}: ${hierItem}`);
                        }
                    });
                // re-choose option?
                // excluding special case - when we add persistent 1st empty value
                if (availableNextChoicesWithData.length === 1 && !(i === 0 && persistentEmptyValueName)) {
                    choices[i] = availableNextChoicesWithData[0].choice;
                    choice = choices[i];
                }
                // next step ?
                const nextChoiceWithData = availableNextChoicesWithData.find(x => x.choice === choice);
                if (!nextChoiceWithData) {
                    if (choice !== EMPTY_CONST) {
                        console.warn(`Unsynchronized algorithm? choice ${choice} for ${choices} not found in options!`);
                    }
                    break;
                }
                collection = nextChoiceWithData.collection;
                selectedGroupType = nextChoiceWithData.type;
                hierItems = nextChoiceWithData.hierItem && nextChoiceWithData.hierItem.items
                    ? nextChoiceWithData.hierItem.items
                    : [nextChoiceWithData.hierItem];
                // should add new empty select?
                if (i === choices.length - 1) {
                    choices.push(EMPTY_CONST);
                }
                accumulatedField += (accumulatedField && choice ? '.' : '') + choice;
                i += 1;
            }
            // selects:
            const closeButton =
                <span className={classnames("controlelement ps-2", !hasRemove && "invisible")} style={{marginLeft: 'auto'}}
                      onClick={() => hasRemove && this.onRemove(choices)}>
                    <i className="fa fa-times"/>
                </span>;
            const cols = selectOptionsArr.map((so, i) => {
                const colWidth = isLastOfSelects && i === selectOptionsArr.length - 1 ? '40%' : '20%';
                return (
                    <Col key={i} className="d-flex" style={{flex: `0 0 ${colWidth}`, maxWidth: colWidth}}>
                        {so &&
                        <select className="form-control flex-shrink-grow"
                                value={choices[i]}
                                onChange={(e) => {
                                    const value = e.nativeEvent.target.value;
                                    // this eliminates all '_empty' from the choices
                                    choices.splice(i, choices.length - i);
                                    choices[i] = value;
                                    this.onChange();
                                }}>
                            <option disabled={i === 0 && persistentEmptyValueName ? undefined : true}
                                    value={EMPTY_CONST}
                                    style={i === 0 && persistentEmptyValueName ? undefined : {display:'none'}}>
                                {(i === 0 && persistentEmptyValueName) || ""}
                            </option>
                            {so.map((o, j) =>
                                <option key={j} value={o.value}>{o.name}</option>)}
                        </select>}
                        {(i === 3 || (isLastOfSelects && i === selectOptionsArr.length - 1)) && closeButton}
                    </Col>);});
            if (isLastOfSelects) {
                while (cols.length < 4) {
                    cols.splice(cols.length - 1, 0, <Col key={cols.length} className="d-flex"
                                                         style={{flex: '0 0 20%', maxWidth: '20%'}}/>)
                }
            } else {
                while (cols.length < 4) {
                    const colWidth = cols.length === 3 ? '40%' : '20%';
                    cols.push(
                        <Col key={cols.length} className="d-flex"
                                   style={{flex: `0 0 ${colWidth}`, maxWidth: colWidth}}>
                            {cols.length === 3 && closeButton}
                        </Col>)
                }
            }
            return (
                <Row className="form-group">
                    {cols}
                </Row>);
        };

        const infoAlert = infoAlertData &&
            <Alert className="mb-0" color={infoAlertData.type}>{infoAlertData.info}</Alert>;

        const xRowComponent = getRowElements(xChoices, "Time", false, true);
        const yRowComponents =
            React.Children.map(
                yChoicesArr.map(yChoices => getRowElements(yChoices, null, true, false)),
                c => React.cloneElement(c));
        return (
            // <React.Fragment>
            <div>
                {infoAlert && infoAlert}
                <LabelWithInfo className="w-100" name={"X Axis"} />
                {xRowComponent}
                <LabelWithInfo className="w-100" name={"Y Axis"} />
                {yRowComponents}
                <div>
                    <span className="float-end controlelement" onClick={this.onCreate}>
                        + New parameter
                    </span>
                </div>
            </div>
            // </React.Fragment>
        );
    }
}


export default withTranslation()(ResultsSeriesEditor);
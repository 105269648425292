import React from 'react';
import {NavLink} from "reactstrap";

export const OldLinkWithoutNavigation = props => {
    const {onClick, executeWithLock, children} = props;
    const handleClick = (e) => {
        e.preventDefault();
        if (executeWithLock) {
            executeWithLock(onClick);
        } else {
            onClick();
        }
        return false;
    };
    return (
        <NavLink href="/" onClick={handleClick}>{children}</NavLink>);
};

export default OldLinkWithoutNavigation;
import React from 'react';


export const faIcon = (faName, className) => {
    return (<i className={`fa ${faName} ${className || ''}`} aria-hidden="true" />);
};


const library = [
    { values: ['finished', 'ok', 'success', 'yes'], icon: faIcon('fa-check', 'text-success') },
    { values: ['warn', 'warning', undefined, null], icon: faIcon('fa-exclamation-triangle', 'text-warning') },
    { values: ['failed', 'error', 'danger'], icon: faIcon('fa-exclamation-circle', 'text-danger') },
    { values: ['new', 'active'], icon: faIcon('fa-bolt', 'text-warning') },
    { values: ['skipped', 'old', 'lock', 'locked', 'no-change', 'noChange', 'no'], icon: faIcon('fa-lock', 'text-warning') },
    { values: ['not-exists', 'notExists', 'not-found', 'notFound', 'blocked'], icon: faIcon('fa-ban', 'text-danger') },
    { values: ['running', 'waiting', 'in-process', 'inProcess', 'in-progress', 'inProgress'], icon: faIcon('ellipsis-h', 'text-info') },
    { values: ['unknown', 'question'], icon: faIcon('question', 'text-warning') }
];


export const coloredIcon = (type) => {
    const e = library.find(e => e.values.includes(type));
    if (!e) {
        throw new Error('icon: Unknown type ' + type);
    }
    return e.icon;
};


export const toArrayWithLineBreaks = (f, optionalTag, optionalClassName) => {
    const arr =
        typeof(f) === 'string' ? String(f).split("\n") :
        f !== null && f !== undefined ? [f] :
        [];

    if (arr.length <= 1) {
        return arr;
    }

    const tag = optionalTag ? optionalTag : optionalClassName ? "span" : undefined;

    const newArr = [];
    arr.forEach((item, i) => {

        let itemComponent = item;
        if (tag) {
            const itemProps = optionalClassName ? {className: optionalClassName} : {};
            if (tag === 'a') {
                itemProps.href = item && item.includes('@') ? 'mailto:' + item : item;
            }
            itemComponent = React.createElement(tag, itemProps, item);
        }
        newArr.push(itemComponent);
        newArr.push(<br key={i}/>);
    });
    newArr.pop();

    return newArr;
};


import React from 'react'
import {Col} from 'reactstrap'

import Error from './elements/Error'
import CheckBox from "components/elements/CheckBox"

function CheckVal(props) {

    const { name, data, field, disabled, error} = props;
    let value = data && data[field];

    const onChange = (e) => {
        value = !value;
        props.onChange(data, field, +value);
    };

    return (
        <div className="form-group row g-0">
            <Col sm="12">
                <CheckBox checked={value} label={name} onChange={onChange} disabled={disabled} />
                {error &&  <Error id={field} error={error}/>}
            </Col>
        </div>);
}

export default CheckVal;
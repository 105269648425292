import React from 'react'
import {Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import NumberInput from './elements/NumberInput'
import Error from './elements/Error'

function IntVal(props) {
    const {isNoInput, wide, name, unit, info, infoImage, disabled, data, field, error} = props;

    const onChange = (e) => {
        let value = e.nativeEvent.target.value;
        if (value.indexOf('.') >= 0) {
            value = value.substring(0, value.indexOf('.'));
        }
        value = value !== "" ? Number(value) : null;
        props.onChange(data, field, value);
    };

    let value = data && (data[field] || data[field] === 0) ? String(data[field]) : "";
    let placeholder;

    if (isNoInput) {
        placeholder = value;
        value = "";
    }

    return (
        <Col sm={6} className="form-group row g-0">
            <Col sm={wide ? 4 : 6}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
            </Col>
            {/* padding accounts for 2-column gap */}
            <Col sm={wide ? 8 : 6} className="pe-2">
                <NumberInput className={classnames("form-control", error && "is-invalid")} value={value}
                             placeholder={placeholder} disabled={disabled} onChange={onChange} />
                {error &&
                <Error id={field} error={error} />}
            </Col>
        </Col>);
}

export default IntVal;
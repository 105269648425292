/**
 * Created by Yuri on 02.07.2017
 * Part of frontend
 */
import React from 'react'
import {withTranslation} from 'react-i18next'
import DialogModal from 'components/modals/DialogModal'


export default function withActionLock(WrappedComponent) {
    class WithActionLock extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLocked: false,
                message: null,
                actions: null,
                // modal lock raised
                isShowLock: false,
                lockedAction: null
            };
            this.setActionLockWithMessageAndActions = this.setActionLockWithMessageAndActions.bind(this);
            this.resetActionLock = this.resetActionLock.bind(this);
            this.executeWithLock = this.executeWithLock.bind(this);
            this.buildModalActions = this.buildModalActions.bind(this);
        }

        setActionLockWithMessageAndActions(message, actions) {
            if (!this.state.isLocked || this.state.message !== message || this.state.actions !== actions) {
                this.setState((prevState) => ({isLocked: true, message, actions}));
            }
        }

        resetActionLock() {
            if (this.state.isLocked) {
                this.setState((prevState) => ({isLocked: false, message: null, actions: null, isShowLock: false, lockedAction: null}));
            }
        }

        executeWithLock(lockedAction) {
            const {isLocked} = this.state;

            if (!isLocked) {
                lockedAction();
            } else {
                this.setState((prevState) => ({ isShowLock: true, lockedAction}));
            }
        }

        buildModalActions() {
            const {lockedAction} = this.state;
            const {t} = this.props;
            let {actions} = this.state;
            actions = actions || {};

            const cancel = () => {
                this.setState((prevState) => ({ isShowLock: false, lockedAction: null }));
            };
            const executeAction = () => {
                this.setState((prevState) => ({
                    isLocked: false,
                    message: null,
                    actions: null,
                    // modal lock raised
                    isShowLock: false,
                    lockedAction: null
                }));
                lockedAction();
            };

            let modalActions = [
                actions['discard'] && {
                    key: "discard", name: t('hocs.withActionLock.cancel'),
                    color: "primary",
                    action: () => {
                        typeof actions.discard === "function"  && actions.discard();
                        executeAction();
                    }
                },
                actions['save'] && {
                    key: "save", name: t('hocs.withActionLock.save'),
                    color: "primary",
                    action: () => {
                        typeof actions.save === "function"  &&  actions.save();
                        executeAction();
                    }
                },
                actions['continue'] && {
                    key: "continue", name: t('hocs.withActionLock.continue'),
                    color: "primary",
                    action: () => {
                        typeof actions.continue === "function" && actions.continue();
                        executeAction();
                    }
                },
                actions['cancel'] && {
                    key: "cancel", name: t('hocs.withActionLock.returnToEdit'),
                    color: "secondary",
                    action: () => {
                        typeof actions.cancel === "function"  && actions.cancel();
                        cancel();
                    }
                },
                {
                    key: "close",
                    action: () => {
                        const action = actions.close || actions.cancel;
                        typeof action === "function"  && action();
                        cancel();
                    }
                }
                ];
            return modalActions.filter(a => a);
        };

        render() {
            const {message, isShowLock} = this.state;
            const modalActions = isShowLock && this.buildModalActions();

            return (
                <div className="h-100 w-100">
                    <WrappedComponent {...this.props}
                                      setActionLockWithMessageAndActions={this.setActionLockWithMessageAndActions}
                                      resetActionLock={this.resetActionLock}
                                      executeWithLock={this.executeWithLock} />

                    <DialogModal key="M" show={isShowLock} title={this.props.t('hocs.confirm')} actions={modalActions}>
                        {message}
                    </DialogModal>
                </div>
            );
        }
    }

    WithActionLock.displayName = `WithActionLock(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return withTranslation()(WithActionLock);
};
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useDebug from 'hooks/useDebug'

import PanelModal from 'components/modals/PanelModal'
import withLocalNavigateContext from 'hocs/withLocalNavigateContext'
import BrowseCatalogView from '../views/BrowseCatalogView'
import {breadcrumbsStore} from "../../appBase/TopNav";

const CatalogSelectModal = compose(
    withLocalNavigateContext,
)(props => {
    useDebug("CatalogSelectModal", props);

    const {
        selectCatalog, selectScenario, selectCourse,
        licenseId, queryAsRole,
        addToType, itemToAddToCatalog, show, onCancel, onSelect, loading, errors} = props;
    const {t} = useTranslation();

    // resetting breadcrumbs
    useEffect(() => {
        breadcrumbsStore.disable();
        return () => {
            breadcrumbsStore.enable();
        }
    });

    const externalActionsGenerator =
        useCallback(
            (catalog, catalogEntry, catalogEntryVersion) => {
                const cancel = {
                    name: t('appCatalog.modals.CatalogSelectModal.cancel'),
                    action: () => onCancel()
                };
                if (selectCatalog) {
                    return [
                        catalog && {
                            // TODO: переименовать в section
                            name: t('appCatalog.modals.CatalogSelectModal.addToThisPartition'),
                            action: () => onSelect(catalog.id)
                        },
                        cancel];
                }
                if (selectScenario || selectCourse) {
                    return [
                        catalogEntryVersion && (catalogEntryVersion.scenario || catalogEntryVersion.event) && {
                            name: addToType === 'license' ?
                                t('appCatalog.modals.CatalogSelectModal.add') :
                                t('appCatalog.modals.CatalogSelectModal.addToEvent'),
                            action: () => onSelect(catalogEntryVersion.id)
                        },
                        cancel];
                }
            },
            [onSelect, onCancel, t]);

    return (
        <PanelModal show={show} title={t('appCatalog.modals.CatalogSelectModal.browseCatalog')} onClose={onCancel} noPadding scrollable>
            <BrowseCatalogView showScenarios={selectScenario}
                               showCourses={selectCourse}
                               licenseId={licenseId}
                               queryAsRole={queryAsRole}
                               info={itemToAddToCatalog && itemToAddToCatalog.name}
                               externalActionsGenerator={externalActionsGenerator}
                               loading={loading} errors={errors}
                               uri={props.uri} uriParams={props.uriParams} location={props.location}
                               executeWithLock={props.executeWithLock} />;
        </PanelModal>);
});


export default CatalogSelectModal;

import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Alert} from 'reactstrap'

import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'

import {breadcrumbsStore} from 'appBase/TopNav'

import entityUtils from 'utils/entityUtils'
import {getInfoAlert} from 'utils/resultsUtils'
import {ensureDevicesConsistency} from 'utils/devicesUtils'

import staticPrototypes from 'constants/_@_staticPrototypes'
import {RESULTS, RESULTS_VIEW} from 'constants/constants'

class ResultsView extends Component
{
    static ensureNamesIds(props) {
        // ensuring names and ids
        if (props.simulation && props.simulation.simulationData && props.simulation.simulationData.spacecrafts) {
            props.simulation.simulationData.spacecrafts.forEach(s => {
                if (s.devices) {
                    ensureDevicesConsistency(s.devices, props.t);
                }
            })
        }
    }

    static getNameClassname(status) {
        switch(status) {
            case "notready":
            case "ready":
            case "running":
            case "finished":
            case "failed":
                return `sim-${status}-name`;
            default:
                //throw new Error(`Unknown simulation status ${status}!`);
                console.error(`Unknown simulation status ${status}!`);
                return 'sim-failed-name';
        }
    }

    constructor(props){
        super(props);
        this.state = {
            resultsParametersDataSource: entityUtils.getEmptyProxyObj()
        };
        this.onAction = this.onAction.bind(this);
        this.onChange = this.onChange.bind(this);

        // ResultsView.setupPolling(props);
        ResultsView.ensureNamesIds(props);

        // links

        const containerId = RESULTS_VIEW;
        breadcrumbsStore.register(containerId);
        breadcrumbsStore.set(containerId, {name: props.t('appEvent.views.solutionViews.ResultsView.2dGraphs'), back: 1, nonBlocking: true}, true);
    }


    componentWillReceiveProps(nextProps) {
        //ResultsView.setupPolling(nextProps);
        // resetting series
        if (nextProps.simulation !== this.props.simulation &&
            (nextProps.simulation && nextProps.simulation.id) !== (this.props.simulation && this.props.simulation.id)) {
            this.setState(prevState => ({
                resultsParametersDataSource: entityUtils.getEmptyProxyObj()
            }));
        }
        if (nextProps.simulation !== this.props.simulation) {
            ResultsView.ensureNamesIds(nextProps);
        }
    }


    onAction(action, data) {
        const onParentAction = this.props.onAction;
        onParentAction(action, data);
    }


    onChange(data, field, value, noChanges) {
        const {resultsParametersDataSource} = this.state;
        resultsParametersDataSource[field] = value;
        console.log(`ResultsView: field ${field}: data ${resultsParametersDataSource[field] !== value ? '!=' : '=='} value`);
        // re-render all children
        this.forceUpdate();
    }


    render() {
        const {simulation, resultsData, t} = this.props;
        const loading = this.props.loading || {resultsData: false};
        const {resultsParametersDataSource} = this.state;

        const noResults = !resultsData || resultsData.length === 0;
        const noSpacecrafts = !simulation.simulationData || !simulation.simulationData.spacecrafts || simulation.simulationData.spacecrafts.length === 0;
        const infoAlertData = getInfoAlert(simulation, noResults, noSpacecrafts, loading.resultsData, t);
        const infoAlert = infoAlertData &&
            <Alert className="mb-0" color={infoAlertData.type}>{infoAlertData.info}</Alert>;

        return (
            <div className="flex-grow d-flex flex-column">
            {infoAlert}
            <GeneratorBlock data={resultsParametersDataSource}
                            items={staticPrototypes[RESULTS]}
                            collectionsDict={{
                                spacecrafts: simulation.simulationData && simulation.simulationData.spacecrafts,
                                stations: simulation.simulationData && simulation.simulationData.stations,
                                orbits: simulation.simulationData && simulation.simulationData.orbits,
                                resultsData: resultsData,
                                parameters: {
                                    simulationId: simulation.id,
                                    isRunning: simulation.status === 'running'
                                }
                            }}
                            onChange={this.onChange} />
            </div>);
    }
}

export default withTranslation()(ResultsView);
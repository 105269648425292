import React, {Component} from 'react'
import {Controlled as ReactCodeMirror} from 'react-codemirror2'
import {Resizable} from 're-resizable'
import {JSHINT} from 'jshint'
import classnames from 'classnames'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/lint/lint.css'

import SimulationFieldSelector from './elements/SimulationFieldSelector'
import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'
import headerTypeScopes from 'constants/headerTypeScopes'

const CodeMirror = require('codemirror/lib/codemirror');
require('codemirror/mode/javascript/javascript');
require('codemirror/addon/search/search');
require('codemirror/addon/search/searchcursor');
require('codemirror/addon/search/jump-to-line');
require('codemirror/addon/dialog/dialog');
require('codemirror/addon/lint/lint');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/selection/active-line');

class JavaScriptEditor extends Component {
    constructor(props) {
        super(props);
        this.instance = null;
        this.validator = this.validator.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const {field, data, onValidate} = nextProps;
        const newValue = data && field && data[field];
        if (!newValue || !(newValue.trim())) {
            onValidate && onValidate(data, field, true);
            this.hasErrors = false;
        }
    }

    validator(text) {
        const {headerType, data, field, onValidate} = this.props;

        if (!text || !(text.trim())) {
            return []
        }

        const options = {
            curly: true, //always use {} around blocks in loops and conditionals
            eqeqeq: true, // force using === instead of ==
            esversion: 9,// for support all new features of es9
            globals: headerType ? headerTypeScopes[headerType]: null, //outer scope
            shadow: "outer", //shadowing (including cases when shadowed variable was defined in outer scope)
            strict: "implied",  //lint the code as if there is the "use strict"; directive
            undef: true, //no undeclared variables
            unused: true, //enable warnings  like this 'b' was defined but never used.
            maxerr: 9999
        };

        JSHINT(text, options);
        this.hasErrors = !!JSHINT.errors.filter(e => e.code[0] === "E").length;
        onValidate && onValidate(data, field, !this.hasErrors);

        return JSHINT.errors.map(error => {
            const start_line = error.line;
            const end_line = error.line;
            const code = error.code;
            const message = error.reason;
            return {
                from: CodeMirror.Pos(start_line - 1),
                to: CodeMirror.Pos(end_line - 1),
                message: message,
                severity: (code[0] === "W") ? "warning": "error"
            };
        });
    }

    render() {
        const {noLabel, name, unit, info, infoImage, error, field, data, disabled} = this.props;
        const value = (data && field && data[field]) || "";

        let contents =
            <Resizable className={classnames("w-100 border", (this.hasErrors || error) ? "border-danger" : "border-dark")}
                       enable={{top: false, right: false, left: false, bottom: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                       onResize={(e, dir, ref, delta) => {
                           if (this.instance) {
                               this.instance.setSize(null, ref.clientHeight + "px");
                           }
                       }}>
                <ReactCodeMirror className="w-100 mb-1"
                                 value={value}
                                 options={{
                                     mode: 'javascript',
                                     lineNumbers: true,
                                     indentUnit: 4,
                                     matchBrackets: true,
                                     styleActiveLine: true,
                                     gutters: ["CodeMirror-lint-markers"],
                                     lint: {
                                         "getAnnotations": this.validator,
                                         lintOnChange: true
                                     },
                                     readOnly: disabled,
                                     extraKeys: {
                                         Tab: function(cm) {
                                             const spaces = new Array(cm.getOption("indentUnit") + 1).join(" ");
                                             cm.replaceSelection(spaces);
                                         }
                                     }
                                 }}
                                 editorDidMount={editor => {
                                     this.instance = editor;
                                     this.instance.setSize(null, 400 + "px");
                                 }}
                                 onBeforeChange={(editor, editorDdata, value) => {
                                     value = value.replace(/\t/g, '    ');
                                     this.props.onChange(data, field, value);
                                 }}
                />
            </Resizable>;

        contents = (
            <SimulationFieldSelector className="w-100" data={data} field={field} disabled={disabled} onChange={this.props.onChange}>
                {contents}
            </SimulationFieldSelector>);

        return (
            <div className="form-group row g-0">
                {!noLabel &&
                    <LabelWithInfo className="mb-1" name={name} unit={unit} info={info} infoImage={infoImage}/>}
                {contents}
                {error &&
                    <Error id={field} error={error}/>}
            </div>);
    }
}
export default JavaScriptEditor;
/**
 * Created by Yuri on 03.08.2020
 * Part of frontend
 */
import React, {useState} from 'react';

export const LocalNavigateContext = React.createContext(undefined);


const withLocalNavigateContext = (WrappedComponent) => {
    const WithLocalNavigateContext = props => {
        // will become Context value
        let [localPathAndOptions, setLocalPathAndOptions] = useState({to: "", options: {}});

        // -- emulate global -- need to substract props.uri from "global" uriParams
        // let uriParams = props.uriParams;
        // while (uriParams.endsWith('/')) { uriParams = uriParams.substring(0, uriParams.length -1); }
        // uriParams = uriParams ? uriParams + '/' + to : to;
        // ...  uri={props.uri} uriParams={uriParams}

        // base uri
        const baseUri = props.uri || '/';

        // -- just local --
        const {to, options} = localPathAndOptions;
        let path;
        if (to.startsWith(baseUri) || to === '') {
            path = to.substring(baseUri.length);
            path = path.startsWith('/') ? path.substring(1) : path;
            path = path.indexOf('?') >= 0 ? path.substring(0, path.indexOf('?')) : path;
        } else {
            console.log(`#localNavCtx: baseUri: ${baseUri}, to: ${to}`);
        }
        const search = to.indexOf('?') >= 0 ? to.substring(to.indexOf('?')) : '';
        const state = (options && options.state) || {};
        const pathname = baseUri + (path ? '/' + path : '');
        const location = {pathname, search, state};

        return (
            <LocalNavigateContext.Provider value={setLocalPathAndOptions}>
                <WrappedComponent {...props} uri={baseUri} uriParams={path} location={location} />
            </LocalNavigateContext.Provider>);
    };
    WithLocalNavigateContext.displayName = `WithLocalNavigateContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithLocalNavigateContext;
};


export default withLocalNavigateContext;
/**
 * From here: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */


export function encodeJsUnicodeStringToBase64EncodedUTF8String(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}


export function decodeBase64EncodedUTF8StringToJsUnicodeString(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


export function safeDecodeBase64EncodedUTF8StringToJsUnicodeString(str) {
    try {
        return decodeBase64EncodedUTF8StringToJsUnicodeString(str);
    } catch(error) {
        console.warn(`Error decoding b64 string: ${error}`);
        return '';
    }
}
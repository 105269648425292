import React from 'react';

export const replaceWithComponents = (s, patternObj) => {
    const cuts = [];
    Object.keys(patternObj).forEach(key => {
        let lastInd = 0;
        while (s.indexOf(key, lastInd) > -1) {
            const matchInd = s.indexOf(key, lastInd);
            cuts.push([matchInd, matchInd + key.length, patternObj[key]]);
            lastInd = matchInd + key.length;
        }
    });
    const components = [];
    let lastStrPos = 0;
    cuts.sort((a, b) => a[0] - b[0])
        .forEach((cut, i) => {
            if (cut[0] > lastStrPos) {
                components.push(<span key={i * 2}>{s.substring(lastStrPos, cut[0])}</span>)
            }
            if (typeof cut[2] === 'object') {
                components.push(React.cloneElement(cut[2], {key: i * 2 + 1}));
            } else {
                components.push(<span key={i * 2}>{cut[2]}</span>)
            }
            lastStrPos = cut[1];
        });
    if (s.length > lastStrPos) {
        components.push(<span key="last">{s.substring(lastStrPos, s.length)}</span>)
    }
    return components;
}

export function trim(str, ch) {
    var start = 0,
        end = str.length;

    while(start < end && str[start] === ch)
        ++start;

    while(end > start && str[end - 1] === ch)
        --end;

    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export function ltrim(str, ch) {
    var start = 0,
        end = str.length;

    while(start < end && str[start] === ch)
        ++start;

    return start > 0 ? str.substring(start) : str;
}
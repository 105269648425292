import React from 'react'

export function getInfoAlert(simulation, noResults, noSpacecrafts, loading, t) {
    if (simulation.taskError) {
            return {
                type: "danger",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.taskError')}<br/>{simulation.taskError}<br/>{t('appEvent.views.solutionViews.ResultsView.correctError')}</span>
            };
        } else if (noResults !== null && noResults && simulation.status === 'running') {
            return {
                type: "info",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.stillNoResults')}<br/>{t('appEvent.views.solutionViews.ResultsView.waitForResults')}</span>
            };
        } else if (loading && simulation.status === 'finished') {
            return {
                type: "info",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.loading')}</span>
            };
        } else if (noResults !== null && noResults && simulation.status === 'finished') {
            return {
                type: "danger",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.noResults')}<br/>{t('appEvent.views.solutionViews.ResultsView.help')}</span>
            };
        } else if (noResults !== null && noResults && simulation.status === 'failed') {
            return {
                type: "info",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.simulationAborted')}<br/>{t('appEvent.views.solutionViews.ResultsView.restartSimulation')}</span>
            }
        } else if (noSpacecrafts !== null && noSpacecrafts) {
            return {
                type: "info",
                info: <span>{t('appEvent.views.solutionViews.ResultsView.noSpacecrafts')}<br/>{t('appEvent.views.solutionViews.ResultsView.addSpacecrafts')}</span>
            }
        }
        return null;
};
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useReactToPrint} from 'react-to-print'

import PanelModal from 'components/modals/PanelModal'
import {Button} from "reactstrap";

const PrintPreviewModal = (props) => {
    const {content, onClose} = props;

    const printComponentRef = useRef();
    const {t} = useTranslation();

    const containerPrefix = 'components.print.';
    const _t = (value) => t(containerPrefix + value);

    const onPrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    return (
        <PanelModal show={true} title={_t('printVersion')}
                    onClose={onClose}
                    titleComponents={<Button color="primary" onClick={onPrint}>
                        <i className="fa fa-print"/>&nbsp;{_t('print')}
                    </Button>}
                    noPadding scrollable>

            <div className="printform" ref={printComponentRef}>
                {content}
            </div>
        </PanelModal>);

};

export default PrintPreviewModal;
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import classnames from 'classnames'

import ActionConfirmationModalContainer, {LiWithActions} from 'components/blocks/ContainersWithActions'


const List = (props) => {
    return (
        <ActionConfirmationModalContainer>
            <RowContainer {...props} />
        </ActionConfirmationModalContainer>);
};


class RowContainer extends Component {
    static getWidthStyle(widthArray, index) {
        // when widths are not specified: always return flex-grow:
        if (!widthArray || widthArray.length === 0) {
            return { flex: 1 }
        }
        // when no width is given for current index:
        if (widthArray.length <= index || !widthArray[index]) {
            return undefined;
        }
        // processing width
        const width = widthArray[index];
        // flex-width:
        if (typeof(width) === 'string' && width.endsWith("*")) {
            return { flex: Number(width.substring(0, width.length - 1)) };
        }
        // normal width in px or %:
        return { width: width + (typeof(width) === 'number' ? 'px' : '') };
    }

    constructor(props) {
        super(props);
        this.state = {
            isLimited: true
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState((prevState) => {
            prevState.isLimited = !prevState.isLimited;
            return prevState;
        });
    }

    render() {
        let {
            className, rows, limit, headers, headerSizes, rowSizes, sizes, nameIndex, selectedIndex, onSort, sortIndex, sortDirection, onAction,
            disabled, executeWithLock, t} = this.props;
        const {isLimited} = this.state;

        headerSizes =
            headerSizes ? headerSizes :
                sizes ? sizes : [];
        rowSizes =
            rowSizes ? rowSizes :
                sizes ? sizes : [];


        const isOverLimit = limit > 0 && rows.length > limit;

        rows = isOverLimit && isLimited ? rows.slice(0, limit) : rows;

        return (
            <div className={className}>
                <ul className="list-group listpanel">
                    {headers &&
                    <li className={classnames("list-group-item listpanel-header", disabled && "bg-light")}>
                        {headers.map((h, i) =>
                            <div key={i} className="listpanel-header-elem"
                                 style={RowContainer.getWidthStyle(headerSizes, i)}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     if (onSort) {
                                         onSort(i);
                                     }
                                 }}>
                                {h}
                                { (i === sortIndex) && sortDirection &&
                                <i className="fa fa-chevron-up"/> }

                                { (i === sortIndex) && !sortDirection &&
                                <i className="fa fa-chevron-down"/> }
                            </div>)}
                    </li>}
                    {rows && rows.map((r, index) =>
                        <Row key={index} row={r} t={t}
                             rowIndex={index} selectedIndex={selectedIndex}
                             rowSizes={rowSizes} nameIndex={nameIndex}
                             onAction={onAction}
                             executeWithLock={executeWithLock}
                             disabled={disabled} />)}
                </ul>
                {isOverLimit &&
                <span className={classnames("controlelement", disabled && "bg-light")}
                      onClick={this.toggle}>{ !isLimited ? "Hide" : "Expand" }</span>}
            </div>);
    }
}


const Row = (props) => {
    const {row, rowIndex, nameIndex, selectedIndex, onAction, disabled, executeWithLock} = props;
    let {rowSizes, t} = props;
    const {notSelectable, className, actions} = row;
    let {fields} = row;

    const isSelected = rowIndex === selectedIndex;

    let name = t('components.object');
    if (row.hasOwnProperty('name')) {
        name = row.name;
    } else if (fields) {
        const correctedNameIndex = nameIndex >= 0 && nameIndex < fields.length ? nameIndex : 0;
        name = fields[correctedNameIndex];
    }
    fields = fields || [name];

    rowSizes = fields.length > 1 ? rowSizes : null;

    return (
        <LiWithActions className={classnames(
            "list-group-item listpanel-row", {
                active: isSelected,
                linktoobject: !isSelected && !notSelectable && !disabled,
                controlelement: notSelectable && actions && actions.hasOwnProperty('click') && !disabled,
                "bg-light": disabled
            },
            className)}
                       data={{...row, name}} onAction={onAction} executeWithLock={executeWithLock}>
            {fields.map((f,i) =>
                <div key={i}
                     className={classnames("listpanel-row-elem")}
                     style={RowContainer.getWidthStyle(rowSizes, i)}>{f}</div>)}
        </LiWithActions>);
};

export default withTranslation()(List);
import entityUtils from 'utils/entityUtils'

export const convertOldJSONPrototypeParameters = (prototypeParametersJSON) => {
    // correct old prototypes:
    let prototypeParameters = entityUtils.safeJSONParse(prototypeParametersJSON, []);
    // correct old {} prototypes
    if (!Array.isArray(prototypeParameters)) {
        prototypeParameters = (prototypeParameters && prototypeParameters.items) || [];
    }
    // correct old [{field: "parameters", items: []}, {field: "description"}] prototypes
    prototypeParameters = [
        ...(prototypeParameters.find(p => p.field === "parameters") || {}).items || [],
        ...prototypeParameters.filter(p => p.field !== "parameters")
    ];
    // back to string:
    return JSON.stringify(prototypeParameters);
};


export const convertOldJSONPrototypeResults = (prototypeResultsJSON) => {
    // correct old prototypes:
    let prototypeResults = entityUtils.safeJSONParse(prototypeResultsJSON, []);
    // correct old {} prototypes
    if (!Array.isArray(prototypeResults)) {
        prototypeResults = (prototypeResults && prototypeResults.items) || [];
    }
    // back to string:
    return JSON.stringify(prototypeResults);
};


export const prepareSolutionObject = (solution) => {
    // parse JSON strings
    return solution && {
        ...solution,
        // adding description from main object into parameters structure
        parameters: {
            ...entityUtils.safeJSONParse(solution.parameters, {}),
            description: solution.description
        },
        simulation: solution.simulation && {
            ...solution.simulation,
            simulationData: entityUtils.safeJSONParse(solution.simulation.simulationData, {})
        },
        results: entityUtils.safeJSONParse(solution.results, {})
    }
};


export const prepareSolutionObjectForSave = (solution, parameters) => {
    const description = parameters.description;
    delete parameters.description;
    return {
        ...(solution || {}),
        description: description,
        parameters: JSON.stringify(parameters)
    }
};


export const prepareResultsData = (resultsData) => {
    let start = new Date().getTime();
    let results = entityUtils.safeJSONParse(resultsData, null);
    if (results) {
        let end = new Date().getTime();
        console.log("JSON.parse: " + (end - start) / 1000);

        start = new Date().getTime();
        results = results.map(d => ({...d, t: d.t.replace('Z', '')}));
        end = new Date().getTime();
        console.log("replace Z: " + (end - start) / 1000);
    }
    return results;
};
import React, {useState} from 'react'
import {UncontrolledTooltip} from 'reactstrap'
import {randomDOMId} from 'utils/randomUtils'


export default function TooltipWrapper(props) {
    const [id] = useState(() => randomDOMId());

    const {tooltip, children} = props;

    const childrenWithIds = children && React.Children.map(
        children,
        (child, i) =>
            React.cloneElement(child, {id: id})
    );

    return (
        <>
            {childrenWithIds}
            <UncontrolledTooltip target={id}>{tooltip}</UncontrolledTooltip>
        </>);
}
/**
 * Created by Yuri on 02.07.2017
 * Part of frontend
 */
import React from 'react'
import {withTranslation} from 'react-i18next'
import DialogModal from 'components/modals/DialogModal'
import {toArrayWithLineBreaks} from "../utils/visualHelpers";


export default function withConfirmation(WrappedComponent) {
    class WithConfirmation extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                iteration: 0,
                message: null,
                isShowConfirmation: false,
                lockedAction: null,
                lockedActionReject: null
            };
            this.executeWithConfirmation = this.executeWithConfirmation.bind(this);
            this.executeWithConfirmationPromise = this.executeWithConfirmationPromise.bind(this);
            this.buildModalActions = this.buildModalActions.bind(this);
        }

        executeWithConfirmation(lockedAction, message) {
            message = message || this.props.t('hocs.withConfirmation.areYouSure');
            this.setState((prevState) => ({
                iteration: this.state.iteration + 1,
                isShowConfirmation: true, message, lockedAction}));
        }

        executeWithConfirmationPromise(message) {
            message = message || this.props.t('hocs.withConfirmation.areYouSure');
            const confirmationPromise = new Promise((resolve, reject) => {
                this.setState((prevState) => ({
                    lockedAction: resolve,
                    lockedActionReject: reject
                }));
            });
            this.setState((prevState) => ({
                iteration: this.state.iteration + 1,
                isShowConfirmation: true, message}));
            return confirmationPromise;
        }

        buildModalActions() {
            const {lockedAction, lockedActionReject} = this.state;

            const cancel = () => {
                this.setState((prevState) => ({ message: null, isShowConfirmation: false, lockedAction: null, lockedActionReject: null }));
                if (lockedActionReject) {
                    lockedActionReject();
                }
            };
            const executeAction = () => {
                this.setState((prevState) => ({ message: null, isShowConfirmation: false, lockedAction: null, lockedActionReject: null }));
                if (lockedAction) {
                    lockedAction();
                }
            };

            return [
                {
                    key: "yes",
                    name: this.props.t('general.capitalYes'),
                    color: "danger",
                    action: () => {
                        executeAction();
                    }
                },
                {
                    key: "no",
                    name: this.props.t('general.capitalNo'),
                    color: "primary",
                    action: () => {
                        cancel();
                    }
                },
                {
                    key: "close",
                    action: () => {
                        cancel();
                    }
                }
            ];
        };

        render() {
            const {iteration, message, isShowConfirmation} = this.state;

            const transformedMessage = message && typeof(message) === 'string' ? toArrayWithLineBreaks(message) : message;

            const modalActions = isShowConfirmation && this.buildModalActions();

            return (
                <>
                    <WrappedComponent {...this.props}
                                      executeWithConfirmation={this.executeWithConfirmation}
                                      executeWithConfirmationPromise={this.executeWithConfirmationPromise} />

                    <DialogModal key={"M"+iteration} show={isShowConfirmation}
                                  title={this.props.t('hocs.confirm')}
                                  actions={modalActions}>
                        {transformedMessage}
                    </DialogModal>
                </>
            );
        }
    }

    WithConfirmation.displayName = `WithConfirmation(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return withTranslation()(WithConfirmation);
};
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import prototypeUtils from 'utils/prototypeUtils'
import staticPrototypes from 'constants/_@_staticPrototypes'
import {DEVICE} from 'constants/constants'

export const booleanProps = ['required', 'unique', 'checkJson', "checkRegExp", 'mandatory'];
export const validationProps = ["min", "exclusiveMin", "max", "exclusiveMax", "minElement",
                                "maxElement", "minLength", "maxLength", "required", "pattern",
                                "unique", "validExtensionsList", "maxFileContentsKB", "checkJson",
                                "checkRegExp", "mandatory"];

class ValidatorBlock extends Component {
    static getHash(props) {
        const hashObject = {};
        validationProps.forEach(vp => {
            hashObject[vp] = props[vp];
        });
        return JSON.stringify(hashObject);
    }

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.state = {
            value: this.props.data && this.props.data[this.props.field],
            isValid: true,
            error: ""
        };
    }

    componentWillMount() {
        this.validate(this.props.data, this.props.field, this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field ||
            this.state.value !== (nextProps.data && nextProps.field && nextProps.data[nextProps.field]) ||
            ValidatorBlock.getHash(nextProps) !== ValidatorBlock.getHash(this.props))
        {
            this.validate(nextProps.data, nextProps.field, nextProps);
            if (this.state.value !== (nextProps.data && nextProps.field && nextProps.data[nextProps.field])) {
                this.setState((prevState) => ({
                    value: nextProps.data && nextProps.field && nextProps.data[nextProps.field]
                }));
            }
        }
    }

    onChange(data, field, value, noChanges) {
        this.props.onChange(data, field, value, noChanges);
    }

    validate(data, field, props) {

        const {max, exclusiveMax, min, exclusiveMin, minElement, maxElement, minLength, maxLength,
               required, mandatory, pattern, unique, maxFileContentsKB, checkJson, checkRegExp, t} = props ? props : this.props;
        let {validExtensionsList} = props ? props : this.props;
        validExtensionsList = validExtensionsList && validExtensionsList.map(ext => `.${ext.toLowerCase()}`);

        if (!data || !field) {
            return;
        }

        const value = data[field];

        function uniqueSiblings() {
            //examples of field we need to extract field with unique value and parent array:
            //devices.id:7.variable-name
            //devices.id:7.functions.id:2.variable-name
            const fieldInfo = prototypeUtils.getFieldsInfo(staticPrototypes[DEVICE]);

            let fieldParts = field.split('.');
            const uniqueName = fieldParts[fieldParts.length - 1];
            //checking if penultimate element is valid, deleting ultimate and penultimate
            if (fieldParts[fieldParts.length - 2].startsWith("id:") || fieldParts[fieldParts.length - 2].startsWith("index:")) {
                fieldParts.length -= 2;
            } else {
                throw new Error(`${t('components.fieldBlocks.ValidatorBlock.uniqueError')}${field}!`);
            }
            const rootArrayField = fieldParts.join('.');

            //How many times parent array contains value. If once is OK (no duplicates)
            const matches = data[rootArrayField]
                .map((e, i) => prototypeUtils.get(data, rootArrayField + ".id:" + e.localId + "." + uniqueName, fieldInfo, {}))
                .filter(e => e === value);

            return matches.length === 1;
        }

        function getKBLengthFromBase64(base64EncodedText) {
            const paddingCount = base64EncodedText.split('==').length - 1;
            const bytesLength = (3 * (base64EncodedText.length / 4)) - paddingCount;
            return Math.ceil(bytesLength / 1024);
        }

        function isValidJson(value) {
            try {
                JSON.parse(value);
                return true;
            } catch (e) {
                return false;
            }
        }

        function isValidRegExp(value) {
            try {
                new RegExp(value);
                return true;
            } catch (e) {
                return false;
            }
        }

        let isValid = false;
        let error = "";

        if (Array.isArray(value)) {
            if (!Array.isArray(max) && ![undefined, null].includes(max) && value.length > max) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayNumberMaxError') + max;
            } else if (!Array.isArray(min) && ![undefined, null].includes(min) && value.length < min) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayNumberMinError') + min;
            } else if (![undefined, null].includes(minElement) && value.some(e => e < minElement)) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayMinElementError') + minElement;
            } else if (![undefined, null].includes(maxElement) && value.some(e => e > maxElement)) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayMaxElementError') + maxElement;
            } else if (Array.isArray(max) && value.length === max.length && value.some((e, i) => e > max[i])) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayMaxError') + max;
            } else if (Array.isArray(exclusiveMax) && value.length === exclusiveMax.length && value.some((e, i) => e >= exclusiveMax[i])) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayExclusiveMaxError') + exclusiveMax;
            } else if (Array.isArray(min) && value.length === min.length && value.some((e, i) => e < min[i])) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayMinError') + min;
            } else if (Array.isArray(exclusiveMin) && value.length === exclusiveMin.length && value.some((e, i) => e <= exclusiveMin[i])) {
                error = t('components.fieldBlocks.ValidatorBlock.arrayExclusiveMinError') + exclusiveMin;
            } else {
                isValid = true;
            }
        }
        else {
            if (required && [undefined, null, ""].includes(value)) {
                error = t('components.fieldBlocks.ValidatorBlock.requiredError');
            } else if (mandatory && value !== 1) {
                error = t('components.fieldBlocks.ValidatorBlock.requiredError');
            } else if (![undefined, null].includes(value) && ![undefined, null].includes(max) && value > max) {
                error = t('components.fieldBlocks.ValidatorBlock.maxError') + max;
            } else if (![undefined, null].includes(value) && ![undefined, null].includes(min) && value < min) {
                error = t('components.fieldBlocks.ValidatorBlock.minError') + min;
            } else if  (![undefined, null].includes(value) && ![undefined, null].includes(exclusiveMax) && value >= exclusiveMax) {
                error = t('components.fieldBlocks.ValidatorBlock.exclusiveMaxError') + exclusiveMax;
            } else if  (![undefined, null].includes(value) && ![undefined, null].includes(exclusiveMin) && value <= exclusiveMin) {
                error = t('components.fieldBlocks.ValidatorBlock.exclusiveMinError') + exclusiveMin;
            } else if (![undefined, null].includes(value) && ![undefined, null].includes(minLength) && value.length < minLength) {
                error = t('components.fieldBlocks.ValidatorBlock.minLengthError') + minLength;
            } else if (![undefined, null].includes(value) && ![undefined, null].includes(maxLength) && value.length > maxLength) {
                error = t('components.fieldBlocks.ValidatorBlock.maxLengthError') + maxLength;
            } else if (pattern !== undefined && pattern !== null && value && !String(value).match(pattern.pattern)) {
                error = pattern.error || t('components.fieldBlocks.ValidatorBlock.patternError');
            } else if (unique === "deviceSiblings" && value && !uniqueSiblings()) {
                error = t('components.fieldBlocks.ValidatorBlock.uniqueError2');
            } else if (maxFileContentsKB && value && getKBLengthFromBase64(value.contents) > maxFileContentsKB) {
                error = t('components.fieldBlocks.ValidatorBlock.fileSizeError');
            } else if (value && validExtensionsList && !validExtensionsList.some(ext => value.name.toLowerCase().endsWith(ext))) {
                error = `${t('components.fieldBlocks.ValidatorBlock.fileExtensionError')} ${validExtensionsList}!`;
            } else if (checkJson && value && !isValidJson(value)) {
                error = t('components.fieldBlocks.ValidatorBlock.jsonError');
            } else if (checkRegExp && value && !isValidRegExp(value)) {
                error = t('components.fieldBlocks.ValidatorBlock.regExpError');
            }
            else {
                isValid = true;
            }
        }


        if (error !== this.state.error) {
            this.setState((prevState) => ({
                error: error
            }));
        }

        // some containers don't need validation
        //we always inform container about validation process. May occur situations (when the component will
        //mount and unmount many times) when the state is the same but containers information is different

        if (props.onValidate) {
            props.onValidate(data, field, isValid);
        }
        if (isValid !== this.state.isValid) {
            this.setState((prevState) => ({
                isValid: isValid
            }));
        }
    }


    render() {
        const {className, style, children, data, fieldsInfo, dynamicSectionName, collectionsDict, editLanguage, isNoInput, disabled, wide} = this.props;
        const components = React.Children.map(
            children,
            (child, i) => React.cloneElement(child, {
                key: i,
                className: className,
                style: style,
                isNoInput: isNoInput,
                dynamicSectionName: dynamicSectionName,
                disabled: child.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: this.onChange,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                error: this.state.error,
                wide: child.props.wide || wide
            }));

        return (
            // <React.Fragment>
            //<div className="form-group">
            components[0]
            //</div>
            // </React.Fragment>
        );
    }
}

export default withTranslation()(ValidatorBlock);
import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert} from 'reactstrap'
import compose from 'lodash/flowRight'
import useDebug from 'hooks/useDebug'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {breadcrumbsStore} from 'appBase/TopNav'

import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import {SERVICE_TEMPLATES_VIEW} from 'constants/constants'

import {queryFlatCatalogsAndEntries} from 'appCatalog/data/catalogQueries'
import {queryLicenseTemplates} from '../data/serviceQueries'
import {createLicenseTemplate, updateLicenseTemplate,
        deleteLicenseTemplate, addCatalogToLicenseTemplate,
        deleteCatalogFromLicenseTemplate, createLicensesFromLicenseTemplate} from '../data/serviceMutations'
import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import serviceStaticPrototypes from '../data/serviceStaticPrototypes.json'

const TemplateView = compose(
     withUriParams([['mode', String, {values: ['view', 'catalog', 'licenses']}],
                    ['templateId', Number]]),

     queryFlatCatalogsAndEntries, queryLicenseTemplates,
     createLicenseTemplate, updateLicenseTemplate, deleteLicenseTemplate,
     addCatalogToLicenseTemplate, deleteCatalogFromLicenseTemplate, createLicensesFromLicenseTemplate,
     withGeneratorDataSource,
     withInitialDataLoadWaiting(['licenseTemplates'])
)((props) => {
    useDebug("TemplateView", props);

    const {mode, templateId, flatCatalogsAndEntries, licenseTemplates,
           createLicenseTemplate, updateLicenseTemplate, deleteLicenseTemplate,
           addCatalogToLicenseTemplate, deleteCatalogFromLicenseTemplate, createLicensesFromLicenseTemplate,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;

    const [error, setError] = useState(null);
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    //template object for dataSource
    const templateSource = useMemo(() => {
        if (!templateId) {
            return null;
        }
        const template = licenseTemplates && licenseTemplates.find(t => t.id === templateId);

        if (!template) {
            return null;
        }

        return {
            ...template,
            catalogIds: template.catalogs.map(c => c.id),
            dataLimitMb: (template.dataLimit / (1024 * 1024)).toFixed(1)
        };
    }, [templateId, licenseTemplates]);

    // stable empty object for dataSource
    const emptySource = useMemo(() => ({}), []);

    // setting dataSource
    useEffect(() => {
        if (mode === "view" && templateId) {
            setDataSource(
                templateSource,
                (data) => {
                    data = {...data, dataLimit: data.dataLimitMb * 1024 * 1024};
                    data = entityUtils.filterFields(data, [
                        'id',
                        'name',
                        'prefix',
                        'durationMonths',
                        'studentsLimit',
                        'eventsLimit',
                        'dataLimit',
                        'comments'
                    ]);
                    updateLicenseTemplate(data)
                        .then(({data: {updateLicenseTemplate: {licenseTemplate, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                        });
                });
        } else if (mode === "catalog") {
            setDataSource(
                emptySource,
                (data) => {
                    data = entityUtils.filterFields(data, [
                        'templateId',
                        'catalogId'
                    ]);
                    addCatalogToLicenseTemplate(data)
                        .then(({data: {addCatalogToLicenseTemplate: {error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                            if (!error) {
                                navigate(`${props.uri}/view/${data.templateId}${props.location.search}`);
                            }
                        });
                });
        } else if (mode === "licenses") {
            setDataSource(
                emptySource,
                (data) => {
                    data = entityUtils.filterFields(data, [
                        'templateId',
                        'numberOfLicenses'
                    ]);
                    createLicensesFromLicenseTemplate(data)
                        .then(({data: {createLicensesFromLicenseTemplate: {error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                            if (!error) {
                                navigate(`/license`);
                            }
                        });
                });
        }

    }, [mode, templateSource, emptySource, dataSource, setDataSource, updateLicenseTemplate, templateId,
        addCatalogToLicenseTemplate, createLicensesFromLicenseTemplate, props.uri, props.location.search, t]);

    // preparing links
    breadcrumbsStore.register(SERVICE_TEMPLATES_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_TEMPLATES_VIEW, {name: t('appService.templates'), to: `${props.uri}`}, true);
    });

    //templates
    const templates = licenseTemplates.map(t => ({
        fields: [t.id, t.name, t.prefix, t.durationMonths, t.eventsLimit, t.studentsLimit, (t.dataLimit / (1024 * 1024)).toFixed(1)],
        actions: {
            "select": () => navigate(`${props.uri}/view/${t.id}${props.location.search}`),
            "removeConfirmation":  () => deleteLicenseTemplate(t.id)
        }
    }));
    templates.push({
        fields: [t('appService.views.TemplateView.addNewTemplate')],
        className: "text-end",
        notSelectable: true,
        actions: {
            // TODO: после создания шаблона должна быть на его id навигация (чтобы открылся сразу новый шаблон)
            "click": () => createLicenseTemplate({name: t('appService.views.TemplateView.newTemplate')})
        }
    });
    // TODO: убрать отсюда, добавить кнопку "добавить каталог" на саму страницу со списком каталогов в щаблоне
    templates.push({
        fields: [t('appService.views.TemplateView.addCatalog')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => navigate(`${props.uri}/catalog/${props.location.search}`),
        }
    });
    templates.push({
        fields: [t('appService.views.TemplateView.createLicenses')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => navigate(`${props.uri}/licenses/${props.location.search}`),
        }
    });

    // template catalogs
    const catalogs = templateSource ? templateSource.catalogs.map((c, i) => ({
        fields: [c.name],
        actions: {
            "removeConfirmation":  () =>
                deleteCatalogFromLicenseTemplate({templateId: templateId, catalogId: c.id})
                .then(({data: {deleteCatalogFromLicenseTemplate: {error}}}) => {
                    setError(error ? parseBackendMessage(error, t): null);
                })
        }
    })): [];

    //onSave, onCancel
    const onSave = () => {
        saveAndReset();
        navigate(`${props.uri}${props.location.search}`);
    };
    const onCancel = () => {
        setDataSource(null);
        navigate(`${props.uri}${props.location.search}`);
    };

    //rendering
    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">
                {error &&
                <Alert className="mb-0" color="danger">{error}</Alert>}

                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <div className="container">
                            <NamedBlock name={t('appService.templates')}>
                                <ListWithSort headers={[t('appService.views.TemplateView.id'), t('appService.views.TemplateView.name'),
                                                        t('appService.views.TemplateView.prefix'), t('appService.views.TemplateView.duration'),
                                                        t('appService.views.TemplateView.eventsNumber'), t('appService.views.TemplateView.studentsNumber'),
                                                        t('appService.views.TemplateView.size')]}
                                              sizes={["45*", "150*", "60*", "60*", "60*", "60*", "60*"]}
                                              rows={templates} nameIndex="1" />
                            </NamedBlock>
                        </div>
                    </div>
                </div>

                {mode === "view" && templateSource &&
                <PanelModal show title={t('appService.views.TemplateView.editTemplate')} noPadding scrollable onClose={onCancel}>
                    <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={onSave} onCancel={() => setDataSource(null)} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <NamedBlock name={t('appService.views.TemplateView.params')}>
                            <GeneratorBlock data={dataSource} items={serviceStaticPrototypes.licenseTemplate} onChange={onChange} onValidate={onValidate} wide />
                        </NamedBlock>

                        <NamedBlock name={t('appService.views.TemplateView.catalogs')}>
                            <ListWithSort rows={catalogs} />
                        </NamedBlock>
                    </div>
                </PanelModal>}

                {mode === "catalog" &&
                <PanelModal show title={t('appService.views.TemplateView.addCatalog2')} onClose={onCancel} noPadding scrollable>
                    <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={onSave} onCancel={onCancel} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                            items={[{
                                type: "select",
                                nullOption: t('appService.views.TemplateView.chooseTemplate'),
                                name: t('appService.views.TemplateView.templates'),
                                field: "templateId",
                                required: true,
                                options: licenseTemplates && licenseTemplates.map(t => ({name: t.name, value: t.id}))
                            },{
                                type: "select",
                                nullOption: t('appService.views.TemplateView.chooseCatalog'),
                                name: t('appService.views.TemplateView.catalogs2'),
                                field: "catalogId",
                                required: true,
                                options: flatCatalogsAndEntries && flatCatalogsAndEntries.catalogs &&
                                         flatCatalogsAndEntries.catalogs.filter(c => !c.parentId)
                                                                     .map(c => ({name: c.name, value: c.id}))
                            }]}/>

                    </div>
                </PanelModal>}

                {mode === "licenses" &&
                <PanelModal show title={t('appService.views.TemplateView.createLicenses2')} onClose={onCancel} noPadding scrollable>
                    <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={onSave} onCancel={onCancel} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                            items={[{
                                type: "select",
                                nullOption: t('appService.views.TemplateView.chooseTemplate'),
                                name: t('appService.views.TemplateView.templates'),
                                field: "templateId",
                                required: true,
                                options: licenseTemplates && licenseTemplates.map(t => ({name: t.name, value: t.id}))
                            },{
                                type: "int",
                                name: t('appService.views.TemplateView.licensesNumber'),
                                min: 1,
                                max: 100,
                                field: "numberOfLicenses",
                                required: true
                            }]}/>

                    </div>
                </PanelModal>}
            </div>
        </div>
    );
});

export default TemplateView;
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import classnames from 'classnames'

import {Col, Button} from 'reactstrap'
import EnumVal from './EnumVal'
import FileVal from './FileVal'
import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'
import ModalSelectImageFromDB from 'appAdmin/modals/ModalSelectImageFromDB'

import {upsertImage} from 'appAdmin/data/adminMutations'
import {parseBackendMessage} from 'utils/translationUtils'


const IMAGE_FROM_DB = 'fromdb';
const IMAGE_FROM_URL = 'url';
const IMAGE_EMBED = 'embed';

const ImageVal = compose(upsertImage
)((props) => {
    const {wide, name, unit, info, infoImage, disabled, data, collectionsDict, field, noLabel, onChange, upsertImage} = props;
    const [error, setError] = useState(props.error);

    const value = data && field && data[field];

    const [imageUploadType, setImageUploadType] = useState(null);
    const [isModalSelectOpen, setIsModalSelectOpen] = useState(false);
    const {t} = useTranslation();

    const imageIdFromValue = value && value.startsWith('/images/') && Number(value.substring(8));

    const stuff = [{"value": IMAGE_FROM_DB, "name": t('components.fields.ImageVal.database')},
                   {"value": IMAGE_FROM_URL, "name": t('components.fields.ImageVal.url')},
                   {"value": IMAGE_EMBED, "name": t('components.fields.ImageVal.embed')}];

    const component =
        <>
        <div>
            <div className="me-2">
                <EnumVal noLabel data={{value: imageUploadType}} field="value" disabled={disabled}
                         onChange={(dummyData, dummyField, value) => {
                             setImageUploadType(value);
                         }}
                         stuff={stuff} />

                {imageUploadType === IMAGE_FROM_DB &&
                <>
                    <Button color="primary" onClick={() => setIsModalSelectOpen(true)} disabled={disabled}>{t('components.fields.ImageVal.choose')}</Button>
                    {isModalSelectOpen &&
                    <ModalSelectImageFromDB queryShowCatalogImages
                                            licenseId={collectionsDict.licenseId}
                                            selectedImageId={imageIdFromValue}
                                            onSelectImageId={id => {
                                                onChange(data, field, `/images/${id}`);
                                                setIsModalSelectOpen(false)
                                            }}
                                            onClose={() => setIsModalSelectOpen(false)}
                                            loading={{}} errors={{}} />}
                </>}

                {imageUploadType === IMAGE_FROM_URL &&
                <input className={classnames("form-control", error && "is-invalid")} type="text"
                       value={value || ""} disabled={disabled} onChange={(e) => onChange(data, field, e.nativeEvent.target.value)} />}

                {imageUploadType === IMAGE_EMBED &&
                    <FileVal allowUpload noLabel disabled={disabled} validExtensionsList={["jpg", "jpeg", "gif", "png", "svg"]}
                             onChange={(dummyData, dummyField, value) => {
                                 upsertImage({licenseId: collectionsDict.licenseId,
                                              eventId: collectionsDict.eventId,
                                              scenarioId: collectionsDict.scenarioId,
                                              file: value})
                                    .then(({data: {upsertImage: {image, error}}}) => {
                                        if (!error) {
                                            onChange(data, field, `/images/${image.id}`);
                                        }
                                        setError(error ? parseBackendMessage(error, t): null);
                                 });
                             }} />}

                {!value && t('components.fields.ImageVal.notChosen')}

                {value &&
                <div className="border border" style={{maxWidth: "200px", maxHeight: "200px"}}>
                    <img className="border border-dark" src={value} alt="preview" style={{maxWidth: "200px", maxHeight: "200px"}} />
                </div>}
            </div>

        </div>
        {error &&  <Error id={field} error={error} />}
        </>;


    return (
        <div className="form-group row g-0">
            {noLabel && component}
            {!noLabel &&
            <>
            <Col sm={wide ? 2 : 3}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
            </Col>
            <Col sm={wide ? 10 : 9}>
                {component}
            </Col>
            </>}
        </div>);
});

export default ImageVal;
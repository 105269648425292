/**
 * Created by Yuri on 01.06.2017
 * Part of frontend
 */
export const restApi = {
    getData: function (request, body) {
        return fetch(
            `/rest/${request}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                credentials: "same-origin",
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    // promise by itself
                    return response.json()
                } else {
                    let error = new Error(response.statusText);
                    error.response = response;
                    return Promise.reject(error.message);
                }
            }, (error) => {
                console.warn(error);
                return Promise.reject(error.message)
            });
    },

    getDataWithStatus: function (request, body) {
        return fetch(
            `/rest/${request}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                credentials: "same-origin",
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    // promise itself
                    return response.json().then(json => {
                        console.log(`return Promise.resolve({status: ${response.status}, data: ${json}})`);
                        return Promise.resolve({status: response.status, data: json});
                    })
                } else {
                    let error = new Error(response.statusText);
                    error.response = response;
                    console.log(`return Promise.reject({status: ${response.status}, error: ${error}})`);
                    return Promise.reject({status: response.status, error: error.message});
                }
            }, (error) => {
                console.warn(error);
                console.log(`return Promise.reject({status: null, error: ${error}})`);
                return Promise.reject({status: null, error: error.message})
            });
    }
};

import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Button} from 'reactstrap'
import {CSVLink} from 'react-csv'
import useSmartNavigate from 'hooks/useSmartNavigate'

import FileUpload from '../components/FileUpload'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import Messages from 'components/elements/Messages'
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryStudentsWithUsers, queryStudentGroups, queryStudentGroupAssignments} from '../../data/adminQueries'
import {createStudentGroup, updateStudentGroup, createStudentGroupAssignment,
        deleteStudentGroupAssignment, uploadData} from '../../data/adminMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {breadcrumbsStore} from 'appBase/TopNav'
import {ADMIN_STUDENTS_VIEW, STUDENT_GROUP, STUDENT_GROUP_ASSIGNMENT} from 'constants/constants'
import useDebug from '../../../hooks/useDebug'


const EditAssignmentView = compose(
    createStudentGroupAssignment,
    withGeneratorDataSource)
((props) => {
    const {groupId, studentsWithUsers,
        dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate,
        createStudentGroupAssignment, uri, location} = props;

    useDebug("EditAssignmentView", props);

    const [errorMessages, setErrorMessages] = useState({});
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const groupAssignmentData = useMemo(() => ({}), []);

    useEffect(() => {
        setDataSource(
            groupAssignmentData,
            (data) => {
                data = {studentGroupId: groupId, studentId: data.studentId};
                createStudentGroupAssignment(data)
                    .then(({data: {createStudentGroupAssignment: {studentGroupAssignment, error}}}) => {
                        setErrorMessages({...errorMessages, [STUDENT_GROUP_ASSIGNMENT]: error ? parseBackendMessage(error, t): null });
                        navigate(`${uri}${error ? "/addAssignment": ""}${location.search}`);
                    });
            });
    }, [groupAssignmentData, dataSource, setDataSource, t, createStudentGroupAssignment,
        errorMessages, groupId, uri, location.search]);

    const onCancelEdit = () => {
        setDataSource(null);
        setErrorMessages({});
        navigate(`${uri}${location.search}`);
    };

    return (
        <>
            <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                              onSave={saveAndReset} onCancel={onCancelEdit} />
            <Messages error={errorMessages[STUDENT_GROUP_ASSIGNMENT]} />
            <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                items={[{
                                    type: "select",
                                    nullOption: t('appAdmin.views.studentViews.GroupView.notSelected'),
                                    name: t('appAdmin.views.studentViews.GroupView.students'),
                                    field: "studentId",
                                    required: true,
                                    options: studentsWithUsers && studentsWithUsers.map(({student, user}) => ({name: `${user && user.name} ${student.email}`, value: student.id}))
                                }]}/>
            </div>
    </>);
});


const GroupView = compose(
    withUriParams([
        ['mode', String, {optional: true, values: ['edit', 'addAssignment']}]]),
    queryStudentsWithUsers,
    queryStudentGroups,
    queryStudentGroupAssignments,
    createStudentGroup,
    updateStudentGroup,
    deleteStudentGroupAssignment,
    uploadData("studentGroupAssignments", ['StudentGroupsQuery', 'StudentGroupAssignmentsQuery']),
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['studentGroups', 'studentGroupAssignments', 'studentsWithUsers']))
((props) => {
    const {licenseId, groupId, mode, disabled, studentsWithUsers, studentGroups, studentGroupAssignments,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate,
           deleteStudentGroupAssignment,
           uploadStudentGroupAssignments, uri, location} = props;

    useDebug("GroupView", props);

    const [errorMessages, setErrorMessages] = useState({});

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const group = studentGroups && studentGroups.find(g => g.id === groupId);

    // group object for dataSource
    const groupData = useMemo(() => {
        return groupId === 0 ? {} : group;
    }, [groupId, group]);

    //links
    const containerId = ADMIN_STUDENTS_VIEW + "child";
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.studentViews.GroupView.groups'), to: `${uri}${location.search}`}];
    if (group) {
        links.push({name: group.name, to: `${uri}/${group.id}${location.search}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    // setting dataSource
    // edit: group dataSource
    // add:
    useEffect(() => {
        setDataSource(
            groupData,
            (data) => {
                let mutation;
                if (groupId > 0) {
                   mutation = 'updateStudentGroup';
                   data = entityUtils.filterFields(data, ['id','name']);
                } else {
                   mutation = 'createStudentGroup';
                   data = {licenseId: licenseId, name: data.name};
                }
                props[mutation](data)
                    .then(({data: {[mutation]: {studentGroup, error}}}) => {
                        setErrorMessages({...errorMessages, [STUDENT_GROUP]: error ? parseBackendMessage(error, t): null });
                        // was new group created?
                        if (groupId === 0 && studentGroup && !error) {
                            navigate(`${uri}/../${studentGroup.id}${location.search}`);
                        } else {
                            navigate(`${uri}${error ? "/edit": ""}${location.search}`);
                        }
                  });
            });
    }, [mode, licenseId, groupId, groupData, dataSource, setDataSource, t, errorMessages, uri, location.search]);

    // subnavigate

    if (groupId !== 0 && !group) {
        navigate(`${uri}/..${location.search}`);
        return null;
    }

    // rendering

    const studentGroupAssignmentsExport = studentGroupAssignments
        .filter(a => a.studentGroup.id === groupId)
        .map(a => [a.student.email]);

    // conditions
    const showExportButton = studentGroupAssignmentsExport && studentGroupAssignmentsExport.length > 0;
    const isExistingGroup = groupId > 0;

    const groupAssignments =
            studentGroupAssignments
                .map(a => {
                    const swu = studentsWithUsers.find(({student, user}) => student.id === a.student.id);
                    return {
                        fields: [
                            a.student.email,
                            (swu && swu.user) ? swu.user.name: ""
                        ],
                        actions: {
                            "removeConfirmation": !disabled && (() => {
                                deleteStudentGroupAssignment(a.id);
                            })
                        }
                    };
                });

    if (!disabled) {
        groupAssignments.push({
            fields: [t('appAdmin.views.studentViews.GroupView.addToGroup')],
            className: "text-end",
            notSelectable: true,
            actions: {
                "click": () => {
                    navigate(`${uri}/addAssignment${location.search}`);
                }
            }
        });
    }

    const onCancelEdit = () => {
        setDataSource(null);
        setErrorMessages({});
        navigate(`${uri}${location.search}`);
    };

    return (<>
        {!disabled &&
        <SaveButtonsBlock withEditMode
                          isChanged={isChanged} hasErrors={hasErrors}
                          onSave={mode === 'edit' && saveAndReset}
                          onCancel={mode === 'edit' && onCancelEdit}
                          actions={!disabled && [
                              mode !== 'edit' && {
                                  name: t('appAdmin.views.studentViews.GroupView.edit'),
                                  allowOnErrors: true,
                                  action: () => {
                                      navigate(`${uri}/edit${location.search}`)
                                  }}]}
        />}
        <Messages errors={{...errorMessages, ...props.errors}} />
        <div className="scroll flex-grow workpanel-gray">
            <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                            items={[{
                                name: t('appAdmin.views.studentViews.GroupView.groupName'),
                                type: "string",
                                field: "name",
                            }]}
                            disabled={disabled || mode !== 'edit'} />

            {isExistingGroup &&
            <>
                {showExportButton &&
                <div>
                    <CSVLink uFEFF data={studentGroupAssignmentsExport} separator=";"
                             filename={"group assignments"}>
                            <Button className="mt-2" color="primary">{t('appAdmin.views.studentViews.GroupView.export')}</Button>
                    </CSVLink>
                </div>}

                <NamedBlock name={t('appAdmin.views.studentViews.GroupView.students2')} className="bg-light">
                    <ListWithSort headers={[t('appAdmin.views.studentViews.GroupView.email'),
                                            t('appAdmin.views.studentViews.GroupView.name')]}
                                  rows={groupAssignments} disabled={disabled} />
                </NamedBlock>

                {mode === 'addAssignment' &&
                <PanelModal show title={t('appAdmin.views.studentViews.GroupView.addToGroup2')} onClose={onCancelEdit} noPadding scrollable>
                    <EditAssignmentView licenseId={licenseId}
                                        groupId={groupId}
                                        studentsWithUsers={studentsWithUsers}
                                        errors={props.errors} loading={props.loading}
                                        uri={uri} location={location}/>
                </PanelModal>}

                {!disabled &&
                <>
                <div className="text-muted">{t('appAdmin.views.studentViews.GroupView.fileFormat')}</div>
                <FileUpload name={t('appAdmin.views.studentViews.GroupView.import')}
                            uploadMutation={(data) => uploadStudentGroupAssignments({
                                licenseId: licenseId, type: "studentGroupAssignments",
                                groupId: groupId, ...data})}
                            mutationResultsName="uploadData"
                            validExtensionsList={["xlsx"]} disabled={disabled} />
                </>}
            </>}
        </div>
    </>);
});

export default GroupView;
/**
 * Created by Yury on 19.11.2017.
 */
import React, {Component} from 'react';

import echarts from 'echarts/dist/echarts';
import ReactEcharts from 'echarts-for-react';

// import ReactEchartsCore from 'echartsInstance-for-react/lib/core';
// import echartsInstance from 'echartsInstance/lib/echartsInstance';
// import 'echartsInstance/lib/chart/line';
// import 'echartsInstance/lib/component/axis'
// import 'echartsInstance/lib/component/legend'
// import 'echartsInstance/lib/component/tooltip'
// import 'echartsInstance/lib/component/axisPointer';
// import 'echartsInstance/lib/component/dataZoom';


export default class XYChart extends Component {
    static buildOption(props, width) {
        // if (width === 0) {
        //     return null;
        // }

        console.log(`>> XYChart.BuildOption, data: ${props.data && props.data.length}`);

        const getNameWithUnit = (s) => s.name + (s.unit ? ', ' + s.unit : '');

        const {legendInside} = props;

        let {xSeries, ySeriesArr, data, dataZoom} = props;
        xSeries = xSeries ? xSeries : [{name: "Time", unit: "sec", field: "time"}];
        ySeriesArr = ySeriesArr ? ySeriesArr : [];
        data = data ? data : [];

        const isScatter = xSeries.field !== 'time' && xSeries.field !== 't';

        dataZoom = !isScatter && dataZoom;

        // xSeries:     { name, unit, field }
        // ySeriesArr: [{ name, unit, field }]

        // group by indices
        const seriesAxisIndices = [];
        const groupKeyToAxisIndexMap = {};
        const axisInfo = [];
        ySeriesArr.forEach((s) => {
            const groupKey = s.unit;
            let axisIndex;
            if (groupKeyToAxisIndexMap.hasOwnProperty(groupKey)) {
                axisIndex = groupKeyToAxisIndexMap[groupKey];
            } else {
                axisIndex = axisInfo.length;
                groupKeyToAxisIndexMap[groupKey] = axisIndex;
                axisInfo.push({
                    unit: s.unit
                });
            }
            seriesAxisIndices.push(axisIndex);
        });

        // const LEFT_OFFSET = MARGIN + AXIS_Y_WIDTH;
        // const RIGHT_OFFSET = MARGIN + AXIS_Y_WIDTH * (axisInfo.length >= 1 ? axisInfo.length - 1 : 0) + (legendInside ? LEGEND_WIDTH : 0);
        // const TOP_OFFSET = MARGIN + AXIS_X_HEIGHT;
        // const BOTTOM_OFFSET = MARGIN + AXIS_X_HEIGHT + (dataZoom ? ZOOM_BAR_HEIGHT : 0);

        let option = {
            // grid: {
            //     left: LEFT_OFFSET,
            //     right: RIGHT_OFFSET,
            //     top: TOP_OFFSET,
            //     bottom: BOTTOM_OFFSET
            // },
            tooltip: {
                trigger: 'axis'
            },
            legend: legendInside
                ? {
                    type: 'scroll',
                    //orient: 'vertical',
                    orient: 'horizontal',
                    // left: width - LEGEND_WIDTH + MARGIN / 2,
                    // //right: MARGIN / 2,
                    // top: MARGIN / 2,
                    // bottom: MARGIN / 2,

                    data: ySeriesArr.map(s => ({name: getNameWithUnit(s), icon: 'rect'}))
                }
                : {},
            // toolbox: {
            //     feature: {
            //         dataZoom: {
            //             yAxisIndex: 'none'
            //         },
            //         restore: {},
            //         saveAsImage: {}
            //     }
            // },
            dataZoom: dataZoom
                ? [{
                    type: 'slider',
                    show: true,
                    start: 0,
                    end: 100,
                    filterMode: 'weakFilter',
                    xAxisIndex: 0
                },
                    {
                        type: 'inside',
                        start: 0,
                        end: 100,
                        filterMode: 'weakFilter',
                        xAxisIndex: 0
                    }]
                : null,
            xAxis: {
                type: isScatter ? 'value' : 'time',
                scale: true,    // should not contain 0 for x axis
                name: xSeries.unit,
                boundaryGap: false,
                splitLine: {
                    show: false
                },
                //data: data.map(d => d[xSeries.field])
            },
            yAxis: axisInfo.length > 0
                ? axisInfo.map((ai, i) => ({
                    type: 'value',
                    scale: isScatter,   // should not contain 0 when scatter, and should contain when regular line
                    name: ai.unit,
                    position: i === 0 ? 'left' : 'right',
                    // offset: i === 0 ? 0 : AXIS_Y_WIDTH * (i - 1)
                }))
                : [{}],
            series: ySeriesArr.map((s, i) => ({
                name: getNameWithUnit(s),
                type: isScatter ? 'scatter' : 'line',
                yAxisIndex: seriesAxisIndices[i],
                symbol: isScatter ? 'circle' : 'rect',
                sampling: 'average',
                data: data.map(d => [d[xSeries.field], d[s.field]])
                    // .filter((x,i) => i%1000 === 0)
                //data: data.map(d => d[s.field])
            })),
            animation: false
        };

        return option;
    }

    static getValueHash(props) {
        let {xSeries, ySeriesArr} = props;
        const seriesToString = (x) => x && [x.name, x.field, x.unit].join(',');
        return [seriesToString(xSeries), ySeriesArr && ySeriesArr.map(y => seriesToString(y)).join(';')].join(';');
    }

    constructor(props) {
        super(props);
        console.log(`>>>> Chart ${props.name} constructor! data: ${props.data && props.data.length}` );
        // this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
        // this.onResize = this.onResize.bind(this);
        this.onReady = this.onReady.bind(this);
        this.state = {
            key: 0,
            valueHash: XYChart.getValueHash(props),
            isVisible: false,
            isMounted: false,
            isReady: false,
            // width: 0,
            // height: 0,
            // option: null,
            option: XYChart.buildOption(props, null),
            echartsInstance: null
        }
    }

    componentDidMount() {
        console.log(`Chart ${this.props.name} mounted!`);
        this.setState((prevState) => ({
            isMounted: true
        }));
    }

    componentWillReceiveProps(nextProps) {
        // if changed data we reset
        const newValueHash = XYChart.getValueHash(nextProps);
        if (nextProps.data !== this.props.data || this.state.valueHash !== newValueHash) {
            console.log(`>> componentWillReceiveProps: UPDATE Chart ${this.props.name}`);
            console.log(`${nextProps.data !== this.props.data ? "Different DATA!!" : ""} ${this.state.valueHash !== newValueHash ? "Different HASH!!": ""}`);
            this.setState((prevState) => ({
                // key: prevState.key + 1,
                valueHash: newValueHash,
                // isVisible: false,
                // isReady: false,
                option: XYChart.buildOption(nextProps, prevState.width),
                //echartsInstance: null
            }));
        }
    }

    // onVisibilityChanged(isVisible) {
    //     // if (this.state.isVisible !== isVisible) {
    //     //     console.log(`Chart ${this.props.name} visible!`);
    //     //     this.setState((prevState) => ({
    //     //         isVisible: isVisible
    //     //     }));
    //     //
    //     // }
    // }

    // onResize(width, height) {
    //     const {autoResize} = this.props;
    //     const {isMounted,  isReady} = this.state;
    //     console.log(`Chart ${this.props.name} resized ${width}x${height} ${autoResize && "autoResize"}!`);
    //     if ((this.state.width !== width || this.state.height !== height) && autoResize && isMounted && /*isVisible &&*/ isReady && this.echartsInstance) {
    //         // this.echartsInstance.resize();
    //         this.setState((prevState) => ({
    //             key: prevState.key + 1,
    //         }));
    //     }
    //     this.setState((prevState) => ({
    //         width: width,
    //         height: height,
    //         // option: XYChart.buildOption(this.props, width),
    //     }));
    // }

    onReady() {
        console.log(`Chart ${this.props.name} is ready!`);
        this.setState((prevState) => ({
            isReady: true
        }));
    }

    render() {
        const {theme} = this.props;
        const {isMounted, key, option} = this.state;

        console.log(`RENDER: Chart key=${key} render ${isMounted && "mounted"}`);

        // const style = //{minWidth: "100px", minHeight: "100px"};
        // if (width) {
        //     style.width = width + "px";
        //     let height = Math.floor(
        //         Math.max(
        //             width - LEGEND_WIDTH - AXIS_Y_WIDTH * (
        //                 option && option.yAxis && option.yAxis.length > 1 ? option.yAxis.length - 1 : 0),
        //             LEGEND_WIDTH)
        //         * 2/3);
        //     style.height = height + "px"
        // }
        // const style={width: "80%", height: "400px"};

        // console.log(`width: ${width}, style: width: ${style && style.width}, height: ${style && style.height}`);

        return (
            // <React.Fragment>
            <div>
                {/*<VisibilitySensor key="V" onChange={this.onVisibilityChanged} />*/}
                {/*<div>*/}
                    {/*<ReactResizeDetector key="R" handleWidth onResize={this.onResize} />*/}
                {/*</div>*/}
                {/*isVisible &&*/ isMounted && option &&
                     <ReactEcharts//Core
                        // key={key}
                        // style={style ? {...style, minWidth: "100px", minHeight: "100px"} : {minWidth: "100px", minHeight: "100px"}}
                        echarts={echarts}
                        option={option}
                        notMerge
                        lazyUpdate
                        theme={theme ? theme : undefined}
                        onChartReady={this.onReady}
                        //loadingOption
                        //showLoading
                        //onEvents={EventsDict}
                        // ref={(e) => {
                        //     // console.log(`Chart ${name} e ${e}`);
                        //     if (e) {
                        //         const inst = e.getEchartsInstance();
                        //         // console.log(`Chart ${name} inst ${inst}`);
                        //         if (inst) {
                        //             this.echartsInstance = inst;
                        //         }
                        //     } else {
                        //         this.echartsInstance = null;
                        //     }
                        // }}
                    />}
            </div>
            // </React.Fragment>
            );
    }
}
import React, {Component} from 'react';

import entityUtils from 'utils/entityUtils';
import calculateExpression from 'constants/expressions';


export default class ExpressionsBlock extends Component {
    constructor(props) {
        super(props);
        // setting new values (new stored state) - part 1
        const {field, data} = props;
        // field can be "", and it is a valid field
        const value = data && (field !== null && field !== undefined) && data[field];
        this.state = {
            isNoInput: value === null || value === undefined || value === "",
            isUndefinedInput: value === undefined,
            lastDefaultValue: undefined,
            minValue: undefined,
            maxValue: undefined
        };
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        // setting new values (new stored state) - part 2
        const {isNoInput, isUndefinedInput} = this.state;
        this.ensureExpressions(this.props, isNoInput, isUndefinedInput);
    }

    componentWillUnmount() {
        // reseting old values to null (we will lose stored state, so transfer it into data)
        const {field, data, onChange, isAllowNulls} = this.props;
        const {isNoInput, isUndefinedInput} = this.state;
        // on unmount the field could be deleted already (like item in list)
        if (isNoInput && entityUtils.doesFieldExist(data, field)) {
            if (isAllowNulls && isUndefinedInput) {
                onChange(data, field, undefined, true);
            } else {
                onChange(data, field, null, true);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        let {isNoInput, isUndefinedInput} = this.state;
        // new context?
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field) {
            // we no longer need our current state (it corresponds to old data, so no chance to call onChange as it is replaced upstream)
            //  (upstream 'withOldSaveManager' should have used its own 'nullValues' to restore the old data' state)
            // setting new values (new stored state)
            let {field, data} = nextProps;
            // field can be "", and it is a valid field
            const value = data && (field !== null && field !== undefined) && data[field];
            isNoInput = value === null || value === undefined || value === "";
            isUndefinedInput = value === undefined;
            this.setState(prevProps => ({
                isNoInput: isNoInput,
                isUndefinedInput: isUndefinedInput,
                lastDefaultValue: undefined,
                minValue: undefined,
                maxValue: undefined
            }));
        } else {
            // data was updated, should we update stored state
            const {field, data, isAllowNulls, initialValue, defaultValue, defaultExpression} = nextProps;
            const {lastDefaultValue} = this.state;
            // field can be "", and it is a valid field
            const value = data && (field !== null && field !== undefined) && data[field];
            if (isNoInput && (initialValue !== undefined || defaultValue !== undefined || defaultExpression) && (!isAllowNulls || isUndefinedInput) &&
                (value !== lastDefaultValue)) {
                // we have value different from default value we have set before! Treat is as user-input:
                isNoInput = value === null || value === undefined || value === "";
                isUndefinedInput = value === undefined;
                this.setState(prevProps => ({
                    isNoInput: isNoInput,
                    isUndefinedInput: isUndefinedInput,
                    lastDefaultValue: undefined
                }));
            }
        }
        this.ensureExpressions(nextProps, isNoInput, isUndefinedInput);
    }

    ensureExpressions(props, isNoInput, isUndefinedInput) {
        const {
            initialValue, expression, defaultValue, defaultExpression, minExpression, maxExpression,
            isAllowNulls, data, fieldsInfo, field, collectionsDict
        } = props;
        // field can be "", and it is a valid field
        let value = data && (field !== null && field !== undefined) && data[field];
        let newValue = value;
        const callParentOnChange = (isInitialValue) => {
            if (newValue !== value && JSON.stringify(newValue) !== JSON.stringify(value)) {
                props.onChange(data, field, newValue, isInitialValue ? false : true);
            }
        };
        // initialValue if isUndefinedInput
        if (isNoInput && isUndefinedInput && initialValue !== undefined) {
            newValue = initialValue;
            // saving last default value
            this.setState(prevProps => {
                callParentOnChange(true);
                return {
                    lastDefaultValue: newValue,
                    isNoInput: false,
                    isUndefinedInput: false
                };
            });
        }// default if !isNoInput
        else if (isNoInput && (defaultValue !== undefined) && (!isAllowNulls || isUndefinedInput)) {
            newValue = defaultValue;
            // saving last default value
            this.setState(prevProps => {
                callParentOnChange();
                return {
                    lastDefaultValue: newValue
                };
            });
        } else if (isNoInput && defaultExpression && (!isAllowNulls || isUndefinedInput)) {
            newValue = calculateExpression(defaultExpression, field, data, fieldsInfo, collectionsDict);
            // saving last default value
            this.setState(prevProps => {
                callParentOnChange();
                return {
                    lastDefaultValue: newValue
                };
            });
        } else if (expression) {
            newValue = calculateExpression(expression, field, data, fieldsInfo, collectionsDict);
            callParentOnChange();
        }

        if (minExpression) {
            const minValue = calculateExpression(minExpression, field, data, fieldsInfo, collectionsDict);
            this.setState(() => ({minValue: minValue}));
        }
        if (maxExpression) {
            const maxValue = calculateExpression(maxExpression, field, data, fieldsInfo, collectionsDict);
            this.setState(() => ({maxValue: maxValue}));
        }
    }

    onChange(data, changedField, value, noChanges) {
        const {field} = this.props;
        if (changedField === field) {
            this.setState(prevProps => {
                this.props.onChange(data, changedField, value, noChanges);
                return {
                    isNoInput: value === null || value === undefined || value === "",
                    isUndefinedInput: value === undefined,
                    lastDefaultValue: undefined
                };
            });
        } else {
            // pass on
            this.props.onChange(data, changedField, value, noChanges);
        }
    };

    render() {
        let {isNoInput, minValue, maxValue} = this.state;
        const {className, style, expression, children, dynamicSectionName, disabled, data, fieldsInfo, collectionsDict, editLanguage, onValidate, wide} = this.props;

        const addProps = {};
        if (minValue !== undefined) {
            addProps.min = minValue;
        }
        if (maxValue !== undefined) {
            addProps.max = maxValue;
        }

        const components = React.Children.map(
            children,
            child => React.cloneElement(child, {
                className: className,
                style: style,
                isNoInput: isNoInput,
                dynamicSectionName: dynamicSectionName,
                disabled: child.props.disabled || disabled || expression,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: this.onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                wide: (child.props && child.props.wide) || wide,
                ...addProps
            }));

        return (
            // <React.Fragment>
            //<div className="form-group">
                components[0]
            //</div>
            // </React.Fragment>
        );
    }
};
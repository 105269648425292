import React from 'react';
import {Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';


function withSelectedTabState(WrappedComponent) {
    class WithSelectedTabState extends React.Component {
        constructor(props) {
            super(props);
            const {tabs} = props;
            this.onSelectTab = this.onSelectTab.bind(this);
            this.state = {
                selectedTabId: tabs && tabs.length > 0 ? tabs[0].id : null
            };
        }

        componentWillReceiveProps(nextProps) {
            const {selectedTabId} = this.state;
            if (nextProps.tabs !== this.props.tabs) {
                this.setState((prevState) => ({
                    selectedTabId:
                        nextProps.tabs && nextProps.tabs.find(s => s.id === selectedTabId) ? selectedTabId :
                            nextProps.tabs && nextProps.tabs.length > 0 ? nextProps.tabs[0].id :
                                null
                }));
            }
        }

        onSelectTab(newTabId) {
            if (this.state.selectedTabId !== newTabId) {
                this.setState((prevState) => ({
                    selectedTabId: newTabId
                }));
            }
        }

        render() {
            const {selectedTabId} = this.state;
            return (<WrappedComponent {...this.props}
                                      selectedTabId={selectedTabId}
                                      onSelectTab={this.onSelectTab} />);
        }
    }

    WithSelectedTabState.displayName = `WithSelectedTabState(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return WithSelectedTabState;
}


export function TabBlock(props) {
    const {className, selectedTabId, showSelectedTabName, children} = props;
    let {tabs} = props;
    tabs = tabs && tabs.filter(t => t);

    const haveTabs = tabs && tabs.length > 0;

    const hasImages = tabs.some(tab => tab.className);

    const selectedTabIndex = tabs && tabs.findIndex(s => s.id === selectedTabId);
    const selectedTab = tabs && tabs.find(s => s.id === selectedTabId);

    const filteredChildren = children && React.Children.map(
        children,
        (child, i) => {
            if (child.props.tab === selectedTabId) {
                return React.cloneElement(child, {className: classnames(child.props.className, "flex-grow")});
            } else {
                return null;
            }
        });

    return (
        <div className={classnames("tab-block", filteredChildren && "d-flex flex-column", className)}>
            {haveTabs &&
            <div className="border border-dark border-top-0 border-end-0 border-start-0"
                 style={{position: "relative"}}>
                <Nav tabs className="pb-3">
                    {tabs.map((tab, i) =>
                        <NavItem key={i} className="lg d-flex align-items-stretch justify-content-center"
                                 style={{
                                     // only first external tab should have this flag
                                     marginLeft: tab.external && tabs.find(s => s.external) === tab && 'auto'
                                 }}>
                            <NavLink className={classnames("d-flex align-items-center justify-content-center", {active: selectedTabId === tab.id, disabled: tab.disabled})}
                                     onClick={
                                         !tab.disabled && tab.onSelect ? () => tab.onSelect() :
                                             !tab.disabled && props.onSelectTab ? () => props.onSelectTab(tab.id) :
                                                 () => {}
                                     }>
                                {hasImages && [
                                    <div key="img" className={classnames("tab-icon", tab.className ? tab.className : "placeholder-tab-icon")} />,
                                    <span key="name">{tab.name}</span> ]}
                                {!hasImages && tab.name}
                            </NavLink>
                        </NavItem>)}
                </Nav>
                {haveTabs && selectedTabIndex >= 0 &&
                <div style={{
                    content: "",
                    position: "absolute",
                    left: (200*(selectedTabIndex+0.5) + 7) + "px",
                    bottom: "-1px",
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "0 10px 10px 10px",
                    borderColor: "transparent transparent #F7F5F7 transparent",
                    zIndex: 99
                }} />}
                {haveTabs && selectedTabIndex >= 0 &&
                <div style={{
                    content: "",
                    position: "absolute",
                    left: (200*(selectedTabIndex+0.5) + 5) + "px",
                    bottom: "-1px",
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "0 12px 12px 12px",
                    borderColor: "transparent transparent #C0CBDB transparent",
                    zIndex: 98
                }} />}
            </div>}

            {(showSelectedTabName || (filteredChildren && selectedTab && selectedTab.title)) &&
            <div className="workpanel-gray" style={{top: "-1px"}}>
                <h6>{selectedTab.title || selectedTab.name}</h6>
            </div>}
            {filteredChildren && filteredChildren}
        </div>);
}


export const TabBlockWithSelectedTabState = withSelectedTabState(TabBlock);


// export default function TabBlock(props) {
//     const {selectedTabId, tabs} = props;
//
//     if (selectedTabId) {
//         return <TabsComponent {...props} selectedTabId={selectedTabId} tabs={tabs}/>
//     } else {
//         return <TabsComponentWithSelectedTabState {...props} tabs={tabs}/>
//     }
// }
import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import {solutionFields, eventFields, SolutionsQuery} from './eventQueries'
import {studentTeamAssignmentsFields, LicenseEventSolutionsWithTeamsQuery} from 'appAdmin/data/adminQueries'

const RegisterInEvent = gql`
  mutation registerInEvent($eventId: ID!) {
    registerInEvent(eventId: $eventId) {
        result
        error
        event {
          ${eventFields}
        }
    }
  }
`;


const DeleteScenarioScenarioUserCustomization = gql`
  mutation deleteScenarioScenarioUserCustomization($id: ID!, $asRole: String) {
    deleteScenarioScenarioUserCustomization(id: $id, asRole: $asRole) {
      id
      error
    }
  }
`;


const CreateSolution = gql`
  mutation createSolution($data: CreateSolutionMutationInput!, $asRole: String) {
    createSolution(data: $data, asRole: $asRole) {
      solution {
        ${solutionFields}
      }
      error
    }
  }
`;


const UpdateSolution = gql`
  mutation updateSolution($data: UpdateSolutionMutationInput!, $asRole: String) {
    updateSolution(data: $data, asRole: $asRole) {
      solution {
        ${solutionFields}
      }
      errors
    }
  }
`;


const DeleteSolution = gql`
  mutation deleteSolution($id: ID!, $asRole: String) {
    deleteSolution(id: $id, asRole: $asRole) {
      error
    }
  }
`;


const CloneSolution = gql`
  mutation cloneSolution($id: ID!, $asRole: String) {
    cloneSolution(id: $id, asRole: $asRole) {
      solution {
        ${solutionFields}
      }
      error
    }
  }
`;


const RecalcSolution = gql`
  mutation recalcSolution($id: ID!, $asRole: String) {
    recalcSolution(id: $id, asRole: $asRole) {
      solution {
        ${solutionFields}
      }
      error
    }
  }
`;


const UpdateEventUserInfo = gql`
  mutation updateEventUserInfo($data: UpdateEventUserInfoMutationInput!) {
    updateEventUserInfo(data: $data) {
      eventUserInfo {
        id
        isWelcomeSeen
        competitionSessionStartedAt
      }
    }
  }
`;


const CreateUserTeam = gql`
  mutation createUserTeam($data: CreateUserTeamMutationInput!) {
    createUserTeam(data: $data) {
      studentTeam {
        id
        eventId
        name
      }
      error
    }
  }
`;


const JoinUserTeam = gql`
  mutation joinUserTeam($teamId: ID!) {
    joinUserTeam(teamId: $teamId) {
      studentTeamAssignment {
        ${studentTeamAssignmentsFields}
      }
      error
    }
  }
`;


const ExitFromUserTeam = gql`
  mutation exitFromUserTeam($teamId: ID!) {
    exitFromUserTeam(teamId: $teamId) {
      id
    }
  }
`;


export const registerInEvent =
    graphql(RegisterInEvent, {
        props: ({ mutate }) => ({ registerInEvent: (eventId) => mutate({ variables: { eventId: eventId } }) }),
        options: { refetchQueries: ['EventsQuery']}
    });


export const deleteScenarioScenarioUserCustomization = graphql(DeleteScenarioScenarioUserCustomization, {
    props: ({ mutate }) => ({ deleteScenarioScenarioUserCustomization: (id, asRole) => mutate({ variables: { id, asRole } }) }),
    options: { refetchQueries: ['LicenseScenarioQuery', 'ScenarioQuery']}
});


export const createSolution =
    graphql(CreateSolution, {
        props: ({ mutate }) => ({ createSolution: (data, asRole) => mutate({ variables: { data, asRole } }) }),
        options: ({ scenarioId, queryAsRole }) => ({ refetchQueries: [
            {query: SolutionsQuery, variables: {scenarioId, asRole: queryAsRole}},
            'CatalogEntryVersionSolutionsQuery']})
    });


export const updateSolution =
    graphql(UpdateSolution, {
        props: ({ mutate }) => ({ updateSolution: (data, asRole) => mutate({ variables: { data, asRole } }) }),
    });


export const deleteSolution =
    graphql(DeleteSolution, {
        props: ({ mutate }) => ({ deleteSolution: (id, asRole) => mutate({ variables: { id, asRole } }) }),
        options: ({ scenarioId, queryAsRole, eventId }) => ({ refetchQueries: [
            {query: SolutionsQuery, variables: {scenarioId, asRole: queryAsRole}},
            {query: LicenseEventSolutionsWithTeamsQuery, variables: {eventId}},
            'CatalogEntryVersionSolutionsQuery']})
    });


export const cloneSolution =
    graphql(CloneSolution, {
        props: ({ mutate }) => ({ cloneSolution: (id, asRole) => mutate({ variables: { id, asRole } }) }),
        options: ({ scenarioId, queryAsRole }) => ({ refetchQueries: [
            {query: SolutionsQuery, variables: {scenarioId, asRole: queryAsRole}},
            'CatalogEntryVersionSolutionsQuery']})
    });


export const recalcSolution =
    graphql(RecalcSolution, {
        props: ({ mutate }) => ({ recalcSolution: (id, asRole) => mutate({ variables: { id, asRole } }) }),
        options: { refetchQueries: ['SolutionQuery']}
    });


export const updateEventUserInfo =
    graphql(UpdateEventUserInfo, {
        props: ({ mutate }) => ({ updateEventUserInfo: (data) => mutate({ variables: { data } }) }),
        options: { refetchQueries: ['EventUserInfoQuery']}
    });


export const createUserTeam =
    graphql(CreateUserTeam, {
        props: ({ mutate }) => ({ createUserTeam: (data) => mutate({ variables: { data } }) }),
        options: { refetchQueries: ['UserTeamQuery', 'EventTeamsWithStudentsQuery']}
    });


export const joinUserTeam =
    graphql(JoinUserTeam, {
        props: ({ mutate }) => ({ joinUserTeam: (teamId) => mutate({ variables: { teamId } }) }),
        options: { refetchQueries: ['UserTeamQuery', 'EventTeamsWithStudentsQuery']}
    });


export const exitFromUserTeam =
    graphql(ExitFromUserTeam, {
        props: ({ mutate }) => ({ exitFromUserTeam: (teamId) => mutate({ variables: { teamId } }) }),
        options: { refetchQueries: ['UserTeamQuery', 'EventTeamsWithStudentsQuery']}
    });



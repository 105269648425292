import React, { useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useDebug from 'hooks/useDebug'

import {breadcrumbsStore} from 'appBase/TopNav'

import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import {SERVICE_EMBED_VIEW} from 'constants/constants'

import {queryGhostEmbedImages} from '../data/serviceQueries'
import {deleteImage} from 'appAdmin/data/adminMutations'

const EmbedView = compose(
     queryGhostEmbedImages,
     deleteImage,
     withInitialDataLoadWaiting(['ghostEmbedImages'])
)((props) => {
    useDebug("EmbedView", props);
    const {deleteImage} = props;
    const {t} = useTranslation();

    // preparing links
    breadcrumbsStore.register(SERVICE_EMBED_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_EMBED_VIEW, {name: t('appService.embed'), to: `${props.uri}`}, true);
    });

    //ghostEmbedImages
    const ghostEmbedImages = props.ghostEmbedImages.map(image => ({
        fields: [
            image.id,
            image.licenseId,
            image.eventId,
            image.scenarioId,
            image.events && image.events.reduce(((acc , e) => acc + "\r\n" + e.licenseId + "/" + e.id + " , "), "[") + "]",
            image.scenarios && image.scenarios.reduce(((acc , s) => acc + s.eventId + "/" + s.id + " , "), "[") + "]",
            image.thumbnail &&
                <img src={image.thumbnail} alt={image.name} style={{maxWidth: "100px", maxHeight: "100px"}} />
            ],
        actions: {
            "removeConfirmation": () => deleteImage(image.id)
         }
    }));

    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">

                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.embed')}</h5>

                        <div className="container">
                            <NamedBlock>
                                <ListWithSort headers={[t('appService.views.EmbedView.id'), t('appService.views.EmbedView.licenseId'),
                                                        t('appService.views.EmbedView.eventId'), t('appService.views.EmbedView.scenarioId'),
                                                        t('appService.views.EmbedView.events'), t('appService.views.EmbedView.scenarios'),
                                                        t('appService.views.EmbedView.image')]}
                                              sizes={["45*", "45*", "45*", "45*", "150*", "200*", "100*"]}
                                              rows={ghostEmbedImages} />
                            </NamedBlock>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
});

export default EmbedView;
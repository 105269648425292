import calculateExpression from 'constants/expressions'
import entityUtils from 'utils/entityUtils'

export function calculateParamsFromLiveData(params, field, data, fieldsInfo, collectionsDict) {

    const parsedParams = {};

    params && params.forEach(p => {
        let value;
        if (!p.type || p.type === 'const') {
            value = p.value;
        } else if (p.type === 'expression') {
            value = calculateExpression(p.value, field, data, fieldsInfo, collectionsDict);
        } else if (p.type === 'collection') {
            value = entityUtils.get(collectionsDict, p.value);
        } else {
            throw new Error(`Unknown type ${p.type}`)
        }
        parsedParams[p.key] = value;
    });

    return parsedParams;
};

export function replaceVariables(field, parameters) {
    if ('variable' in parameters) {
        field = field.replace(/\*/g, parameters.variable);
    }
    return field;
};
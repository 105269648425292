import React from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {useLocation} from '@reach/router'
import useSmartNavigate from 'hooks/useSmartNavigate'

import FileSaver from 'file-saver'
import {Parser} from 'json2csv'
import PanelModal from 'components/modals/PanelModal'
import Block from 'components/fieldBlocks/Block'
import ListTableWithSort from 'components/blocks/ListTableWithSort'
import SaveButtonsBlock from '../../components/blocks/SaveButtonsBlock'

import {queryEventResults} from '../data/eventQueries'

import {toggleSearchParam} from 'utils/urlUtils'
import {toPrecision} from 'utils/mathUtils'
import {TOURNAMENT_TABLE_VIEW} from 'constants/constants'


const TournamentTableModal = compose(
    queryEventResults
)((props) => {

    const {withExportResults, user, eventResults} = props;

    if (!user || !user.id) {
        return null;
    }

    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const usersInfoIx = {};
    const scenariosIx = {};
    const userScoresByUserAndScenario = {};
    eventResults && eventResults
        .forEach(r => {
            if (!usersInfoIx[r.user.id]) {
                usersInfoIx[r.user.id] = {
                    user: r.user,
                    studentTeam: r.studentTeam,
                    uniqueUserName: r.uniqueUserName,
                    studentScoreCorrections: r.studentScoreCorrections
                };
            }
            if (!userScoresByUserAndScenario[r.user.id]) {
                userScoresByUserAndScenario[r.user.id] = {};
            }
            if (r.scenario) {
                if (!scenariosIx[r.scenario.id]) {
                    scenariosIx[r.scenario.id] = r.scenario;
                }
                userScoresByUserAndScenario[r.user.id][r.scenario.id] = r.score;
            }
        });
    const usersInfo = Object.keys(usersInfoIx).map(id => usersInfoIx[id]);
    const scenarios = Object.keys(scenariosIx).map(id => scenariosIx[id]).sort((a, b) => a.order < b.order);

    const teamsScoresIx = {};
    // team corrections and all scenarios (individual and team)
    eventResults && eventResults
        .filter(r => r.studentTeam)
        .forEach(r => {
            const teamId = r.studentTeam.id;
            if (!teamsScoresIx[teamId]) {
                teamsScoresIx[teamId] = {
                    team: r.studentTeam,
                    name: r.studentTeam.name,
                    teamScoreCorrections: r.teamScoreCorrections,
                    scenariosIx: {},
                    scenarios: []};
            }
            if (r.scenario) {
                const scenarioId = r.scenario.id;
                const ts = teamsScoresIx[teamId];
                if (!ts.scenariosIx[scenarioId]) {
                    const s = {...r.scenario, score: r.score};
                    ts.scenariosIx[s.id] = s;
                    ts.scenarios.push(s);
                }
                const s = ts.scenariosIx[scenarioId];
                if (s.score < r.score) {
                    s.score = r.score;
                }
            }
        });
    Object.keys(teamsScoresIx).map(id => teamsScoresIx[id])
        .forEach(ts => {
            ts.scenarios.sort((a, b) => a.order < b.order);
        });

    const usersScoresIx = {};
    usersInfo.forEach(userInfo => {
        const userId = userInfo.user.id;
        const teamId = userInfo.studentTeam && userInfo.studentTeam.id;
        if (!usersScoresIx[userId]) {
            usersScoresIx[userId] = {
                user: userInfo.user,
                team: userInfo.studentTeam,
                name: userInfo.uniqueUserName,
                studentScoreCorrections: userInfo.studentScoreCorrections,
                teamScoreCorrections: teamId && teamsScoresIx[teamId] && teamsScoresIx[teamId].teamScoreCorrections,
                scenarios: []
            };
        }
        // filling details by scenarios
        scenarios.forEach(scenario => {
            const scenarioId = scenario.id;
            const userScore = userScoresByUserAndScenario[userId][scenarioId];
            let maxScore = userScore;
            if (!scenario.isIndividual) {
                const teamScore =
                    teamId && teamsScoresIx[teamId] && teamsScoresIx[teamId].scenariosIx[scenarioId] &&
                    teamsScoresIx[teamId].scenariosIx[scenarioId].score;
                if (teamScore !== null && teamScore !== undefined) {
                    if (userScore !== null && userScore !== undefined) {
                        maxScore = Math.max(userScore, teamScore);
                    } else {
                        maxScore = teamScore;
                    }
                }
            }
            if (maxScore !== null && maxScore !== undefined) {
                const us = usersScoresIx[userId];
                us.scenarios.push({...scenario, score: maxScore, ownScore: userScore});
            }
        });
    });

    const aggregateScores = scores =>
        scores.map(sc => ({
            ...sc,
            individualScore:
                toPrecision(
                    sc.scenarios.filter(s => s.isIndividual).reduce((acc, s) => acc + s.score, 0)
                    + ((sc.studentScoreCorrections && sc.studentScoreCorrections.reduce((acc, s) => acc + s.score, 0)) || 0), 3, t),
            teamScore:
                toPrecision(
                    sc.scenarios.filter(s => !s.isIndividual).reduce((acc, s) => acc + s.score, 0)
                    + ((sc.teamScoreCorrections && sc.teamScoreCorrections.reduce((acc, s) => acc + s.score, 0)) || 0), 3, t),
            totalScore:
                toPrecision(
                    sc.scenarios.reduce((acc, s) => acc + s.score, 0)
                    + ((sc.studentScoreCorrections && sc.studentScoreCorrections.reduce((acc, s) => acc + s.score, 0)) || 0)
                    + ((sc.teamScoreCorrections && sc.teamScoreCorrections.reduce((acc, s) => acc + s.score, 0)) || 0), 3, t),
            scoreCorrections: [
                ...(sc.studentScoreCorrections || []),
                ...(sc.teamScoreCorrections || [])
            ].filter(x => x)
        }));

    // has only teamScoreCorrections
    const teamsScores = aggregateScores(Object.keys(teamsScoresIx).map(k => teamsScoresIx[k]).sort((a, b) => a.name < b.name));
    // has both team and student score corrections
    const usersScores = aggregateScores(Object.keys(usersScoresIx).map(k => usersScoresIx[k]).sort((a, b) => a.name < b.name));

    const getRows = (scores, scoreField) => {
        const filterIsIndividual = s => (s.isIndividual && scoreField !== 'teamScore') || (!s.isIndividual && scoreField !== 'individualScore');
        return scores
            .sort((r1, r2) => (r1[scoreField] > r2[scoreField]) ? -1 : 1)
            .map(tt => ({
                    fields: [tt.name, tt[scoreField]],
                    headers: [t('appEvent.modals.TournamentTableModal.scenario'), t('appEvent.modals.TournamentTableModal.grade')],
                    children: [
                        ...tt.scenarios
                            .filter(filterIsIndividual)
                            .map(s => ({
                                notSelectable: true,
                                fields: [
                                    s.name,
                                    s.hasOwnProperty('ownScore') && s.ownScore !== s.score
                                        ? (`${s.ownScore !== null && s.ownScore !== undefined ? s.ownScore : ''} (${s.score})`)
                                        : s.score]
                            })),
                        ...tt.scoreCorrections.map(sc => ({
                            notSelectable: true,
                            fields: [<span
                                className={`text-${sc.score < 0 ? 'danger' : 'success'}`}>{sc.achievement}</span>, sc.score]
                        }))
                    ]
                })
            );
    };

    const saveAdminRows = (scores, structure) => {
        const json2csvParser = new Parser({ fields: structure.map(x => x.field), delimiter: ";", header: false, withBOM: true });
        const rows = scores
            .sort((r1, r2) => (r1.totalScore > r2.totalScore) ? -1 : 1)
            .map(u => ({
                email: (u.user && u.user.email) || '',
                name: (u.user && u.user.name) || '',
                teamName: (u.team && u.team.name) || '',
                individualScore: u.individualScore,
                teamScore: u.teamScore,
                totalScore: u.totalScore
            }));

        rows.unshift(structure.reduce((o, column) => ({ ...o, [column.field]: column.header}), {}));
        const csv = json2csvParser.parse(rows);
        const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});

        FileSaver.saveAs(blob, "results.csv");
    };



    const sections = [{name: t('appEvent.modals.TournamentTableModal.users'), key: "users"}];
    sections.push({name: t('appEvent.modals.TournamentTableModal.teams'), key: "teams"});

    return (<PanelModal show title={t('appEvent.modals.TournamentTableModal.tournamentTable')}
                        onClose={() => {
                                navigate(
                                    `${location.pathname}${toggleSearchParam(location.search, TOURNAMENT_TABLE_VIEW)}`,
                                    {state: location.state});
                            }}
                        noPadding>
        <SaveButtonsBlock actions={[
                withExportResults && {
                    name: t('appEvent.modals.TournamentTableModal.saveUserData'),
                    action: () => saveAdminRows(usersScores, [
                        {field: "email", header: t('appEvent.modals.TournamentTableModal.email')},
                        {field: "name", header: t('appEvent.modals.TournamentTableModal.name')},
                        {field: "teamName", header: t('appEvent.modals.TournamentTableModal.teamName')},
                        {field: "individualScore", header: t('appEvent.modals.TournamentTableModal.individualScore')},
                        {field: "teamScore", header: t('appEvent.modals.TournamentTableModal.teamScore')},
                        {field: "totalScore", header: t('appEvent.modals.TournamentTableModal.individualTotalScore')}
                    ])
                },
                withExportResults && {
                    name: t('appEvent.modals.TournamentTableModal.saveTeamData'),
                    action: () => saveAdminRows(teamsScores, [
                        {field: "teamName", header: t('appEvent.modals.TournamentTableModal.teamName')},
                        {field: "individualScore", header: t('appEvent.modals.TournamentTableModal.individualScore2')},
                        {field: "teamScore", header: t('appEvent.modals.TournamentTableModal.teamScore2')},
                        {field: "totalScore", header: t('appEvent.modals.TournamentTableModal.teamTotalScore')}
                    ])
                }
            ]} />
        <div className="pt-3">
            {props.loading['eventResults'] && t('appEvent.modals.TournamentTableModal.loading')}
            {!props.loading['eventResults'] &&
            <Block sections={sections}>
                <Block section="users">
                    <div>
                        <ListTableWithSort headers={[t('appEvent.modals.TournamentTableModal.userName'), t('appEvent.modals.TournamentTableModal.grade')]} rows={getRows(usersScores, 'totalScore')}/>
                    </div>
                </Block>
                <Block section="teams">
                    <div>
                        <ListTableWithSort headers={[t('appEvent.modals.TournamentTableModal.teamName'), t('appEvent.modals.TournamentTableModal.grade')]} rows={getRows(teamsScores, 'teamScore')}/>
                    </div>
                </Block>
            </Block>}
        </div>
    </PanelModal>);
});

export default TournamentTableModal;
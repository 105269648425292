import React, {Children} from 'react'
import entityUtils from 'utils/entityUtils'

const languages = ["ru", "en"];

function MultiLanguageFieldBlock(props) {
    const {data, field, children, editLanguage, ...restProps} = props;
    const multiLanguageValue = entityUtils.safeJSONParse(data[field], {});

    const hasAllLanguagesFilled = languages.every(lang =>
        ![undefined, null, ""].includes(multiLanguageValue[lang]));

    // TODO: localData нельзя пересчитывать постоянно, она должна быть фиксированной. Посмотри как сделана data в withDataSource и сделай так же
    const localData = {
        [field]: multiLanguageValue[editLanguage]
    };

    const onChange = (localData, field, value) => {
        // TODO: data[field] должна быть JSON-объектом, и праситься из строки при получении данных от запроса, и в строку при сохранении
        // TODO: посмотри как работает например поле prototype
        const newValue = JSON.stringify({
            ...multiLanguageValue,
            [editLanguage]: value
        });

        // TODO: тут надо подумать, потому что в одном варианте data[field] не меняется, меняется его содержимое
        // TODO: во втором варианте, может быть более подходящем, он immutable: data[field]={...data[field]}
        // TODO: не знаю какой вариант лучше, надо попробовать например первый вариант, и если будут противоречия (наверное будут), то второй
        props.onChange(data, field, newValue);
    };

    const components = Children.map(children,
        (child, i) => React.cloneElement(child, {
            ...restProps,
            key: i + editLanguage,
            data: localData,
            onChange: onChange,
            multiLanguageProps: {
                hasAllLanguagesFilled
            }
    }));

    return (
        <>
            {components}
        </>);
}

export default MultiLanguageFieldBlock;
import React from 'react'
import {Alert} from 'reactstrap'

function Messages(props) {
    const {error, errors, info, infos} = props;
    if (!error && !errors && !info && !infos) {
        return null;
    }
    const allMessages = [];
    if (error) {
        allMessages.push({message: error, type: 'danger'});
    }
    if (errors) {
        Object.keys(errors).forEach(k => {
            errors[k] && allMessages.push({message: errors[k], type: 'danger'});
        })
    }
    if (info) {
        allMessages.push({message: info, type: 'info'});
    }
    if (infos) {
        Object.keys(infos).forEach(k => {
            infos[k] && allMessages.push({message: infos[k], type: 'info'});
        })
    }
    if (allMessages.length === 0) {
        return null;
    }

    const alerts =
        allMessages.map((message, indMessage) => {
            const lines = message.message.split("\n");
            const linesWithBreakLines = [];
            lines.forEach((l, i) => {
                linesWithBreakLines.push(l);
                if (i < lines.length - 1) {
                    linesWithBreakLines.push(<br key={i}/>);
                }
            });
            return <Alert key={indMessage} className="mb-0" color={message.type}>{linesWithBreakLines}</Alert>;
        });

    return <>{alerts}</>;
}

export default Messages;



import React from 'react';

function CheckBox(props) {
    const {onChange, className, checked, label, disabled} = props;
    return(
        <div className={className}>
            <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
            <label className="form-check-label ms-2" onClick={onChange}>{label}</label>
        </div>);
}

export default CheckBox;
import React from 'react'
import classNames from 'classnames'

function NamedBlock(props) {
    const {name, actions, children, className, nameClassName, style, onClick} = props;

    return (
        <div className={classNames("form-group configpageblock", className, onClick && "clickable")} style={style} onClick={onClick ? onClick : undefined}>
            {name &&
            <div className={classNames("configpageblock-name", nameClassName)}>{name}
                {actions && actions.hasOwnProperty('create') &&
                    <i className="fa fa-plus text-muted" onClick={() => actions.create()} />}
                {actions && actions.hasOwnProperty('view') &&
                    <i className="fa fa-eye text-muted" onClick={() => actions.view()} />}
                {actions && actions.hasOwnProperty('edit') &&
                    <i className="fa fa-edit text-muted" onClick={() => actions.edit()} />}
            </div>}
        {children &&
            children}
        </div>);
}

export default NamedBlock;
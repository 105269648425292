import React from 'react'
import classnames from 'classnames'

import helpIcon from 'icons/help.svg'
import TooltipWrapper from '../../elements/TooltipWrapper'


const LabelWithInfo = (props) => {
    let {className, name, unit, info, infoImage, small, multiLanguageProps} = props;

    const tooltip =
        <>
        {infoImage &&
            <img src={`/images/${infoImage}`} alt="" style={{maxWidth: "190px", maxHeight: "300px"}} />}
        {info}
        </>;

    return (
        <label className={classnames(className, "col-form-label", small && "col-form-label-small")}>
            {name} <span className="measureunit">{unit ? ` [${unit}]` :  ""}</span>
            {(info || infoImage) && <>
                <TooltipWrapper tooltip={tooltip}>
                    <img className="helpicon" src={helpIcon} alt="helpicon" style={{width: 20}} />
                </TooltipWrapper>
            </>}
            {multiLanguageProps &&
                <i className={classnames("d-print-none fa fa-globe", !multiLanguageProps.hasAllLanguagesFilled && "text-danger")} />}
        </label>);
}

export default LabelWithInfo;
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from 'reactstrap'
import DialogModal from 'components/modals/DialogModal'
import Messages from 'components/elements/Messages'
import {parseBackendMessage} from 'utils/translationUtils'

function FileUpload(props) {
    const {disabled, uploadMutation, mutationResultsName, noReplaceCurrentData, extraMutationData, uploadConfirmation} = props;
    const {t} = useTranslation();

    const name = props.name ? props.name: t('appAdmin.views.components.FileUpload.upload');
    const validExtensionsList = props.validExtensionsList && props.validExtensionsList.map(ext => `.${ext.toLowerCase()}`);

    if (!uploadMutation) {
        throw new Error("FileUpload: Should receive upload function!");
    }

    if (!validExtensionsList) {
        throw new Error("FileUpload: Should receive validExtensionsList!");
    }

    const [fileData, setFileData] = useState({
        name: null,
        contents: null,
        error: null,
        replaceCurrentData: false
    });

    const [infoMessages, setInfoMessages] = useState({});
    const [errorMessages, setErrorMessages] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const upload = () => {
        setIsLoading(true);

        const uploadData = {file: fileData.contents};
        if (!noReplaceCurrentData) {
            uploadData.shouldReplaceOldData = fileData.replaceCurrentData;
        }
        if (extraMutationData) {
            extraMutationData.forEach(e => {
                uploadData[e.field] = e.value;
            })
        }
        uploadMutation(uploadData)
            .then(({data: {[mutationResultsName]: {result, infos, errors}}}) => {
                setIsLoading(false);
                if (!infos) {
                    infos = []
                }
                if (!errors) {
                    errors = []
                }
                result ? infos.push('{appAdmin.views.components.FileUpload.success}'):
                         errors.push('{appAdmin.views.components.FileUpload.error}');
                setInfoMessages({...infoMessages, [mutationResultsName]: infos &&
                    infos.map(i => parseBackendMessage(i, t)).join('\n')});
                setErrorMessages({...errorMessages, [mutationResultsName]: errors &&
                    errors.map(e => parseBackendMessage(e, t)).join('\n')});
            });

        setFileData({
            ...fileData,
            name: null,
            contents: null,
       });
    }

    return (<div>
            {!isLoading &&
            <div>
            <DialogModal show={showDialog} title={uploadConfirmation && uploadConfirmation.header}
                         actions={[
                            {
                                key: "remove",
                                name: t('appAdmin.views.components.FileUpload.load'),
                                color: "primary",
                                action: () => {
                                    upload();
                                    setShowDialog(false);
                                }
                            },
                            {
                                key: "close",
                                name: t('appAdmin.views.components.FileUpload.cancel'),
                                color: "secondary",
                                action: () => setShowDialog(false)
                            }
            ]}>
                {uploadConfirmation && uploadConfirmation.text}
            </DialogModal>

            <div>{name}:</div>

            <Messages infos={infoMessages} errors={{...errorMessages, extensionError: fileData.error}} />

            <input type="file" value={fileData.name ? undefined : ""} disabled={disabled}
                   onChange={e => {
                        const file = e.target.files[0];
                        if (file && validExtensionsList.some(ext => file.name.toLowerCase().endsWith(ext))) {
                            setFileData({
                                ...fileData,
                                name: file.name,
                                contents: null,
                                error: null
                            });

                            const reader = new FileReader();
                            reader.onloadend = () => {
                                setFileData({
                                    ...fileData,
                                    name: file.name,
                                    contents: reader.result,
                                    error: null
                                });
                            };
                            reader.readAsDataURL(file);

                        } else {
                            const error = file ? `${t('appAdmin.views.components.FileUpload.validExtensionsList')} [${validExtensionsList.join(", ")}]`: null;
                            setFileData({
                                ...fileData,
                                error: error,
                                name: null,
                                contents: null,
                            });
                        }
                   }}
            />

            <Button color="primary" disabled={disabled || !fileData.name}
                    onClick={() => uploadConfirmation ? setShowDialog(true): upload()}>
                {t('appAdmin.views.components.FileUpload.load')}
            </Button>
            {!noReplaceCurrentData &&
            <div>
                <input type="checkbox" checked={fileData.replaceCurrentData} disabled={disabled}
                       onChange={() => setFileData({...fileData, replaceCurrentData: !fileData.replaceCurrentData})}/>
                 {t('appAdmin.views.components.FileUpload.replaceData')}
            </div>}
        </div>}
        {isLoading &&
        <div>{t('appAdmin.views.components.FileUpload.loading')}</div>}
    </div>);
}

export default FileUpload;
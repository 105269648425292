import React from 'react'
import Datetime from 'react-datetime'
import {Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'

function DateVal(props) {

    const {noLabel, wide, isNoInput, name, unit, info, infoImage, disabled, withTime, data, field, error} = props;

    const onChange = (v) => {
        v = v ? v.replace(' ', 'T') : null;
        props.onChange(data, field, v);
    };

    let value = data && data[field] ? data[field].replace('T', ' ') : "";

    if (!withTime) {
        value = value.split(" ")[0];
    }

    let placeholder = undefined;
    if (isNoInput) {
        placeholder = value;
        value = "";     // Datetime accepts only "" as empty value
    }

   // console.info('rendering "MyDTPicker" with ' + value);

    const contents = disabled ?
        <div>
            <input className={classnames("form-control", error && "is-invalid")} type="text" value={value} placeholder={placeholder} disabled={disabled} />
            {error &&  <Error id={field} error={error} />}
        </div>:
        <div>
            <MyDTPicker className={classnames("form-control", error && "is-invalid")}
                        value={value} placeholder={placeholder} onChange={onChange} withTime={withTime} />
            {error &&  <Error id={field} error={error} />}
        </div>;

    if (noLabel) {
        return (
            // padding accounts for 2-column gap
            <Col sm={6} className="form-group row g-0 pe-2">
                {contents}
            </Col>);
    } else {
        return (
            <Col sm={6} className="form-group row g-0">
                <Col sm={wide ? 4 : 6}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
                </Col>
                {/* padding accounts for 2-column gap */}
                <Col sm={wide ? 8 : 6} className="pe-2">
                    {contents}
                </Col>
            </Col>);
    }
}

class MyDTPicker extends React.Component {
    // props: value, onChange
    constructor(props) {
        super(props);
        this.state = { key: 0, value: this.props.value, error: false};
        this.handleChange = this.handleChange.bind(this);
        // console.info('MyDTPicker:constructor: ' + this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        // console.info(`MyDTPicker:componentWillReceiveProps: state: ${this.state.value}, nextProps: ${nextProps.value}`);
        if (nextProps.value !== this.state.value) {
            this.setState((prevState) => {
                // console.info(`                                      not equal! changing to: key++: ${prevState.key+1}, value: ${nextProps.value}`);
                return {
                    key: prevState.key + 1,
                    value: nextProps.value,
                    error: false
                };
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.info(`MyDTPicker:componentDidUpdate: prevProps: ${prevProps.value}, props: ${this.props.value}`);
        // console.info(`                               prevState: ${prevState.value}, state: ${this.state.value}`);
        // console.info(`   comparing props - initial data that came from parent (${this.props.value}) with new state changed here (${this.state.value})`);
        if (this.props.value !== this.state.value) {
            // console.info(`                               not equal! informing parent, calling onChange(${this.state.value})`);
            if (this.props.onChange) {
                this.props.onChange(this.state.value);
            }
        }
    }

    handleChange(value) {
        // console.info(`MyDTPicker:handleChange: state: ${this.state.value}, value: ${value}`);
        var error = typeof(value) === 'string';
        value = error ? null : value.format("YYYY-MM-DD HH:mm:ss");
        if (value !== this.state.value) {
            // console.info(`                         not equal! seting state to ${value}`);
            this.setState({value: value, error: error});
        }
    }

    render() {
        const {placeholder, className, withTime} = this.props;
        // console.info(`MyDTPicker:render.`);
        return (
            <div>
                <Datetime key={this.state.key} defaultValue={this.props.value}
                          inputProps={{placeholder: placeholder, className: className}}
                          dateFormat="YYYY-MM-DD"
                          timeFormat={withTime ? "HH:mm:ss": false}
                          strictParsing={false} onChange={this.handleChange} />
            </div>);
    }
}

export default DateVal;
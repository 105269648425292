import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Alert} from 'reactstrap'
import compose from 'lodash/flowRight'
import {Router} from '@reach/router'
import useSmartNavigate from 'hooks/useSmartNavigate'

import Tree from 'components/blocks/Tree'
import CheckBox from 'components/elements/CheckBox'
import withUriParams from 'hocs/withUriParams'
import withUriSearchParam from 'hocs/withUriSearchParam'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import AdminImagesView from 'appAdmin/views/AdminImagesView'
import CatalogView from './CatalogView'
import CatalogEntryView from './CatalogEntryView'

import {queryFlatCatalogsAndEntries} from '../data/catalogQueries'
import {createCatalog, deleteCatalog, updateCatalog,
        createCatalogEntry, updateCatalogEntry, deleteCatalogEntry, cloneCatalogEntry} from '../data/catalogMutations'

import entityUtils from 'utils/entityUtils'
import {toggleSearchParam} from 'utils/urlUtils'
import {createMlJSONStringWithCurrentLanguage, parseBackendMessage} from 'utils/translationUtils'

import {ARCHIVED, DB_ROLE_NAME_SUPERADMIN, DB_ROLE_NAME_WRITER} from 'constants/constants'

const IndexCatalogView = compose(
    withUriParams([
        ['catalogSection', String, {optional: true, values: ['images']}],
        ['catalogId', Number, {optional: true}],
        ['catalogEntryId', Number, {optional: true}]]),
    withUriSearchParam(ARCHIVED, 'showArchived'),
    queryFlatCatalogsAndEntries,
    createCatalog, deleteCatalog, updateCatalog,
    createCatalogEntry, updateCatalogEntry, deleteCatalogEntry, cloneCatalogEntry,
    withInitialDataLoadWaiting(['flatCatalogsAndEntries'])
)(props => {

    const {
        showArchived, catalogSection, catalogId, catalogEntryId,
        createCatalog, deleteCatalog, updateCatalog, // cloneCatalog,
        createCatalogEntry, updateCatalogEntry, deleteCatalogEntry, cloneCatalogEntry, user} = props;
    const selectedId = catalogEntryId ? 'e' + catalogEntryId : catalogId ? 'c' + catalogId : null;
    const isUserSuperadmin = user && user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN);

    const {t, i18n} = useTranslation();
    const [error, setError] = useState(null);
    const navigate = useSmartNavigate();

    // make a copy to be able to change
    const flatCatalogsAndEntries = useMemo(() => ({
            catalogs: [...props.flatCatalogsAndEntries.catalogs],
            catalogEntries: [...props.flatCatalogsAndEntries.catalogEntries]}),
        [props.flatCatalogsAndEntries]);

    // subnavigation:
    // access: superadmins, writers
    const hasAccess = user && user.id && user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN || r.name === DB_ROLE_NAME_WRITER);
    if (!hasAccess) {
        navigate(`${props.uri}/..`);
        return null;
    }

    // rendering

    const nodes = [];

    const catalogNodesByCatalogId = {};
    flatCatalogsAndEntries.catalogs
        .sort(entityUtils.sortByOrder)
        .forEach(c => {
            const nodeId = `c${c.id}`;
            const catalogNode = {
                id: nodeId,
                parentId: c.parentId ? `c${c.parentId}`: null,
                name: c.isArchived ? t('appCatalog.views.IndexCatalogView.archive') + c.name : c.name,
                items: [],
                catalog: c,
                actions: {
                    "select": () => navigate(`${props.uri}/${c.id}${props.location.search}`),
                    ...(isUserSuperadmin) && {
                        "removeConfirmation": () =>
                            deleteCatalog(c.id)
                    }
                }
            };
            catalogNodesByCatalogId[c.id] = catalogNode;
            nodes.push(catalogNode);
        });

        nodes.push({
            id: 'addNew',
            parentId: null,
            // TODO: переименовать в section
            name: t('appCatalog.views.IndexCatalogView.addNewPartition'),
            notSelectable: true,
            items: [],
            actions: {
                "click": () =>
                    createCatalog({
                        parentId: null,
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appCatalog.views.IndexCatalogView.newPartition'), i18n)
                    }).then(({data: {createCatalog: {id}}}) => navigate(`${props.uri}/${id}${props.location.search}`))
            }
        });

    flatCatalogsAndEntries.catalogEntries
        .sort(entityUtils.sortByOrder)
        .forEach((e, i) => {
            const nodeId = `e${e.id}`;
            const entryNode = {
                id: nodeId,
                parentId: `c${e.catalogId}`,
                name: e.isArchived ? t('appCatalog.views.IndexCatalogView.archive') + e.name : e.name,
                // adds: catalogEntry, lastPublishedVersion, unpublishedVersion
                entry: e,
                icon: e.type === "course" && "folder",
                actions: {
                    "select": () => navigate(`${props.uri}/${e.catalogId}/${e.id}${props.location.search}`),
                    "clone": () =>
                        cloneCatalogEntry(e.id)
                            .then(({data: {cloneCatalogEntry: {id, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                                if (!error) {
                                    navigate(`${props.uri}/${e.catalogId}/${id}/entry${props.location.search}`);
                                }
                            }),
                    ...(isUserSuperadmin) && {
                        "removeConfirmation": () =>
                            deleteCatalogEntry(e.id)
                    }
                }
            };
            const catalogNode = catalogNodesByCatalogId[e.catalogId];
            catalogNode.items.push(entryNode);
        });


    nodes.forEach(catalogNode => {
        const addNewScenario = {
            id: 'addNewScenario',
            parentId: catalogNode.id,
            name: t('appCatalog.views.IndexCatalogView.addNewScenario'),
            notSelectable: true,
            actions: {
                "click": () =>
                    createCatalogEntry({
                        catalogId: catalogNode.catalog.id,
                        type: "scenario",
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appCatalog.views.IndexCatalogView.newScenario'), i18n)
                    }).then(({data: {createCatalogEntry: {id}}}) => navigate(`${props.uri}/${catalogNode.catalog.id}/${id}${props.location.search}`))
            }
        };
        const addNewSection = {
            id: 'addNewSection',
            parentId: catalogNode.id,
            name: t('appCatalog.views.IndexCatalogView.addNewPage'),
            notSelectable: true,
            actions: {
                "click": () =>
                    createCatalogEntry({
                        catalogId: catalogNode.catalog.id,
                        type: "page",
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appCatalog.views.IndexCatalogView.newPage'), i18n)
                    }).then(({data: {createCatalogEntry: {id}}}) => navigate(`${props.uri}/${catalogNode.catalog.id}/${id}${props.location.search}`))
            }
        };
        const addNewCourse = {
            id: 'addNewCourse',
            parentId: catalogNode.id,
            name: t('appCatalog.views.IndexCatalogView.addNewCourse'),
            notSelectable: true,
            actions: {
                "click": () =>
                    createCatalogEntry({
                        catalogId: catalogNode.catalog.id,
                        type: "course",
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appCatalog.views.IndexCatalogView.newCourse'), i18n)
                    }).then(({data: {createCatalogEntry: {id}}}) => navigate(`${props.uri}/${catalogNode.catalog.id}/${id}${props.location.search}`))
            }
        };
        const addNewCatalog = {
            id: 'addNew',
            parentId: catalogNode.id,
            name: t('appCatalog.views.IndexCatalogView.addNewSubPartition'),
            notSelectable: true,
            actions: {
                "click": () =>
                    createCatalog({
                        parentId: catalogNode.catalog.id,
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appCatalog.views.IndexCatalogView.newSubPartition'), i18n)
                    }).then(({data: {createCatalog: {id}}}) => navigate(`${props.uri}/${id}${props.location.search}`))
            }
        };
        catalogNode.items.push(addNewScenario);
        catalogNode.items.push(addNewSection);
        catalogNode.items.push(addNewCourse);
        catalogNode.items.push(addNewCatalog);
    });

    nodes.unshift({
        id: 'images',
        name: t('appCatalog.views.IndexCatalogView.images'),
        actions: {
            "click": () => navigate(`${props.uri}/images${props.location.search}`),
        },
        noDragAndDrop: true
    });


    return (
        <div className="flex-grow d-flex flex-row scroll-parent">

            <div className="w-25 d-flex flex-column">
                <CheckBox checked={showArchived} className="ms-4 mt-3"
                          label={t('appCatalog.views.IndexCatalogView.showArchived')}
                          onChange={() => navigate(`${props.location.pathname}${toggleSearchParam(props.location.search, ARCHIVED)}`, {state: props.location.state})} />
                <div className="scroll">
                    <Tree nodes={nodes} selectedId={selectedId} executeWithLock={props.executeWithLock}
                          dragAndDrop
                          onDrop={(nodeToMove, newParent, afterNode) => {
                              if (nodeToMove.catalog) {
                                  updateCatalog({
                                      id: nodeToMove.catalog.id,
                                      parentId: newParent && newParent.catalog.id,
                                      order: afterNode && afterNode.id !== 'images' ? (afterNode.catalog.order + 1) : null
                                  });
                              } else if (nodeToMove.entry) {
                                  updateCatalogEntry({
                                      id: nodeToMove.entry.id,
                                      catalogId: newParent.catalog.id,
                                      order: afterNode && (afterNode.entry.order + 1)
                                  });
                              }
                          }} />
                </div>
            </div>

            <div className="w-75 d-flex flex-column border border-dark border-top-0 border-end-0 border-bottom-0">
               {error &&
               <Alert className="mb-0" color="danger">{error}</Alert>}

                {catalogSection &&
                <Router basepath={props.uri + '/'} className="scroll">
                    <AdminImagesView path="images/*uriParams"
                                     licenseId={null}
                                     user={props.user} errors={props.errors} loading={props.loading}
                                     setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                     resetActionLock={props.resetActionLock}
                                     executeWithLock={props.executeWithLock}
                                     executeWithConfirmation={props.executeWithConfirmation} />
                </Router>}

                {!catalogSection && catalogId && !catalogEntryId &&
                 flatCatalogsAndEntries && flatCatalogsAndEntries.catalogs &&
                 flatCatalogsAndEntries.catalogs.map(c => c.id).includes(catalogId) &&
                <CatalogView catalogId={catalogId}
                             user={props.user} errors={props.errors} loading={props.loading}
                             setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                             resetActionLock={props.resetActionLock}
                             executeWithLock={props.executeWithLock}
                             executeWithConfirmation={props.executeWithConfirmation}
                             uri={`${props.uri}/${catalogId}`} uriParams={props.uriParams} location={props.location}/>}

                {!catalogSection && catalogEntryId &&
                 flatCatalogsAndEntries && flatCatalogsAndEntries.catalogEntries &&
                 flatCatalogsAndEntries.catalogEntries.map(e => e.id).includes(catalogEntryId) &&
                <CatalogEntryView catalogEntryId={catalogEntryId}
                                  user={props.user} errors={props.errors} loading={props.loading}
                                  setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                  resetActionLock={props.resetActionLock}
                                  executeWithLock={props.executeWithLock}
                                  executeWithConfirmation={props.executeWithConfirmation}
                                  uri={`${props.uri}/${catalogId}/${catalogEntryId}`} uriParams={props.uriParams} location={props.location}/>}
            </div>
        </div>);
});

export default IndexCatalogView;
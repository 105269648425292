/*global google*/
import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Col} from 'reactstrap'
import compose from 'lodash/flowRight'
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps'
import {MarkerClusterer} from 'react-google-maps/lib/components/addons/MarkerClusterer'

import NumberInput from './elements/NumberInput'
import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'

import {calculateParamsFromLiveData} from 'utils/parametersUtils'

import gcMarkerIcon from 'icons/gc-marker.png'
import grey1Icon from 'icons/clusters/grey1.png'
import grey2Icon from 'icons/clusters/grey2.png'
import grey3Icon from 'icons/clusters/grey3.png'
import grey4Icon from 'icons/clusters/grey4.png'
import grey5Icon from 'icons/clusters/grey5.png'

const OTHER = "Other";

const markerIcons = {
    grey1: grey1Icon,
    grey2: grey2Icon,
    grey3: grey3Icon,
    grey4: grey4Icon,
    grey5: grey5Icon
};

class MapVal extends Component {

    static prepareParameters(props) {
        const parameters = calculateParamsFromLiveData(props.parameters, props.field, props.data, props.fieldsInfo, props.collectionsDict);
        return {
            locations: parameters.locations,
        }
    }

    static parseProps(props) {
        const value = props.data && props.field && props.data[props.field];
        const parameters = MapVal.prepareParameters(props);
        const {locations} = parameters;
        const center =
            value ? value :
            locations && locations.length > 0 ? locations.reduce((acc, l) => [acc[0] + l.location[0], acc[1] + l.location[1]], [0, 0]).map(x => x / locations.length) :
            [0, 0];
        return {
            value: value,
            locations: locations,
            center: center
        }
    }

    constructor(props) {
        super(props);
        const {value, locations, center} = MapVal.parseProps(props);

        this.onChange = this.onChange.bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);

        this.state = {
            value: value,
            locations: locations,
            center: center
        };

        this.MapWithAMarkerClusterer = compose(
            withScriptjs,
            withGoogleMap
        )(MyGoogleMap);
    }

    componentWillReceiveProps(nextProps) {
        const {value, locations, center} = MapVal.parseProps(nextProps);

        // should update locations?
        if (this.state.locations !== locations) {
            this.setState({
                locations: locations
            });
        }

        // should change value & center?
        if (nextProps.data !== this.props.data ||
            (value && String(this.state.value) !== String(value)))
        {
            this.setState({
                value: value,
                center: center
            });
        }
    }

    onChange(i, e) {
        const {field, data} = this.props;
        let arr = data[field] ? [...data[field]] : [undefined, undefined];
        arr[i] = Number(e.nativeEvent.target.value);
        if (arr.every(x => x === null || x === undefined)) {
            arr = undefined;
        }
        this.props.onChange(data, field, arr);
    }

    handleMapClick(mapClick) {
        const {field, data} = this.props;
        this.props.onChange(data, field, [mapClick.latLng.lat(), mapClick.latLng.lng()]);
    }

    render() {
        const {locations, center /*, iteration*/} = this.state;
        const {data, field, isNoInput, markerImagePrefix, error, disabled, fieldsInfo, collectionsDict, t} = this.props;
        const parameters = calculateParamsFromLiveData(this.props.parameters, field, data, fieldsInfo, collectionsDict);

        const value = data && field && data[field];


        const mapProps = {
            markerImagePrefix: markerImagePrefix,
            locations: locations,
            center: center,
            disabled: disabled,
            value: value,
            handleMapClick: this.handleMapClick,
            markerClickCallback: parameters.markerClickCallback
        };

        return (
            <div className={error && "border border-danger"}>
                <this.MapWithAMarkerClusterer googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC1g3P2yRyebiVLKV5oP564mwv0IsqG_FA&v=3.exp&libraries=geometry,drawing,places"
                                              loadingElement={<div style={{ height: `100%` }} />}
                                              containerElement={<div style={{ height: `400px` }} />}
                                              mapElement={<div style={{ height: `100%` }} />}
                                              {...mapProps} />
                <div className="flex-grow d-flex flex-row mt-2">
                    <div className="flex-shrink-grow d-flex flex-row">
                        <Col className="flex-grow-0">
                            <LabelWithInfo className="me-2" name={t('components.fields.MapVal.latitude')} />
                        </Col>
                        <Col className="flex-shrink-grow">
                            <NumberInput className="form-control"
                                         value={!isNoInput && value ? String(value[0]) : ""}
                                         placeholder={isNoInput && value ? String(value[0]) : ""}
                                         disabled={disabled} onChange={this.onChange.bind(null, 0)} />
                        </Col>
                    </div>
                    <div className="flex-shrink-grow d-flex flex-row">
                        <Col className="flex-grow-0">
                            <LabelWithInfo className="me-2" name={t('components.fields.MapVal.longtitude')} />
                        </Col>
                        <Col className="flex-shrink-grow">
                            <NumberInput className="form-control"
                                         value={!isNoInput && value ? String(value[1]) : ""}
                                         placeholder={isNoInput && value ? String(value[1]) : ""}
                                         disabled={disabled} onChange={this.onChange.bind(null, 1)} />
                        </Col>
                    </div>
                </div>
                {error &&  <Error id={field} error={error} />}
            </div>);
    }
};


class MyGoogleMap extends Component {

    static buildMarkers(props) {
        const {locations, markerImagePrefix, markerClickCallback} = props;
        const markersData = [];
        const markers = [];
        if (locations) {
            locations.forEach((location, i) => {
                const marker =
                    <Marker key={i}
                            icon={{
                                url: markerIcons[markerImagePrefix + "1"],
                                size: new google.maps.Size(53, 52),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(26, 26)
                            }}
                            label={{text: "1", color: '#ffffff', fontSize: "10"}}
                            position={{lat: location.location[0], lng: location.location[1]}}
                            title={location.group === OTHER || !location.group ? location.name : `${location.group}:\n  ${location.name}`}
                            //clickable={!!markerClickCallback}
                            onClick={markerClickCallback ? (() => markerClickCallback(i)) : undefined}
                            options={{index: i}}
                        //onMouseOver={() => this.onMarkerMouseOver(location)}
                        //onMouseOut={() => this.onMarkerMouseOut()}
                    >
                        {/*{location.info && location === locationWithInfo &&
                           <InfoWindow>*/}
                        {/*{location.info}*/}
                        {/*</InfoWindow>}*/}
                    </Marker>;
                const markerData = {
                    index: i,
                    group: location.group,
                    name: location.name
                };
                markers.push(marker);
                markersData.push(markerData);
            });
        }
        return {markers: markers, markersData: markersData};
    }

    constructor(props) {
        super(props);
        const {markers, markersData} = MyGoogleMap.buildMarkers(this.props);
        this.state = {//
            markers: markers,
            markersData: markersData,
            //locationsWithInfo: null
            zoom: props.value ? 5 : 2,
            iteration: 1
        };
        this.googleMapRef = null;
        // this.onClusterClick = this.onClusterClick.bind(this);
        // this.onClusterMouseOver = this.onClusterMouseOver.bind(this);
        // this.onClusterMouseOut = this.onClusterMouseOut.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.locations !== nextProps.locations) {
            const {markers, markersData} = MyGoogleMap.buildMarkers(nextProps);//eslint-disable-next-line
            this.state = {
                markers: markers,
                markersData: markersData,
                //locationsWithInfo: null
            };
        }
    }

    // onClusterClick(cluster) {
    //     const {markerClickCallback} = this.props;
    //     const {markersData} = this.state;
    //     const markers = cluster.getMarkers();
    //     if (markers.length === 1) {
    //         if (markerClickCallback && markersData.hasOwnProperty(markers[0])) {
    //             markerClickCallback(markersData[markers[0].index].index);
    //         }
    //         return false;
    //     }
    //     //this.setState({locationsWithInfo: location});
    // }

    // onClusterMouseOver(cluster) {
    //     //this.setState({locationsWithInfo: location});
    // }
    //
    // onClusterMouseOut(cluster) {
    //     //this.setState({locationsWithInfo: null});
    // }

    render() {
        const {
            markerImagePrefix,
            center,
            disabled,
            value,
            handleMapClick,
            markerClickCallback,
            ...restProps
        } = this.props;

        const {markers, markersData} = this.state;

        const calculator = (clusterMarkers, numStyles) => {
            let index = 0;
            let title = "";
            const count = clusterMarkers.length.toString();

            let dv = count;
            while (dv !== 0) {
                dv = parseInt(dv / 10, 10);
                index++;
            }

            index = Math.min(index, numStyles);

            let groups = [];
            const namesByGroup = {};
            clusterMarkers.forEach(m => {
                const markerData = markersData[m.index];
                const g = markerData.group ? markerData.group : OTHER;
                const n = markerData.name;
                if (!namesByGroup.hasOwnProperty(g)) {
                    namesByGroup[g] = [];
                    groups.push(g);
                }
                namesByGroup[g].push(n);
            });
            if (groups.length === 1 && groups[0] === OTHER) {
                title = namesByGroup[OTHER].join("\n");
            } else if (groups.length > 0) {
                groups.sort();
                if (OTHER in groups) {
                    groups.splice(groups.indexOf(OTHER), 1);
                    groups.push(OTHER);
                }
                title = groups
                    .map(g => [
                        g + ":",
                        ...(namesByGroup[g].map(n => "  " + n))].join("\n"))
                    .join("\n");
            }

            return {
                text: count,
                index: index,
                title: title
            };
        };

        // center here is "almost-controlled" - to make it fully controlled need to react on center-change and update state

        return (
            <GoogleMap key={this.state.iteration}
                ref={el => {
                    this.googleMapRef = el
                }}
                onClick={!disabled && handleMapClick ? handleMapClick : undefined}
                defaultZoom={value ? 5 : 2}
                zoom={this.state.zoom}
                center={{lat: center[0], lng: center[1]}}
                onZoomChanged={() => {
                    if (this.googleMapRef.getZoom() > 5) {
                        this.setState(prevState => ({zoom: 5, iteration: prevState.iteration + 1}));
                    }
                }}
                {...restProps}>
                {markers &&
                <MarkerClusterer
                    zoomOnClick={markerClickCallback ? true : false}
                    // onClick={markerClickCallback ? this.onClusterClick : undefined}
                    // onMouseOver={this.onClusterMouseOver}
                    // onMouseOut={this.onClusterMouseOut}
                    averageCenter
                    // enableRetinaIcons
                    gridSize={60}
                    // minimumClusterSize={1}
                    calculator={calculator}
                    styles={[{
                        url: markerIcons[markerImagePrefix + "1"],
                        width: 53,
                        height: 52,
                        textColor: '#ffffff',
                        textSize: 10
                    }, {
                        url: markerIcons[markerImagePrefix + "2"],
                        width: 56,
                        height: 55,
                        textColor: '#ffffff',
                        textSize: 10
                    }, {
                        url: markerIcons[markerImagePrefix + "3"],
                        width: 66,
                        height: 65,
                        textColor: '#ffffff',
                        textSize: 10
                    }, {
                        url: markerIcons[markerImagePrefix + "4"],
                        width: 78,
                        height: 77,
                        textColor: '#ffffff',
                        textSize: 10
                    }, {
                        url: markerIcons[markerImagePrefix + "5"],
                        width: 90,
                        height: 89,
                        textColor: '#ffffff',
                        textSize: 10
                    }]}>
                    {markers}
                </MarkerClusterer>}
                {value &&
                <Marker key='M'
                        icon={{
                            url: gcMarkerIcon,
                            size: new google.maps.Size(31, 39),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 39)
                        }}
                        position={{lat: value[0], lng: value[1]}}
                        clickable={false} />}
            </GoogleMap>
        );
    }
}

export default withTranslation()(MapVal);
import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Badge, Table, Button} from 'reactstrap'

import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryLicenseEvents, queryUsersRegistrations} from '../data/adminQueries'
import {registerUsers} from '../data/adminMutations'
import {parseBackendMessage} from 'utils/translationUtils'
import adminPrototypes from '../data/adminStaticPrototypes'
import {breadcrumbsStore} from 'appBase/TopNav'

const REGISTER_USERS_VIEW = 'registerUsers';

const RegisterUsersView = compose(
    queryLicenseEvents,
    queryUsersRegistrations,
    registerUsers,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['licenseEvents'])
)((props) => {

    const {
        dataSource, setDataSource, hasErrors, onChange, onValidate,
        licenseEvents, usersRegistrations, registerUsers,
        executeWithConfirmation,
        disabled} = props;

    const [isReal, setIsReal] = useState(false);
    const [registerUsersResult, setRegisterUsersResult] = useState({});
    const {t} = useTranslation();
    // preparing links

    breadcrumbsStore.register(REGISTER_USERS_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(REGISTER_USERS_VIEW, {name: t('appAdmin.views.RegisterUsersView.globalRegistration'), to: `${props.uri}`}, true);
    });

    // setting dataSource
    useEffect(() => {
        setDataSource({});
    }, [setDataSource]);


    // rendering
    return (
        <div className="flex-grow d-flex container-fluid scroll-parent">
            <div className="flex-grow workpanel-gray scroll">
                <h6>{t('appAdmin.views.RegisterUsersView.currentUploads')}</h6>
                {usersRegistrations && usersRegistrations.length === 0 &&
                <div>{t('appAdmin.views.RegisterUsersView.notAvaiable')}</div>}
                {usersRegistrations && usersRegistrations.length > 0 &&
                <Table striped size="sm">
                    <thead>
                        <tr>
                            <th>{t('appAdmin.views.RegisterUsersView.event')}</th>
                            <th>{t('appAdmin.views.RegisterUsersView.topic')}</th>
                            <th>{t('appAdmin.views.RegisterUsersView.numberOfWaitingForRegistration')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {usersRegistrations.map((r, i) =>
                        <tr key={i}>
                            <td>{r.event.name}</td>
                            <td>{r.subject}</td>
                            <td>{r.usersCount}</td>
                        </tr>)}
                    </tbody>
                </Table>}


                {!disabled &&
                <>
                <h6>{t('appAdmin.views.RegisterUsersView.newUpload')}</h6>

                {dataSource &&
                <GeneratorBlock data={dataSource}
                                items={adminPrototypes.registerUsers}
                                onChange={onChange} onValidate={onValidate}
                                disabled={isReal}
                                collectionsDict={{events: licenseEvents}}
                                wide />}

                {registerUsersResult.result &&
                <h6><Badge color="success">{t('appAdmin.views.RegisterUsersView.listSendToUpload')}</Badge></h6>}

                {!registerUsersResult.result &&
                <div className="mb-3">
                <h6>{t('appAdmin.views.RegisterUsersView.viewAndConfirmation')}</h6>
                {registerUsersResult.previewSubject &&
                <h6>{registerUsersResult.previewSubject}</h6>}
                {registerUsersResult.previewText &&
                <p>{registerUsersResult.previewText.split("\n").map((x,i) => <span key={i}>{x}<br/></span>)}</p>}
                {registerUsersResult.previewUsers &&
                <Table striped size="sm">
                    <thead>
                        <tr>
                            <th>{t('appAdmin.views.RegisterUsersView.name')}</th>
                            <th>{t('appAdmin.views.RegisterUsersView.email')}</th>
                            <th>{t('appAdmin.views.RegisterUsersView.parameters')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {registerUsersResult.previewUsers.map((u, i) =>
                    <tr key={i}>
                        <td>{u.name}</td><td>{u.email}</td>
                        <td>{u.parameters}</td>
                    </tr>)}
                    </tbody>
                </Table>}
                {registerUsersResult.errors && registerUsersResult.errors.length === 0 &&
                <h6 className="text-success">{t('appAdmin.views.RegisterUsersView.readyToUpload')}</h6>}
                {registerUsersResult.errors && registerUsersResult.errors.length > 0 &&
                <>
                <h6 className="text-danger">{t('appAdmin.views.RegisterUsersView.errors')}</h6>
                {registerUsersResult.errors.map((e, i) =>
                <div key={i} className="text-danger">{parseBackendMessage(e, t)}</div>)}
                </>}
                </div>}

                {!isReal &&
                <Button className="me-2" onClick={() => registerUsers({
                            eventId: dataSource.eventId, subject: dataSource.subject, text: dataSource.text,
                            isSendIfRegistered: dataSource.isSendIfRegistered, fileContents: dataSource.file && dataSource.file.contents,
                            isTest: true})
                            .then(({data: {registerUsers}}) => {
                                setRegisterUsersResult(registerUsers);
                                if (registerUsers.errors && registerUsers.errors.length === 0) {
                                    setIsReal(true);
                                }
                        })}
                        disabled={hasErrors} color="primary">{t('appAdmin.views.RegisterUsersView.sendToCheck')}</Button>}

                {isReal && !registerUsersResult.result &&
                <>
                <Button className="me-2" onClick={() => executeWithConfirmation(() => registerUsers({
                            eventId: dataSource.eventId, subject: dataSource.subject, text: dataSource.text,
                            isSendIfRegistered: dataSource.isSendIfRegistered, fileContents: dataSource.file && dataSource.file.contents,
                            isTest: false})
                            .then(({data: {registerUsers}}) => {
                                setRegisterUsersResult(registerUsers);
                        }))}
                        disabled={hasErrors} color="primary">{t('appAdmin.views.RegisterUsersView.sendToUpload')}</Button>
                <Button className="me-2" onClick={() => {
                            setIsReal(false);
                            setRegisterUsersResult({});
                            setDataSource({});
                        }}
                        disabled={hasErrors} color="primary">{t('appAdmin.views.RegisterUsersView.cancelUpload')}</Button>
                </>}

                {isReal && registerUsersResult.result &&
                <Button className="me-2" onClick={() => {
                            setIsReal(false);
                            setRegisterUsersResult({});
                            setDataSource({});
                        }}
                        disabled={hasErrors} color="primary">{t('appAdmin.views.RegisterUsersView.newUpload')}</Button>}

                </>}
            </div>
        </div>);
});
export default RegisterUsersView;
import React from 'react'
import {useTranslation} from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'
import {parseBackendMessage} from "utils/translationUtils";

function HtmlVal(props) {
    const {data, field, name, format, value} = props;
    const {t} = useTranslation();

    let html = "";
    let additionalComponentsBelow = [];

    if (format === 'testResult') {
        let htmlData = value || (data && field && data[field]);
        let totalScore = null;
        let scores = null;
        let result = null;
        // result without scores?
        if (htmlData.result && (!htmlData.scores || htmlData.scores.length === 0)) {
            result = htmlData.result;
        } else {
            scores = htmlData.scores ? htmlData.scores : htmlData.score ? [htmlData.score] : [0];
            totalScore = scores.reduce((a, b) => a + b, 0);
        }
        const isTest = htmlData.test;
        const achievements = htmlData.achievements;
        const error = parseBackendMessage(htmlData.error, t);
        const log = htmlData.log;

        const scoreName = isTest ? t('components.fields.HtmlVal.points') : t('components.fields.HtmlVal.points2');

        if (result) {
            html += result;
            html += "<br/>";
        }

        if (scores) {
            html += "</div>";
            if (totalScore > 0) {
                html += `<span class='text-primary'>${scoreName}: <b>${totalScore}</b></span>`;
            } else {
                html += `<span class='text-danger'>${scoreName}: <b>${totalScore}</b></span>`;
            }
            if (scores.length > 1) {
                html += "<div class='border-dark border-start ms-2 ps-2'>";
                scores.forEach((s,i) => {
                    html += `<div class='text-secondary'>${t('components.fields.HtmlVal.part')} ${i+1}: <b>` +
                        (Math.round(s * 10) / 10) +
                        "</b></div>";
                });
                html += "</div>";
            }
            html += "</div><br/>";
        }

        if (error) {
            html += "<br/>";
            html += "<h6 class='text-danger'>" + t('components.fields.HtmlVal.error') + "</h6>";
            html += "<p class='text-danger'>" + error.replace(/\n/g, "<br/>") + "</p><br/>";
        }

        if (achievements && achievements.length > 0) {
            html += "<br/>";
            html += "<h6>" + t('components.fields.HtmlVal.progress') + "</h6>";
            achievements.forEach(a => {
                html += "<p class='text-info'>" + a + "</p>"
            });
            html += "<br/>";
        }

        if (log && log.length > 0) {
            html += "<br/>";
            html += "<h6>" + t('components.fields.HtmlVal.log') + "</h6>";
            additionalComponentsBelow.push(
                <TextareaAutosize key="log" className="form-control" style={{resize: "none"}}
                                  value={log} disabled maxRows={20} />);
        }
    } else {
        html = value || (data && field && data[field]);
    }

    return (
        <div className="form-group row g-0">
            {name &&
            <h6>{name}</h6>}
            <div dangerouslySetInnerHTML={{__html: html}}/>
            {additionalComponentsBelow.map(x => x)}
        </div>);
}

export default HtmlVal;
import React, {Component} from 'react';

import TableBlock from "components/blocks/TableBlock";
import withSort from "components/blocks/withSort";

class TableBlockWithSort extends Component {
    constructor(props) {
        super(props);
        this.TableBlockSort = withSort(TableBlock);
    }

    render() {
        const TableBlockSort =  this.TableBlockSort;
        return (<TableBlockSort {...this.props} />);
    }

}
export default TableBlockWithSort;
import React, {Component} from 'react';

import ListTable from "components/blocks/ListTable";
import withSort from "components/blocks/withSort";

class ListTableWithSort extends Component {
    constructor(props) {
        super(props);
        this.ListTableSort = withSort(ListTable);
    }

    render() {
        const ListTableSort =  this.ListTableSort;
        return (<ListTableSort {...this.props} />);
    }

}
export default ListTableWithSort;
import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {Button} from 'reactstrap'
import {CSVLink} from 'react-csv'
import FileUpload from '../components/FileUpload'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import Messages from 'components/elements/Messages'
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryStudentsWithUsers} from '../../data/adminQueries'
import {createStudent, updateStudent, deleteStudent, uploadData} from '../../data/adminMutations'
import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'

import {breadcrumbsStore} from 'appBase/TopNav'
import {ADMIN_STUDENTS_VIEW, EMAIL_REGEX_STRING, STUDENT} from 'constants/constants'

const StudentsView = compose(
    withUriParams([
    ['mode', String, {values: ['select', 'edit']}],
    ['studentId', Number]]),
    queryStudentsWithUsers,
    createStudent, updateStudent, deleteStudent,
    uploadData("students", ['StudentsWithUsersQuery']),
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['studentsWithUsers'])
)((props) => {
    const {licenseId, mode, studentId, disabled, studentsWithUsers, dataSource, setDataSource, saveAndReset,
           isChanged, hasErrors, onChange, onValidate, deleteStudent, uploadStudents, uri, location} = props;

    const [errorMessages, setErrorMessages] = useState({});

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // student object for dataSource
    const studentData = useMemo(() => {
        const swu = studentsWithUsers && studentsWithUsers.find(({student, user}) => student.id === studentId);
        return swu ? swu.student: {};
    }, [studentId, studentsWithUsers]);

    //links
    const containerId = ADMIN_STUDENTS_VIEW + "child";
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.studentViews.StudentsView.allStudents'), to: `${uri}${location.search}`}];
    if (studentId) {
        links.push({name: studentData.email, to: `${uri}/${studentData.email}/${location.search}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });


    // setting dataSource
    useEffect(() => {
      setDataSource(
          studentData,
          (data) => {
              let mutation;
              if (studentId > 0) {
                 mutation = 'updateStudent';
                 data = entityUtils.filterFields(data, ['id','email']);
              } else {
                 mutation = 'createStudent';
                 data = {licenseId: licenseId, email: data.email};
              }
              props[mutation](data)
                  .then(({data: {[mutation]: {student, error}}}) => {
                      setErrorMessages({...errorMessages, [STUDENT]: error ? parseBackendMessage(error, t): null });
                      navigate(`${uri}/${student ? "select/" + student.id: ""}${location.search}`);
                  });
          });
    }, [studentData, dataSource, setDataSource, licenseId, t, uri, studentId, licenseId, errorMessages, location.search]);


    const students = studentsWithUsers.map(({student, user}) => ({
        fields: [
            !user ? "": user.active ? t('appAdmin.views.studentViews.StudentsView.active'):
                                      t('appAdmin.views.studentViews.StudentsView.notActive'),
            user && user.name,
            student.email
        ],
        actions: {
            "select": () => {
                navigate(`${uri}/edit/${student.id}${location.search}`);
            },
            "removeConfirmation": !disabled && (() => {
                deleteStudent(student.id);
            })
        }
    }));

    students.push({
        fields: [t('appAdmin.views.studentViews.StudentsView.addNewStudent')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => {
                navigate(`${uri}/edit/0${location.search}`);
            }
        }
    });

    //selectedIndex
    const selectedIndex = studentsWithUsers && studentId > 0 &&
        studentsWithUsers.indexOf(studentsWithUsers.find(s => s.student.id === studentId));

    const studentsExport = studentsWithUsers && studentsWithUsers.length ?
        studentsWithUsers.map(s => [s.student.email, s.user ? s.user.name: ""]): null;

    const onCancelEdit = () => {
        setDataSource(null);
        navigate(`${uri}${studentId ? "/select/" + studentId: ""}${location.search}`);
    };


    return (<>
        <Messages errors={{...errorMessages, ...props.errors}} />
        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
            {studentsExport &&
            <CSVLink uFEFF data={studentsExport} separator=";">
                <Button color="primary">{t('appAdmin.views.studentViews.StudentsView.export')}</Button>
            </CSVLink>}

            <NamedBlock name={t('appAdmin.views.studentViews.StudentsView.students')} className="bg-light">
                <ListWithSort headers={[t('appAdmin.views.studentViews.StudentsView.status'),
                                        t('appAdmin.views.studentViews.StudentsView.name'),
                                        t('appAdmin.views.studentViews.StudentsView.email')]}
                              sizes={["70*", "130*", "280*"]}
                              rows={students} nameIndex="2" selectedIndex={selectedIndex} disabled={disabled} />
            </NamedBlock>


            {mode === "edit" &&
            <PanelModal show title={t('appAdmin.views.studentViews.StudentsView.student')} onClose={onCancelEdit} noPadding scrollable>
                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                                  onSave={saveAndReset} onCancel={onCancelEdit} />
                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                    <GeneratorBlock data={dataSource}
                                    items={[{
                                        name: "Email",
                                        type: "string",
                                        field: "email",
                                        pattern: {
                                            pattern: EMAIL_REGEX_STRING,
                                            error: t('appAdmin.views.studentViews.StudentsView.emailError'),
                                        },
                                        required: true
                                    }]}
                                    onChange={onChange} onValidate={onValidate}
                                    wide />
                </div>
            </PanelModal>}

            {!disabled &&
            <>
            <div className="text-muted">{t('appAdmin.views.studentViews.StudentsView.fileFormat')}</div>
            <FileUpload name={t('appAdmin.views.studentViews.StudentsView.import')}
                        uploadMutation={(data) => uploadStudents({licenseId: licenseId, type: "students", ...data})}
                        mutationResultsName="uploadData" validExtensionsList={["xlsx"]} disabled={disabled} />
            </>}
        </div>
    </>);
});

export default StudentsView;
/**
 * Created by Yuri on 02.07.2017
 * Part of frontend
 */
import React from 'react'
import {withTranslation} from 'react-i18next'

// This function takes params...
export default function withInitialDataLoadWaiting(propNames, optional) {
    // ...and return another function:
    // This function takes a component...

    return function withInitialDataLoadWaiting(WrappedComponent) {
        // ...and returns another component:
        class withInitialDataLoadWaiting extends React.Component {
            render() {
                const {loading, t /*, errors*/} = this.props;
                const isAnyQueryUnknown = loading &&
                    propNames
                        .some(k => !loading.hasOwnProperty(k) && !this.props[k]);
                // const isAnyQueryLoading = loading &&
                //     propNames
                //         .filter(k => loading.hasOwnProperty(k))
                //         .some(k => loading[k]);
                // const allErrors = errors &&
                //     propNames
                //         .filter(k => errors.hasOwnProperty(k))
                //         .filter(k => errors[k])
                //         .map(k => errors[k]).join(', ');
                //
                if (!optional && isAnyQueryUnknown) {
                    return <div>{t('hocs.withInitialDataLoadWaiting.waiting')}</div>
                }
                const isAnyQueryInitiallyLoading = loading &&
                    propNames
                        .filter(k => loading.hasOwnProperty(k))
                        .some(k => loading[k] && this.props[k] === undefined);
                if (isAnyQueryInitiallyLoading) {
                    const log = propNames.filter(k => loading[k]).join(', ');
                    console.log('>> withInitialDataLoadWaiting-initial...: ' + log);
                    return <div>{t('hocs.withInitialDataLoadWaiting.loading')}</div>
                }
                // if (allErrors) {
                //     return <div>Error! {allErrors}</div>
                // }

                return <WrappedComponent {...this.props} />;
            }
        }

        withInitialDataLoadWaiting.displayName = `withInitialDataLoadWaiting(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
        return withTranslation()(withInitialDataLoadWaiting);
    }
}
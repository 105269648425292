import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Alert} from 'reactstrap'
import classnames from 'classnames'

import useSmartNavigate from 'hooks/useSmartNavigate'
import LicenseAndEventStatisticsView from './components/LicenseAndEventStatisticsView'
import PanelModal from 'components/modals/PanelModal'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import SaveButtonsBlock  from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import {breadcrumbsStore} from 'appBase/TopNav'

import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryImages} from '../data/adminQueries'
import {upsertImage, deleteImage} from '../data/adminMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'

import adminPrototypes from '../data/adminStaticPrototypes'
import {ADMIN_IMAGES_VIEW} from 'constants/constants'


const AdminImagesView = compose(
    withUriParams([['imageId', Number]]),
    queryImages,
    upsertImage, deleteImage,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['images'])
)((props) => {

    const {licenseId, imageId, disabled, upsertImage, deleteImage,
           dataSource, setDataSource, isChanged, hasErrors, onValidate, saveAndReset} = props;

    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    //stable object for datasource
    const image = useMemo(() => {
        const emptyImage = {licenseId: licenseId};
        if (!imageId) {
            return emptyImage;
        }

        const image = props.images.find(i => i.id === imageId);
        return image ? {...image, ...emptyImage}: emptyImage;
    }, [props.images, licenseId, imageId]);

    // setting dataSource
    useEffect(() => {
        setDataSource(
            image,
            (data) => {
                upsertImage(entityUtils.filterFields(
                    data, [
                        'id',
                        'licenseId',
                        'width',
                        'height',
                        'name',
                        'file']))
                    .then(({data: {upsertImage: {image, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                        if (!error) {
                            setShowModal(false);
                        }
                    });
            });
    }, [image, dataSource, setDataSource, upsertImage, t]);

    // preparing links
    breadcrumbsStore.register(ADMIN_IMAGES_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(ADMIN_IMAGES_VIEW, {name: t('appAdmin.views.AdminImagesView.images'), to: `${props.uri}`}, true);
    });

    // image prototype
    const imagePrototype = adminPrototypes.image.map(f => {
        if (["width", "height"].includes(f.field)) {
            return {...f, max: image[f.field], disabled: disabled || (dataSource && dataSource.file) || !image.id || image.isVector};
        } else {
            return {...f, disabled: disabled};
        }
    });

    //all license images
    const images = props.images.map(image => ({
            fields: [
                image.id,
                image.name,
                image.filename,
                image.format,
                image.width,
                image.height,
                image.sizeKb,
                image.thumbnail &&
                    <img src={image.thumbnail} alt={image.name} style={{maxWidth: "100px", maxHeight: "100px"}} />
            ],
            actions: {
                "select": !disabled && (() => {
                    setShowModal(true);
                    navigate(`${props.uri}/${image.id}${props.location.search}`);
                 }),
                "removeConfirmation": !disabled && licenseId && (() => deleteImage(image.id))
            }
        }));

    !disabled && images.push({
        fields: [t('appAdmin.views.AdminImagesView.addNewImage')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => setShowModal(true)
        }
    });

    //onChange
    const onChange = (data, field, value) => {
        props.onChange(data, field, value);

        if (field === "file" && value !== null) {
            const defaultName = data.file.name.split(".").filter((e, i, arr) => i < arr.length - 1).join(".");
            props.onChange(data, "name", defaultName);
            props.onChange(data, "width", null);
            props.onChange(data, "height", null);
        } else if (field === "width" && value !== null) {
            props.onChange(data, "height", Math.round(value * image.height / image.width));
        } else if (field === "height" && value !== null) {
            props.onChange(data, "width", Math.round(value * image.width / image.height));
        }
    }

    //onCancel
    const onCancelEdit = () => {
        setDataSource(null);
        setShowModal(false);
        setError(null);
        navigate(`${props.uri}${props.location.search}`);
    };

    // rendering
    return (
        <div className="flex-grow d-flex container-fluid scroll-parent">
            <div className="flex-grow workpanel-gray scroll">
                <LicenseAndEventStatisticsView licenseId={props.licenseId} errors={props.errors} loading={props.loading} withDataSize />
                <NamedBlock name={t('appAdmin.views.AdminImagesView.images')} className={classnames(disabled && "bg-light")}>
                    <ListWithSort headers={[t('appAdmin.views.AdminImagesView.id'), t('appAdmin.views.AdminImagesView.name'),
                                            t('appAdmin.views.AdminImagesView.file'), t('appAdmin.views.AdminImagesView.format'),
                                            t('appAdmin.views.AdminImagesView.width'), t('appAdmin.views.AdminImagesView.height'),
                                            t('appAdmin.views.AdminImagesView.sizeKB'), t('appAdmin.views.AdminImagesView.image')]}
                                  sizes={["50*", "250*", "300*", "50*", "100*", "100*", "100*", "100*"]}
                                  rows={images} nameIndex="1" disabled={disabled} />

                    {showModal &&
                    <PanelModal show title={t('appAdmin.views.AdminImagesView.image')} onClose={onCancelEdit}>
                        {error &&
                            <Alert className="mb-0" color="danger">{error}</Alert>}

                        {image.thumbnail &&
                            <img style={{float:"right", maxWidth: "100px", maxHeight: "100px"}} src={image.thumbnail} alt="Preview" />}

                        <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={saveAndReset} onCancel={() => setDataSource(null)} />
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} items={imagePrototype} wide />
                    </PanelModal>}
                </NamedBlock>
            </div>
        </div>);
});
export default AdminImagesView;
import React, {Component} from 'react'

export default class ChameleonBlock extends Component{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeForm = this.onChangeForm.bind(this);
        this.state = {
            isChanged: false,
            isEditForm: false
        }
    }

    componentWillReceiveProps(nextProps) {
        // if changed data we reset
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field) {
            this.setState((prevState) => ({
                isChanged: false,
                isEditForm: false
            }));
        }
    }

    onChange(data, field, value) {
        const {onChange} = this.props;
        this.setState((prevState) => ({
            isChanged: true
        }));
        if (onChange) {
            onChange(data, field, value);
        }
    }

    onChangeForm(isEditForm) {
        const {isChanged} = this.state;
        this.setState((prevState) => ({
            isEditForm: isEditForm || isChanged
        }));
    }

    render() {
        const {className, children, viewChildren, editChildren, onValidate, disabled} = this.props;
        const {isEditForm} = this.state;
        const canChangeFormOnClick = !disabled && !isEditForm;
        const hasOnChange = this.props.onChange;
        const hasOnValidate = onValidate;

        const components = React.Children.map(
            children,
            child => {
                const newProps = {
                    disabled: child.props.disabled || disabled || !isEditForm
                };
                if (hasOnChange) {
                    newProps.onChange = this.onChange;
                }
                if (hasOnValidate) {
                    newProps.onValidate = onValidate;
                }
                if (isEditForm) {
                    newProps.onBlur = () => this.onChangeForm(false);
                }
                return React.cloneElement(child, newProps);
            });

        const editComponents = React.Children.map(
            editChildren,
            child => {
                const newProps = {
                    disabled: child.props.disabled || disabled || !isEditForm
                };
                if (hasOnChange) {
                    newProps.onChange = this.onChange;
                }
                if (hasOnValidate) {
                    newProps.onValidate = onValidate;
                }
                if (isEditForm) {
                    newProps.onBlur = () => this.onChangeForm(false);
                }
                return React.cloneElement(child, newProps);
            });

        return (
            <div className={className}
                 onClick={canChangeFormOnClick ? () => this.onChangeForm(true) : undefined}>
                {components}
                {isEditForm ? editComponents : viewChildren}
            </div>)

        // return (
        //     <ReactHoverObserver className={className}>
        //         <BorderOnHover showBorderOnHover={canChangeFormOnClick} onClick={canChangeFormOnClick ? () => this.onChangeForm(true) : undefined}>
        //             {components}
        //             {isEditForm ? editComponents : viewChildren}
        //         </BorderOnHover>
        //     </ReactHoverObserver>);
    }
}


// function BorderOnHover(props) {
//     const {isHovering, showBorderOnHover, onClick, children} = props;
//
//     const isBorder = showBorderOnHover && isHovering;
//
//     return (
//         <div className={classnames({ border: true, "border-dark": isBorder, "border-white": !isBorder })} onClick={onClick}>
//             {children}
//         </div>
//     );
// }
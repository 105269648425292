import {graphql} from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import queryUtils from "utils/queryUtils";
import dateUtils from "utils/dateUtils";

const MSEC_20_MINUTES =  1200000;


export const UserQuery = gql`
  query UserQuery {
    user {
      id
      name
      email
      talentId
      parameters
      roles {
        name
      }
      licenses {
        id
        name
        startsAt
        endsAt
      }
      requireTalentLogin
      currentServerUtcDatetime
    }
  }
`;


export const queryUser =
    graphql(UserQuery, {
        options: {
            // 'cache-and-network' does not work with functional components,
            // it gets triggered by itself
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_20_MINUTES
        },
        // skip: ({isAuthorized}) => !isAuthorized,
        props: ({ ownProps, data }) => {
            if (!data.loading && data.user) {
                dateUtils.updateLocalToServerUTCOffsetMillis(data.user.currentServerUtcDatetime, data.user);
            }
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {user: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {user: data.error && data.error.message}),
                user: data.user
            };
        }
    });

import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import TopNav, {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import withConfirmation from 'hocs/withConfirmation'
import LicensesView from './views/LicensesView'
import {DB_ROLE_NAME_SUPERADMIN} from "../constants/constants";


const LicenseApp = compose(
    withConfirmation,
)((props) => {

    const {user} = props;

    const {t} = useTranslation();
    const _t = (value) => t('appLicense.LicenseApp.' + value);

    const navigate = useSmartNavigate();

    // initial breadcrumbs
    const containerId = null;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({name: _t('manager'), to: `${props.uri}`});

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
        linksStore.set(containerId, [], isTerminalBreadcrumbs);
    });

    //access: superadmins only
    const hasAccess = user && user.id && user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN);
    if (!hasAccess) {
        navigate(`${props.uri}/..`);
        return null;
    }

    // rendering
    return (
        <div className="d-flex flex-column h-100 w-100">
            <TopNav user={user} skipRootPath={props.uri} executeWithLock={props.executeWithLock} />

            <div className="flex-grow d-flex flex-column scroll-parent">
                <LicensesView user={user} errors={props.errors} loading={props.loading}
                              setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                              resetActionLock={props.resetActionLock}
                              executeWithLock={props.executeWithLock}
                              executeWithConfirmation={props.executeWithConfirmation}
                              uri={props.uri} uriParams={props.uriParams} location={props.location} />
            </div>
        </div>
    );
});

export default LicenseApp;
import React, {Component} from 'react'

export default class NumberInput extends Component {
    static getRawValue(props) {
        return props.value || props.value === 0 ? String(props.value) : "";
    }

    constructor(props) {
        super(props);
        this.state = {
            iteration: 1
        };
        this.rawValue = NumberInput.getRawValue(props);
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const getParsedValue = (v) => !v && v !== 0 ? null : Number(v);

        if (getParsedValue(this.rawValue) !== getParsedValue(nextProps.value)) {
            this.setState(prevState => ({
                iteration: prevState.iteration + 1
            }));
            this.rawValue = NumberInput.getRawValue(nextProps);
        }
    }

    onChange(e) {
        let value = e.nativeEvent.target.value;
        // "Object.is" differs between positive 0 and negative 0
        if (!Object.is(value, this.state.rawValue)) {
            this.rawValue = value;
            if (e.target.validity.valid) {
                // here we will receive new props and get redraw
                this.props.onChange(e);
            } else {
                // here we should schedule redraw manualy
                this.setState(prevState => ({
                    iteration: prevState.iteration + 1
                }));
            }
        }
    }

    render() {
        const {className, placeholder, disabled} = this.props;

        return (
            <input className={className} type="number" value={this.rawValue} step="any"
                   onWheel={(e) => e.target.blur()}
                   placeholder={placeholder} disabled={disabled} onChange={this.onChange} />
        );
    }
}

import React from 'react'
import {useTranslation} from 'react-i18next'
import {observer} from 'mobx-react'
import classnames from 'classnames'
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'

import Link from 'components/links/Link'
import BlockingLink from 'components/links/BlockingLink'

import {breadcrumbsStore} from 'appBase/TopNav'

const SaveButtonsBlock = observer((props) => {

    function save() {
        const {onSave} = props;
        if (onSave) {
            onSave();
        }
    }

    function cancel() {
        const {onCancel} = props;
        if (onCancel) {
            onCancel();
        }
    }

    const {onCancel, onSave, disabled, withEditMode, withBack, grayWhenEmpty, isChanged, hasErrors, actions,
        additionalInfo, executeWithLock} = props;

    const {t} = useTranslation();

    let linkComponent = null;
    if (withBack) {
        const breadcrumbsData = breadcrumbsStore.getAll();
        const linkDataWithBack = breadcrumbsData.slice().reverse().find(l => l.back);
        if (linkDataWithBack) {
            const targetLinkIndex = breadcrumbsData.indexOf(linkDataWithBack) - linkDataWithBack.back;
            const targetLink = breadcrumbsData[targetLinkIndex];
            if (targetLink) {
                linkComponent = linkDataWithBack.nonBlocking
                    ? <Link to={targetLink.to} state={targetLink.state}>&lt;{t('components.blocks.SaveButtonsBlock.back')}</Link>
                    : <BlockingLink to={targetLink.to} state={targetLink.state}
                                    executeWithLock={executeWithLock}>&lt;{t('components.blocks.SaveButtonsBlock.back')}</BlockingLink>;
            }
        }
    }

    const isEmpty = !(withEditMode && onCancel) && !isChanged && !hasErrors && !linkComponent &&
        (!actions || actions.filter(a => a).length === 0);

    return (
        <div className={classnames("messagearea",
                isEmpty && !grayWhenEmpty && "d-none",
                isEmpty && grayWhenEmpty && "messagearea-gray")}>
            <div>{linkComponent && linkComponent}</div>
            {additionalInfo && <div>{additionalInfo}</div>}
            <div className="d-flex flex-row flex-wrap">
                <Button className="invisible" />

                {!disabled &&
                <>
                {onSave &&
                <Button onClick={save} color="primary" disabled={!isChanged || hasErrors}>
                    <span className={hasErrors ? "text-danger" : ""}>{t('components.blocks.SaveButtonsBlock.save')}</span>
                    {hasErrors &&
                    <i className="fa fa-exclamation-circle text-danger" /> }
                </Button>}
                {withEditMode && !isChanged && onCancel &&
                <Button onClick={cancel} color="primary">{t('components.blocks.SaveButtonsBlock.finish')}</Button>}
                {isChanged && onCancel &&
                <Button onClick={cancel} color="primary">{t('components.blocks.SaveButtonsBlock.cancel')}</Button>}
                </>}

                {actions && actions.filter(a => a).map((a,i) => {
                    if (a.selectItems) {
                        return (<UncontrolledDropdown key={i}>
                            <DropdownToggle nav caret>{a.name}</DropdownToggle>
                            <DropdownMenu>
                                {a.selectItems.map((si, j) =>
                                    <DropdownItem key={j} disabled={!si.to}>
                                        {si.to && (
                                            si.nonBlocking
                                                ? <Link to={si.to} state={si.state}>{si.name}</Link>
                                                : <BlockingLink to={si.to} state={si.state}
                                                                executeWithLock={executeWithLock}>{si.name}</BlockingLink>)}
                                        {!si.to && si.name}
                                    </DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>);
                    }
                    return (<Button key={i} color={a.color} onClick={() => a.action()}
                                    disabled={a.disabled || isChanged || (!a.allowOnErrors && hasErrors)}>{a.name}</Button>);
                })}
            </div>
        </div>);
});

export default SaveButtonsBlock;
import React, {useCallback, useMemo} from 'react'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'
import useSelectVersion from '../hooks/useSelectVersion'

import Tree from 'components/blocks/Tree'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import NamedBlock from 'components/blocks/NamedBlock'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import LicenseScenarioView from 'appAdmin/views/LicenseScenarioView'
import AdminEventsView from 'appAdmin/views/AdminEventsView'

import {queryBrowserFlatCatalogsAndEntries, queryBrowserCatalogEntryVersion} from '../data/catalogQueries'
import entityUtils from 'utils/entityUtils'


const BrowseCatalogView = compose(
    withUriParams([
        ['catalogId', Number],
        ['catalogEntryId', Number],
        ['catalogEntryVersionId', Number]]),
    queryBrowserFlatCatalogsAndEntries,
    queryBrowserCatalogEntryVersion,
    withInitialDataLoadWaiting(['browserFlatCatalogsAndEntries'])
)(props => {
    useDebug("BrowseCatalogView", props);

    const {showScenarios, showCourses, info, externalActionsGenerator, browserCatalogEntryVersion,
        catalogId, catalogEntryId, catalogEntryVersionId, loading, errors} = props;

    // make a copy to be able to sort in-place

    const catalogs = useMemo(() =>
        [...(
            props.browserFlatCatalogsAndEntries &&
            props.browserFlatCatalogsAndEntries.catalogs ||
            [])],
        [props.browserFlatCatalogsAndEntries]);

    const browserCatalogEntries = useMemo(() =>
        [...(
            props.browserFlatCatalogsAndEntries &&
            props.browserFlatCatalogsAndEntries.browserCatalogEntries ||
            [])],
        [props.browserFlatCatalogsAndEntries]);

    const catalog = catalogs.find(c => c.id === props.catalogId);
    const browserCatalogEntry = browserCatalogEntries.find(bce => bce.catalogEntry.id === props.catalogEntryId);

    const navigate = useSmartNavigate();

    // stable object for query override for AdminEventsView
    let licenseEventsDataOverride = useMemo(() =>
            browserCatalogEntryVersion && browserCatalogEntryVersion.event && [browserCatalogEntryVersion.event],
        [browserCatalogEntryVersion]);

    // actions
    let externalActions =
        useMemo(
            () =>
                externalActionsGenerator &&
                externalActionsGenerator(catalog, browserCatalogEntry, browserCatalogEntryVersion),
            [catalog, browserCatalogEntry, browserCatalogEntryVersion, externalActionsGenerator]);

    const {selectVersionDropdownAction} = useSelectVersion(
        browserCatalogEntry && browserCatalogEntry.versionHeaders,
        browserCatalogEntryVersion,
        useCallback((versionId) =>
                `${props.uri}/${catalogId}/${catalogEntryId}/${versionId}${props.location.search}`,
            [props.uri, catalogEntryId, props.location.search]));

    // rendering

    const nodes = [];
    const catalogNodesByCatalogId = {};
    catalogs
        .sort(entityUtils.sortByOrder)
        .forEach(c => {
            const nodeId = `c${c.id}`;
            const catalogNode = {
                id: nodeId,
                parentId: c.parentId && `c${c.parentId}`,
                name: c.name,
                items: [],
                catalog: c,
                actions: {
                    "select": () => navigate(`${props.uri}/${c.id}`)
                }
            };
            catalogNodesByCatalogId[c.id] = catalogNode;
            nodes.push(catalogNode);
        });

    browserCatalogEntries
        .sort(entityUtils.sortByOrder(ewc => ewc.catalogEntry))
        .forEach((bce, i) => {
            if (['scenario', 'page'].includes(bce.catalogEntry.type) && showScenarios) {
                const nodeId = `e${bce.catalogEntry.id}`;
                const entryNode = {
                    id: nodeId,
                    parentId: `c${bce.catalogEntry.catalogId}`,
                    name: bce.catalogEntry.name,
                    // adds: catalogEntry, versionDescription, versionPublishedAt, scenario
                    ...bce,
                    actions: {
                        "select": () => bce.versionHeaders && bce.versionHeaders.length > 0
                            ? navigate(`${props.uri}/${bce.catalogEntry.catalogId}/${bce.catalogEntry.id}/${bce.versionHeaders.slice(-1)[0].id}/scenario`)
                            : navigate(`${props.uri}/${bce.catalogEntry.catalogId}/${bce.catalogEntry.id}`)
                    }
                };
                const catalogNode = catalogNodesByCatalogId[bce.catalogEntry.catalogId];
                catalogNode.items.push(entryNode);
            } else if (bce.catalogEntry.type === 'course' && showCourses) {
                const nodeId = `e${bce.catalogEntry.id}`;
                const entryNode = {
                    id: nodeId,
                    parentId: `c${bce.catalogEntry.catalogId}`,
                    name: bce.catalogEntry.name,
                    icon: "folder",
                    // adds: catalogEntry, versionDescription, versionPublishedAt, scenario
                    ...bce,
                    actions: {
                        "select": () => bce.versionHeaders && bce.versionHeaders.length > 0
                            ? navigate(`${props.uri}/${bce.catalogEntry.catalogId}/${bce.catalogEntry.id}/${bce.versionHeaders.slice(-1)[0].id}/event`)
                            : navigate(`${props.uri}/${bce.catalogEntry.catalogId}/${bce.catalogEntry.id}`)
                    }
                };
                const catalogNode = catalogNodesByCatalogId[bce.catalogEntry.catalogId];
                catalogNode.items.push(entryNode);
            }
        });

    const selectedId = catalogEntryId ? 'e' + catalogEntryId : catalogId ? 'c' + catalogId : null;

    return (
            <div className="flex-grow d-flex flex-row scroll-parent">
                <div className="w-25 scroll">
                    <Tree nodes={nodes} selectedId={selectedId}/>
                </div>
                <div className="w-75 flex-grow d-flex flex-column scroll-parent border border-dark border-top-0 border-end-0 border-bottom-0">
                    <SaveButtonsBlock withBack
                                      executeWithLock={props.executeWithLock}
                                      actions={[...(externalActions || []), selectVersionDropdownAction]} />
                        <div className="flex-grow d-flex flex-column scroll">
                            {licenseEventsDataOverride &&
                            <AdminEventsView licenseId={null}    // no license queries
                                             licenseEventsWithScenarios={licenseEventsDataOverride}    // override data instead of query
                                             disabled
                                             enableCatalogPrototypes
                                             disableSolutions
                                             executeWithLock={props.executeWithLock}
                                             user={props.user}
                                             loading={props.loading} errors={props.errors}
                                             uri={`${props.uri}/${catalogId}/${catalogEntryId}/${catalogEntryVersionId}`}
                                             uriParams={`${props.uriParams}`} location={props.location} />}

                            {browserCatalogEntryVersion && browserCatalogEntryVersion.scenario &&
                            <LicenseScenarioView scenarioId={browserCatalogEntryVersion.scenario.id}
                                                 eventType="competition"
                                                 disabled
                                                 enableCatalogPrototypes
                                                 disableSolutions
                                                 executeWithLock={props.executeWithLock}
                                                 user={props.user}
                                                 loading={loading} errors={errors}
                                                 uri={`${props.uri}/${catalogId}/${catalogEntryId}/${catalogEntryVersionId}`}
                                                 uriParams={props.uriParams} location={props.location}/>}
                            {info &&
                            <div className="workpanel-gray flex-grow">
                                <NamedBlock>
                                    {info}
                                </NamedBlock>
                            </div>}
                        </div>
                </div>
            </div>);
});


export default BrowseCatalogView;
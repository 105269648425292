/**
 * Created by Yury Volkov on 03.08.2020.
 */

export const toggleSearchParam = (locationSearch, searchParam) => {
    locationSearch = locationSearch.substring(1);
    let modes = locationSearch ? locationSearch.split('&') : [];    // location.search starts with '?'
    const ind = modes.indexOf(searchParam);
    if (ind >= 0) {
        modes.splice(ind, 1);
    } else {
        modes.push(searchParam);
    }
    if (modes.length === 0) {
        return '';
    }
    return '?' + modes.join('&');
};

export const setSearchParam = (locationSearch, searchParam) => {
    locationSearch = locationSearch.substring(1);
    let modes = locationSearch ? locationSearch.split('&') : [];    // location.search starts with '?'
    const ind = modes.indexOf(searchParam);
    if (ind === -1) {
        modes.push(searchParam);
    }
    return '?' + modes.join('&');
};

export const removeSearchParam = (locationSearch, searchParam) => {
    locationSearch = locationSearch.substring(1);
    let modes = locationSearch ? locationSearch.split('&') : [];    // location.search starts with '?'
    const ind = modes.indexOf(searchParam);
    if (ind >= 0) {
        modes.splice(ind, 1);
    }
    if (modes.length === 0) {
        return '';
    }
    return '?' + modes.join('&');
};

export const addSearchParamToUrl = (url, searchParam) => {
    const parts = (url || '').split('?');
    const pathname = parts[0];
    const locationSearch = setSearchParam('?' + (parts[1] || ''), searchParam);
    return `${pathname}${locationSearch}`;
};
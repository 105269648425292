import React from 'react'
import {Col} from 'reactstrap'

import LabelWithInfo from './elements/LabelWithInfo'
import NumberInput from './elements/NumberInput'
import Error from './elements/Error'

function DoubleSquareMatrixVal(props) {

    const {isNoInput, wide, name, unit, info, infoImage, disabled, data, field, size, error} = props;

    // copy of data
    const arr = data[field] && Array.isArray(data[field]) ? data[field].slice() : [];

    const onChange = (i, j, e) => {
        arr[i][j] = e.nativeEvent.target.value;
        // converting back
        let isAllNull = true;
        for (let i=0; i < size; i++) {
            for (let j=0; j < size; j++)  {
                arr[i][j] = arr[i][j] !== "" ? Number(arr[i][j]) : null;
                if (arr[i][j] !== null) {
                    isAllNull = false;
                }
            }
        }
        // saving
        if (isAllNull) {
            props.onChange(data, field, null);
        } else {
            props.onChange(data, field, arr);
        }
    };

    for(let i=0; i < size; i++) {
        arr[i] = arr[i] && Array.isArray(arr[i]) ? arr[i].slice() : [];
        for(let j=0; j < size; j++)  {
            arr[i][j] = (arr[i][j] || arr[i][j] === 0) ? String(arr[i][j]) : "";
        }
    }

    return (
        <div className="form-group row g-0">
            <Col sm={wide ? 2 : 3}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
            </Col>
            <Col sm={wide ? 10 : 9}>
            {arr.map( (row, i) =>
                <div className="g-0 parameters-multibox d-flex flex-row" key={i}>
                    {row.map( (v, j) =>
                        <NumberInput key={i + '_' + j} className="form-control flex-shrink-grow"
                                     value={!isNoInput ? v : ""}
                                     placeholder={isNoInput ? v : ""}
                                     disabled={disabled} onChange={onChange.bind(null, i, j)} />
                    )}
                </div>)}
                {error &&  <Error id={field} error={error}/>}
            </Col>
        </div>);
}
export default DoubleSquareMatrixVal;
import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useSimpleGeneratorDataSource from 'hooks/useSimpleGeneratorDataSource'
import useDebug from 'hooks/useDebug'
import classnames from 'classnames'

import NamedBlock from 'components/blocks/NamedBlock'
import List from 'components/blocks/List'
import Block from 'components/fieldBlocks/Block'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import Error from 'components/fields/elements/Error'
import TextVal from 'components/fields/TextVal'
import withUriParams from 'hocs/withUriParams'
import ConstellationSolutionItemView from './ConstellationSolutionItemView'
import ResultsView from './ResultsView'
import Cesium3dView from './Cesium3dView'

import {queryResultsData} from '../../data/eventQueries'

import {prepareResultsData} from '../../data/eventObjects'
import {breadcrumbsStore} from 'appBase/TopNav'
import prototypeUtils from 'utils/prototypeUtils'
import entityUtils from 'utils/entityUtils'
import dateUtils from 'utils/dateUtils'
import staticPrototypes from 'constants/_@_staticPrototypes'
import {SPACECRAFTS, STATIONS, SIMULATION, RESULTS_VIEW, CESIUM_3D_VIEW, SOLUTION, FULL_SCREEN} from 'constants/constants'


const getListSizeLimits = (type, scenario) => {
    const simulationFieldsInfo = prototypeUtils.getFieldsInfo(staticPrototypes[SIMULATION]);
    let min =
        (scenario && scenario.parameters && scenario.parameters.simulation &&
        prototypeUtils.get(scenario.parameters.simulation, 'min-'+type, simulationFieldsInfo)) || 0;
    let max =
        (scenario && scenario.parameters && scenario.parameters.simulation &&
        prototypeUtils.get(scenario.parameters.simulation, 'max-'+type, simulationFieldsInfo)) || 0;
    // корректировка на всякий случай (min 0, max 999)
    min = min >= 0 ? min : 0;
    min = min <= 999 ? min : 999;
    max = max >= min ? max : min;
    max = max <= 999 ? max : 999;
    return [min, max];
};

const checkListSizesAndSetErrors = (dataSource, fromRoot, type, scenario, onValidate, oldListSizeError, setListSizeError, t) => {
    const [min, max] = getListSizeLimits(type, scenario);
    const field = fromRoot(type);
    const length =
        dataSource[field] ?
            dataSource[field].length : 0;
    const error =
        length < min ? `${t('appEvent.views.solutionViews.ConstellationSolutionView.min')} ${min}` :
            length > max ? `${t('appEvent.views.solutionViews.ConstellationSolutionView.max')} ${max}` : null;
    onValidate && onValidate(dataSource, field, !error);
    if (oldListSizeError !== error) {
        setListSizeError(error);
    }
};


const ensureNamesIdsUniqueness = (arrayOfItems) => {
    entityUtils.ensureArrayLocalIds(arrayOfItems);
    entityUtils.ensureNamesUniqueness(arrayOfItems, 'name');
};


const ConstellationSolutionView = compose(
    withUriParams([
        ['itemType', String]]),
    queryResultsData
)(props => {
    useDebug("ConstellationSolutionView", props);

    const {itemType, dataSource, field, scenario, solution, onChange, onValidate, showSolution, showResults} = props;

    // if not exists use default value
    const loading = props.loading || {resultsData: false};

    const fromRoot = useCallback((relativeField) => field ? field + "." + relativeField : relativeField, [field]);

    const [spacecraftListSizeError, setSpacecraftListSizeError] = useState(null);
    const [stationListSizeError, setStationListSizeError] = useState(null);

    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    // process changes to dataSource and scenario

    useEffect(() => {
        if (dataSource) {
            ensureNamesIdsUniqueness(dataSource[fromRoot(SPACECRAFTS)]);
            ensureNamesIdsUniqueness(dataSource[fromRoot(STATIONS)]);
            checkListSizesAndSetErrors(dataSource, fromRoot, SPACECRAFTS, scenario, onValidate, spacecraftListSizeError, setSpacecraftListSizeError, t);
            checkListSizesAndSetErrors(dataSource, fromRoot, STATIONS, scenario, onValidate, stationListSizeError, setStationListSizeError, t);
        }
    }, [dataSource, scenario, onValidate, t,
        spacecraftListSizeError, setSpacecraftListSizeError,
        stationListSizeError, setStationListSizeError, fromRoot]);

    // stable object to use as results dataSource
    const resultsData = useMemo(
        () => prepareResultsData(props.resultsData),
        [props.resultsData]);
    const resultsSource = useMemo(
        () => ({
            results: solution && solution.results,
            resultsData: (solution && solution.overrideResultsData) || resultsData || [],
            resultsData_loading: loading.resultsData
        }),
        [solution, resultsData, loading.resultsData]);
    const [resultsDataSource] = useSimpleGeneratorDataSource(resultsSource);

    // preparing links

    const containerId = SOLUTION;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({
        name: (solution && solution.createdAt && dateUtils.dateToLocaleDateTimeString(solution.createdAt, i18n)) || t('appEvent.views.solutionViews.ConstellationSolutionView.newSolution'),
        to: `${props.uri}`,
        nonBlocking: true});

    useEffect(() => {
        const isTerminal = !itemType;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
    });

   // subnavigation

    if (![null, SPACECRAFTS, STATIONS, RESULTS_VIEW, CESIUM_3D_VIEW].includes(itemType)) {    // item not found
        navigate(`${props.uri}${props.location.search}`);
        return null;
    }

    // actions

    const getNextId = (type) =>
        dataSource[fromRoot(type)]
            .map(y => y.localId)
            .reduce((cMax, c) => cMax > c ? cMax : c, 0) + 1;

    const cloneItem = (type, itemLocalId) => {
        const arr = dataSource[fromRoot(type)];
        const item = arr.find(item => item.localId === itemLocalId);
        if (!item) {
            return;
        }
        const newItem = {
            ...JSON.parse(JSON.stringify(item)),
            localId: getNextId(type)
        };
        arr.push(newItem);
        onChange(dataSource, fromRoot(type), arr);
        checkListSizesAndSetErrors(dataSource, fromRoot, SPACECRAFTS, scenario, onValidate, spacecraftListSizeError, setSpacecraftListSizeError, t);
        checkListSizesAndSetErrors(dataSource, fromRoot, STATIONS, scenario, onValidate, stationListSizeError, setStationListSizeError, t);
        ensureNamesIdsUniqueness(arr);
        navigate(`${props.uri}/${type}/${newItem.localId}${props.location.search}`, {state: {[FULL_SCREEN]: true}});
    };

    const removeItem = (type, itemLocalId) => {
        const arr = dataSource[fromRoot(type)];
        const item = arr.find(item => item.localId === itemLocalId);
        if (!item) {
            return;
        }
        arr.splice(arr.indexOf(item), 1);
        onChange(dataSource, fromRoot(type), arr);
        checkListSizesAndSetErrors(dataSource, fromRoot, SPACECRAFTS, scenario, onValidate, spacecraftListSizeError, setSpacecraftListSizeError, t);
        checkListSizesAndSetErrors(dataSource, fromRoot, STATIONS, scenario, onValidate, stationListSizeError, setStationListSizeError, t);
        ensureNamesIdsUniqueness(arr);
        navigate(`${props.uri}/${type}${props.location.search}`, {state: {[FULL_SCREEN]: true}});
    };

    const createItem = (type) => {
        const arr = dataSource[fromRoot(type)];
        const name = {[SPACECRAFTS]: t('appEvent.views.solutionViews.ConstellationSolutionView.newSpacecraft'), [STATIONS]: t('appEvent.views.solutionViews.ConstellationSolutionView.newStation')}[type];
        const newItem = {
            name: name,
            localId: getNextId(type)
        };
        arr.push(newItem);
        onChange(dataSource, fromRoot(type), arr);
        checkListSizesAndSetErrors(dataSource, fromRoot, SPACECRAFTS, scenario, onValidate, spacecraftListSizeError, setSpacecraftListSizeError, t);
        checkListSizesAndSetErrors(dataSource, fromRoot, STATIONS, scenario, onValidate, stationListSizeError, setStationListSizeError, t);
        ensureNamesIdsUniqueness(arr);
        navigate(`${props.uri}/${type}/${newItem.localId}${props.location.search}`, {state: {[FULL_SCREEN]: true}});
    };


    // rendering

    const {disabled, progress} = props;

    const solutionStatus = solution && solution.status;

    const nameClassNames = {[SPACECRAFTS]: 'spacecraft-name', /*[ORBITS]: 'orbit-name',*/ [STATIONS]: 'gc-name'};

    // spacecrafts, /*orbits,*/ stations:

    const lists = {};

    const types = [SPACECRAFTS, /*ORBITS,*/ STATIONS];

    dataSource && types.forEach(type => {
        !dataSource[fromRoot(type)] && (dataSource[fromRoot(type)] = []);
        const arr = dataSource[fromRoot(type)];

        const rows = [
            ...arr.map(x => ({
                name: <span className={nameClassNames[type]}>{x.name}</span>,
                actions: {
                    "clone": !disabled && (() => cloneItem(type, x.localId)),
                    "removeConfirmation": !disabled && (() => removeItem(type, x.localId)),
                    "select": () => navigate(`${props.uri}/${type}/${x.localId}${props.location.search}`, {state: {[FULL_SCREEN]: true}})}
            }))];
        if (!disabled) {
            rows.push(
                {
                    name: t('appEvent.views.solutionViews.ConstellationSolutionView.add'),
                    className: "text-end",
                    notSelectable: true,
                    actions: {"click": () => createItem(type)}
                });
        }
        lists[type] = rows;
    });

    const spacecraftsNumber = lists.spacecrafts.length - (disabled ? 0 : 1);
    const stationsNumber = lists.stations.length - (disabled ? 0 : 1);

    const [minSpacecrafts, maxSpacecrafts] = getListSizeLimits(SPACECRAFTS, scenario);
    const [minStations, maxStations] = getListSizeLimits(STATIONS, scenario);

    // we need sections only if we show results
    const sections = showResults ? [] : null;
    // if we need sections
    if (sections && showSolution) {
        sections.push({className: "solution-tab-icon", name: t('appEvent.views.solutionViews.ConstellationSolutionView.configuration'), title: t('appEvent.views.solutionViews.ConstellationSolutionView.configuration'), key: "solution"});
    }
    // if we need sections and results are available
    if (sections && showResults && solutionStatus !== "ready") {
        sections.push({className: "solution-result-tab-icon", name: t('appEvent.views.solutionViews.ConstellationSolutionView.results'), title: t('appEvent.views.solutionViews.ConstellationSolutionView.results'), key: "solution-result"});
        sections.push({
            key: "2d", name: t('appEvent.views.solutionViews.ConstellationSolutionView.2dGraphs'), className: "tab-icon sim-results-setup-tab-icon",
            onClick: () => navigate(`${props.uri}/${RESULTS_VIEW}${props.location.search}`)});
        sections.push({
            key: "3d", name: t('appEvent.views.solutionViews.ConstellationSolutionView.3dTrajectory'), className: "tab-icon sim-3D-tab-icon",
            onClick: () => navigate(`${props.uri}/${CESIUM_3D_VIEW}${props.location.search}`, {state: {[FULL_SCREEN]: true}})});
    }

    const resultsPrototypeItems = [
        {
            "field": "results",
            "type": "html",
            "format": "testResult"
        }
    ];

    return (
        <>
        {!itemType &&
        <Block sections={sections}>
            {showSolution &&
            <Block section="solution" className={classnames(!sections && "workpanel-gray")}>
                {(maxSpacecrafts > 0 || spacecraftsNumber > 0) &&
                <NamedBlock name={`${t('appEvent.views.solutionViews.ConstellationSolutionView.spacecrafts')} (${spacecraftsNumber})`}>
                    <List rows={lists.spacecrafts} />
                    {spacecraftListSizeError &&  <Error error={spacecraftListSizeError}/>}
                </NamedBlock>}
                {/*<NamedBlock name={`Orbits (${})`}>*/}
                {/*<List rows={lists.orbits}/>*/}
                {/*</NamedBlock>*/}
                {(maxStations > 0 || stationsNumber > 0) &&
                <NamedBlock name={`${t('appEvent.views.solutionViews.ConstellationSolutionView.stations')} (${stationsNumber})`}>
                    <List rows={lists.stations} />
                    {stationListSizeError &&  <Error error={stationListSizeError}/>}
                </NamedBlock>}
            <TextVal data={dataSource} field="description" name={t('appEvent.views.solutionViews.ConstellationSolutionView.solutionDescription')} onChange={onChange} disabled={disabled} />
            </Block>}
            {showResults && solutionStatus !== "ready" &&
            <Block section="solution-result" className={classnames(!sections && "workpanel-gray")}>
                <p>{t('appEvent.views.solutionViews.ConstellationSolutionView.solution')}{solution && solution.number}:</p>
                {solutionStatus === "running" &&
                <p className="text-info">{t('appEvent.views.solutionViews.ConstellationSolutionView.running')} {progress}</p>}
                {solutionStatus === "evaluating" &&
                <p className="text-info">{t('appEvent.views.solutionViews.ConstellationSolutionView.evaluating')}</p>}
                {(solutionStatus === "evaluating" || solutionStatus === 'failed' || solutionStatus === 'finished') &&
                <GeneratorBlock data={resultsDataSource}
                                items={resultsPrototypeItems} />}
            </Block>}
        </Block>}

        {(itemType === SPACECRAFTS || itemType === STATIONS) &&
        <ConstellationSolutionItemView itemType={itemType} rows={lists[itemType]}
                                       listSizeError={
                                           itemType === SPACECRAFTS ? spacecraftListSizeError :
                                           itemType === STATIONS ? stationListSizeError : null}
                                       disabled={disabled}
                                       dataSource={dataSource}
                                       field={field}
                                       onChange={onChange} onValidate={onValidate}
                                       progress={progress}
                                       loading={props.loading} errors={props.errors}
                                       uri={`${props.uri}/${itemType}`} uriParams={props.uriParams} location={props.location} />}

        {itemType === RESULTS_VIEW &&
        <ResultsView simulation={(solution && solution.simulation) || {}}
                     resultsData={resultsSource.resultsData}
                     loading={loading}
                     uri={`${props.uri}/${itemType}`} uriParams={props.uriParams} location={props.location} />}

        {itemType === CESIUM_3D_VIEW &&
        <Cesium3dView simulation={(solution && solution.simulation) || {}}
                      resultsData={resultsSource.resultsData}
                      loading={loading}
                      uri={`${props.uri}/${itemType}`} uriParams={props.uriParams} location={props.location} />}
        </>);
});



export default ConstellationSolutionView;

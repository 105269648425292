import React from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {useLocation} from '@reach/router'
import useSmartNavigate from 'hooks/useSmartNavigate'

import PanelModal from 'components/modals/PanelModal'
import MarkdownOutput from 'components/elements/MarkdownOutput'
import {updateEventUserInfo} from '../data/eventMutations'

import {toggleSearchParam} from 'utils/urlUtils'
import {EVENT_WELCOME_VIEW} from 'constants/constants'
import entityUtils from "../../utils/entityUtils";


const EventWelcomeModal = compose(
    updateEventUserInfo
)((props) => {

    const location = useLocation();
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // rendering

    const {event, user, eventUserInfo, updateEventUserInfo} = props;

    const eventAnonymousInfo = entityUtils.safeJSONParse(localStorage.eventsInfo, {})[event.id] || {};

    return (
        <PanelModal show title={event.welcomeTitle || t('appEvent.modals.EventWelcomeModal.help')}
                    onClose={() => {
                            if (user.id && (!eventUserInfo || !eventUserInfo.isWelcomeSeen)) {
                                updateEventUserInfo({
                                    eventId: event.linkUuid ? event.linkUuid: event.id,
                                    isWelcomeSeen: true})
                            } else if (!user.id && !eventAnonymousInfo.isWelcomeSeen) {
                                localStorage.eventsInfo = JSON.stringify({
                                    ...entityUtils.safeJSONParse(localStorage.eventsInfo, {}),
                                    [event.id]: {...eventAnonymousInfo, isWelcomeSeen: true}
                                });
                            }
                            navigate(
                                `${location.pathname}${toggleSearchParam(location.search, EVENT_WELCOME_VIEW)}`,
                                {state: {...location.state, eventWelcomeModalWasClosed: true}});
                        }}>

            <MarkdownOutput value={event.welcomeMessage || t('appEvent.modals.EventWelcomeModal.welcome')} />
        </PanelModal>);
});

export default EventWelcomeModal;
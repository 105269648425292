import {useEffect, useRef} from 'react';
import dateUtils from 'utils/dateUtils';

function useUTCTimeout(onTimeoutCallback, endsAtUTCTimestamp) {
    const savedOnTimeoutCallbackRef = useRef();

    // Remember the latest onTimeoutCallback.
    // Change to onTimeoutCallback will not disturb the timeout
    useEffect(() => {
        savedOnTimeoutCallbackRef.current = onTimeoutCallback;
    }, [onTimeoutCallback]);

    // Set up the timeout.
    // If timeout changes, it will clear previous Timeout and set new
    useEffect(() => {
        function onTimeout() {
            // console.log('⏰ Timeout!');
            savedOnTimeoutCallbackRef.current();
        }
        if (endsAtUTCTimestamp) {
            const NowUTCTimestamp = dateUtils.getCurrentServerUTCDate().getTime();
            let timeout = endsAtUTCTimestamp - NowUTCTimestamp;
            // limit to: min 0 max 2147483647
            timeout = Math.min(Math.max(timeout, 0), 2147483647);
            // console.log('⏰ Setting timeout s ' + timeout/1000);
            let id = setTimeout(onTimeout, timeout);
            // clears previous Timeout when timeout changes
            return () => {
                // console.log('⏰ Clearing timeout');
                clearTimeout(id);
            }
        }
    }, [endsAtUTCTimestamp]);
}

export default useUTCTimeout;
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'
import {faIcon} from 'utils/visualHelpers'
import {copyTextToClipboard} from "utils/clipboardUtils";
import TooltipWrapper from "../elements/TooltipWrapper";
import {useInterval} from "usehooks-ts";

export function formatAsSerial(value, mask) {
    if (!value) {
        return '';
    }
    const maskGroupLengths = mask.split('-').map(group => group.length);
    let unprocessedValue = value;
    let groupValues = maskGroupLengths.map(len => {
        let part = unprocessedValue.substring(0, len);
        if (part.length < len) {
            part += new Array(len - part.length + 1).join('_');
        }
        unprocessedValue = unprocessedValue.substring(part.length);
        return part;
    });
    return groupValues.join('-');
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


function SerialNumberVal(props) {

    const {wide, name, info, infoImage, disabled, data, field, error, mask, allowGenerate} = props;
    const {t} = useTranslation();

    const [isCopyNotification, setCopyNotification] = useState(false);

    useInterval(() => {
        setCopyNotification(false);
    }, isCopyNotification ? 1000 : null);

    const maskGroupLengths = mask.split('-').map(group => group.length);

    const originalValue = data && data[field] ? String(data[field]) : "";

    let unprocessedValue = originalValue;
    const groupValues = maskGroupLengths.map(len => {
        const part = unprocessedValue.substring(0, len);
        unprocessedValue = unprocessedValue.substring(part.length);
        return part;
    });

    let isDisabledFromNow = false;
    const groupDisablings = groupValues.map((v, i) => {
        if (!isDisabledFromNow && v.length < maskGroupLengths[i]) {
            isDisabledFromNow = true;
            return false;
        }
        return isDisabledFromNow;
    });

    const groupErrors = groupValues.map((v, i) => originalValue && v.length !== maskGroupLengths[i]);

    const onChange = (i, e) => {
        groupValues[i] = e.nativeEvent.target.value;
        groupValues[i] = groupValues[i].replace(/[^a-zA-Z0-9]/g, '');
        groupValues[i] = groupValues[i].toUpperCase();
        groupValues[i] = groupValues[i].substring(0, maskGroupLengths[i]);
        const value = groupValues.join('');
        props.onChange(data, field, value);
    };

    const onGenerateValue = () => {
        const totalLength = maskGroupLengths.reduce((a, b) => a + b, 0);
        const pseudoRandom = makeid(totalLength);
        props.onChange(data, field, pseudoRandom);
    };

    const onCopyValue = () => {
        copyTextToClipboard(groupValues.join('-'));
        setCopyNotification(true);
    };

    return (
        <div className="form-group row g-0">
            <Col sm={wide ? 2 : 3}>
                <LabelWithInfo name={name} info={info} infoImage={infoImage} />
            </Col>
            <Col sm={wide ? 10 : 9}>
                <div className="g-0 parameters-multibox d-flex flex-row">
                    {groupValues.map((v, i) =>
                        <input key={i} className={classnames("form-control flex-shrink-grow", (groupErrors[i] || error) && "is-invalid")} type="text"
                               value={v}
                               placeholder={!disabled ? new Array(maskGroupLengths[i] + 1).join('X') : ''}
                               disabled={disabled || groupDisablings[i]} onChange={onChange.bind(null, i)} />)}
                    {allowGenerate && !disabled &&
                    <TooltipWrapper tooltip={t('components.fields.SerialNumberVal.generateValue')}>
                        <Button color="primary" onClick={onGenerateValue}>
                            {faIcon('fa-magic')}
                        </Button>
                    </TooltipWrapper>}
                    {disabled &&
                    <TooltipWrapper tooltip={t('components.fields.SerialNumberVal.copy')}>
                        <Button className="d-print-none" color="primary" onClick={onCopyValue}>
                            {isCopyNotification ? faIcon('fa-check') : faIcon('fa-clipboard')}
                        </Button>
                    </TooltipWrapper>}
                </div>
                {error &&  <Error id={field} error={error}/>}
            </Col>
        </div>);
}
export default SerialNumberVal;
/**
 * Created by Yuri on 03.08.2020
 * Part of frontend
 */
import React from 'react'
import useSmartNavigate from 'hooks/useSmartNavigate'

const PARAMS_PROPS_NAME = 'uriParams';

export default function withUriParams(paramDefinitions) {
    if (!Array.isArray(paramDefinitions[0])) {
        paramDefinitions = [paramDefinitions];
    }
    return function withUriParams(WrappedComponent) {
        const WithUriParams = props => {
            const navigate = useSmartNavigate();
            const newProps = {...props};
            const parts = props[PARAMS_PROPS_NAME] ? props[PARAMS_PROPS_NAME].split('/') : [];
            let logParts = [];
            let currentPartInd = 0;
            let redirectPath;
            for(let i = 0; i < paramDefinitions.length; i++) {
                const [propName, classType, options] = paramDefinitions[i];
                const value = parts[currentPartInd] && (classType ? classType(parts[currentPartInd]) : parts[currentPartInd]);
                // check parameters for validity
                if (options && options.optional && (options.defaultValue || options.defaultValue === 0)) {
                    throw new Error("defaultValue and optional should not be enabled together");
                }
                // check value
                const isEmptyOrNotMatching =
                    ((!options || !options.values) && !value && value !== 0) ||           // ("", null, undefined, NaN) are not allowed
                    (options && options.values && !options.values.includes(value));
                // optional and empty or not matching: skipping, no advancing (will try to match same part with next specification)
                if (options && options.optional && isEmptyOrNotMatching) {  // only specified values are allowed
                    newProps[propName] = null;
                } // with default and empty or not matching: redirect to default
                else if (options && (options.defaultValue || options.defaultValue === 0) && isEmptyOrNotMatching) {
                    redirectPath = [...parts.slice(0, currentPartInd), options.defaultValue].join('/');
                    break;
                } // not matching: redirect to top-level
                else if (options && options.values && !options.values.includes(value)) {
                    redirectPath = parts.slice(0, currentPartInd).join('/');
                    break;
                } // take value and advance (next part and next specification)
                else {
                    // convert all bad/undefined values (undefined, NaN) to null
                    newProps[propName] = value === undefined || (typeof value === "number" && isNaN(value)) ? null : value;
                    currentPartInd += 1;
                }
                logParts.push(`${propName}: ${newProps[propName]}`);
            }

            if (redirectPath) {
                navigate(`${props.uri}/${redirectPath}`);
            }

            // console.log(`#withUriParams: ${logParts.join(', ')}`);
            newProps[PARAMS_PROPS_NAME] = parts.slice(currentPartInd).join('/');
            return <WrappedComponent {...newProps} />;
        }

        WithUriParams.displayName = `WithUriParams(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
        return WithUriParams;
    }
};
import React, {Component} from 'react';
import classnames from 'classnames';

class ListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: this.props.selectedIndex
        };
        this.onSelect = this.onSelect.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState((prevState) => {
            prevState.selectedIndex = nextProps.selectedIndex;
            return prevState;
        });
    }

    onSelect(selectedIndex) {
        this.setState((prevState) => ({ selectedIndex }));
    }

    render() {
        const {headers, rows, childMode, sortIndex, sortDirection, onSort } = this.props;
        const {selectedIndex} = this.state;
        return (
                <ul className="list-group listpanel">
                    {headers &&
                    <li className="list-group-item">
                        {headers.map((h,i) =>
                            <span key={i} className={classnames("listpanel-header-elem text-secondary", i%2 && "float-end")}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      if (onSort) {
                                          onSort(i);
                                      }}}>
                                {h}
                                { (i === sortIndex) && sortDirection &&
                                <i className="fa fa-chevron-up" /> }

                                { (i === sortIndex) && !sortDirection &&
                                <i className="fa fa-chevron-down" /> }
                            </span>)}
                    </li>}

                    {rows && rows.map((r, index) =>
                        <Row key={index}
                             row={r}
                             rowIndex={index}
                             selectedIndex={selectedIndex}
                             onSelect={this.onSelect}
                             childMode={childMode} />)}
                </ul>);
    }
}


const onClick = (props) => {
    const {row, rowIndex, onSelect} = props;
    if (!row.notSelectable && onSelect) {
        onSelect(rowIndex);
    }
};

const Row = (props) => {
    const isSelected = props.rowIndex === props.selectedIndex;

    const {row ,childMode } = props;
    const {headers, fields, notSelectable} = row;

    return (
        <li className={
            classnames("list-group-item", {
                    active: isSelected,
                    linktoobject: !isSelected && !notSelectable
                })}
            onClick={(e) => {
                e.stopPropagation();
                onClick(props);
            }}>

            {fields.map((f,i) => {
                return (
                    <span key={i} className={classnames("listpanel-row-elem wrap", childMode && "text-secondary", i%2 && "float-end")}>
                        {f}
                    </span>);})}

            {isSelected && row.children &&
            <div>
                <ListTable childMode headers={headers} rows={row.children} />
            </div>}
        </li>);
};

export default ListTable;
import {useState, useCallback} from 'react';

function useForceRender() {
    const [, setForceRenderTriggerObj] = useState({});

    const forceRender = useCallback(
        () => setForceRenderTriggerObj({}),
        []);

    return forceRender;
}

export default useForceRender;
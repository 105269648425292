/**
 * Created by Yuri on 02.07.2017
 * Part of frontend
 */
import React, {useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import entityUtils from 'utils/entityUtils'
import useForceRender from 'hooks/useForceRender';
import useDebug from "./useDebug";

const sourceObjectSymbol = Symbol('sourceObject');

export const UPDATE_ITERATION_FIELD = "_updateIterationNumber";

const getEmptyState = () => ({
    data: null,
    onSave: null,
    virtualPropsOriginalValues: {},
    isChanged: false,
    validationValues: {},
    hasErrors: false
});

const aggregateErrors = (validationValues) => {
    let hasErrors = false;
    Object.keys(validationValues).forEach(key => {
        hasErrors = hasErrors || validationValues[key] === false;
    });
    return hasErrors;
};

const updateActionLock = (setActionLockWithMessageAndActions, resetActionLock, isChanged, hasErrors, setDataSource, saveAndReset, t) => {
    if (isChanged) {
        if (hasErrors) {
            setActionLockWithMessageAndActions &&
            setActionLockWithMessageAndActions(
                <span>{t('hocs.withGeneratorDataSource.unsavedChanges')}<br />{t('hocs.withGeneratorDataSource.cancelOrReturn')}</span>, {
                    discard: () => setDataSource(null),
                    cancel: () => {}
                });
        } else {
            setActionLockWithMessageAndActions &&
            setActionLockWithMessageAndActions(
                <span>{t('hocs.withGeneratorDataSource.unsavedChanges')}<br />{t('hocs.withGeneratorDataSource.saveCancelOrReturn')}</span>, {
                    discard: () => setDataSource(null),
                    save: saveAndReset,
                    cancel: () => {}
                });
        }
    } else {
        resetActionLock &&
        resetActionLock();
    }
};


export default function useGeneratorDataSource(props) {

    useDebug("useGeneratorDataSource", props);

    // state change from useState is delayed, and our callbacks' changes are delayed, and old callbacks use stale state values
    // so we store state in ref
    const stateRef = useRef(getEmptyState());

    const forceRender = useForceRender();

    const {setActionLockWithMessageAndActions, resetActionLock} = props;
    const {t} = useTranslation();

    // data should be stable object (should not change if underlying data object has not changed)
    const setDataSource = useCallback((newData, newOnSave) => {
        // no previous data or new data (proxy is of other object)
        if (!stateRef.current.data && newData ||
            stateRef.current.data && stateRef.current.data[sourceObjectSymbol] !== newData) {

            // proxificating copy of object
            const proxy = newData ?
                entityUtils.getExistigProxyOrProxificateObj(JSON.parse(JSON.stringify(newData)), true) :
                null;
            if (proxy) {
                proxy[sourceObjectSymbol] = newData;
                proxy[UPDATE_ITERATION_FIELD] = 1;
            }
            // we totaly reset everything including onSave
            stateRef.current = {
                ...getEmptyState(),
                data: proxy,
                onSave: newOnSave
            };
            // force re-render
            forceRender();
            // reset action lock if saving allowed
            newOnSave && resetActionLock && resetActionLock();
        } // if dataSource was not changed, we can change only onSave (if it was changed this was already done)
        else if (stateRef.current.onSave !== newOnSave) {
            stateRef.current.onSave = newOnSave;
        }
    }, [resetActionLock]);

    const saveAndReset = useCallback(() => {
        // discard Virtual Props
        Object.keys(stateRef.current.virtualPropsOriginalValues).forEach(k => {
            if (entityUtils.doesFieldExist(stateRef.current.data, k)) {
                stateRef.current.data[k] = stateRef.current.virtualPropsOriginalValues[k];
            }
            delete stateRef.current.virtualPropsOriginalValues[k];
        });
        delete stateRef.current.data[UPDATE_ITERATION_FIELD];

        // saving
        stateRef.current.onSave && stateRef.current.onSave(stateRef.current.data);
        // resetting data source with same onSave - will reset action lock if it was set
        // will re-render
        setDataSource(null, stateRef.current.onSave);
        return stateRef.current.data;
    }, [setDataSource]);

    const onChange = useCallback((externalData, field, value, isVirtual, noRerender) => {
        // is it the same data as we have?
        if (externalData !== stateRef.current.data) {
            return;
        }

        if (isVirtual && !stateRef.current.virtualPropsOriginalValues.hasOwnProperty(field)) {
            stateRef.current.virtualPropsOriginalValues[field] = stateRef.current.data[field];
        } else if (!isVirtual && stateRef.current.virtualPropsOriginalValues.hasOwnProperty(field)) {
            delete stateRef.current.virtualPropsOriginalValues[field];
        }
        stateRef.current.data[field] = value;

        stateRef.current.data[UPDATE_ITERATION_FIELD] += 1;
        const newIsChanged = isVirtual ? stateRef.current.isChanged : true;

        // update information about fields validation:

        // deleting errors in non-active branch of enum-block
        if (field.endsWith("_type")) {
            const commonPrefix = field.substring(0, field.length - "_type".length);
            const validPrefix = commonPrefix + value;

            Object.keys(stateRef.current.validationValues).forEach(key => {
                if ((key !== field) && (key.startsWith(commonPrefix)) && (!key.startsWith(validPrefix))){
                    delete stateRef.current.validationValues[key];
                }
            });
        }

        //deleting errors from embedded fields if they has been deleted
        Object.keys(stateRef.current.validationValues).forEach(key => {
            if (key.startsWith(field + '.') && !entityUtils.doesFieldExist(stateRef.current.data, key)) {
                delete stateRef.current.validationValues[key];
            }
        });

        const newHasErrors = aggregateErrors(stateRef.current.validationValues);

        if (stateRef.current.isChanged !== newIsChanged || stateRef.current.hasErrors !== newHasErrors) {
            stateRef.current.isChanged = newIsChanged;
            stateRef.current.hasErrors = newHasErrors;
            // re-render because state was changed
            forceRender()
            // update action lock
            stateRef.current.onSave.current &&
                updateActionLock(setActionLockWithMessageAndActions, resetActionLock, newIsChanged, newHasErrors, setDataSource, saveAndReset, t);
        } else if (!noRerender) {
            // re-render because no-rerender flag was not supplied
            forceRender();
        }
    }, [setActionLockWithMessageAndActions, resetActionLock, setDataSource, saveAndReset, t]);

    const onValidate = useCallback((externalData, field, isValid) => {
        // ***************************************************************
        // VALIDATION STRUCTURE
        // First stream (up): user changes field, data is sending through onChange
        // Rendering will start the second stream (down), ValidatorBlock cathes
        // and starts third atream (up), data is sending through onValidate
        // and fourth stream (down) is responsible for button state
        // and will be start only when we need to change the button
        // ***************************************************************

        // is it the same data as we have?
        if (externalData !== stateRef.current.data) {
            return;
        }

        // no changes?
        if (stateRef.current.validationValues[field] === isValid) {
            return;
        }

        stateRef.current.validationValues[field] = isValid;

        const newHasErrors = aggregateErrors(stateRef.current.validationValues);

        // if hasErrors has been changed, we update the state and redraw

        if (stateRef.current.hasErrors !== newHasErrors) {
            stateRef.current.hasErrors = newHasErrors;
            // re-render because state was changed
            forceRender();
            // update action lock
            stateRef.current.onSave.current &&
                updateActionLock(setActionLockWithMessageAndActions, resetActionLock, stateRef.current.isChanged, newHasErrors, setDataSource, saveAndReset, t);
            // update action lock
            stateRef.current.onSave.current &&
                updateActionLock(setActionLockWithMessageAndActions, resetActionLock, stateRef.current.isChanged, newHasErrors, setDataSource, saveAndReset, t);
        }
    }, [setActionLockWithMessageAndActions, resetActionLock, setDataSource, saveAndReset, t]);


    // render
    return {
        dataSource: stateRef.current.data,
        isChanged: stateRef.current.isChanged,
        hasErrors: stateRef.current.hasErrors,
        setDataSource: setDataSource,
        saveAndReset: saveAndReset,
        onChange: onChange,
        onValidate: onValidate
    };
};

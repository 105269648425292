import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import PanelModal from 'components/modals/PanelModal'
import ListWithSort from 'components/blocks/ListWithSort'

import {queryImages} from '../data/adminQueries'


const ModalSelectImageFromDB = compose(
    queryImages
)((props) => {
    const {onSelectImageId, onClose} = props;

    const [selectedImageId, setSelectedImageId] = useState(undefined);
    const {t} = useTranslation();

    const images =
        (props.images && props.images.map(image => ({
            fields: [
                image.name,
                image.format,
                image.width,
                image.height,
                image.sizeKb,
                image.thumbnail &&
                    <img src={image.thumbnail} alt={image.name} style={{maxWidth: "100px", maxHeight: "100px"}} />
            ],
            actions: {
                "select": () => setSelectedImageId(image.id)
            }
        }))) || [];

    // initially selected id or selected in a list
    const currentlySelectedImageId = selectedImageId || props.selectedImageId;
    const selectedIndex = currentlySelectedImageId && props.images && props.images.indexOf(props.images.find(i => i.id === currentlySelectedImageId));

    // rendering

    const actions = [
        selectedImageId && {
            name: t('appAdmin.modals.ModalSelectImageFromDB.choose'),
            action: () => onSelectImageId(selectedImageId)
        },
        {
            name: t('appAdmin.modals.ModalSelectImageFromDB.cancel'),
            action: onClose
        }];

    return (
        <PanelModal show title={t('appAdmin.modals.ModalSelectImageFromDB.images')}
                    onClose={onClose}
                    noPadding scrollable>
            {props.loading['images'] && t('appAdmin.modals.ModalSelectImageFromDB.loading')}
            {!props.loading['images'] &&
            <div className="d-flex flex-column scroll-parent">
                <SaveButtonsBlock actions={actions} />
                <div className="flex-grow scroll">
                    <ListWithSort headers={[t('appAdmin.modals.ModalSelectImageFromDB.name'), t('appAdmin.modals.ModalSelectImageFromDB.format'),
                                            t('appAdmin.modals.ModalSelectImageFromDB.width'), t('appAdmin.modals.ModalSelectImageFromDB.height'),
                                            t('appAdmin.modals.ModalSelectImageFromDB.size'), t('appAdmin.modals.ModalSelectImageFromDB.image')]}
                                  sizes={["250*", "50*", "100*", "100*", "100*", "100*"]}
                                  rows={images} nameIndex="0"
                                  selectedIndex={selectedIndex} />
                </div>
            </div>}
        </PanelModal>);
});

export default ModalSelectImageFromDB;
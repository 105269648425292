import React, {useEffect} from 'react'
import compose from 'lodash/flowRight'
import {useTranslation} from "react-i18next";

import BrowseCatalogView from 'appCatalog/views/BrowseCatalogView'
import {ADMIN_CATALOG_VIEW} from "constants/constants";
import {breadcrumbsStore} from "appBase/TopNav";

const AdminCatalogView = compose(
)(props => {

    const {licenseId, loading, errors} = props;
    const {t} = useTranslation();

    // links
    const containerId = ADMIN_CATALOG_VIEW;
    breadcrumbsStore.register(containerId);

    const links = [{
        name: t('appAdmin.views.AdminCatalogView.catalog'),
        to: `${props.uri}${props.location.search}`,
        // return to license admin page
        back: 1,
        nonBlocking: true
    }];

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    return (
        <div className="flex-grow d-flex flex-column scroll-parent">
            <BrowseCatalogView showScenarios
                               showCourses
                               licenseId={licenseId}
                               loading={loading} errors={errors}
                               uri={props.uri} uriParams={props.uriParams} location={props.location}
                               executeWithLock={props.executeWithLock} />
        </div>);
});

export default AdminCatalogView;
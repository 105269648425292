import React from 'react';

function ChoiceBlock(props) {
    const {children, choiceField, choiceFieldName, value, valueName, dynamicSectionName, disabled,
        data, fieldsInfo, onChange, onValidate, collectionsDict, editLanguage, wide, noLabel} = props;
    const components = React.Children.map(
            children,
            child => React.cloneElement(child, {
                dynamicSectionName: dynamicSectionName,
                disabled: child.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                wide: (child.props && child.props.wide) || wide }));

    return (
        value === (data && choiceField && data[choiceField]) && components && components.length > 0 &&
            <div className="form-group next-level-parameters-group">
                {/* TODO: заменить на configpageblock-name (а его переименовать) */}
                {!noLabel && <h6 className="parameters-group-heading">{choiceFieldName + ": " + valueName}</h6>}
                {components && components}
            </div>);
}

export default ChoiceBlock;
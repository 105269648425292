import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'


class DialogModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: props.hasOwnProperty("show") ? props.show : true
        };

        this.onAction = this.onAction.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // don't reset current state if the "show" was not changed. just do a rerender with current state saved.
        if (this.state.show !== nextProps.show) {
            this.setState((prevState) => ({
                show: nextProps.hasOwnProperty("show") ? nextProps.show : prevState.show
            }));
        }
    }

    onAction(key) {
        const {actions, notCancellable} = this.props;
        const action = actions.find(a => a.key === key);
        if (action || key === 'close') {
            if (key === 'close' && notCancellable) {
                return;
            }
            if (!action || !action.notHideOnAction) {
                this.setState(ps => ({show: !ps.show}));
            }
            if (action && action.action) {
                action.action();
            }
        } else {
            throw new Error(`Unknown action ${key}!`)
        }
    }

    render() {
        const {title, titleComponents, actions, noPadding, children, notCancellable, t} = this.props;
        const {show} = this.state;

        return (
            <Modal isOpen={show} size="xl" aria-labelledby="contained-modal-title-lg" toggle={notCancellable ? undefined : () => this.onAction('close')}
                   backdrop="static" keyboard={false}>
                <ModalHeader id="contained-modal-title-lg" toggle={() => this.onAction('close')}>
                    <div className="float-end">{titleComponents}</div>
                    <div>{title}</div>
                </ModalHeader>

                <ModalBody style={noPadding && {padding: 0}}>
                    {children}
                </ModalBody>

                <ModalFooter style={noPadding && {paddingTop: 0}}>
                    {actions && actions.filter(a => a.name).map((a,i) =>
                        <Button key={i} color={a.color} disabled={a.disabled} onClick={() => this.onAction(a.key)}>{a.name}</Button>)}
                    {actions && !actions.find(a => a.key === 'close') && !notCancellable &&
                        <Button onClick={() => this.onAction('close')}>{t('components.modals.DialogModal.cancel')}</Button>}
                </ModalFooter>
            </Modal>);

    }
}

export default withTranslation()(DialogModal);
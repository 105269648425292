import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Button, Container} from 'reactstrap'
import {CSVLink} from 'react-csv'
import useSmartNavigate from 'hooks/useSmartNavigate'

import FileUpload from '../components/FileUpload'
import PanelModal from 'components/modals/PanelModal'
import StringVal from 'components/fields/StringVal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import Messages from "components/elements/Messages"
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {queryLicenseEvent, queryStudentsWithUsers, queryStudentGroupAssignments, queryStudentTeams, queryStudentTeamAssignments} from '../../data/adminQueries'
import {createStudentTeam, updateStudentTeam, createStudentTeamAssignment, updateStudentTeamAssignment, deleteStudentTeamAssignment, uploadData} from '../../data/adminMutations'

import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import {breadcrumbsStore} from 'appBase/TopNav'
import {ADMIN_STUDENTS_VIEW, STUDENT_TEAM, STUDENT_TEAM_ASSIGNMENT} from 'constants/constants'

const TeamView = compose(
    withUriParams([
    ['mode', String, {optional: true, values: ['select', 'edit']}],
    ['teamAssignmentId', Number]]),
    queryLicenseEvent,
    queryStudentsWithUsers,
    queryStudentGroupAssignments,
    queryStudentTeams,
    queryStudentTeamAssignments,
    createStudentTeam, updateStudentTeam,
    createStudentTeamAssignment, updateStudentTeamAssignment, deleteStudentTeamAssignment,
    uploadData("studentTeamAssignments", ['StudentTeamsQuery', 'StudentTeamAssignmentsQuery']),
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['licenseEvent', 'studentsWithUsers', 'studentGroupAssignments', 'studentTeams', 'studentTeamAssignments']))
((props) => {
    const {licenseId, eventId, teamId, mode, teamAssignmentId, disabled, licenseEvent, studentsWithUsers, studentGroupAssignments,
           studentTeams, studentTeamAssignments, dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate,
           deleteStudentTeamAssignment, uploadStudentTeamAssignments, uri, location} = props;

    const [errorMessages, setErrorMessages] = useState({});

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const studentIds = useMemo(() =>
        studentGroupAssignments.filter(a => a.studentGroup.id === licenseEvent.studentGroupId)
                               .map(a => a.student.id),
        [licenseEvent, studentGroupAssignments]);

    const teamData = useMemo(() => {
        const team = studentTeams.find(t => t.id === teamId);
        return team ? team: {};
    }, [teamId, studentTeams]);

    const teamAssignmentData = useMemo(() => {
        const teamAssignment = studentTeamAssignments && studentTeamAssignments.find(a => a.id === teamAssignmentId);
        return teamAssignment ? {
                id: teamAssignment.id,
                studentId: teamAssignment.student.id,
                studentTeamId: teamAssignment.studentTeam.id
            }: teamId ? {studentTeamId: teamId}: {};
    }, [teamAssignmentId, studentTeamAssignments, teamId]);

    const studentTeamAssignmentsExport = studentTeamAssignments.map(a => [a.student.email, a.studentTeam.name]);


    //links
    const containerId = ADMIN_STUDENTS_VIEW + "child";
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.studentViews.TeamView.eventsAndTeams'), to: `${uri}${location.search}`},
        {name: licenseEvent.isArchived ? t('appAdmin.views.studentViews.TeamView.archive') + licenseEvent.name: licenseEvent.name, to: `${uri}/${eventId}${location.search}`},
        {name: t('appAdmin.views.studentViews.TeamView.teams'), to: `${uri}/${eventId}/team${location.search}`}];

    if (teamId) {
        links.push({name: teamData.name, to: `${uri}/${eventId}/team/${teamId}${location.search}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    // show conditions
    const isTeamInEvent = teamData.event && teamData.event.id === eventId;
    const isAllTeamsMode = !teamId && teamId !== 0;
    const areEventAndTeamMatched = isTeamInEvent || isAllTeamsMode;

    const showTeamNameEditEnabled = ((teamId > 0 && isTeamInEvent) || teamId === 0) && mode === "edit" && !teamAssignmentId && teamAssignmentId !== 0;
    const showTeamNameEditDisabled = areEventAndTeamMatched && !isAllTeamsMode && (mode !== "edit" || teamAssignmentId || teamAssignmentId === 0);
    const showTeamAssignments = areEventAndTeamMatched;
    const showExportButton = areEventAndTeamMatched && studentTeamAssignmentsExport && !!studentTeamAssignmentsExport.length;
    const showPanelModal = areEventAndTeamMatched && mode === "edit" && (teamAssignmentId > 0 || teamAssignmentId === 0);


    // setting dataSource
    useEffect(() => {
        if (showTeamNameEditEnabled) {
            setDataSource(
                teamData,
                (data) => {
                    let mutation;
                    if (teamId > 0) {
                       mutation = 'updateStudentTeam';
                       data = entityUtils.filterFields(data, ['id','name']);
                    } else {
                       mutation = 'createStudentTeam';
                       data = {eventId: eventId, name: data.name};
                    }
                    props[mutation](data)
                        .then(({data: {[mutation]: {studentTeam, error}}}) => {
                            setErrorMessages({...errorMessages, [STUDENT_TEAM]: error ? parseBackendMessage(error, t): null });
                            navigate(`${uri}/${eventId}/team/${studentTeam ? studentTeam.id: teamId}${error ? "/edit": ""}${location.search}`);
                      });
                });
        }
        else {
            setDataSource(
                teamAssignmentData,
                (data) => {
                    let mutation;
                    if (teamAssignmentId > 0) {
                       mutation = 'updateStudentTeamAssignment';
                       data = entityUtils.filterFields(data, ['id','studentTeamId']);
                    } else {
                       mutation = 'createStudentTeamAssignment';
                       data = entityUtils.filterFields(data, ['studentTeamId','studentId']);
                    }
                    props[mutation](data)
                        .then(({data: {[mutation]: {studentTeamAssignment, error}}}) => {
                            setErrorMessages({...errorMessages, [STUDENT_TEAM_ASSIGNMENT]: error ? parseBackendMessage(error, t): null });
                            if (studentTeamAssignment) {
                                navigate(`${uri}/${eventId}/team/${studentTeamAssignment.studentTeam.id + "/select/" + studentTeamAssignment.id}${location.search}`);
                            } else { //error
                                navigate(`${uri}/${eventId}/team${teamId ? "/" + teamId: ""}${teamAssignmentId ?  "/select/" + teamAssignmentId: ""}${location.search}`);
                            }
                      });
                });
        }

    }, [licenseId, teamId, teamAssignmentId, showTeamNameEditEnabled, teamData, teamAssignmentData, dataSource, setDataSource,
        errorMessages, t, uri, location.search, eventId]);

    const teamAssignments =
            studentTeamAssignments
                .map(a => {
                    const swu = studentsWithUsers.find(({student, user}) => student.id === a.student.id);
                    return {
                        fields: [
                            a.student.email,
                            (swu && swu.user) ? swu.user.name: ""
                        ],
                        className: !a.isInStudentGroup && "text-danger",
                        actions: {
                            "select": () => {
                                navigate(`${uri}/${eventId}/team${teamId ? "/" + teamId: ""}/edit/${a.id}${location.search}`);
                            },
                            "removeConfirmation": !disabled && (() => {
                                deleteStudentTeamAssignment(a.id);
                            })
                        }
                    };
                });

    teamAssignments.push({
        fields: [t('appAdmin.views.studentViews.TeamView.addStudentToTeam')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => {
                navigate(`${uri}/${eventId}/team${teamId ? "/" + teamId: ""}/edit/0${location.search}`);
            }
        }
    });

    //selectedIndex
    const selectedIndex = teamAssignments && teamAssignmentId > 0 &&
        teamAssignments.indexOf(teamAssignments.find(t => t.fields[0] === teamAssignmentId));


    const onCancelEdit = () => {
        setDataSource(null);
        navigate(`${uri}/${eventId}/team${teamId ? "/" + teamId: ""}${teamAssignmentId ? "/select/" + teamAssignmentId: ""}${location.search}`);
    };

    return (<>
        <Messages errors={{...errorMessages, ...props.errors}} />
        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
           {showTeamNameEditDisabled &&
            <div>
                <Container fluid>
                    <StringVal wide disabled data={teamData} name={t('appAdmin.views.studentViews.TeamView.teamName')} field="name" />
                </Container>
                <Button color="primary" onClick={() => {
                    navigate(`${uri}/${eventId}/team${teamId ? "/" + teamId: ""}/edit${location.search}`)}}>{t('appAdmin.views.studentViews.TeamView.edit')}
                </Button>
            </div>}


            {showTeamNameEditEnabled &&
            <div>
                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                                  onSave={saveAndReset} onCancel={onCancelEdit} />
                <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                items={[{
                                    name: t('appAdmin.views.studentViews.TeamView.teamName'),
                                    type: "string",
                                    field: "name",
                                }]} />
                {teamId > 0 &&
                <Button color="primary" disabled>{t('appAdmin.views.studentViews.TeamView.edit')}</Button>}
            </div>}

            <br/>
            {showExportButton &&
            <CSVLink uFEFF data={studentTeamAssignmentsExport} separator=";">
                <Button color="primary">{t('appAdmin.views.studentViews.TeamView.export')}</Button>
            </CSVLink>}

            {showTeamAssignments &&
            <NamedBlock name={t('appAdmin.views.studentViews.TeamView.teamsEntryList')} className="bg-light">
                <ListWithSort headers={[t('appAdmin.views.studentViews.TeamView.email'),
                                        t('appAdmin.views.studentViews.TeamView.name')]}
                              rows={teamAssignments} selectedIndex={selectedIndex} disabled={disabled} />
            </NamedBlock>}

            {showPanelModal &&
            <PanelModal show title={`${teamAssignmentId ? t('appAdmin.views.studentViews.TeamView.moveStudentToTeam'):
                                                          t('appAdmin.views.studentViews.TeamView.addStudentToTeam2')}`} onClose={onCancelEdit} noPadding scrollable>
                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                                  onSave={saveAndReset} onCancel={onCancelEdit} />
                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                    <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                    items={[{
                                        type: "select",
                                        nullOption: t('appAdmin.views.studentViews.TeamView.chooseStudent'),
                                        name: t('appAdmin.views.studentViews.TeamView.students'),
                                        field: "studentId",
                                        required: true,
                                        disabled: teamAssignmentId,
                                        options: studentsWithUsers.map(({student, user}) => ({name: `${user && user.name} ${student.email}`, value: student.id}))
                                                                  .filter(s => studentIds.includes(s.value))
                                    },
                                    {
                                        type: "select",
                                        nullOption: t('appAdmin.views.studentViews.TeamView.chooseTeam'),
                                        name: t('appAdmin.views.studentViews.TeamView.teams2'),
                                        field: "studentTeamId",
                                        required: true,
                                        options: studentTeams.map(t => ({name: t.name, value: t.id})),
                                    }]}/>
                </div>
            </PanelModal>}

            {!disabled && showTeamAssignments &&
            <>
                <div className="text-muted">{t('appAdmin.views.studentViews.TeamView.fileFormat')}</div>
                <FileUpload name={t('appAdmin.views.studentViews.TeamView.import')}
                            uploadMutation={(data) => uploadStudentTeamAssignments({licenseId: licenseId, eventId: eventId, type: "studentTeamAssignments", ...data})}
                            mutationResultsName="uploadData"validExtensionsList={["xlsx"]} disabled={disabled} />
            </>}
        </div>
    </>);
});

export default TeamView;
import entityUtils from 'utils/entityUtils'
import {translateObjOrArray} from 'utils/translationUtils'
import {staticAllFunctionsTypes} from 'constants/staticReferenceData'

export function ensureDevicesConsistency(devices, t) {
    entityUtils.ensureArrayLocalIds(devices);
    entityUtils.ensureNamesUniqueness(devices, '$name', 'name');
    devices.forEach((d, i) => {
        // updating indices
        d.index = i;
        if (d.functions) {
            entityUtils.ensureArrayLocalIds(d.functions);
            entityUtils.ensureNamesUniqueness(d.functions, '$name', 'type._type', translateObjOrArray(staticAllFunctionsTypes, t));
            // updating indices
            d.functions.forEach((f, j) => f.index = j);
        }
    })
};
import React, {Component} from 'react'
import {graphql} from '@apollo/client/react/hoc'
import {Controlled as ReactCodeMirror} from 'react-codemirror2'
import {Resizable} from 're-resizable'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'
import {PylintQuery} from 'appEvent/data/eventQueries'

import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/lint/lint.css'

const CodeMirror = require('codemirror/lib/codemirror');
require('codemirror/mode/python/python');
require('codemirror/addon/search/search');
require('codemirror/addon/search/searchcursor');
require('codemirror/addon/search/jump-to-line');
require('codemirror/addon/dialog/dialog');
require('codemirror/addon/lint/lint');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/selection/active-line');


export default class PythonEditorVal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iteration: null,
            iterationValue: null
        };
        this.interval = null;
        this.onTick = this.onTick.bind(this);
        this.PythonEditorWithData =
            graphql(PylintQuery, {
                options: ({iterationValue, iteration}) => {
                    return ({
                        variables: {text: iterationValue, iteration: iteration}
                    })
                },
                skip: ({iterationValue}) => !iterationValue,
                props: ({loading, errors, data}) => {
                    return ({
                        loading: {...loading, pylint: data.loading},
                        errors: {...errors, pylint: data.error && data.error.message},
                        pylint: data.pylint && data.pylint && {
                            iteration: data.pylint.iteration,
                            errors: data.pylint.errors
                        }
                    })
                },
            })(PythonEditor);
    }

    componentDidMount() {
        this.interval = setInterval(this.onTick, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentWillReceiveProps(nextProps) {
        const newValue = nextProps.data && nextProps.field && nextProps.data[nextProps.field];
        const {iterationValue} = this.state;
        const {field, data, onValidate} = nextProps;
        if (!newValue) {
            // clear any errors
            onValidate && onValidate(data, field, true);
        } else if (newValue !== iterationValue) {
            // setting validation-undefined until validation finishes
            onValidate && onValidate(data, field, undefined);
        }
    }

    onTick() {
        const {field, data} = this.props;
        const value = data && field && data[field];
        const {iterationValue} = this.state;
        if (value !== iterationValue) {
            // requesting new query
            this.setState(prevState => ({
                iteration: prevState.iteration + 1,
                iterationValue: value
            }));
        }
    }

    render() {
        const {field, data, error} = this.props;
        const value = data && field && data[field];
        const {iteration, iterationValue} = this.state;
        return (
            <this.PythonEditorWithData {...this.props}
                                       value={value}
                                       error={error}
                                       onChange={this.props.onChange}
                                       onValidate={this.props.onValidate}
                                       // for querying
                                       iteration={iteration} iterationValue={iterationValue}
            />
        );
    }
}

class PythonEditor extends Component {

    static remoteValidator = function(text, options) {
        if (!text || !(text.trim())) {
            return []
        }
        const errors = options.getLastPylintErrors();
        return errors.map(error => {
            const start_line = error.lineNo;
            const start_char = error.column_no_start || error.column_no;
            const end_char = error.column_no_stop || error.column_no;
            const end_line = error.lineNo;
            const message = error.message;
            const severity = error.severity || 'error';
            return {
                from: CodeMirror.Pos(start_line - 1, start_char),
                to: CodeMirror.Pos(end_line - 1, end_char),
                message: message,
                severity: severity // "error", "warning"
            };
        });
    };

    constructor(props) {
        super(props);
        this.instance = null;
        this.errors = [];
        this.getLastPylintErrors = this.getLastPylintErrors.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const oldPylintIteration = this.props.pylint && this.props.pylint.iteration;
        const newPylintIteration = nextProps.pylint && nextProps.pylint.iteration;
        const hasErrorsOrWarnings = this.errors && this.errors.length > 0;
        if (!this.props.value && hasErrorsOrWarnings) {
            // clear any errors
            this.errors = [];
            this.hasErrors = false;
            this.instance.performLint();
        } else if (newPylintIteration !== oldPylintIteration && nextProps.pylint) {
            // performLint is called when new properties have not yet replaced old ones, so we store errors in class.
            this.errors =
                nextProps.pylint.errors ||
                [{lineNo: 1, message: "Syntax checking not working. You have too many errors?", severity: "error"}];
            this.hasErrors = this.errors.some(e => e.severity === "error");
            this.instance.performLint();
            // updating validation info
            nextProps.onValidate && nextProps.onValidate(this.props.data, this.props.field, !this.hasErrors);
        }
    }

    getLastPylintErrors() {
        return this.errors;
    }

    render() {
        const {noLabel, name, unit, info, infoImage, disabled, field, data, error} = this.props;
        const value = this.props.value || "";

        let contents =
            <Resizable className={classnames("w-100 border", (this.hasErrors || error) ? "border-danger" : "border-dark")}
                       enable={{
                           top: false,
                           right: false,
                           bottom: true,
                           left: false,
                           topRight: false,
                           bottomRight: false,
                           bottomLeft: false,
                           topLeft: false,
                       }}
                      onResize={(e, dir, ref, delta) => {
                          if (this.instance) {
                              this.instance.setSize(null, ref.clientHeight + "px");
                          }
                      }}>
                <ReactCodeMirror className="w-100 mb-1"
                                 value={value}
                                 options={{
                                     mode: 'python',
                                     //theme: 'material',
                                     lineNumbers: true,
                                     indentUnit: 4,
                                     extraKeys: {
                                         Tab: function(cm) {
                                             const spaces = new Array(cm.getOption("indentUnit") + 1).join(" ");
                                             cm.replaceSelection(spaces);
                                         }
                                     },
                                     matchBrackets: true,
                                     styleActiveLine:true,
                                     gutters: ["CodeMirror-lint-markers"],
                                     lint: {
                                         "getAnnotations": PythonEditor.remoteValidator,
                                         "getLastPylintErrors": this.getLastPylintErrors,
                                         lintOnChange: false
                                     },
                                     readOnly: disabled
                                 }}
                                 editorDidMount={editor => {
                                     this.instance = editor;
                                     this.instance.setSize(null, 400 + "px");
                                 }}
                                 onBeforeChange={(editor, editorDdata, value) => {
                                     //this.setState({value});
                                     value = value.replace(/\t/g, '    ');
                                     this.props.onChange(data, field, value);
                                 }}
                />
            </Resizable>;

            return (
                <div className="form-group row g-0">
                    {!noLabel &&
                        <LabelWithInfo className="mb-1" name={name} unit={unit} info={info} infoImage={infoImage}/>}
                    {contents}
                    {error &&
                        <Error id={field} error={error}/>}
                </div>);

    }
}
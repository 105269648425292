import React, {useState, useMemo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Nav, Alert} from 'reactstrap'
import compose from 'lodash/flowRight'
import {Router} from '@reach/router'
import DownloadLink from 'react-download-link'

import useSmartNavigate from 'hooks/useSmartNavigate'
import Link from 'components/links/Link'
import TopNav, {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import withConfirmation from 'hocs/withConfirmation'
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import AdminEventsView from 'appAdmin/views/AdminEventsView'
import AdminStudentsView from 'appAdmin/views/AdminStudentsView'
import AdminImagesView from 'appAdmin/views/AdminImagesView'
import AdminSolutionsView from 'appAdmin/views/AdminSolutionsView'
import ScoreCorrectionsView from 'appAdmin/views/ScoreCorrectionsView'
import UsersProgressView from 'appAdmin/views/UsersProgressView'
import RegisterUsersView from 'appAdmin/views/RegisterUsersView'
import AdminCatalogView from 'appAdmin/views/AdminCatalogView'
import FileUpload from 'appAdmin/views/components/FileUpload'
import dateUtils from 'utils/dateUtils'
import entityUtils from 'utils/entityUtils'
import {parseBackendMessage} from 'utils/translationUtils'
import licenseStaticPrototypes from 'appLicense/data/licenseStaticPrototypes.json'

import {queryUserLicenses, queryLicense} from './data/adminQueries'
import {exportLicense, importLicense} from './data/adminMutations'
import {activateLicense} from 'appLicense/data/licenseMutations'
import {ACCESS_ROLE_ADMIN, DB_ROLE_NAME_SUPERADMIN} from "constants/constants";


const AdminApp = compose(
    withUriParams([
        ['licenseId', Number],
        ['mode', String, {values: ['activate'], optional: true}]]),
    queryUserLicenses,
    queryLicense,
    exportLicense,
    importLicense,
    activateLicense,
    withConfirmation,
    withGeneratorDataSource,
    withInitialDataLoadWaiting(['userLicenses'])
)((props) => {

    const {licenseId, mode, userLicenses, license, exportLicense, importLicense, activateLicense, user,
          dataSource, setDataSource, saveAndReset, isChanged, onChange, onValidate} = props;
    const [isExporting, setIsExporting] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // initial breadcrumbs
    const containerId = null;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({name: t('appAdmin.AdminApp.admin'), to: `${props.uri}`});
    // const license = userLicenses && userLicenses.find(l => l.id === licenseId);
    if (license) {
        breadcrumbs.push({name: license.name, to: `${props.uri}/${licenseId}`});
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
        linksStore.set(containerId, [], isTerminalBreadcrumbs);
    });

    const isActivateLicenseMode =
        mode === "activate" && license && license.isIssued && !license.isActivated && license.prefix && license.templateDurationMonths;

    // license object for dataSource
    const licenseSourceForActivateLicenseMode = useMemo(() => license ? license: null
    , [license]);

    // setting dataSource
    useEffect(() => {
        if (isActivateLicenseMode) {
            setDataSource(
                licenseSourceForActivateLicenseMode,
                (data) => {
                    data = entityUtils.filterFields(
                                data, [
                                    'id',
                                    'organizerNameMl',
                                    'address',
                                    'contacts'
                                ]);
                    activateLicense(data)
                        .then(({data: {activateLicense: {license, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                        });
                    });
        }
    }, [licenseSourceForActivateLicenseMode, activateLicense, dataSource, setDataSource, setError, t]);

    //access: superadmins, license admins
    const hasAccess = user && user.id && ((user.licenses && user.licenses.length) ||
                                          (user.roles && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN)));
    if (!hasAccess) {
        navigate(`${props.uri}/..`);
        return null;
    }

    //onSave, onCancel
    const onSave = () => {
        saveAndReset();
        navigate(`${props.uri}/${licenseId}${props.location.search}`);
    };
    const onCancel = () => {
        setDataSource(null);
        navigate(`${props.uri}/${licenseId}${props.location.search}`);
    };

    // rendering router
    return (
        <div className="d-flex flex-column h-100 w-100">
            <TopNav user={user} skipRootPath={props.uri} executeWithLock={props.executeWithLock} />

             {error &&
             <Alert className="mb-0" color="danger">{error}</Alert>}

            {!props.uriParams &&
            <div className="container mt-3">
                <h5 className="text-center">{t('appAdmin.AdminApp.adminPanel')}</h5>
                <div>
                    <div>{t('appAdmin.AdminApp.chooseLicense')}</div>
                    <select value={licenseId || ''} onChange={(e) => props.navigate(`./${Number(e.nativeEvent.target.value) || ''}`)}>
                        <option value="">{t('appAdmin.AdminApp.chooseLicense2')}</option>
                        {userLicenses.map(l => (<option key={l.id} value={l.id}>{l.name}{l.disabled ? t('appAdmin.AdminApp.noActive') : ''}</option>))}
                        {license && !userLicenses.find(l => l.id === license.id) &&
                            <option value={licenseId}>{license.name}{t('appAdmin.AdminApp.viewOnly')}</option>}
                    </select>
                    {licenseId && !license &&
                    <div>{t('appAdmin.AdminApp.loading')}</div>}
                    {license &&
                    <Nav vertical>
                        {license.isIssued && !license.isActivated && license.templateDurationMonths && license.prefix &&
                        <Link to={`./${licenseId}/activate`}>{t('appAdmin.AdminApp.activate')}</Link>}
                        <Link to={`./${licenseId}/events`}>{t('appAdmin.AdminApp.events')}</Link>
                        <Link to={`./${licenseId}/students`}>{t('appAdmin.AdminApp.students')}</Link>
                        <Link to={`./${licenseId}/images`}>{t('appAdmin.AdminApp.images')}</Link>
                        <Link to={`./${licenseId}/solutions`}>{t('appAdmin.AdminApp.solutions')}</Link>
                        <Link to={`./${licenseId}/scoreCorrections`}>{t('appAdmin.AdminApp.scoreCorrections')}</Link>
                        <Link to={`./${licenseId}/registerUsers`}>{t('appAdmin.AdminApp.registerUsers')}</Link>
                        <Link to={`./${licenseId}/usersProgress`}>{t('appAdmin.AdminApp.usersProgress')}</Link>
                        <Link to={`./${licenseId}/catalog`}>{t('appAdmin.AdminApp.catalog')}</Link>

                        {!isExporting &&
                        <DownloadLink label={t('appAdmin.AdminApp.exportLicense')} filename={`${license.name}-${dateUtils.dateToFilenameDateString(new Date())}.json`}
                                      className="nav-link" style={{}}
                                      exportFile={() => {
                                         setIsExporting(true);
                                            return exportLicense(licenseId).then(({data: {exportLicense: {json}}}) => {setIsExporting(false); return json;})
                                      }}/>}
                    </Nav>}
                    <div className="mt-3">
                        {isExporting && <>{t('appAdmin.AdminApp.archive')}</>}
                        <FileUpload name={t('appAdmin.AdminApp.importLicense')} noReplaceCurrentData
                                    uploadConfirmation={{header: t('appAdmin.AdminApp.importLicense'), text: t('appAdmin.AdminApp.importLicenseConfirmation')}}
                                    extraMutationData={[{"field": "licenseId", "value": licenseId}]} validExtensionsList={["json"]}
                                    uploadMutation={(data) => importLicense(data)} mutationResultsName="importLicense" />
                    </div>
                </div>
            </div>}

            {license &&
            <Router className="flex-grow d-flex flex-column scroll-parent" basepath={props.uri + '/' + licenseId}>
                <AdminEventsView path="events/*uriParams"
                                 licenseId={licenseId}
                                 disabled={license.disabled}
                                 enableAddNewEvents
                                 enableShowArchived
                                 enableDeleteStudentSolutions
                                 enableBackLink     // return back to license page
                                 showBackLink       // show back link
                                 user={user} errors={props.errors} loading={props.loading} refetch={{}}
                                 setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                 resetActionLock={props.resetActionLock}
                                 executeWithLock={props.executeWithLock}
                                 executeWithConfirmation={props.executeWithConfirmation} />
                <AdminStudentsView path="students/*uriParams"
                                   licenseId={licenseId}
                                   disabled={license.disabled}
                                   user={user} errors={props.errors} loading={props.loading}
                                   setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                   resetActionLock={props.resetActionLock}
                                   executeWithLock={props.executeWithLock}
                                   executeWithConfirmation={props.executeWithConfirmation} />
                <AdminImagesView path="images/*uriParams"
                                 licenseId={licenseId}
                                 disabled={license.disabled}
                                 user={user} errors={props.errors} loading={props.loading}
                                 setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                 resetActionLock={props.resetActionLock}
                                 executeWithLock={props.executeWithLock}
                                 executeWithConfirmation={props.executeWithConfirmation} />
                <AdminSolutionsView path="solutions/*uriParams"
                                    licenseId={licenseId}
                                    queryAsRole={ACCESS_ROLE_ADMIN}
                                    disabled={license.disabled}
                                    user={user} errors={props.errors} loading={props.loading}
                                    setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                    resetActionLock={props.resetActionLock}
                                    executeWithLock={props.executeWithLock}
                                    executeWithConfirmation={props.executeWithConfirmation} />
                <ScoreCorrectionsView path="scoreCorrections/*uriParams"
                                      licenseId={licenseId}
                                      disabled={license.disabled}
                                      user={user} errors={props.errors} loading={props.loading}
                                      setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                      resetActionLock={props.resetActionLock}
                                      executeWithLock={props.executeWithLock}
                                      executeWithConfirmation={props.executeWithConfirmation} />
                <RegisterUsersView path="registerUsers"
                                   licenseId={licenseId}
                                   disabled={license.disabled}
                                   user={user} errors={props.errors} loading={props.loading}
                                   setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                   resetActionLock={props.resetActionLock}
                                   executeWithLock={props.executeWithLock}
                                   executeWithConfirmation={props.executeWithConfirmation} />
                <UsersProgressView path="usersProgress/*uriParams"
                                   licenseId={licenseId}
                                   disabled={license.disabled}
                                   user={user} errors={props.errors} loading={props.loading}
                                   setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                   resetActionLock={props.resetActionLock}
                                   executeWithLock={props.executeWithLock}
                                   executeWithConfirmation={props.executeWithConfirmation} />
                <AdminCatalogView path="catalog/*uriParams"
                                  licenseId={licenseId}
                                  user={user} errors={props.errors} loading={props.loading}
                                  executeWithLock={props.executeWithLock} />
            </Router>}

            {isActivateLicenseMode &&
            <PanelModal show title={t('appAdmin.AdminApp.activate')} onClose={onCancel}>
                <SaveButtonsBlock withEditMode isChanged={isChanged} onSave={onSave} onCancel={onCancel} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                        items={licenseStaticPrototypes.licenseActivateFields} />
                    </div>
             </PanelModal>}
        </div>
    );
});

export default AdminApp;
import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import {licenseEventFields, licenseScenarioFields, studentGroupAssignmentsFields, studentTeamAssignmentsFields} from './adminQueries'
import {SolutionsQuery} from "../../appEvent/data/eventQueries";


const CreateEvent = gql`
  mutation createEvent($data: CreateEventMutationInput!) {
    createEvent(data: $data) {
      id
      error
    }
  }
`;


const UpdateEvent = gql`
  mutation updateEvent($data: UpdateEventMutationInput!) {
    updateEvent(data: $data) {
      licenseEvent {
        ${licenseEventFields}
      }
      error
    }
  }
`;


const DeleteEvent = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;


const CloneEvent = gql`
  mutation cloneEvent($id: ID!) {
    cloneEvent(id: $id) {
      id
      error
    }
  }
`;


const CreateScenario = gql`
  mutation createScenario($data: CreateScenarioMutationInput!) {
    createScenario(data: $data) {
      id
      error
    }
  }
`;


const UpdateScenario = gql`
  mutation updateScenario($data: UpdateScenarioMutationInput!) {
    updateScenario(data: $data) {
      licenseScenario {
        ${licenseScenarioFields}
      }
      error
    }
  }
`;


const DeleteScenario = gql`
  mutation deleteScenario($id: ID!) {
    deleteScenario(id: $id) {
      id
    }
  }
`;


const CloneScenario = gql`
  mutation cloneScenario($id: ID!) {
    cloneScenario(id: $id) {
      id
      error
    }
  }
`;


const CreateStudent = gql`
  mutation createStudent($data: CreateStudentMutationInput!) {
    createStudent(data: $data) {
      student {
        id
        email
      }
      error
    }
  }
`;


const UpdateStudent = gql`
  mutation updateStudent($data: UpdateStudentMutationInput!) {
    updateStudent(data: $data) {
      student {
        id
        email
      }
      error
    }
  }
`;


const DeleteStudent = gql`
  mutation deleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      id
    }
  }
`;


const CreateStudentGroup = gql`
  mutation createStudentGroup($data: CreateStudentGroupMutationInput!) {
    createStudentGroup(data: $data) {
      studentGroup {
        id
        name
      }
      error
    }
  }
`;


const UpdateStudentGroup = gql`
  mutation updateStudentGroup($data: UpdateStudentGroupMutationInput!) {
    updateStudentGroup(data: $data) {
      studentGroup {
        id
        name
      }
      error
    }
  }
`;


const DeleteStudentGroup = gql`
  mutation deleteStudentGroup($id: ID!) {
    deleteStudentGroup(id: $id) {
      id
    }
  }
`;


const CreateStudentGroupAssignment = gql`
  mutation createStudentGroupAssignment($data: CreateStudentGroupAssignmentMutationInput!) {
    createStudentGroupAssignment(data: $data) {
      studentGroupAssignment {
        ${studentGroupAssignmentsFields}
      }
      error
    }
  }
`;


const DeleteStudentGroupAssignment = gql`
  mutation deleteStudentGroupAssignment($id: ID!) {
    deleteStudentGroupAssignment(id: $id) {
      id
    }
  }
`;


const CreateStudentTeam = gql`
  mutation createStudentTeam($data: CreateStudentTeamMutationInput!) {
    createStudentTeam(data: $data) {
      studentTeam {
        id
        eventId
        name
      }
      error
    }
  }
`;


const UpdateStudentTeam = gql`
  mutation updateStudentTeam($data: UpdateStudentTeamMutationInput!) {
    updateStudentTeam(data: $data) {
      studentTeam {
        id
        eventId
        name
      }
      error
    }
  }
`;


const DeleteStudentTeam = gql`
  mutation deleteStudentTeam($id: ID!) {
    deleteStudentTeam(id: $id) {
      id
    }
  }
`;


const CreateStudentTeamAssignment = gql`
  mutation createStudentTeamAssignment($data: CreateStudentTeamAssignmentMutationInput!) {
    createStudentTeamAssignment(data: $data) {
      studentTeamAssignment {
        ${studentTeamAssignmentsFields}
      }
      error
    }
  }
`;


const UpdateStudentTeamAssignment = gql`
  mutation updateStudentTeamAssignment($data: UpdateStudentTeamAssignmentMutationInput!) {
    updateStudentTeamAssignment(data: $data) {
      studentTeamAssignment {
        ${studentTeamAssignmentsFields}
      }
      error
    }
  }
`;


const DeleteStudentTeamAssignment = gql`
  mutation deleteStudentTeamAssignment($id: ID!) {
    deleteStudentTeamAssignment(id: $id) {
      id
    }
  }
`;


const UploadData = gql`
  mutation uploadData($data: UploadDataMutationInput!) {
    uploadData(data: $data) {
        result
        infos
        errors
    }
  }
`;


export const getMutationName = (verb, type) => {
     return verb + type.charAt(0).toUpperCase() + type.slice(1);
};


const UpsertImage = gql`
  mutation upsertImage($data: UpsertImageMutationInput!) {
    upsertImage(data: $data) {
        image {
          id
          name
          isVector
          thumbnail
          width
          height
          sizeKb
        }
        error
    }
  }
`;


const DeleteImage = gql`
  mutation deleteImage($id: ID!) {
    deleteImage(id: $id) {
      id
    }
  }
`;


const CreateScoreCorrection = gql`
  mutation createScoreCorrection($data: CreateScoreCorrectionMutationInput!) {
    createScoreCorrection(data: $data) {
      error
    }
  }
`;


const UpdateScoreCorrection = gql`
  mutation updateScoreCorrection($data: UpdateScoreCorrectionMutationInput!) {
    updateScoreCorrection(data: $data) {
      error
    }
  }
`;


const DeleteScoreCorrection = gql`
  mutation deleteScoreCorrection($id: ID!) {
    deleteScoreCorrection(id: $id) {
      id
    }
  }
`;


const RegisterUsers = gql`
  mutation registerUsers($data: RegisterUsersMutationInput!) {
    registerUsers(data: $data) {
      result
      previewSubject
      previewText
      previewUsers {
        name
        email
        parameters
      }
      errors
    }
  }
`;



const RecalcSolutions = gql`
  mutation recalcSolutions($id: ID!) {
    recalcSolutions(id: $id) {
      id
      error
    }
  }
`;


const DeleteSolutions = gql`
  mutation deleteSolutions($eventId: ID, $scenarioId: ID, $isTest: Boolean!) {
    deleteSolutions(eventId: $eventId, scenarioId: $scenarioId, isTest: $isTest) {
      error
    }
  }
`;


const DeleteSimulationsAndResults = gql`
  mutation deleteSimulationsAndResults($eventId: ID, $scenarioId: ID, $isDeleteRunning: Boolean!) {
    deleteSimulationsAndResults(eventId: $eventId, scenarioId: $scenarioId, isDeleteRunning: $isDeleteRunning) {
      hasRunning
      error
    }
  }
`;


const ExportLicense = gql`
  mutation exportLicense($id: ID!) {
    exportLicense(id: $id) {
      json
    }
  }
`;


const ImportLicense = gql`
  mutation importLicense($data: ImportLicenseMutationInput!) {
    importLicense(data: $data) {
      result
      infos
      errors
    }
  }
`;


const DeleteCompetitionSession = gql`
  mutation deleteCompetitionSession($id: ID!) {
    deleteCompetitionSession(id: $id) {
      id
      error
    }
  }
`;
// Event

export const createEvent = graphql(CreateEvent, {
    props: ({ mutate }) => ({ createEvent: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'LicenseEventsQuery', 'LicenseStatisticsQuery']}
});
export const updateEvent = graphql(UpdateEvent, {
    props: ({ mutate }) => ({ updateEvent: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'LicenseStatisticsQuery', 'CatalogEntryWithVersionsQuery']}
});
export const deleteEvent = graphql(DeleteEvent, {
    props: ({ mutate }) => ({ deleteEvent: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'LicenseEventsQuery', 'LicenseStatisticsQuery']}
});
export const cloneEvent = graphql(CloneEvent, {
    props: ({ mutate }) => ({ cloneEvent: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'LicenseEventsQuery', 'LicenseStatisticsQuery']}
});

// Scenario

export const createScenario = graphql(CreateScenario, {
    props: ({ mutate }) => ({ createScenario: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'CatalogEntryWithVersionsQuery', 'LicenseStatisticsQuery']}
});
export const updateScenario = graphql(UpdateScenario, {
    props: ({ mutate }) => ({ updateScenario: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'CatalogEntryWithVersionsQuery', 'LicenseStatisticsQuery']}
});
export const deleteScenario = graphql(DeleteScenario, {
    props: ({ mutate }) => ({ deleteScenario: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'CatalogEntryWithVersionsQuery', 'LicenseStatisticsQuery']}
});
export const cloneScenario = graphql(CloneScenario, {
    props: ({ mutate }) => ({ cloneScenario: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseEventsWithScenariosQuery', 'CatalogEntryWithVersionsQuery', 'LicenseStatisticsQuery']}
});

// Student

export const createStudent = graphql(CreateStudent, {
    props: ({ mutate }) => ({ createStudent: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentsWithUsersQuery']}
});
export const updateStudent = graphql(UpdateStudent, {
    props: ({ mutate }) => ({ updateStudent: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentsWithUsersQuery']}
});
export const deleteStudent = graphql(DeleteStudent, {
    props: ({ mutate }) => ({ deleteStudent: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['StudentsWithUsersQuery', 'StudentGroupAssignmentsQuery', 'StudentTeamAssignmentsQuery', 'LicenseStatisticsQuery']}
});

// Group

export const createStudentGroup = graphql(CreateStudentGroup, {
    props: ({ mutate }) => ({ createStudentGroup: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentGroupsQuery']}
});
export const updateStudentGroup = graphql(UpdateStudentGroup, {
    props: ({ mutate }) => ({ updateStudentGroup: (data) => mutate({ variables: { data } }) }),
});
export const deleteStudentGroup = graphql(DeleteStudentGroup, {
    props: ({ mutate }) => ({ deleteStudentGroup: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['StudentGroupsQuery', 'StudentGroupAssignmentsQuery', 'LicenseStatisticsQuery']}
});


// Group Assignment

export const createStudentGroupAssignment = graphql(CreateStudentGroupAssignment, {
    props: ({ mutate }) => ({ createStudentGroupAssignment: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentGroupAssignmentsQuery', 'LicenseStatisticsQuery']}
});
export const deleteStudentGroupAssignment = graphql(DeleteStudentGroupAssignment, {
    props: ({ mutate }) => ({ deleteStudentGroupAssignment: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['StudentGroupAssignmentsQuery', 'LicenseStatisticsQuery']}
});


// Team

export const createStudentTeam = graphql(CreateStudentTeam, {
    props: ({ mutate }) => ({ createStudentTeam: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentTeamsQuery']}
});


export const updateStudentTeam = graphql(UpdateStudentTeam, {
    props: ({ mutate }) => ({ updateStudentTeam: (data) => mutate({ variables: { data } }) }),
});


export const deleteStudentTeam = graphql(DeleteStudentTeam, {
    props: ({ mutate }) => ({ deleteStudentTeam: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['StudentTeamsQuery', 'StudentTeamAssignmentsQuery']}
});


export const createStudentTeamAssignment = graphql(CreateStudentTeamAssignment, {
    props: ({ mutate }) => ({ createStudentTeamAssignment: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['StudentTeamAssignmentsQuery']}
});


export const updateStudentTeamAssignment = graphql(UpdateStudentTeamAssignment, {
    props: ({ mutate }) => ({ updateStudentTeamAssignment: (data) => mutate({ variables: { data } }) }),
});


export const deleteStudentTeamAssignment = graphql(DeleteStudentTeamAssignment, {
    props: ({ mutate }) => ({ deleteStudentTeamAssignment: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['StudentTeamAssignmentsQuery']}
});


export const uploadData = (type, refetchQueries) => {
    return graphql(UploadData, {
        props: ({ mutate }) => ({ [getMutationName("upload", type)]: (data) => mutate({ variables: { data } }) }),
        options: { refetchQueries: refetchQueries }
    });
};


export const upsertImage = graphql(UpsertImage, {
    props: ({ mutate }) => ({ upsertImage: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ImagesQuery', 'LicenseStatisticsQuery']}
});


export const deleteImage = graphql(DeleteImage, {
    props: ({ mutate }) => ({ deleteImage: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['ImagesQuery', 'GhostEmbedImagesQuery', 'LicenseStatisticsQuery']}
});

// Score Correction

export const createScoreCorrection = graphql(CreateScoreCorrection, {
    props: ({ mutate }) => ({ createScoreCorrection: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ScoreCorrectionsQuery']}
});


export const updateScoreCorrection = graphql(UpdateScoreCorrection, {
    props: ({ mutate }) => ({ updateScoreCorrection: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['ScoreCorrectionsQuery']}
});


export const deleteScoreCorrection = graphql(DeleteScoreCorrection, {
    props: ({ mutate }) => ({ deleteScoreCorrection: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['ScoreCorrectionsQuery']}
});


export const registerUsers = graphql(RegisterUsers, {
    props: ({ mutate }) => ({ registerUsers: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['UsersRegistrationsQuery']}
});


export const recalcSolutions = graphql(RecalcSolutions, {
    props: ({ mutate }) => ({ recalcSolutions: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicenseScenarioQuery']}
});


export const deleteSolutions = graphql(DeleteSolutions, {
    props: ({ mutate }) => ({ deleteSolutions: (eventId, scenarioId, isTest) => mutate({ variables: { eventId, scenarioId, isTest } }) }),
    options: ({ scenarioId, queryAsRole, eventId }) => ({ refetchQueries: [
            {query: SolutionsQuery, variables: {scenarioId, asRole: queryAsRole}},
            'LicenseEventQuery',
            'LicenseScenarioQuery',
            'CatalogEntryVersionSolutionsQuery']})
});


export const deleteSimulationsAndResults = graphql(DeleteSimulationsAndResults, {
    props: ({ mutate }) => ({ deleteSimulationsAndResults: (eventId, scenarioId, isDeleteRunning) =>
            mutate({ variables: { eventId, scenarioId, isDeleteRunning } }) }),
    options: { refetchQueries: ['LicenseEventQuery', 'LicenseScenarioQuery']}
});


export const exportLicense = graphql(ExportLicense, {
    props: ({ mutate }) => ({ exportLicense: (id) => mutate({ variables: { id } }) }),
});


export const importLicense = graphql(ImportLicense, {
    props: ({ mutate }) => ({ importLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['UserLicensesQuery']}
});


export const deleteCompetitionSession = graphql(DeleteCompetitionSession, {
    props: ({ mutate }) => ({ deleteCompetitionSession: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['EventUsersDataQuery']}
});

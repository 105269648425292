import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import LicenseView from './LicenseView'

import {queryLicenses} from '../data/licenseQueries'
import {createLicense} from '../data/licenseMutations'

import dateUtils from 'utils/dateUtils'
import {formatAsSerial} from 'components/fields/SerialNumberVal'
import {coloredIcon} from 'utils/visualHelpers'
import {breadcrumbsStore} from 'appBase/TopNav'
import {LICENSES_VIEW} from 'constants/constants'


const LicensesView = compose(
    withUriParams([
        ['licenseId', Number]]),
    queryLicenses,
    createLicense,
    withInitialDataLoadWaiting(['licenses'])
)((props) => {
    const {licenses, licenseId, createLicense} = props;

    const {t, i18n} = useTranslation();
    const _t = (value) => t('appLicense.views.LicensesView.' + value);

    const navigate = useSmartNavigate();
    const license = licenseId && licenses.find(l => l.id === licenseId);


    // links
    const containerId = LICENSES_VIEW;
    breadcrumbsStore.register(containerId);
    const links = [];
    if (license) {
        links.push({name: `${license.name}`, to: `${props.uri}/${licenseId}/${props.location.search}`});
    }
    useEffect(() => {
        const isTerminalBreadcrumbs = !licenseId;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    // rendering

    const licenseRows =
        licenses.map(license => ({
            fields: [
                formatAsSerial(license.number, 'XXXX-XXXXXX'),
                license.name,
                license.organizerName,
                license.isSuperlicense && coloredIcon('yes'),
                license.isIssued && coloredIcon('yes'),
                (license.isActivated && coloredIcon('yes')) || coloredIcon('lock'),
                license.startsAt && dateUtils.dateToLocaleDateTimeString(license.startsAt, i18n),
                license.endsAt && dateUtils.dateToLocaleDateTimeString(license.endsAt, i18n),
                license.isSuperlicense ? t('general.capitalNo'): license.studentsLimit,
                license.isSuperlicense ? t('general.capitalNo'): license.eventsLimit,
                license.eventsNumber,
                (license.dataSize / (1024 * 1024)).toFixed(1),
                license.isSuperlicense ? _t('unlimited'): Math.max(0.1, license.dataLimit / (1024 * 1024)).toFixed(1),
                license.priority
            ],
            actions: {
                "select": () => navigate(`${props.uri}/${license.id}/license${props.location.search}`),
                "view": () => navigate(`admin/${license.id}`)
            }
        }));

    licenseRows.push({
        fields: [_t('addNewLicense')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () =>
                createLicense({name: _t('newLicense')})
                    .then(({data: {createLicense: {id}}}) => navigate(`${props.uri}/${id}${props.location.search}`))
        }
    });

    return (
        <div className="flex-grow d-flex container-fluid scroll-parent">
            {!licenseId &&
            <div className="flex-grow workpanel-gray scroll">
                <NamedBlock name={_t('licenses')}>
                    <ListWithSort headers={[_t('code'), _t('name'),_t('organizer'), _t('isSuperLicense'),
                                            _t('isIssued'), _t('isActivated'),_t('startDate'), _t('endDate'),
                                            _t('studentsLimit'), _t('eventsLimit'),_t('eventsNumber'), _t('dataSize'),
                                            _t('maxDataSize'), _t('priority')]}
                                  sizes={["50*", "100*", "100*", "40*", "40*", "40*", "40*", "40*", "40*", "40*", "40*", "50*", "50*", "40*"]}
                                  rows={licenseRows} nameIndex={0} sortIndex={5} />
                </NamedBlock>
            </div>}
            {licenseId && license &&
            <LicenseView licenseId={licenseId}  // used by queryAdminsWithUsers
                         license={license}
                         errors={props.errors} loading={props.loading}
                         setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                         resetActionLock={props.resetActionLock}
                         executeWithLock={props.executeWithLock}
                         executeWithConfirmation={props.executeWithConfirmation}
                         uri={`${props.uri}/${licenseId}`} uriParams={props.uriParams} location={props.location} />}
        </div>);
});

export default LicensesView;
import entityUtils from "../../utils/entityUtils";

export const prepareEventObjectForEdit = (event) => {
    if (!event) {
        return null;
    }

    // preparing
    return {
        // rest of the fields that are edited as-is
        ...event,
        // ontiMode fields
        ontiParameters: {
            _type: event.isOntiMode,
            "true": entityUtils.safeJSONParse(event.ontiParameters, {})
        },
        // competitionMode fields
        competitionRules: {
            _type: event.isCompetitionMode,
            "true": {
                ...entityUtils.safeJSONParse(event.competitionRules, {}),
                competitionModeInfoMessageMl: event.competitionModeInfoMessageMl
            }
        }
    }
}

export const prepareEventEditObjectForSave = (eventEditObj, isSuperlicense) => {
    if (!eventEditObj) {
        return null;
    }

    const isOntiMode = eventEditObj.ontiParameters && eventEditObj.ontiParameters._type;
    const ontiParameters = (eventEditObj.ontiParameters && eventEditObj.ontiParameters._type &&
        eventEditObj.ontiParameters[eventEditObj.ontiParameters._type]) || null;

    const isCompetitionMode = eventEditObj.competitionRules && eventEditObj.competitionRules._type;
    const competitionRules = (eventEditObj.competitionRules && eventEditObj.competitionRules._type &&
        eventEditObj.competitionRules[eventEditObj.competitionRules._type]) || null;

    // result:
    return {
        ...eventEditObj,
        // default value if undefined
        teamMembersLimit: eventEditObj.teamMembersLimit || 4,
        // ontiMode fields
        isOntiMode: isOntiMode,
        ontiParameters: ontiParameters && JSON.stringify(ontiParameters),
        // competitionMode fields
        isCompetitionMode: isCompetitionMode,
        competitionRules: competitionRules && JSON.stringify(competitionRules),
        competitionModeInfoMessageMl: competitionRules?.competitionModeInfoMessageMl
    };
};

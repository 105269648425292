import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import convert from 'constants/conversions'


class ConversionsBlock extends Component {
    static convert(props, changedField, value, noChange) {
        const {conversion, data, field} = props;
        const changes = convert(conversion, field, data, changedField, value, props.t);
        Object.entries(changes).forEach(([f, v]) => {
            props.onChange(data, f, v, noChange);
        });
    }

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        ConversionsBlock.convert(this.props, null, null, true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field) {
            ConversionsBlock.convert(nextProps, null, null, true);
        }
    }

    onChange(data, changedField, value, noChanges) {
        this.props.onChange(data, changedField, value, noChanges);
        ConversionsBlock.convert(this.props, changedField, value, noChanges);
    }

    render() {
        const {children, dynamicSectionName, disabled, data, fieldsInfo, collectionsDict, editLanguage, onValidate, wide} = this.props;

        const components = React.Children.map(
            children,
            child => React.cloneElement(child, {
                dynamicSectionName: dynamicSectionName,
                disabled: child.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: this.onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                wide: child.props.wide || wide
            }));

        return (
            // <React.Fragment>
            <div className="form-group">
                {components}
            </div>
            // </React.Fragment>
        );
    }
};

export default withTranslation()(ConversionsBlock);
import React from 'react';
import {NavLink} from "reactstrap";
import useSmartNavigate from 'hooks/useSmartNavigate'

const BlockingLink = props => {
    const {to, state, action, executeWithLock, children} = props;
    const navigate = useSmartNavigate();

    const onClick = (e) => {
        e.preventDefault();
        executeWithLock(action ? action: () => navigate(to, {state: state}));
        return false;
    };
    return (
        <NavLink href={to} onClick={onClick}>{children}</NavLink>);
};

export default BlockingLink;
import entityUtils from 'utils/entityUtils'
import {encodeJsUnicodeStringToBase64EncodedUTF8String, safeDecodeBase64EncodedUTF8StringToJsUnicodeString} from 'utils/b64Utils'
import {convertOldJSONPrototypeParameters, convertOldJSONPrototypeResults} from 'appEvent/data/eventObjects'

// helpers

const buildProgPrototypeParams = (prototypeProgramNames, t) => {
    return [
        ...(prototypeProgramNames || []).map((nameObj,i) => ({field: "program"+(i+1), name: nameObj.name || t('appAdmin.data.adminScenarioObjects.program'), type: "pythonEditor"})),
        {
            field: "description",
            type: "text",
            name: t('appAdmin.data.adminScenarioObjects.description')
        }];
};


// converters

export const prepareScenarioScenarioObjectForEdit = (scenario) => {
    if (!scenario) {
        return null;
    }
    const prototypeParameters = convertOldJSONPrototypeParameters(scenario.prototypeParameters);
    const prototypeResults = convertOldJSONPrototypeResults(scenario.prototypeResults);
    const parameters = entityUtils.safeJSONParse(scenario.parameters, {});

    let {_style, monitor, data, ...mainSimulationTemplate} = entityUtils.safeJSONParse(scenario.simulationTemplate, {});
    let {runs, ...progData} = data || {};
    // const dataRuns = data["runs"];
    // const dataAggregate = data["aggregate"];
    // const dataControllerProgram = data["controller-program"];
    // const dataScoreMultiplier = data["score-multiplier"];
    // const dataAllowLogs = data["allow-logs"];
    // const dataRunLogLimit = data["run-log-limit"];
    const {parts, test, number_without_fee, delta_fee_percent, is_multiplicative, minimum_result_percent} = entityUtils.safeJSONParse(scenario.scoringRules, {});
    // procesing parts for test scenario
    const parsedParts =
        parts && parts.map(p => ({
            ...p,
            rules: (p.rules || []).map(r => ({
                ...r,
                and_conditions: (r.and_conditions || []).map(cond => ({
                        ...cond,
                        type: {
                            _type: cond.type,
                            [cond.type]: {
                                condition: cond.condition
                            }
                        }
                    })
                )
            }))
        }));
    // preparing
    return {
        // rest of the fields that are edited as-is
        ...scenario,
        // contains simulation object, that is rendered in separate tab
        parameters: parameters,
        // type-dependent fields
        type: {
            _type: scenario.type || "",
            [scenario.type || ""]: {
                // for prog:
                prototypeProgramNames: entityUtils.safeJSONParse(prototypeParameters, [])
                    .filter(p => p.type === 'pythonEditor').map(p => ({name: p.name})),
                runs: (runs || []).map(r => ({
                    ...r,
                    input: safeDecodeBase64EncodedUTF8StringToJsUnicodeString(r.input || ""),
                    output: safeDecodeBase64EncodedUTF8StringToJsUnicodeString(r.output || "")
                })),
                progData : {
                    ...progData,
                    "controller-program": safeDecodeBase64EncodedUTF8StringToJsUnicodeString(progData["controller-program"] || "")
                },
                // for test, generative test, sim:
                prototypeParameters: prototypeParameters,
                // for sim:
                prototypeResults: prototypeResults,
                mainSimulationTemplate: JSON.stringify(mainSimulationTemplate || {}),
                // for test:
                testScoringRulesParts: parsedParts || [],
                // for generative test:
                parameters: parameters,
                // for sim:
                show3dResults: scenario.show3dResults,
                // for sim, constellation:
                monitorProgram: safeDecodeBase64EncodedUTF8StringToJsUnicodeString((monitor || {}).program || ""),
                resultsDecimation: scenario.resultsDecimation
            }
        },
        // parsed scoring rules
        scoringRules: {test, number_without_fee, delta_fee_percent, is_multiplicative, minimum_result_percent},
        initialParameters: entityUtils.safeJSONParse(scenario.initialParameters, {})
    };

};


export const prepareScenarioScenarioEditObjectForSave = (scenarioEditObj, includeInitialParams, t) => {
    if (!scenarioEditObj) {
        return null;
    }

    // All first-level db-object fields that are inside conditional blocks (like Choice) should be explicitly set to null!
    // because undefined is treated as not supplied data, and conditionals can become undefined.
    // and all unconditionals cannot be set to undefined again.
    const scenarioType = scenarioEditObj.type && scenarioEditObj.type._type;
    const scenarioData = (scenarioEditObj.type && scenarioEditObj.type._type && scenarioEditObj.type[scenarioEditObj.type._type]) || {};
    const progData = scenarioData.progData || {};
    const progPrototypeParameters = buildProgPrototypeParams(scenarioData.prototypeProgramNames, t) || [];
    const simplePrototypeResults = [{
        field: "results",
        type: "html",
        format: "testResult"
    }];
    // from sim:
    const simulationTemplateForSim =
        scenarioType === 'sim' ?
            {
                ...entityUtils.safeJSONParse(scenarioData.mainSimulationTemplate, {}),
                monitor: {
                    runtime: "challenge",
                    program: encodeJsUnicodeStringToBase64EncodedUTF8String(scenarioData.monitorProgram || "")
                }
            } : {};
    // from constellation:
    const simulationTemplateForConstellation =
        scenarioType === 'constellation' ?
            {
                monitor: {
                    runtime: "challenge",
                    program: encodeJsUnicodeStringToBase64EncodedUTF8String(scenarioData.monitorProgram || "")
                }
            } : {};
    // from prog:
    const simulationTemplateForProg =
        scenarioType === 'prog' ?
            {
                data: {
                    runs: (scenarioData.runs || []).map(r => ({
                        ...r,
                        input: encodeJsUnicodeStringToBase64EncodedUTF8String(r.input || ""),
                        output: encodeJsUnicodeStringToBase64EncodedUTF8String(r.output || ""),
                        count: r.count || 1,
                        score: r.score || 0,
                        time: r.time || 10
                    })),
                    ...progData,
                    aggregate: progData.aggregate || "sum",
                    "score-multiplier": progData["score-multiplier"] || 1,
                    "controller-type": "user",
                    "controller-program": encodeJsUnicodeStringToBase64EncodedUTF8String(progData["controller-program"] || "")
                },
                programs: progPrototypeParameters
                    .filter(p => p.type === 'pythonEditor').map(p => `$$solution.${p.field}:base64`),
            } : {};
    // procesing parts for test scenario
    const parsedTestScoringRulesParts =
        scenarioData.testScoringRulesParts && scenarioData.testScoringRulesParts.map(p =>({
            ...p,
            rules: (p.rules || []).map(r => ({
                ...r,
                and_conditions: (r.and_conditions || []).map(cond => ({
                    field: cond.field,
                    type: cond.type._type,
                    condition: cond.type[cond.type._type].condition,
                }))
            }))
        }));

    // result:
    return {
        // rest of the fields that were edited as-is
        ...scenarioEditObj,
        type: scenarioType,
        // in constellation case contains simulation object, that was rendered in separate tab
        parameters:
            scenarioType === 'constellation' ?
                JSON.stringify(scenarioEditObj.parameters || {}) :
            scenarioType === 'generative_test' ?
                JSON.stringify(scenarioData.parameters || {}):
            JSON.stringify({}),
        // !it is conditional, so should be set explicitly if undefined!
        prototypeParameters:
            scenarioType === 'prog' ?
                JSON.stringify(progPrototypeParameters) :
                scenarioData.prototypeParameters || "[]",
        // !it is conditional, so should be set explicitly if undefined!
        prototypeResults:
            scenarioType === 'test' || scenarioType === 'prog' || scenarioType === 'generative_test' ?
                JSON.stringify(simplePrototypeResults) :
                scenarioData.prototypeResults || "[]",
        // !it is conditional, so should be set explicitly (to null or to some value) if undefined!
        simulationTemplate: scenarioType === 'test' ? null :
            JSON.stringify({
                _style: scenarioType === 'constellation' ? undefined : "$$",
                ...simulationTemplateForSim,
                ...simulationTemplateForConstellation,
                ...simulationTemplateForProg
            }),
        scoringRules: JSON.stringify({
            // for test:
            parts: parsedTestScoringRulesParts,
            // for all:
            ...(scenarioEditObj.scoringRules || {})
        }),
        // for sim only:
        // !it is conditional, so should be set explicitly (to null or to some value) if undefined!
        show3dResults: scenarioData.show3dResults || false,
        // for sim and constellation:
        // !it is conditional, so should be set to null explicitly if undefined!
        resultsDecimation: scenarioData.resultsDecimation || null,
        // we either clear it or save it
        initialParameters: JSON.stringify(includeInitialParams ? scenarioEditObj.initialParameters : {})
    };
};



export const prepareEditScenarioScenarioObjectForSolutionAndResultsView = (scenario, t) => {
    if (!scenario) {
        return null;
    }

    const scenarioType = scenario.type && scenario.type._type;
    const scenarioData = (scenario.type && scenario.type._type && scenario.type[scenario.type._type]) || {};
    const progPrototypeParameters = buildProgPrototypeParams(scenarioData.prototypeProgramNames, t);
    const simplePrototypeResults = [{
        field: "results",
        type: "html",
        format: "testResult"
    }];
    // result:
    return {
        id: scenario.id,
        type: scenarioType,
        // contains simulation object, that was rendered in separate tab. Will add empty simulation by default
        parameters: { simulation: {}, ...(scenario.parameters || {}) },
        // for all:
        prototypeParameters: scenarioType === 'prog' ? progPrototypeParameters : entityUtils.safeJSONParse(scenarioData.prototypeParameters) || [],
        prototypeResults: scenarioType === 'test' || scenarioType === 'prog' || scenarioType === 'generative_test'
            ? simplePrototypeResults
            : entityUtils.safeJSONParse(scenarioData.prototypeResults, [])
    };
};

import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Alert} from 'reactstrap'

import {breadcrumbsStore} from 'appBase/TopNav'
import {getInfoAlert} from 'utils/resultsUtils'
import {CESIUM_3D_VIEW} from 'constants/constants'

class Cesium3dView extends Component {

    static analyzeData(props) {
        let {simulation, resultsData} = props;
        const loading = props.loading || {resultsData: false};

        const noResults = !resultsData || resultsData.length === 0;
        const noSpacecrafts = !props.skipSimulationDataCheck &&
            (!simulation.simulationData || !simulation.simulationData.spacecrafts || simulation.simulationData.spacecrafts.length === 0);

        return {noResults: noResults, noSpacecrafts: noSpacecrafts, loading: loading.resultsData};
    }

    static createCesiumViewer(props) {
        const resultsData = props.resultsData.map(data => {
            const spacecrafts = [];
            for (let i = 0; i < 100; i++) {
                const root = `s.${i}`;
                if (!(`${root}.m.p.0` in data)) {
                    break;
                }
                spacecrafts.push({
                    'motion': {
                        'position': [data[`${root}.m.p.0`], data[`${root}.m.p.1`], data[`${root}.m.p.2`]],
                        'orientation': [data[`${root}.m.o.0`], data[`${root}.m.o.1`], data[`${root}.m.o.2`], data[`${root}.m.o.3`]],
                        'linear-velocity': [data[`${root}.m.lv.0`], data[`${root}.m.lv.1`], data[`${root}.m.lv.2`]],
                        'angular-velocity': [data[`${root}.m.av.0`], data[`${root}.m.av.1`], data[`${root}.m.av.2`]]
                    },
                    'construction': {
                        'mass': data[`${root}.cn.m`]
                    }
                });
            }
            return {
                'time': data.t + 'Z',   // adding UTC time zone back
                'spacecrafts': spacecrafts
            };
        });

        let directLoader = new window.DirectDataProvider();
        window.orbitaGlobal = new window.OrbitaApp({
            converter : {
                converterFilterRange : 1
            },
            loader: directLoader,
            // onBack: props.onBack
        });
        directLoader.setData(resultsData);
    }

    static destroyCesiumViewer() {
        if (window.orbitaGlobal && window.orbitaGlobal._viewer) {
            try {
                window.orbitaGlobal._viewer.destroy();
            } catch (e) {
                console.error(e);
            }
        }
    }


    constructor(props) {
        super(props);
        // links
        const containerId = CESIUM_3D_VIEW;
        breadcrumbsStore.register(containerId);
        //breadcrumbsStore.set(containerId, [{name: props.solution., to: props.uri, back: 1}, {name: "3D trajectory", props.uri, back: 1}], true);
        breadcrumbsStore.set(containerId, {name: props.t('appEvent.views.solutionViews.Cesium3dView.3dTrajectory'), back: 1, nonBlocking: true}, true);
    }

    componentDidMount() {
        const {noResults, noSpacecrafts, loading} = Cesium3dView.analyzeData(this.props);
        if (!loading && !noResults && !noSpacecrafts) {
            Cesium3dView.createCesiumViewer(this.props);
        }
    }

    componentWillUnmount() {
        Cesium3dView.destroyCesiumViewer();
    }

    componentWillReceiveProps(nextProps) {
        // resetting series
        if ((nextProps.simulation && nextProps.simulation.id) !== (this.props.simulation && this.props.simulation.id) ||
            (nextProps.resultsData && nextProps.resultsData.length) !== (this.props.resultsData && this.props.resultsData.length)
        ) {
            Cesium3dView.destroyCesiumViewer();
            const {noResults, noSpacecrafts, loading} = Cesium3dView.analyzeData(nextProps);
            if (!loading && !noResults && !noSpacecrafts) {
                Cesium3dView.createCesiumViewer(nextProps);
            }
            // re-render
            this.forceUpdate();
        }
    }

    render() {
        const {simulation, t} = this.props;
        const {noResults, noSpacecrafts, loading} = Cesium3dView.analyzeData(this.props);

        const infoAlertData = getInfoAlert(simulation, noResults, noSpacecrafts, loading, t);
        const infoAlert = infoAlertData &&
            <Alert className="mb-0" color={infoAlertData.type}>{infoAlertData.info}</Alert>;

        return (
            <div id="content">
                {infoAlert}
                {/*<!--<div id="header"><h1>Start New Simulation</h1></div>-->*/}
                {/*<!--<div><a href="#viewer" onClick="onOrbitaStartClick();">Start Simulation</a></div>-->*/}
                <div id="app">
                    <div id="cesiumContainer">

                    </div>
                    <div id="orbitaLeftWidgetToolbar"></div>
                    <div id="orbitaRightWidgetToolbar"></div>
                    <div id="timeline"></div>
                    <div>
                        <form name="simulationParams">
                        </form>
                    </div>
                    <div id="toolbars">
                        <div className="toolbar" id="start-toolbar"></div>
                        <div className="toolbar" id="orbita-toolbar"></div>
                    </div>
                </div>
                {/*<!--<div id="footer"></div>-->*/}
            </div>);
    }
}

export default withTranslation()(Cesium3dView);
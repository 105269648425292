import React from 'react';
import {Collapse,Card,CardHeader,CardBody} from 'reactstrap'

function CollectionItemBlock(props) {
    const {index, expand, onToggle, name, noBorder, className, containerClassName, children, disabled, data, fieldsInfo,
        onChange, onValidate, onDelete, collectionsDict, editLanguage, removable} = props;

    const components = expand && React.Children.map(
            children,
            child => React.cloneElement(child, {
                disabled: child.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage }));

    if (noBorder) {
        return (
            // <React.Fragment>
            <div>
                <Card>
                    <CardHeader onClick={() => onToggle(index)}>
                        <div className={className}>
                            {name}
                            {removable &&
                            <i className="fa fa-remove float-end" onClick={(e) => {
                                e.stopPropagation();
                                onDelete();
                            }}/>}
                        </div>
                    </CardHeader>
                </Card>
                <Collapse isOpen={expand}>
                    {components && components}
                </Collapse>
            </div>
            // </React.Fragment>
        );
    } else {
        return (
            <Card>
                <CardHeader style={{cursor:"default"}} onClick={() => onToggle(index)}>
                    <div className={className} style={{cursor:"default"}}>
                        {name}
                        {removable && !disabled &&
                        <i className="fa fa-remove float-end" onClick={(e) => {
                            e.stopPropagation();
                            props.onDelete();
                        }}/>}
                    </div>
                </CardHeader>
                <Collapse isOpen={expand}>
                    {<CardBody className={containerClassName}>
                       {components && components}
                    </CardBody>}
                </Collapse>
            </Card>);
    }
}

export default CollectionItemBlock;
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Col} from 'reactstrap'
import {ComposableMap, Geographies, Geography, Line, Graticule} from 'react-simple-maps'

import LabelWithInfo from './elements/LabelWithInfo'
import TopoJSON from 'maps/world-110m.json'
import {calculateParamsFromLiveData, replaceVariables} from 'utils/parametersUtils'

const projection = "geoEquirectangular";

function SimpleMapVal(props) {

    // console.log('Rendering SimpleMapVal...');

    const {noLabel, wide, name, unit, info, infoImage, data, field, width, height, projectionConfig,
        timeField, border, fieldsInfo, collectionsDict} = props;
    let {latFieldsArr, lonFieldsArr} = props;
    const {t} = useTranslation();

    const resultsArr = (data && field && data[field]) || [];
    const fieldLoading = field + "_loading";
    const resultsArr_loading = data && data.hasOwnProperty(fieldLoading) && data[fieldLoading];

    const parameters = calculateParamsFromLiveData(props.parameters, field, data, fieldsInfo, collectionsDict);
    latFieldsArr = latFieldsArr.map(s => replaceVariables(s, parameters));
    lonFieldsArr = lonFieldsArr.map(s => replaceVariables(s, parameters));

    let filterStep = 1;
    if (resultsArr && resultsArr.length > 1 && timeField) {
        const totalMsec = Date.parse(resultsArr[resultsArr.length - 1][timeField]) - Date.parse(resultsArr[0][timeField]);
        filterStep = totalMsec / (resultsArr.length - 1);    // msec per 1 point
        filterStep = 1*60*1000 / filterStep;    // 1 minute step
        filterStep = Math.ceil(filterStep);
    }

    let filteredFields =  (latFieldsArr && lonFieldsArr &&
        [...latFieldsArr, ...lonFieldsArr]) || [];

    const resultsArrWithoutNulls = resultsArr && resultsArr.filter(x => filteredFields.every(f => [null, undefined].indexOf(x[f]) === -1));
    const filteredResultsArr = (resultsArrWithoutNulls && resultsArrWithoutNulls.filter((_,i) => i%filterStep === 0)) || [];

    const latSeriesArr = (resultsArr && latFieldsArr && latFieldsArr.map(f => filteredResultsArr.map(x => x[f]))) || [[]];
    const lonSeriesArr = (resultsArr && lonFieldsArr && lonFieldsArr.map(f => filteredResultsArr.map(x => x[f]))) || [[]];

    // here we take only first elements of lat-long arrays
    const points = [];
    for (let i = 0; i < latSeriesArr[0].length; i++) {
        points.push([lonSeriesArr[0][i], latSeriesArr[0][i]]);
    }
    const paths = mapUtils.createPaths(points);
    const lines = paths.map((path, i) => {
        return (
            <Line key={i}
                  coordinates={path}
                  stroke="#f00"
                  strokeWidth={2} />);
    });


    const map =
        <ComposableMap className="flex-grow" projection={projection} width={width} height={height} projectionConfig={{...projectionConfig, scale: 130}}>
            <Geographies geography={TopoJSON}>
                {({geographies}) => geographies.map((geography, i) => (
                    <Geography key={i}
                               geography={geography}
                               style={{
                                   default: {fill: "#888"},
                                   hover: {fill: "#888"},
                                   pressed: {fill: "#888"},
                               }}/>))}
            </Geographies>
            {lines}
            {border &&
            <Graticule step={[]} stroke={"#000"}/>}
        </ComposableMap>;

    return (
        <div className="form-group row g-0 d-flex semitransparent-overlay-container">
            {noLabel && map}
            {!noLabel &&
            <div className="flex-grow">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                <Col sm={wide ? 10 : 9} className="d-flex">
                    {map}
                </Col>
            </div>}
            {resultsArr_loading &&
            <div className="semitransparent-overlay">
                <p style={{textAlign: "center", lineHeight: "200px"}}>{t('components.fields.SimpleMapVal.wait')}</p>
            </div>}
        </div>);
}

const mapUtils = {

    //makes paths from points array
    createPaths: (points) => {
        if (!points) {
            return [];
        }
        const paths = [[points[0]]];
        const len = points.length;

        for(let i = 1; i < len; i++) {
            const lastPath = paths[paths.length - 1];
            const lastPoint = lastPath[lastPath.length - 1];

            if (Math.abs(points[i][0] - lastPoint[0]) > 180) {//path will end in longtitude
                paths.push([points[i]]);
            } else { //path will continue
                lastPath.push(points[i]);
            }
        }
        return paths.filter(p => p.length > 1);
    },

    //[[x0, y0], [x1, y1], [x2, y2]] —> [ [[x0, y0],[x1, y1]], [[x1, y1],[x2, y2]] ]
    transformPointsToLineSegments: (coords) => {
        const len = coords.length;
        const lineSegmentsArray = [];

        for(let i = 0; i < len - 1; i++) {
            lineSegmentsArray.push([coords[i], coords[i + 1]]);
        }
        return lineSegmentsArray;
    }
};

export default SimpleMapVal;
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {useLocation, useMatch} from '@reach/router'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'

import {Badge, Button} from 'reactstrap'
import withUriSearchParam from 'hocs/withUriSearchParam'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withUriParams from 'hocs/withUriParams'
import Tree from 'components/blocks/Tree'
import DialogModal from 'components/modals/DialogModal'
import ScenarioCard from './components/ScenarioCard'
import ScenarioView from './ScenarioView'
import EventWelcomeModal from '../modals/EventWelcomeModal'
import RegistrationModal from '../modals/RegistrationModal'
import TeamModal from '../modals/TeamModal'
import TournamentTableModal from '../modals/TournamentTableModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import UTCTimerSpan from 'components/elements/UTCTimerSpan'

import {queryEvent, queryEventUserInfo, queryUserTeam} from '../data/eventQueries'
import {updateEventUserInfo} from '../data/eventMutations'

import {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import {removeSearchParam, setSearchParam} from 'utils/urlUtils'
import {faIcon} from 'utils/visualHelpers'
import {EVENT, EVENT_WELCOME_VIEW, TOURNAMENT_TABLE_VIEW, TEAM_VIEW} from 'constants/constants'
import entityUtils from 'utils/entityUtils'
import {calculateEventCompetitionModeDates} from './components/EventCard'
import dateUtils from '../../utils/dateUtils'
import {replaceWithComponents} from '../../utils/stringUtils'
import useUTCTimeout from "../../hooks/useUTCTimeout"
import useForceRender from "../../hooks/useForceRender"
import MarkdownOutput from "../../components/elements/MarkdownOutput";

const AUTHORIZATION_VIEW = 'authorization';
const REGISTRATION_VIEW = 'registration';


const EventView = compose(
    withUriParams([
        ['scenarioId', Number]]),
    withUriSearchParam(EVENT_WELCOME_VIEW, 'showEventWelcome'),
    withUriSearchParam(TOURNAMENT_TABLE_VIEW, 'showTournamentTable'),
    withUriSearchParam(TEAM_VIEW, 'showTeam'),
    withUriSearchParam(AUTHORIZATION_VIEW, 'showAuthorization'),
    withUriSearchParam(REGISTRATION_VIEW, 'showRegistration'),
    queryEvent,
    queryEventUserInfo,
    queryUserTeam,
    updateEventUserInfo,
    withInitialDataLoadWaiting(['event', 'eventUserInfo'])
)(props => {
    useDebug("EventView", props);

    const {
        scenarioId, user, eventUserInfo, userTeam, queryAsRole, updateEventUserInfo, executeWithConfirmation,
        // features
        showEventWelcome, showTournamentTable, showTeam, showAuthorization, showRegistration,
        disabled, showBackLink
    } = props;

    const uriMatch = useMatch('/:lang/*');
    const location = useLocation();
    const navigate = useSmartNavigate();
    const forceRender = useForceRender();
    const {t} = useTranslation();

    // parsing JSON
    const event = useMemo(() =>
        props.event && {
            ...props.event,
            competitionRules: entityUtils.safeJSONParse(props.event.competitionRules)
        }, [props.event]);

    const {isCompetitionSessionMode,
        competitionRegistrationEndsAtUTCTimestamp,
        competitionSessionEndsAtUTCTimestamp,
        isCompetitionRegistrationEnded,
        isCompetitionSessionStarted,
        isCompetitionSessionEnded} = calculateEventCompetitionModeDates(event, eventUserInfo);

    // competition session timeout effect
    useUTCTimeout(
        forceRender,
        isCompetitionSessionStarted && !isCompetitionSessionEnded
            ? competitionSessionEndsAtUTCTimestamp : null
    );

    // preparing links
    const containerId = EVENT;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    const links = [];

    if (event) {
        breadcrumbs.push({
            name: event.name,
            to: props.uri,
            // event always is fullscreen, so we need back link
            back: 1,
            nonBlocking: true
        });
        if (event.welcomeTitle || event.welcomeMessage) {
            links.push({
                name: event.welcomeTitle || t('appEvent.views.EventView.welcomeTitle'),
                to: `${props.location.pathname}${setSearchParam(props.location.search, EVENT_WELCOME_VIEW)}`,
                state: props.location.state,
                nonBlocking: true});
        }

        if (!event.isTester &&
                ((event.type === 'competition' && event.isUserTeams === true) || userTeam)) {
            links.push({
                name: userTeam ? (userTeam.name ? userTeam.name: t('appEvent.views.EventView.teamWithoutName')): ('appEvent.views.EventView.joinToTeam'),
                to: `${props.location.pathname}${setSearchParam(props.location.search, TEAM_VIEW)}`,
                state: props.location.state,
                nonBlocking: true});
        }

        if ((event.isTournamentTableVisible || event.isAdmin) &&
            (event.type === 'competition' || event.scenarios.some(s => s.type === 'test'))) {
            links.push({
                name: t('appEvent.views.EventView.tournamentTable'),
                to: `${props.location.pathname}${setSearchParam(props.location.search, TOURNAMENT_TABLE_VIEW)}`,
                state: props.location.state,
                nonBlocking: true});
        }
    }

    useEffect(() => {
        const isTerminal = !scenarioId;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
        linksStore.set(containerId, links, isTerminal);
    });

    // subnavigation
    if (!event) {
        navigate(`${props.uri}/..`);
        return null;
    }

    if (event.isInfo && !event.isVisibleWithoutRegistration) {
        if ((!user || !user.id) && !showAuthorization) {
            navigate(
                `${props.location.pathname}${setSearchParam(props.location.search, AUTHORIZATION_VIEW)}`,
                {state: props.location.state});
            return null;
        }
        if (user && user.id && !showRegistration) {
            navigate(
                `${props.location.pathname}${setSearchParam(props.location.search, REGISTRATION_VIEW)}`,
                {state: props.location.state});
            return null;
        }
    }

    // access granted, but by a obfuscated link
    if (!event.isInfo && String(event.id) !== String(props.eventId)) {
        navigate(`${props.uri}/../${event.id}${props.location.search}`);
        return null;
    }

    // redirect to the first scenario of course (if access not info)
    if (!event.isInfo && event.type === "course" && event.scenarios && event.scenarios.length && !scenarioId) {
        navigate(`${props.uri}/${event.scenarios[0].id}`);
        return null;
    }

    // check if the message was read before
    //  users: have entry in event_user_info
    //  anonymous users: store in local storage
    const eventAnonymousInfo = entityUtils.safeJSONParse(localStorage.eventsInfo, {})[event.id] || {};
    let isWelcomeSeen = (eventUserInfo || eventAnonymousInfo).isWelcomeSeen;

    // should redirect to event welcome?
    if ((event.welcomeTitle || event.welcomeMessage) &&
        !showEventWelcome && !isWelcomeSeen &&
        !(props.location.state && props.location.state.eventWelcomeModalWasClosed)) {
        navigate(
            `${props.location.pathname}${setSearchParam(props.location.search, EVENT_WELCOME_VIEW)}`,
            {state: props.location.state});
        return null;
    }

    const isDisableAnyAccessToScenarioInCompetitionMode = isCompetitionSessionMode && (!isCompetitionSessionStarted || isCompetitionSessionEnded);
    if (scenarioId && isDisableAnyAccessToScenarioInCompetitionMode) {
        navigate(`${props.uri}`);
    }

    // rendering

    const additionalEventStatus =
        (event.isAdmin || event.isTester) &&
            <><br/>
        {event.isAdmin &&
            <Badge color="warning">{t('appEvent.views.EventView.admin')}</Badge>}
        {event.isAdmin && event.isStudent && event.type !== 'course' &&
            <Badge color="danger">{t('appEvent.views.EventView.student')}</Badge>}
        {event.isTester &&
            <Badge color="secondary">{t('appEvent.views.EventView.testMode')}</Badge>}
        </>;

    // competition:

    const competitionSessionTimeComponent =
        isCompetitionSessionMode &&
        <Badge color="warning">{dateUtils.getLocaleStringTimeSpanBetween(
            0, event.competitionRules.allowedSolutionTimeMinutes * 60 * 1000, t, false)}</Badge>;

    const competitionSessionTimeLeftComponent =
        isCompetitionSessionMode && isCompetitionSessionStarted && !isCompetitionSessionEnded &&
        replaceWithComponents(
            t('appEvent.views.EventView.competitionSessionTimeLeft'),
            {'{{competitionSessionTime}}': <UTCTimerSpan endsAtUTC={competitionSessionEndsAtUTCTimestamp} />});

    const cards = [...event.scenarios.map(s =>
        <ScenarioCard user={user} event={event} eventUserInfo={eventUserInfo} scenario={s} uri={props.uri} />)];
    const rows = [];

    for (let i = 0; i < Math.ceil(cards.length / 2); i++) {
        rows[i] = <div className="row mb-4" key={i}>
            {cards[2 * i]}
            {cards[2 * i + 1]}
        </div>;
    }

    // course:

    const nodes = [];

    event.scenarios.forEach(s => {
        const nameWithSolutionIndicator =
            <span>
                {s.name}
                {s.type === 'test' && (s.hasUserSolution || s.hasTeamSolution) &&
                <span className="text-success"> {faIcon('fa-check')}</span>}
            </span>;

        const node = {
            id: s.id,
            parentId: s.parentId ? s.parentId : null,
            name: nameWithSolutionIndicator,
            scenario: s,
            items: [],
            actions: {
                "select": () => navigate(`${props.uri}/${s.id}`),
            }
        };
        nodes.push(node);
    });

    // scenario:

    const scenarioComponent = scenarioId && !isDisableAnyAccessToScenarioInCompetitionMode &&
        <ScenarioView scenarioId={scenarioId} queryAsRole={queryAsRole}
                      disabled={disabled}
                      enableBackLink={event.type !== 'course'}
                      showBackLink={showBackLink}
                      event={event} additionalEventStatus={additionalEventStatus}
                      additionalButtonsBlockInfo={competitionSessionTimeLeftComponent}
                      user={props.user} errors={props.errors} loading={props.loading} refetch={props.refetch}
                      setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                      resetActionLock={props.resetActionLock}
                      executeWithLock={props.executeWithLock}
                      executeWithConfirmation={props.executeWithConfirmation}
                      uri={`${props.uri}/${scenarioId}`} uriParams={props.uriParams} location={props.location} />;

    // render:

    return (
        <>
        {event.type === 'competition' &&
        <>
            {(!scenarioId || isCompetitionSessionEnded) &&
            <div className="flex-grow d-flex flex-column scroll-parent">
                {showBackLink &&
                <SaveButtonsBlock withBack
                                  additionalInfo={competitionSessionTimeLeftComponent}
                                  executeWithLock={props.executeWithLock} />}

                <div className="flex-grow scroll">
                    <h5 className="text-center mt-3">
                        {event.name}{additionalEventStatus}
                    </h5>
                    {isCompetitionSessionMode && !isCompetitionSessionStarted && !isCompetitionRegistrationEnded &&
                    <>
                        <div className="mt-3 ms-5 me-5">
                            {event.competitionModeInfoMessage &&
                                <MarkdownOutput value={event.competitionModeInfoMessage} />}
                            <h5 className="text-center mt-1">
                                {replaceWithComponents(
                                    t('appEvent.views.EventView.competitionSessionInfo'),
                                    {
                                        '{{registrationTime}}': <UTCTimerSpan endsAtUTC={competitionRegistrationEndsAtUTCTimestamp} />,
                                        '{{time}}': competitionSessionTimeComponent})}
                            </h5>
                        </div>
                        <div className="text-center mt-3">
                            <Button color="primary" onClick={
                                () => executeWithConfirmation(
                                    () => updateEventUserInfo({eventId: event.id, isStartCompetitionSession: true}),
                                    replaceWithComponents(
                                        t('appEvent.views.EventView.startCompetitionSessionConfirmation'),
                                        {'{{time}}': competitionSessionTimeComponent})
                                )}>
                                    {t('appEvent.views.EventView.startCompetitionSession')}
                            </Button>
                        </div>
                    </>}
                    {isCompetitionSessionEnded &&
                    <>
                        <h5 className="text-center mt-3">
                            {t('appEvent.views.EventView.endedCompetitionSession')}
                        </h5>
                        {event.isTester &&
                        <div className="text-center mt-3">
                            <Button color="primary" onClick={
                                    () => updateEventUserInfo({eventId: event.id, isRemoveCompetitionSession: true})}>
                                {t('appEvent.views.EventView.removeCompetitionSession')}
                            </Button>
                        </div>}
                    </>}
                    {(!isCompetitionSessionMode || isCompetitionSessionStarted) &&
                    <div className="container">{rows}</div>}
                </div>
            </div>}

            {scenarioComponent}
        </>}

        {event.type === 'course' &&
        <div className="flex-grow d-flex flex-column scroll-parent">
            {!scenarioId && showBackLink &&
            <SaveButtonsBlock withBack
                              executeWithLock={props.executeWithLock} />}

            <div className="flex-grow d-flex flex-row scroll-parent">
                <div className="w-25 d-flex flex-column">
                    <div className="scroll">
                        <Tree nodes={nodes} selectedId={scenarioId} />
                    </div>
                </div>
                <div className="w-75 d-flex flex-column border border-dark border-top-0 border-end-0 border-bottom-0">
                    {scenarioComponent}
                </div>
            </div>
        </div>}

        {/* they stack on top of each other */}

        {showRegistration &&
        <RegistrationModal eventId={props.eventId} event={event}
                           user={user}
                           uri={props.uri} />}

        {showAuthorization &&
        <DialogModal show notCancellable
                     title={t('appEvent.views.EventView.continue')}
                     actions={[
                         {
                             key: "yes", name: t('appEvent.views.EventView.login'),
                             action: () =>
                                 navigate(`/${uriMatch.lang}/auth/login`, {
                                     state: {
                                         goBackLinkData: {
                                             to: `${location.pathname}${removeSearchParam(location.search, AUTHORIZATION_VIEW)}`,
                                             state: location.state,
                                             isAuthorized: false}}})
                         }]}>
            {t('appEvent.views.EventView.loginAndContinue')}
        </DialogModal>}

        {showEventWelcome && (event.welcomeTitle || event.welcomeMessage) &&
        <EventWelcomeModal event={event} user={user} eventUserInfo={eventUserInfo} />}

        {showTeam &&
        <TeamModal user={user} event={event} eventId={event.id} errors={props.errors} loading={props.loading} />}

        {showTournamentTable &&
        <TournamentTableModal eventId={event.id} user={user} errors={props.errors} loading={props.loading} />}

        {event.isOnti && user.requireTalentLogin &&
        <DialogModal show notCancellable
                     title={t('appEvent.views.EventView.continue')}
                     actions={[
                         {
                             key: "yes", name: t('appEvent.views.EventView.loginWithTalent'),
                             action: () =>
                                 navigate(`/${uriMatch.lang}/auth/initiateTalentOauth`, {
                                     state: {
                                         goBackLinkData: {
                                             to: `${location.pathname}${location.search}`,
                                             state: location.state,
                                             isAuthorized: false}}})
                         }]}>
          {t('appEvent.views.EventView.loginWithTalentAndContinue')}
        </DialogModal>}

        {event.accessError &&
        <DialogModal show notCancellable
                     title={t('appEvent.views.EventView.noAccess')}
                     actions={[
                         {
                             key: "yes", name: t('appEvent.views.EventView.returnToEventsList'),
                             action: () =>
                                 navigate(`${props.uri}/..`)
                         }]}>
            {event.accessError}
        </DialogModal>}

        </>);
});

export default EventView;
import React, {Component} from 'react';
import ResultsSeriesEditor from "components/editors/ResultsSeriesEditor";
import DialogModal from "components/modals/DialogModal";

export default class PresetsBlock extends Component {
    static ensurePresetValues(props) {
        const {stuff, field, presetField, data} = props;
        const presetValue = data && presetField && data[presetField];
        if (presetValue === '_user' || presetValue === null) {
            // user value or null without default:
            return;
        }
        const preset = stuff && stuff.find(x => x.value === presetValue);
        if (preset) {
            Object.keys(preset.values)
                .filter(k => data[field + (field && k ? "." : "") + k] !== preset.values[k])
                .forEach(k => {
                    console.log(`PresetBlock.ensurePresetValues: we have preset ${preset.value} with deviating values. Reverting values accordingly`);
                    props.onChange(data, field + (field && k ? "." : "") + k, preset.values[k], true);
                });
        } else {
            // throw new Error(`Unknown preset ${presetValue}`);
            console.warn(`Unknown preset ${presetValue}`);
            props.onChange(data, presetField, null, true);
        }
    }

    constructor(props) {
        super(props);
        const {presetField, data} = props;
        this.state = {
            value: data && presetField && data[presetField],
            showEditor: false
        };
        this.onChange = this.onChange.bind(this);
        this.onHideEditor = this.onHideEditor.bind(this);
    }

    componentWillMount() {
        PresetsBlock.ensurePresetValues(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const newPresetValue = nextProps.data && nextProps.presetField && nextProps.data[nextProps.presetField];
        if (nextProps.data !== this.props.data || nextProps.field !== this.props.field || this.state.value !== newPresetValue) {
            const {stuff} = nextProps;
            // new object or preset changed - recalculate all:
            PresetsBlock.ensurePresetValues(nextProps);
            // show editor?
            const preset = stuff && stuff.find(x => x.value === newPresetValue);
            const showEditor = preset && preset.editor ? true : false;
            // set state
            this.setState(prevState => ({
                value: newPresetValue,
                showEditor: showEditor
            }));
        } else if (nextProps.data === this.props.data) {
            // old object, something has changed - maybe one of preset fields? validate that preset is still correct:
            const {stuff, field, presetField, data} = nextProps;
            // check for preset deviation:
            const presetValue = data && presetField && data[presetField];
            const preset = stuff && stuff.find(x => x.value === presetValue);
            if (preset) {
                if (Object.keys(preset.values).some(k => data[field + (field && k ? "." : "") + k] !== preset.values[k])) {
                    console.log(`PresetBlock.componentWillReceiveProps: we have preset ${preset.value} with deviating values. Changing to '_user'`);
                    this.props.onChange(data, presetField, '_user');
                    // re-copy preset values as real changes. Because some values could be set with 'noChanges=true'
                    Object.keys(preset.values)
                        .forEach(k => {
                            const kField = field + (field && k ? "." : "") + k;
                            this.props.onChange(data, kField, data[kField]);
                        });
                }
            }
        }
    }

    onChange(data, field, value, noChanges) {
        const {stuff, presetField} = this.props;
        // catch subsequent clicks to chosen presets, maybe we need to show popup?
        if (field === presetField && value === this.state.value) {
            // subsequent click
            const preset = stuff && stuff.find(x => x.value === value);
            if (preset && preset.editor) {
                this.setState(prevState => ({
                    showEditor: true
                }));
            }
        }
        this.props.onChange(data, field, value, noChanges);
    }

    onHideEditor() {
        this.setState(prevState => ({
            showEditor: false
        }));
    }

    render() {
        const {stuff, presetField, children, dynamicSectionName, disabled, field, data, fieldsInfo, collectionsDict,
               editLanguage, onValidate, wide} = this.props;
        const {showEditor} = this.state;

        const components = React.Children.map(
            children,
            child => React.cloneElement(child, {
                dynamicSectionName: dynamicSectionName,
                disabled: child.props.disabled || disabled,
                data: data,
                fieldsInfo: fieldsInfo,
                onChange: this.onChange,
                onValidate: onValidate,
                collectionsDict: collectionsDict,
                editLanguage: editLanguage,
                wide: (child.props && child.props.wide) || wide
            }));

        let preset;
        let editorComponent;
        let editorModalTitle;
        let updatedValues;
        if (showEditor) {
            const presetValue = data && presetField && data[presetField];
            preset = stuff && stuff.find(x => x.value === presetValue);
            if (preset && preset.editor) {
                updatedValues = JSON.parse(JSON.stringify(preset.values));
                const editorComponentType = {
                    resultsSeries: ResultsSeriesEditor
                }[preset.editor];
                editorComponent = preset && preset.editor &&
                    React.createElement(
                        editorComponentType,
                        {values: updatedValues, parameters: preset.parameters, field: field, data: data, fieldsInfo: fieldsInfo,
                               collectionsDict: collectionsDict, editLanguage: editLanguage});
                editorModalTitle = {
                    resultsSeries: "2D Results Setup - User-defined"
                }[preset.editor];
            }
        }

        return (
            <div className="form-group">
                {/*{preset && (preset.infoImage || preset.info) &&*/}
                {/*<StaticInfo info={preset.info} infoImage={preset.infoImage}/>}*/}
                {components && components}
                {editorComponent && !disabled &&
                <DialogModal key="M" show={showEditor}
                              title={editorModalTitle}
                              actions={[
                                  {
                                      key: "apply", name: "Apply",
                                      color: "primary",
                                      action: () => {
                                          this.onHideEditor();
                                          preset.values = updatedValues;
                                          PresetsBlock.ensurePresetValues(this.props)
                                      }
                                  },
                                  {
                                      key: "close",
                                      name: "Cancel",
                                      color: "secondary",
                                      action: this.onHideEditor
                                  }
                              ]}>
                    {editorComponent}
                </DialogModal>}
            </div>);
    }
};
import {useMemo, useCallback} from 'react';
import useForceRender from 'hooks/useForceRender'
import entityUtils from "utils/entityUtils";
import {UPDATE_ITERATION_FIELD} from 'hocs/withGeneratorDataSource'


function useSimpleGeneratorDataSource(data) {

    // calculate proxy when data changes
    const dataSource = useMemo(() => {
        // console.log('useSimpleGeneratorDataSource: useMemo->proxificate for ' + JSON.stringify(data).substring(0, 100));
        return data
            ? entityUtils.getExistigProxyOrProxificateObj({...data, [UPDATE_ITERATION_FIELD]: 1})
            : null;
    }, [data]);

    const forceRender = useForceRender();

    const onChange = useCallback(
        (data, field, value, noChanges) => {
            if (dataSource) {
                dataSource[field] = value;
                forceRender();
            }
        },
        [dataSource, forceRender]);

    return [dataSource, onChange];
}

export default useSimpleGeneratorDataSource;
import React from 'react'
import {Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import ChameleonBlock from './elements/ChameleonBlock'
import Error from './elements/Error'

function StringVal(props) {

    const {clickToEdit, noLabel, wide, name, unit, info, infoImage, disabled, className, multiLanguageProps, data, field, error} = props;

    const onChange = (e) => {
        props.onChange(data, field, e.nativeEvent.target.value);
    };

    let value = (data && (data[field] || data[field] === 0)) ? String(data[field]) : "";

    let contents =
        <div className={classnames(className, clickToEdit && "w-100")}>
            <input className={classnames("form-control", clickToEdit && "w-100", error && "is-invalid")} type="text"
                   value={value} disabled={disabled} onChange={onChange}
                   ref={clickToEdit && (input => {
                       if (input && !input['data-wasFocusedOnce']) {
                           input.focus();
                           input['data-wasFocusedOnce'] = true;
                       }
                   })}/>
            {error &&  <Error id={field} error={error}/>}
        </div>;

    if (clickToEdit) {
        const viewContents =
            <div className={(className ? className : "") + " w-100"}>
                <h5 className={classnames("field-on-hover", error && "border border-danger")}>{value}</h5>
                {error &&  <Error id={field} error={error}/>}
            </div>;
        contents =
            <ChameleonBlock className="w-100" disabled={disabled} data={data} onChange={onChange}
                            viewChildren={viewContents}
                            editChildren={contents}/>;
    }

    if (noLabel) {
        return (
            <div className={"form-group row g-0"}>
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info}
                                   infoImage={infoImage} multiLanguageProps={multiLanguageProps} />
                </Col>
                <Col sm={wide ? 10 : 9}>
                    {contents}
                </Col>
            </div>);
    }
}

export default StringVal;

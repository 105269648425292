import React, {Children, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TabBlock} from '../blocks/TabBlock'

function MultiLanguageTabBlock(props) {
    const {children, ...restProps} = props;
    const {i18n} = useTranslation();
    const [editLanguage, setEditLanguage] = useState(i18n.resolvedLanguage);
    const languageTabs = [
        {
            name: "Русский",
            id: "ru",
            onSelect: () => setEditLanguage("ru")
        },
        {
            name: "English",
            id: "en",
            onSelect: () => setEditLanguage("en")
        },
    ];

    //throw down all the props received from GeneratorBlock (data, disabled, etc)
    const components = Children.map(
        children,
        (child, i) => React.cloneElement(child, {
            ...restProps,
            key: i,
            editLanguage: editLanguage
    }));

    return (
        <>
            <TabBlock className="mb-3 d-print-none" selectedTabId={editLanguage} tabs={languageTabs} />

            {components}

        </>);
}

export default MultiLanguageTabBlock;
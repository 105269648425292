import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import TopNav, {breadcrumbsStore, linksStore} from 'appBase/TopNav'
import withConfirmation from 'hocs/withConfirmation'
import IndexCatalogView from './views/IndexCatalogView'
import IndexMaterialsView from "./views/IndexMaterialsView";


const CatalogApp = compose(
    withConfirmation,
)((props) => {

    const {user, asEditableCatalog, asReadonlyMaterials} = props;
    const {t} = useTranslation();
    const navigate = useSmartNavigate();

    // initial breadcrumbs
    const containerId = null;
    breadcrumbsStore.register(containerId);
    linksStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({
        name: t(asEditableCatalog ? 'appCatalog.views.IndexCatalogView.catalog' : 'appCatalog.views.IndexMaterialsView.materials'),
        to: `${props.uri}`});

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
        linksStore.set(containerId, [], isTerminalBreadcrumbs);
    });

    // subnavigation:
    // if wrong type of view requested
    if ((!asEditableCatalog && !asReadonlyMaterials) || (asEditableCatalog && asReadonlyMaterials)) {
        navigate(`${props.uri}/..`);
        return null;
    }

    // rendering
    return (
        <div className="d-flex flex-column h-100 w-100">
            <TopNav user={user} skipRootPath={props.uri} executeWithLock={props.executeWithLock} />

            <div className="flex-grow d-flex flex-column scroll-parent">
                {asEditableCatalog &&
                <IndexCatalogView user={user} errors={props.errors} loading={props.loading}
                                  setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                  resetActionLock={props.resetActionLock}
                                  executeWithLock={props.executeWithLock}
                                  executeWithConfirmation={props.executeWithConfirmation}
                                  uri={props.uri} uriParams={props.uriParams} location={props.location} />}

                {asReadonlyMaterials &&
                <IndexMaterialsView user={user} errors={props.errors} loading={props.loading}
                                    setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                    resetActionLock={props.resetActionLock}
                                    executeWithLock={props.executeWithLock}
                                    executeWithConfirmation={props.executeWithConfirmation}
                                    uri={props.uri} uriParams={props.uriParams} location={props.location} />}
            </div>
        </div>
    );
});

export default CatalogApp;


export const prepareLicenseObjectForEdit = (license) => {
    if (!license) {
        return null;
    }

    const isSuperLicenceChoiceValue = String(license.isSuperlicense || false);
    return {
        // rest of the fields that are edited as-is
        ...license,
        // type-dependent fields
        isSuperlicense: {
            _type: isSuperLicenceChoiceValue,
            [isSuperLicenceChoiceValue]: {
                // for ordinary license:
                studentsLimit: license.studentsLimit,
                eventsLimit: license.eventsLimit,
                number: license.number,
                priority: license.priority,
                key: license.key,
                address: license.address,
                contacts: license.contacts,
                comments: license.comments,
                isIssued: license.isIssued,
                isActivated: license.isActivated,
                startsAt: license.startsAt,
                endsAt: license.endsAt,
                dataLimitMb: (license.dataLimit / (1024 * 1024)).toFixed(1),
                prefix: license.prefix,
                templateDurationMonths: license.templateDurationMonths
            }
        }
    };
};


export const prepareLicenseEditObjectForSave = (licenseEditObj) => {
    if (!licenseEditObj) {
        return null;
    }

    // All first-level db-object fields that are inside conditional blocks (like Choice) should be explicitly set to null!
    // because undefined is treated as not supplied data, and conditionals can become undefined.
    // and all unconditionals cannot be set to undefined again.

    const isSuperlicense = licenseEditObj.isSuperlicense && licenseEditObj.isSuperlicense._type === "true";
    const licenseData = (
        licenseEditObj.isSuperlicense && licenseEditObj.isSuperlicense._type &&
        licenseEditObj.isSuperlicense[licenseEditObj.isSuperlicense._type]) || {};
    // result:
    return {
        // rest of the fields that were edited as-is
        ...licenseEditObj,
        isSuperlicense: isSuperlicense,
        // !it is conditional, so should be set explicitly if undefined!
        studentsLimit: isSuperlicense ? 0 : ![null, undefined].includes(licenseData.studentsLimit) ? licenseData.studentsLimit : 100,
        eventsLimit: isSuperlicense ? 0 : ![null, undefined].includes(licenseData.eventsLimit) ? licenseData.eventsLimit : 1,
        number: (!isSuperlicense && licenseData.number) || null,
        priority: (!isSuperlicense && licenseData.priority) || 1,
        key: (!isSuperlicense && licenseData.key) || null,
        address: (!isSuperlicense && licenseData.address) || null,
        contacts: (!isSuperlicense && licenseData.contacts) || null,
        comments: (!isSuperlicense && licenseData.comments) || null,
        isIssued: (!isSuperlicense && licenseData.isIssued) || false,
        isActivated: isSuperlicense ? true : licenseData.isActivated || false,
        startsAt: (!isSuperlicense && licenseData.startsAt) || null,
        endsAt: (!isSuperlicense && licenseData.endsAt) || null,
        dataLimit: isSuperlicense ? 0 : ![null, undefined].includes(licenseData.dataLimitMb) ? licenseData.dataLimitMb * 1024 * 1024 : 10485760,
    };
};

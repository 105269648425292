import React from 'react'
import classnames from 'classnames'

import List from 'components/blocks/List'
import LabelWithInfo from 'components/fields/elements/LabelWithInfo'

function DataBlock(props) {
    const {className, style, name, parameters, rows} = props;

    return (
        <div className={classnames("datablock", className)} style={style}>
            {name &&
            <h6>{name}</h6>}
            {parameters &&
                <div className="data ">
                    {parameters.map((p,i) => (
                        <div key={i} className="d-flex flex-row">
                            <LabelWithInfo className="w-50 text-truncate" name={p.name} unit={p.unit} small />
                            <div className="text-truncate">{p.value}</div>
                        </div>))}
                </div>}
            {rows &&
            <List className="list" rows={rows} />}
        </div>);
}

export default DataBlock;
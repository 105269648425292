import React from 'react'
import {Col} from 'reactstrap'
import classnames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

import LabelWithInfo from './elements/LabelWithInfo'
import ChameleonBlock from './elements/ChameleonBlock'
import Error from './elements/Error'

function TextVal(props) {

    const maxLength = undefined; // 600;
    const maxRows = 10;

    const {clickToEdit, className, noLabel, wide, name, unit, info, infoImage, disabled, multiLanguageProps, data, field, error} = props;
    const value = data && field && data[field] ? String(data[field]) : "";

    const onChange = (e) => {
        props.onChange(data, field, e.nativeEvent.target.value);
    };

    let contents =
        <div className={classnames(className, clickToEdit && "w-100")}>
            <TextareaAutosize className={classnames("form-control", error && "is-invalid")} style={{resize: "none"}}
                              value={value} disabled={disabled} onChange={onChange}
                              ref={clickToEdit && (elem => {
                                  if (elem && !elem['data-wasFocusedOnce']) {
                                      elem.focus();
                                      elem['data-wasFocusedOnce'] = true;
                                  }
                              })}
                              maxLength={maxLength} maxRows={maxRows}/>
            {error &&
            <Error id={field} error={error}/>}
        </div>;

    if (clickToEdit) {
        const viewContents =
            <div className={(className ? className : "") + " w-100"}>
                <TextareaAutosize className={classnames("form-control field-on-hover", error && "border border-danger")}
                                  value={value} disabled
                                  maxRows={maxRows}/>
                {error &&
                <Error id={field} error={error}/>}
            </div>;
        contents =
            <ChameleonBlock className="w-100" disabled={disabled} data={data} onChange={onChange}
                            viewChildren={viewContents}
                            editChildren={contents}/>;
    }
    if (noLabel) {
        return (
            <div className={"form-group row g-0"}>
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} multiLanguageProps={multiLanguageProps} />
                </Col>
                <Col sm={wide ? 10 : 9}>
                    {contents}
                </Col>
            </div>);
    }

}

export default TextVal;
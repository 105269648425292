import mathUtils from 'utils/mathUtils'


export default function convert(conversion, rootField, data, changedField, changedValue, t) {
    function getRelativeField(expression) {
        let currentField = rootField;
        if (expression.startsWith('../')) {
            while (expression.startsWith('../')) {
                currentField = currentField.substring(0, currentField.lastIndexOf('.'));
                expression = expression.substring(3);
            }
        } else if (expression.startsWith('./')) {
            expression = expression.substring(2);
        }
        return currentField + (currentField && expression ? "." : "") + expression;
    }

    function getRelativeValue(expression) {
        const currentField = getRelativeField(expression);
        return currentField === changedField ? changedValue : data[currentField];
    }

    let relativeChangedField = null;
    if (changedField) {
        if (rootField) {
            if (!changedField.startsWith(rootField + ".")) {
                throw new Error(`Conversion: rootField ${rootField} is not start of changedField ${changedField}!`);
            }
            relativeChangedField = changedField.substring(rootField.length + 1);
        } else {
            relativeChangedField = changedField;
        }
    }

    const precisions = {
        "semimajor-axis": 3,
        "periapsis-height": 3,
        "apoapsis-height": 3,
        "eccentricity": 7,
        "gain": 3,
        "gain-db": 3,
        "power": 3,
        "power-dbm": 3,
        "sensitivity": 3,
        "sensitivity-dbm": 3
    };

    switch (conversion) {
        case "axis-eccentricity/periapsis-apoapsis":
            const R = 6371.0088;
            if (!relativeChangedField || ["semimajor-axis", "eccentricity"].includes(relativeChangedField)) {
                // axis-eccentricity group
                const semimajorAxis = getRelativeValue("semimajor-axis");
                const eccentricity = getRelativeValue("eccentricity");
                return {
                    [getRelativeField("periapsis-height")]: mathUtils.toPrecision((1 - eccentricity) * semimajorAxis - R, precisions["periapsis-height"], t),
                    [getRelativeField("apoapsis-height")]: mathUtils.toPrecision((1 + eccentricity) * semimajorAxis - R, precisions["apoapsis-height"], t)
                };
            } else if (["periapsis-height", "apoapsis-height"].includes(relativeChangedField)) {
                // periapsis-apoapsis group
                const periapsisHeight = getRelativeValue("periapsis-height");
                const apoapsisHeight = getRelativeValue("apoapsis-height");
                const semimajorAxis = (periapsisHeight + apoapsisHeight)/2 + R;
                return {
                    [getRelativeField("semimajor-axis")]: mathUtils.toPrecision(semimajorAxis, precisions["semimajor-axis"], t),
                    [getRelativeField("eccentricity")]: mathUtils.toPrecision((apoapsisHeight - periapsisHeight) / (2 * semimajorAxis), precisions["eccentricity"], t)
                };
            }
            break;
        case "gain/gain-db":
            if (!relativeChangedField || ["gain"].includes(relativeChangedField)) {
                const gain = getRelativeValue("gain");
                return {
                    [getRelativeField("gain-db")]: mathUtils.toPrecision(10 * Math.log10(gain), precisions["gain-db"], t)
                };
            } else if (["gain-db"].includes(relativeChangedField)) {
                const gainDb = getRelativeValue("gain-db");
                return {
                    [getRelativeField("gain")]: mathUtils.toPrecision(Math.pow(10, gainDb / 10), precisions["gain"], t)
                };
            }
            break;
        case "power/power-dbm":
            if (!relativeChangedField || ["power"].includes(relativeChangedField)) {
                const power = getRelativeValue("power");
                return {
                    [getRelativeField("power-dbm")]: mathUtils.toPrecision(10 * Math.log10(power) + 30, precisions["power-dbm"], t)
                };
            } else if (["power-dbm"].includes(relativeChangedField)) {
                const powerDbm = getRelativeValue("power-dbm");
                return {
                    [getRelativeField("power")]: mathUtils.toPrecision(Math.pow(10, (powerDbm - 30) / 10), precisions["power"], t)
                };
            }
            break;
        case "minimum-output-power/minimum-output-power-dbm":
            if (!relativeChangedField || ["minimum-output-power"].includes(relativeChangedField)) {
                const power = getRelativeValue("minimum-output-power");
                return {
                    [getRelativeField("minimum-output-power-dbm")]: mathUtils.toPrecision(10 * Math.log10(power) + 30, precisions["power-dbm"], t)
                };
            } else if (["minimum-output-power-dbm"].includes(relativeChangedField)) {
                const powerDbm = getRelativeValue("minimum-output-power-dbm");
                return {
                    [getRelativeField("minimum-output-power")]: mathUtils.toPrecision(Math.pow(10, (powerDbm - 30) / 10), precisions["power"], t)
                };
            }
            break;
        case "maximum-output-power/maximum-output-power-dbm":
            if (!relativeChangedField || ["maximum-output-power"].includes(relativeChangedField)) {
                const power = getRelativeValue("maximum-output-power");
                return {
                    [getRelativeField("maximum-output-power-dbm")]: mathUtils.toPrecision(10 * Math.log10(power) + 30, precisions["power-dbm"], t)
                };
            } else if (["maximum-output-power-dbm"].includes(relativeChangedField)) {
                const powerDbm = getRelativeValue("maximum-output-power-dbm");
                return {
                    [getRelativeField("maximum-output-power")]: mathUtils.toPrecision(Math.pow(10, (powerDbm - 30) / 10), precisions["power"], t)
                };
            }
            break;
        case "sensitivity/sensitivity-dbm":
            if (!relativeChangedField || ["sensitivity"].includes(relativeChangedField)) {
                const sensitivity = getRelativeValue("sensitivity");
                return {
                    [getRelativeField("sensitivity-dbm")]: mathUtils.toPrecision(10 * Math.log10(sensitivity) + 30, precisions["sensitivity-dbm"], t)
                };
            } else if (["sensitivity-dbm"].includes(relativeChangedField)) {
                const sensitivityDbm = getRelativeValue("sensitivity-dbm");
                return {
                    [getRelativeField("sensitivity")]: mathUtils.toPrecision(Math.pow(10, (sensitivityDbm - 30) / 10), precisions["sensitivity"], t)
                };
            }
            break;
        case "minimum-sensitivity/minimum-sensitivity-dbm":
            if (!relativeChangedField || ["minimum-sensitivity"].includes(relativeChangedField)) {
                const sensitivity = getRelativeValue("minimum-sensitivity");
                return {
                    [getRelativeField("minimum-sensitivity-dbm")]: mathUtils.toPrecision(10 * Math.log10(sensitivity) + 30, precisions["sensitivity-dbm"], t)
                };
            } else if (["minimum-sensitivity-dbm"].includes(relativeChangedField)) {
                const sensitivityDbm = getRelativeValue("minimum-sensitivity-dbm");
                return {
                    [getRelativeField("minimum-sensitivity")]: mathUtils.toPrecision(Math.pow(10, (sensitivityDbm - 30) / 10), precisions["sensitivity"], t)
                };
            }
            break;
        case "maximum-sensitivity/maximum-sensitivity-dbm":
            if (!relativeChangedField || ["maximum-sensitivity"].includes(relativeChangedField)) {
                const sensitivity = getRelativeValue("maximum-sensitivity");
                return {
                    [getRelativeField("maximum-sensitivity-dbm")]: mathUtils.toPrecision(10 * Math.log10(sensitivity) + 30, precisions["sensitivity-dbm"], t)
                };
            } else if (["maximum-sensitivity-dbm"].includes(relativeChangedField)) {
                const sensitivityDbm = getRelativeValue("maximum-sensitivity-dbm");
                return {
                    [getRelativeField("maximum-sensitivity")]: mathUtils.toPrecision(Math.pow(10, (sensitivityDbm - 30) / 10), precisions["sensitivity"], t)
                };
            }
            break;
        default:
            throw new Error(`Unknown conversion ${conversion}!`);
    }

    return {};
}
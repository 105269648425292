import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Row} from 'reactstrap'

import LabelWithInfo from './elements/LabelWithInfo'
import XYChart from './elements/XYChart'
import {calculateParamsFromLiveData, replaceVariables} from 'utils/parametersUtils'

class SimpleChartVal extends Component {

    render() {
        const {name, field, info, style, infoImage, noLabel, data, fieldsInfo, collectionsDict, t} = this.props;
        let {xSeries, ySeriesArr} = this.props;
        const resultsArr = data && field && data[field];
        const fieldLoading = field + "_loading";
        const resultsArr_loading = data && data.hasOwnProperty(fieldLoading) && data[fieldLoading];

        const parameters = calculateParamsFromLiveData(this.props.parameters, field, data, fieldsInfo, collectionsDict);
        xSeries = {...xSeries, field: replaceVariables(xSeries && xSeries.field, parameters)};
        ySeriesArr = ySeriesArr && ySeriesArr.map(s => ({...s, field: replaceVariables(s.field, parameters)}));

        let dataZoom = xSeries && (xSeries.field === 'time' || xSeries.field === 't');
        let legendInside = true;


        return (<div className="form-group">
            {!noLabel &&
            <Row noGutters>
                <Col>
                    <LabelWithInfo name={name} info={info} infoImage={infoImage}/>
                </Col>
            </Row>}
            <Row noGutters className="d-flex flex-row flex-nowrap">
                <div className="flex-grow semitransparent-overlay-container">
                    <XYChart
                        xSeries={xSeries}
                        ySeriesArr={ySeriesArr}
                        data={resultsArr}
                        dataZoom={dataZoom} legendInside={legendInside}
                        style={style}/>
                    {resultsArr_loading &&
                    <div className="semitransparent-overlay">
                        <p style={{textAlign: "center", lineHeight: "200px"}}>{t('components.fields.SimpleChartVal.wait')}</p>
                    </div>}
                </div>
            </Row>
        </div>);
    }
}

export default withTranslation()(SimpleChartVal);
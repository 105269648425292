import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import Tree from 'components/blocks/Tree'
import withUriParams from 'hocs/withUriParams'
import withUriSearchParam from 'hocs/withUriSearchParam'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import LicenseAndEventStatisticsView from './components/LicenseAndEventStatisticsView'
import StudentsView from './studentViews/StudentsView'
import GroupView from './studentViews/GroupView'
import EventStudentsView from './studentViews/EventStudentsView'
import TeamView from './studentViews/TeamView'

import {queryLicense, queryStudentGroups, queryStudentTeams, queryLicenseEvents} from '../data/adminQueries'
import {deleteStudentGroup, deleteStudentTeam} from '../data/adminMutations'

import {breadcrumbsStore} from 'appBase/TopNav'
import {ADMIN_STUDENTS_VIEW, ARCHIVED} from 'constants/constants'

const STUDENT_GROUP = 'student_group';
const TESTER_GROUP = 'tester_group';

const AdminStudentsView = compose(
    withUriParams([
        ['itemType', String, {values: ['students', 'groups', 'events']}],
        ['itemId', Number, {optional: true}],
        ['elementType', String, {optional:true, values: [STUDENT_GROUP, TESTER_GROUP, 'team']}],
        ['elementId', Number, {optional:true}]]),
    withUriSearchParam(ARCHIVED, 'showArchived'),
    queryLicense,
    queryLicenseEvents,
    queryStudentGroups, queryStudentTeams,
    deleteStudentGroup, deleteStudentTeam,
    withInitialDataLoadWaiting(['license', 'licenseEvents', 'studentGroups', 'studentTeams'])
)((props) => {

    const {itemType, itemId, elementType, elementId,
            licenseId, license, licenseEvents, studentGroups, studentTeams,
            disabled} = props;

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    //dictionaries
    const licenseEventsIx = useMemo(() => {
        const dict = {};
        licenseEvents.forEach(e => {
            dict[e.id] = e;
        });
        return dict;
    }, [licenseEvents]);

    const studentGroupsIx = useMemo(() => {
        const dict = {};
        studentGroups.forEach(g => {
            dict[g.id] = g;
        });
        return dict;
    }, [studentGroups]);

    const studentTeamsByEventId = useMemo(() => {
        const dict = {};
        studentTeams.forEach(t => {
            if (!dict[t.event.id]) {
                dict[t.event.id] = [];
            }
            dict[t.event.id].push(t);
        });
        return dict;
    }, [studentTeams]);


    // links
    const containerId = ADMIN_STUDENTS_VIEW;
    breadcrumbsStore.register(containerId);

    const links = [{name: t('appAdmin.views.AdminStudentsView.studentsGroupsAndTeams'), to: `${props.uri}${props.location.search}`}];

    useEffect(() => {
        const isTerminalBreadcrumbs = !itemType;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });


    // object tree nodes
    const nodes = [];
    const studentsNode = {
        id: 'students',
        name: t('appAdmin.views.AdminStudentsView.allStudents'),
        items: [],
        actions: {
            "select": () => navigate(`${props.uri}/students${props.location.search}`),
        }
    };

    const groupsNode = {
        id: 'groups',
        name: t('appAdmin.views.AdminStudentsView.groups'),
        items: [],
        actions: {
            "select": () => navigate(`${props.uri}/groups${props.location.search}`),
        }
    };

    studentGroups.forEach(g => {
        const groupItem = {
            id: 'g' + g.id,
            name: g.name,
            actions: {
                "select": () => navigate(`${props.uri}/groups/${g.id}${props.location.search}`),
                "removeConfirmation": () => props.deleteStudentGroup(g.id)
            }
        };
        groupsNode.items.push(groupItem);
    });
    if (!disabled) {
        groupsNode.items.push({
            id: 'createGroup',
            name: t('appAdmin.views.AdminStudentsView.addGroup'),
            notSelectable: true,
            actions: {
                "click": () => {
                    navigate(`${props.uri}/groups/0/edit${props.location.search}`);
                }
            }
        });
    }

    const eventsNode = {
        id: 'events',
        name: t('appAdmin.views.AdminStudentsView.eventsAndTeams'),
        items: [],
        actions: {
            "select": () => navigate(`${props.uri}/events${props.location.search}`),
        }
    };

    licenseEvents.forEach(e => {
        const eventNode = {
            id: 'e' + e.id,
            parentId: 'events',
            name: e.isArchived ? t('appAdmin.views.AdminStudentsView.archived') + e.name: e.name,
            items: [],
            actions: {
                "select": () => navigate(`${props.uri}/events/${e.id}${props.location.search}`),
            }
        };

        const studentGroupNode = {
            id: STUDENT_GROUP + e.id,
            parentId: 'e' + e.id,
            name: e.studentGroupId && studentGroupsIx[e.studentGroupId] ?
                      t('appAdmin.views.AdminStudentsView.students') + studentGroupsIx[e.studentGroupId].name + ')':
                      t('appAdmin.views.AdminStudentsView.studentsNotSelected'),
            actions: {
                "select": () => navigate(`${props.uri}/events/${e.id}/${STUDENT_GROUP}${props.location.search}`),
            }
        };

        const testerGroupNode = {
            id: TESTER_GROUP + e.id,
            parentId: 'e' + e.id,
            name: e.testerGroupId && studentGroupsIx[e.testerGroupId] ?
                      t('appAdmin.views.AdminStudentsView.testers') + studentGroupsIx[e.testerGroupId].name + ')':
                      t('appAdmin.views.AdminStudentsView.testersNotSelected'),
            actions: {
                "select": () => navigate(`${props.uri}/events/${e.id}/${TESTER_GROUP}${props.location.search}`),
            }
        };

        const eventTeamsParentNode = {
            id: 'teams' + e.id,
            parentId: 'e' + e.id,
            name: t('appAdmin.views.AdminStudentsView.teams'),
            items: [],
            actions: {
                "select": () => navigate(`${props.uri}/events/${e.id}/team${props.location.search}`),
            }
        };

        studentTeamsByEventId[e.id] &&
        studentTeamsByEventId[e.id].forEach(t => {
            const teamItem = {
                id: 't' + t.id,
                name: t.name,
                actions: {
                    "select": () => navigate(`${props.uri}/events/${e.id}/team/${t.id}${props.location.search}`),
                    "removeConfirmation": () => props.deleteStudentTeam(t.id)
                }
            };
            eventTeamsParentNode.items.push(teamItem);
        });
        if (!disabled) {
            eventTeamsParentNode.items.push({
                id: 'createTeam',
                name: t('appAdmin.views.AdminStudentsView.addTeam'),
                notSelectable: true,
                actions: {
                    "click": () => {
                        navigate(`${props.uri}/events/${e.id}/team/0/edit${props.location.search}`);
                    }
                }
            });
        }
        nodes.push(eventNode, studentGroupNode, testerGroupNode, eventTeamsParentNode);
    });
    nodes.push(studentsNode, groupsNode, eventsNode);

    // uri parts:
    const itemTypePart    = ![undefined, null].includes(itemType)    ? `/${itemType}`    : "";
    const itemIdPart      = ![undefined, null].includes(itemId)      ? `/${itemId}`      : "";
    const elementTypePart = ![undefined, null].includes(elementType) ? `/${elementType}` : "";
    const elementIdPart   = ![undefined, null].includes(elementId)   ? `/${elementId}`   : "";

    //selectedId
    let selectedId;
    if (itemType === 'students') {
        selectedId = 'students';
    } else if (itemType === 'groups' && [undefined, null].includes(itemId)) {
        selectedId = 'groups';
    } else if (itemType === 'groups' && itemId) {
        selectedId = 'g' + itemId;
    } else if (itemType === 'events' && !itemId) {
        selectedId = 'events';
    } else if (itemType === 'events' && itemId && !elementType) {
        selectedId = 'e' + itemId;
    } else if (elementType === STUDENT_GROUP) {
        selectedId = STUDENT_GROUP + itemId;
    } else if (elementType === TESTER_GROUP) {
        selectedId = TESTER_GROUP + itemId;
    } else if (elementType === 'team' && [undefined, null].includes(elementId)) {
        selectedId = 'teams' + itemId;
    } else if (elementType === 'team' && elementId) {
        const eventNode = nodes.find(n => n.id === `teams${itemId}`);
        const isTeamInEvent = eventNode && eventNode.items && !!eventNode.items.find(i => i.id === `t${elementId}`);
        selectedId = isTeamInEvent && 't' + elementId;
    }

    return (
        <div className="flex-grow d-flex flex-row scroll-parent">
            <div className="w-25 d-flex flex-column">
                <div className="scroll">
                    <Tree nodes={nodes} selectedId={selectedId} executeWithLock={props.executeWithLock} />
                </div>
            </div>

            <div className="w-75 d-flex flex-column border border-dark border-top-0 border-end-0 border-bottom-0">
                <LicenseAndEventStatisticsView licenseId={licenseId} errors={props.errors} loading={props.loading} withEvents withStudents />

                {itemType === 'students' &&
                <StudentsView licenseId={licenseId}
                              user={props.user} errors={props.errors} loading={props.loading}
                              disabled={license.disabled}
                              setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                              resetActionLock={props.resetActionLock}
                              executeWithLock={props.executeWithLock}
                              executeWithConfirmation={props.executeWithConfirmation}
                              uri={`${props.uri}/students`}
                              uriParams={props.uriParams}
                              location={props.location}/>}

                {((itemType === 'groups' && ![null, undefined].includes(itemId)) ||
                    (elementType === STUDENT_GROUP && licenseEventsIx[itemId] && licenseEventsIx[itemId].studentGroupId) ||
                    (elementType === TESTER_GROUP && licenseEventsIx[itemId] && licenseEventsIx[itemId].testerGroupId)) &&
                <GroupView licenseId={licenseId}
                           groupId={itemType === 'groups' ?
                                    itemId:
                                    elementType === STUDENT_GROUP ?
                                        licenseEventsIx[itemId] && licenseEventsIx[itemId].studentGroupId:
                                        licenseEventsIx[itemId] && licenseEventsIx[itemId].testerGroupId}
                           eventId = {itemType === 'events' ? itemId: null}
                           user={props.user} errors={props.errors} loading={props.loading}
                           disabled={license.disabled}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation}
                           uri={`${props.uri}${itemTypePart}${itemIdPart}${elementTypePart}${elementIdPart}`}
                           uriParams={props.uriParams}
                           location={props.location}/>}

                {itemType === 'events' && ![STUDENT_GROUP, TESTER_GROUP, "team"].includes(elementType) &&
                <EventStudentsView licenseId={licenseId} eventId={itemId}
                           user={props.user} errors={props.errors} loading={props.loading}
                           disabled={license.disabled}
                           setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                           resetActionLock={props.resetActionLock}
                           executeWithLock={props.executeWithLock}
                           executeWithConfirmation={props.executeWithConfirmation}
                           uri={`${props.uri}/events`}
                           uriParams={props.uriParams}
                           location={props.location}/>}

                {elementType === "team" &&
                <TeamView licenseId={licenseId}
                          eventId={itemId}
                          teamId={elementId}
                          user={props.user} errors={props.errors} loading={props.loading}
                          disabled={license.disabled}
                          setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                          resetActionLock={props.resetActionLock}
                          executeWithLock={props.executeWithLock}
                          executeWithConfirmation={props.executeWithConfirmation}
                          uri={`${props.uri}/events`}
                          uriParams={props.uriParams}
                          location={props.location}/>}
            </div>
        </div>);
});

export default AdminStudentsView;
import {useEffect, useRef } from 'react';
import useForceRender from 'hooks/useForceRender'

function useThrottledEffect(callback, dependencies, delay) {
    const savedCallback = useRef();
    const weHaveChanges = useRef(false);
    const forceRender = useForceRender();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (weHaveChanges.current) {
                weHaveChanges.current = false;
                savedCallback.current();
                forceRender();
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);

    // Check dependency changes
    useEffect(() => {
        if (!weHaveChanges.current) {
            weHaveChanges.current = true;
            forceRender();
        }
    }, dependencies);

    return weHaveChanges.current;
}

export default useThrottledEffect;
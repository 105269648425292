import React, {useState, useMemo, useRef, useEffect} from 'react'
import {Col} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import useSimpleGeneratorDataSource from 'hooks/useSimpleGeneratorDataSource'
import useForceRender from 'hooks/useForceRender'

import MdEditor, {Plugins} from 'react-markdown-editor-lite'
import PanelModal from '../modals/PanelModal'
import MarkdownOutput from '../elements/MarkdownOutput'
import SaveButtonsBlock from '../blocks/SaveButtonsBlock'
import ImageVal from './ImageVal'
import Error from './elements/Error'

import 'react-markdown-editor-lite/lib/index.css'
import LabelWithInfo from './elements/LabelWithInfo'

//built-in plugins list: https://github.com/HarryChen0506/react-markdown-editor-lite/blob/HEAD/docs/plugin.md
MdEditor.unuse(Plugins.FontUnderline);
MdEditor.unuse(Plugins.Clear);
MdEditor.unuse(Plugins.Image);

const MyImage = (props) => {
    const handleCustomImageUpload = (e) => {
        const { onCustomImageUpload } = props.editorConfig;
        if (onCustomImageUpload) {
            const res = onCustomImageUpload(e);
            Promise.resolve(res)
                .then(result => {
                    if (result && result.url) {
                        const newImage = `![${result.text}](${result.url || 'http://url...'})`;
                        props.editor.insertText(newImage, true, {start: 0, end: newImage.length});
                    }
                });
        }
    };
    const isCustom = !!props.editorConfig.onCustomImageUpload;
    return isCustom && (
        <span className="button button-type-image" title={/*i18n.get('btnImage')*/'Image'} onClick={handleCustomImageUpload}>
            <i className={`rmel-iconfont rmel-icon-image`} />
        </span>);
};
MyImage.align = 'left';
MyImage.pluginName = 'my-image';
MdEditor.use(MyImage);



export default function MarkdownVal(props) {
    const forceRender = useForceRender();
    const [isPreviewVisible, setIsPreviewVisible] = useState(true);
    const [isShowSelectImage, setIsShowSelectImage] = useState(false);
    const [selectImagePromise, setSelectImagePromise] = useState(null);
    const ref = useRef();

    const {data, field, name, canEdit, info, infoImage, noLabel, asFormComponent, wide, error, collectionsDict, onChange, multiLanguageProps} = props;
    const disabled = canEdit ? props.disabled : true;

    let {value} = props;
    value = value || (data && field && data[field]);

    const imageSource = useMemo(() => ({value: ""}), []);
    const [imageDataSource, onImageChange] = useSimpleGeneratorDataSource(imageSource);

    const {t} = useTranslation();

    useEffect(() => {
        const editor = ref.current;
        if (editor) {
            const onViewChange = ({html}) => {
                console.log(html);
                setIsPreviewVisible(html);
                editor.state.html = <MarkdownOutput value={editor.state.text}/>;
            };
            editor.on('viewchange', onViewChange);
            return () => editor.off('viewchange', onViewChange);
        }
    }, [ref, setIsPreviewVisible]);

    // rendering

    // viewComponent:
    let viewComponent = null;
    if (asFormComponent) {
        viewComponent =
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} info={info} infoImage={infoImage} multiLanguageProps={multiLanguageProps}/>
                </Col>
                <Col sm={wide ? 10 : 9}>
                    <MarkdownOutput className="form-control" value={value} />
                </Col>
            </div>;
    } else {
        viewComponent =
            <div className="form-group row g-0">
                {!noLabel && name &&
                    <h6><LabelWithInfo name={name} multiLanguageProps={multiLanguageProps} /></h6>}
                <MarkdownOutput value={value}/>
            </div>;
    }

    // render:
    return (
        <>
        {disabled && viewComponent}
        {!disabled &&
        <>
        {name &&
        <h6><LabelWithInfo name={name} multiLanguageProps={multiLanguageProps} /></h6>}
        <div className="mb-3">
            <MdEditor ref={ref}
                      style={{ height: "300px" }}
                      renderHTML={(text) => isPreviewVisible &&
                           <MarkdownOutput value={text}/>}
                      config={{ htmlClass: " " }}
                      value={value}
                      onChange={({html, text}) => {
                          onChange(data, field, text, false, true);
                          forceRender();
                      }}
                      onCustomImageUpload={() => {
                          setIsShowSelectImage(true);
                          imageDataSource.value = "";
                          const text = ref.current.getMdValue() || '';
                          const selection = ref.current.getSelection() || {start: 0, end: 0};
                          // selecting default values - text is what was selected, empty url:
                          imageDataSource.text = text.substring(selection.start, selection.end) || "";
                          imageDataSource.url = "";
                          // now looking for a picture:
                          // start of selection should be before closing bracket (or next to it)
                          const endIndex = text.indexOf(")", selection.start - 1);
                          // from here we start looking for begining
                          const startIndex = text.lastIndexOf("!", text.lastIndexOf("(", endIndex));
                          // does found pattern intersects with selection?
                          if (selection.start <= endIndex + 1 && selection.end >= startIndex) {
                              // does the pattern match?
                              const imgRegex = /^!\[(.*?)\]\(([^\s]*?)(\s+".*?")?\)$/;
                              const pattern = text.substring(startIndex, endIndex + 1);
                              const match = imgRegex.exec(pattern);
                              if (match) {
                                  imageDataSource.text = match[1];
                                  imageDataSource.url = match[2];
                                  ref.current.setSelection({start: startIndex, end: endIndex + 1});
                              }
                          }
                          return new Promise((resolve, reject) => {
                              const promise = {resolve: resolve, reject: reject};
                              setSelectImagePromise(promise);
                              return promise;
                          });
                      }} />
            {error &&  <Error id={field} error={error} />}
        </div>

        {isShowSelectImage &&
        <PanelModal show title={t('components.fields.MarkdownVal.chooseImage')} size="md"
                    onClose={() => {
                        selectImagePromise.reject();
                        setIsShowSelectImage(false);
                    }}
                    noPadding>
            <SaveButtonsBlock actions={[{
                key: "select",
                name: t('components.fields.MarkdownVal.ok'),
                action: () => {
                    selectImagePromise.resolve({text: imageDataSource.text, url: imageDataSource.url});
                    setIsShowSelectImage(false);
                }}, {
                name: t('components.fields.MarkdownVal.cancel'),
                action: () => {
                    selectImagePromise.reject();
                    setIsShowSelectImage(false);
                }}]} />
            <div className="p-3">
                <ImageVal data={imageDataSource} field="url"
                          onChange={onImageChange}
                          collectionsDict={collectionsDict}
                          noLabel />
            </div>
        </PanelModal>}
        </>}
        </>);
};
const queryUtils = {

    joinErrors: (e1, e2) => {
        if (e1 && !e2) {
            return e1;
        } else if (!e1 && e2) {
            return e2;
        } else if (e1 && e2) {
            return [...(Array.isArray(e1) ? e1 : [e1]), ...(Array.isArray(e2) ? e2 : [e2])]
        } else {
            return undefined;
        }
    },

    mergeWith: (obj, add, isOptional) => {
        if (!obj) {
            if (isOptional) {
                return undefined;
            }
            throw new Error('Cannot merge query info, aggregate query object does not exist! Alternatively mark as optional');
        }
        Object.keys(add).forEach(k => obj[k] = add[k]);
        return obj;
    }
};

export default queryUtils;
import React, {useMemo} from 'react'
import Select from 'react-select'
import {Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import Error from './elements/Error'

export default function SelectVal(props) {

    const {
        wide, name, unit, info, infoImage, disabled, data, field, collectionsDict, collection,
        itemNameField, itemValueField, nullOption, hideNullOptionOnSelect, namePattern, error, noLabel
    } = props;

    if (props.options && collection) {
        throw new Error("SelectVal: Should be either options or collection!");
    }

    // selectOptions: label, value
    const [value, selectOptions, itemValuesMap] = useMemo(() => {
        let value = null;
        // options: name, value
        let options = [];
        const itemValuesMap = {};

        if (props.options) {
            options = props.options.slice();
            if (data && options.find(o => o.value === data[field])) {
                value = data[field];
            }
        }

        if (collection) {
            const items = collectionsDict[collection] || [];
            items.forEach((item, i) => {
                const itemName =
                    namePattern ? namePattern.replace('*', i + 1) :
                    itemNameField ? item[itemNameField] :
                    item;
                const itemValue =
                    itemValueField === '$index' ? i:
                    itemValueField ? item[itemValueField] :
                    item;
                const itemKey = String(i);
                itemValuesMap[itemKey] = itemValue;
                if (data && data[field] === itemValue) {
                    value = itemKey;
                }
                options.push({name: itemName, value: itemKey});
            });
        }

        // adding null option
        if (nullOption && !hideNullOptionOnSelect) {
            options.unshift({name: nullOption, value: null});
        }

        const selectOptions = options.map(o => ({label: o.name, value: o.value}));

        return [value, selectOptions, itemValuesMap];
    }, [props.options, collection, collectionsDict, nullOption, hideNullOptionOnSelect])


    const onChange = (option) => {
        let value = option.value;
        if (itemValuesMap.hasOwnProperty(value)) {
            value = itemValuesMap[value];
        }
        props.onChange(data, field, value);
    };


    return (
        <Col sm={6} className="form-group row g-0">
            {!noLabel &&
            <Col sm={wide ? 4 : 6}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage} />
            </Col>}
            {/* padding accounts for 2-column gap */}
            <Col sm={wide ? 8 : 6} className="pe-2">
                <Select isSearchable menuPortalTarget={document.body} isDisabled={disabled} onChange={onChange}
                        className={classnames(error && "border border-danger", disabled && "text-muted")}
                        placeholder={value === null && nullOption}
                        options={selectOptions}
                        value={selectOptions.find(o => o.value === value) || null}
                        styles={{
                            menuPortal: (defaultStyles) =>
                                ({...defaultStyles, "zIndex": 9999})
                        }} />
                {error &&  <Error id={field} error={error} />}
            </Col>
        </Col>);
}
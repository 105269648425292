/**
 * Created by Yuri on 03.08.2020
 * Part of frontend
 */
import React from 'react'

export default function withUriSearchParam(searchParamName, propName) {
    return function withUriSearchParam(WrappedComponent) {
        class WithUriSearchParam extends React.Component {
            render() {
                const modes = this.props.location.search.substring(1).split('&');    // location.search starts with '?'
                const isFound = modes.includes(searchParamName);

                const newProps = {...this.props, [propName]: isFound};
                return <WrappedComponent {...newProps} />;
            }
        }

        WithUriSearchParam.displayName = `WithUriSearchParam(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
        return WithUriSearchParam;
    }
};
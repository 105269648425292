const proxySymbol = Symbol('proxy');

const proxificateObj = (obj) => {
    if (obj[proxySymbol]) {
        throw new Error('Already proxificated!');
        // return obj[proxySymbol];
    }
    obj[proxySymbol] = new Proxy(obj, {
        get(target, prop) {
            if (typeof prop === 'symbol') {
                return target[prop];
            }
            return entityUtils.get(target, prop);
        },
        set(target, prop, value) {
            if (typeof prop === 'symbol') {
                target[prop] = value;
                return true;
            }
            return entityUtils.set(target, prop, value);
        }
    });
    return obj[proxySymbol];
};


const entityUtils = {

    // - not needed -
    // isEmptyObject(obj) {
    //     // https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
    //     return Object.keys(obj).length === 0 && obj.constructor === Object;
    // },

    filterFields(obj, fields) {
        let result = {};
        fields.forEach(f => {
            if (obj.hasOwnProperty(f)) {
                result[f] = obj[f];
            }
        });
        return result;
    },

    sortByField(field, fExtractor = (x => x)) {
        return function( a, b) {
            if ( fExtractor(a)[field] < fExtractor(b)[field] ){
                return -1;
            }
            if ( fExtractor(a)[field] > fExtractor(b)[field] ){
                return 1;
            }
            return 0;
        }
    },

    sortByFieldDesc(field, fExtractor = (x => x)) {
        return function( a, b) {
            if ( fExtractor(a)[field] > fExtractor(b)[field] ){
                return -1;
            }
            if ( fExtractor(a)[field] < fExtractor(b)[field] ){
                return 1;
            }
            return 0;
        }
    },

    sortByOrder(fExtractor = (x => x)) {
        return function( a, b) {
            if ( fExtractor(a).order < fExtractor(b).order ){
                return -1;
            }
            if ( fExtractor(a).order > fExtractor(b).order ){
                return 1;
            }
            return 0;
        }
    },

    uniquifyName(name, names) {
        const wasNumbered = name.match(/(.+)\s\(\d+\)$/);// postfix (1) -> (2) instead of (1)(1)
        const oldname = wasNumbered ? wasNumbered[1] : name;

        for (let i = 1; names.indexOf(name) >= 0; i++) {
            name = oldname + " (" + i + ")";
        }
        return name;
    },

    isNumericPart(part) {
        return (part.startsWith("id:") || part.startsWith("index:")) && isFinite(part.substring(part.indexOf(':') + 1));
    },

    safeJSONParse(contents, defaultValue) {
        if (!contents) {
            return defaultValue;
        }

        try {
            return JSON.parse(contents);
        } catch(error) {
            console.warn(`Error parsing JSON string: ${error}`);
            return defaultValue;
        }
    },

    doesFieldExist(target, prop) {
        let arr = prop.split('.');
        for (let i = 0; i < arr.length; i++) {
            let part = arr[i];
            if (target === null || target === undefined) {
                return false;
            } else if (entityUtils.isNumericPart(part)) {
                const number = Number(part.substring(part.indexOf(':') + 1));
                // special select from array of objects
                if (Array.isArray(target)) {
                    if (part.startsWith("id:")) {
                        target = target.find(x => x.localId === number);
                    } else if (part.startsWith("index:")) {
                        target = target[number];
                    }
                    if (!target) {
                        // non-exisitng array item
                        return false;
                    }
                } else {
                    throw new Error("numerical index should be executed against array, got " + String(target));
                }
            } else {
                if (typeof(target) !== "object") {
                    console.warn(`bad prop: non-object at ${part} in ${prop}`);
                    target = {};
                }
                //sequentially go through the chain of properties
                target = target[part];
            }
        }
        // we have successfuly reached final node so it exists
        return true;
    },

    get(target, prop) {
        // console.log(`proxification getter: ${prop} of `); console.log(target);
        let arr = typeof(prop) === 'string' ? prop.split('.') : String(prop) ? [String(prop)] : [];
        for (let i = 0; i < arr.length; i++) {
            let part = arr[i];
            if (target === null || target === undefined) {
                return target;
            } else if (entityUtils.isNumericPart(part)) {
                const number = Number(part.substring(part.indexOf(':') + 1));
                // special select from array of objects
                if (Array.isArray(target)) {
                    if (part.startsWith("id:")) {
                        target = target.find(x => x.localId === number);
                    } else if (part.startsWith("index:")) {
                        target = target[number];
                    }
                } else {
                    throw new Error("numerical index should be executed against array, got " + String(target));
                }
            } else {
                if (typeof(target) !== "object") {
                    console.warn(`bad prop: non-object at ${part} in ${prop}`);
                    target = {};
                }
                //sequentially go through the chain of properties
                target = target[part];
            }
        }
        return target !== undefined ? target : target;
    },

    set(target, prop, value) {
        //console.log(`proxification setter: ${prop}`);
        const origTarget = target;
        let fullField = '';
        let arr = prop.split('.');
        for (let i = 0; i < arr.length; i++) {
            let part = arr[i];
            const next_part = i < arr.length - 1 ? arr[i + 1] : null;
            if (i < arr.length - 1) {
                if (entityUtils.isNumericPart(part)) {
                    const number = Number(part.substring(part.indexOf(':') + 1));
                    // special select from array of objects
                    if (Array.isArray(target)) {
                        let item;
                        if (part.startsWith("id:")) {
                            item = target.find(x => x.localId === number);
                            if (item === undefined) {
                                item = {localId: number};
                                target.push(item);
                            }
                        } else if (part.startsWith("index:")) {
                            item = target[number];
                            if (item === undefined) {
                                item = {};
                                target.push(item);
                            }
                        }
                        fullField += (fullField && part ? '.' : '') + part;
                        target = item;
                    } else {
                        throw new Error("numerical index should be executed against array, got " + String(target));
                    }
                } else {
                    if (typeof(target) !== "object") {
                        console.warn(`bad prop: non-object at ${part} in ${prop}`);
                        target = {};
                        entityUtils.set(origTarget, fullField, target);
                    }
                    if (!target[part]) {
                        if (entityUtils.isNumericPart(next_part)) {
                            target[part] = [];
                        } else {
                            target[part] = {};
                        }
                    }
                    fullField += (fullField && part ? '.' : '') + part;
                    target = target[part];
                }
            } else {
                // last one
                if (entityUtils.isNumericPart(part)) {
                    throw new Error("numerical indices not allowed as leaf properties for set! Can only set individual properties, not whole array items");
                }
                if (typeof(target) !== "object") {
                    console.warn(`bad prop: non-object at ${part} in ${prop}`);
                    target = {};
                    entityUtils.set(origTarget, fullField, target);
                }
                target[part] = value;
            }
        }
        return true;
    },

    getExistigProxyOrProxificateObj(obj) {
        return obj[proxySymbol] ? obj[proxySymbol] : proxificateObj(obj);
    },

    getEmptyProxyObj() {
        return proxificateObj({});
    },

    // isProxyOfObject(proxy, obj) {
    //     return proxy && obj && proxy === obj[proxySymbol];
    // },

    ensureArrayLocalIds(arr) {
        const localIdsIx = {};
        let maxLocalId = 0;
        const itemsWithoutIds = [];
        arr.forEach(a => {
            if (!a.hasOwnProperty('localId') || a.localId in localIdsIx) {
                itemsWithoutIds.push(a);
            } else {
                localIdsIx[a.localId] = a;
                maxLocalId = maxLocalId > a.localId ? maxLocalId : a.localId;
            }
        });
        itemsWithoutIds.forEach(a => {
            a.localId = maxLocalId + 1;
            maxLocalId += 1;
        });
    },

    ensureNamesUniqueness(arr, nameField, typeField, typeInfoArr) {
        const typesCount = {};
        arr.forEach((item, i) => {
            let typeName;
            if (typeField) {
                const type = typeField.split('.').reduce((obj, field) => obj && obj[field], item);
                const typeInfo = typeInfoArr ? typeInfoArr.find(x => x.type === type) : {name: type};
                typeName = typeInfo ? typeInfo.name : "Unknown";
            } else {
                const wasNumbered = /(.+)\s+\d+$/.exec(item[nameField]);  // postfix (1) -> (2) instead of (1)(1)
                typeName = wasNumbered && wasNumbered[1] ? wasNumbered[1] : item[nameField];
            }
            if (!typesCount.hasOwnProperty(typeName)) {
                typesCount[typeName] = {count: 1, firstItem: item};
                item[nameField] = typeName;
            } else {
                if (typesCount[typeName].count === 1) {
                    typesCount[typeName].firstItem[nameField] = `${typeName} 1`;
                }
                typesCount[typeName].count += 1;
                item[nameField] = `${typeName} ${typesCount[typeName].count}`;
            }
        });
    },

    nextVariableName(arr, prefix) {
        const maxNumber =
            arr.filter(x => x && x.startsWith(prefix) && isFinite(x.substring(prefix.length)))
                .map(x => Number(x.substring(prefix.length)))
                .reduce((max, v) => max === undefined || max < v ? v : max, undefined);
        const nextNumber = (maxNumber || 0) + 1;
        return prefix + nextNumber;
    },

    removeDuplicatesById(arr) {
        const idsDict = {};

        return arr.filter(e => {
            if (!idsDict[e['id']]) {
                idsDict[e['id']] = true
                return true;
            }
            return false;
        });
    },
};

export default entityUtils;

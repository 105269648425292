import {graphql} from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import queryUtils from 'utils/queryUtils'
import {MSEC_20_MINUTES} from 'constants/constants'


const FlatCatalogsAndEntriesQuery = gql`
  query FlatCatalogsAndEntriesQuery($showArchived: Boolean) {
    flatCatalogsAndEntries(showArchived: $showArchived) {
      catalogs {
        id
        parentId
        isArchived
        name
        order
      }
      catalogEntries {
        id
        catalogId
        isArchived
        type
        name
        order
      }
    }
  }
`;


export const catalogFields = `
    id
    parentId
    isArchived
    name
    nameMl
    description
    descriptionMl
    order
`;


const CatalogQuery = gql`
  query CatalogQuery($catalogId: ID!) {
    catalog(catalogId: $catalogId) {
      ${catalogFields}
    }
  }
`;


export const catalogEntryFields = `
    id
    catalogId
    isArchived
    type
    name
    nameMl
    order
    visibilityType
`;

export const catalogEntryVersionFields = `
    id
    catalogEntryId
    name
    nameMl
    description
    descriptionMl
    comments
    createdAt
    modifiedAt
    publishedAt
    event {
      id
      name
      type
      scenarios {
        id
        parentId
        order
        name
        type
      }
    }
    scenario {
      id
      parentId
      name
      type
    }
`;


const CatalogEntryWithVersionsQuery = gql`
  query CatalogEntryWithVersionsQuery($catalogEntryId: ID!) {
    catalogEntryWithVersions(catalogEntryId: $catalogEntryId) {
      ${catalogEntryFields}
      catalogEntryVersions {
        ${catalogEntryVersionFields}
      }
    }
  }
`;


const browserCatalogEntryVersionFields = `
  id
  description
  publishedAt
  event {
    id
    name
    type
    imageUrl
    description
    startsAt
    endsAt
    scenarios {
      id
      parentId
      order
      name
      type
    }
  }
  scenario {
    id
    type
  }
`

const BrowserFlatCatalogsAndEntriesQuery = gql`
  query BrowserFlatCatalogsAndEntriesQuery($licenseId: ID, $asRole: String) {
    browserFlatCatalogsAndEntries(licenseId: $licenseId, asRole: $asRole) {
      catalogs {
        id
        parentId
        name
        order
      }
      browserCatalogEntries {
        catalogEntry {
          id
          catalogId
          type
          name
          order
        }
        lastVersion {
          ${browserCatalogEntryVersionFields}
        }
        versionHeaders {
          id
          publishedAt
        }
      }
    }
  }
`;


const BrowserCatalogEntryVersionQuery = gql`
  query BrowserCatalogEntryVersionQuery($catalogEntryVersionId: ID!, $licenseId: ID) {
    browserCatalogEntryVersion(catalogEntryVersionId: $catalogEntryVersionId, licenseId: $licenseId) {
      ${browserCatalogEntryVersionFields}
    }
  }
`;


export const queryFlatCatalogsAndEntries =
    graphql(FlatCatalogsAndEntriesQuery, {
        options: ({showArchived}) => ({
            variables: {showArchived},
            fetchPolicy: 'network-only' }),
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {flatCatalogsAndEntries: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {flatCatalogsAndEntries: data.error && data.error.message}),
            flatCatalogsAndEntries: data.flatCatalogsAndEntries
        })
    });

export const queryCatalog =
    graphql(CatalogQuery, {
        options: ({catalogId}) => ({
            variables: {catalogId: catalogId},
            fetchPolicy: 'network-only' }),
        skip: ({catalogId}) => !catalogId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {catalog: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {catalog: data.error && data.error.message}),
            catalog: data.catalog
        })
    });

export const queryCatalogEntryWithVersions =
    graphql(CatalogEntryWithVersionsQuery, {
        options: ({catalogEntryId}) => {
            return {
                variables: {catalogEntryId: catalogEntryId},
                fetchPolicy: 'network-only' }},
        skip: ({catalogEntryId}) => !catalogEntryId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {catalogEntryWithVersions: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {catalogEntryWithVersions: data.error && data.error.message}),
                catalogEntryWithVersions: data.catalogEntryWithVersions
            }}
    });


export const queryBrowserFlatCatalogsAndEntries =
    graphql(BrowserFlatCatalogsAndEntriesQuery, {
        options: ({licenseId, queryAsRole}) => ({
            variables: {licenseId, asRole: queryAsRole},
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_20_MINUTES }),
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {browserFlatCatalogsAndEntries: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {browserFlatCatalogsAndEntries: data.error && data.error.message}),
            browserFlatCatalogsAndEntries: data.browserFlatCatalogsAndEntries
        })
    });


export const queryBrowserCatalogEntryVersion =
    graphql(BrowserCatalogEntryVersionQuery, {
        options: ({catalogEntryVersionId, licenseId}) => {
            return {
                variables: {catalogEntryVersionId, licenseId},
                fetchPolicy: 'cache-first',
                pollInterval: MSEC_20_MINUTES }},
        skip: ({catalogEntryVersionId}) => !catalogEntryVersionId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {browserCatalogEntryVersion: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {browserCatalogEntryVersion: data.error && data.error.message}),
                browserCatalogEntryVersion: data.browserCatalogEntryVersion
            }}
    });

import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import classnames from 'classnames'
import {Container} from 'reactstrap'

import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'

import PanelModal from 'components/modals/PanelModal'
import Tree from 'components/blocks/Tree'
import ListWithSort from 'components/blocks/ListWithSort'
import NamedBlock from 'components/blocks/NamedBlock'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import TableBlock from 'components/blocks/TableBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import Messages from 'components/elements/Messages'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'

import withUriParams from 'hocs/withUriParams'
import {queryAdminsWithUsers} from '../data/licenseQueries'
import {queryFlatCatalogsAndEntries} from 'appCatalog/data/catalogQueries'

import {updateLicense, deleteLicense, activateLicense,
        createAdmin, updateAdmin, deleteAdmin, addCatalogToLicense, deleteCatalogFromLicense} from '../data/licenseMutations'
import entityUtils from 'utils/entityUtils'
import dateUtils from "utils/dateUtils";
import {parseBackendMessage} from 'utils/translationUtils'
import {coloredIcon, toArrayWithLineBreaks} from 'utils/visualHelpers'
import licenseStaticPrototypes from '../data/licenseStaticPrototypes.json'
import {prepareLicenseObjectForEdit, prepareLicenseEditObjectForSave} from '../data/licenseObjects'
import logoIconRu from 'icons/logo/company_logo_ru.png'
import logoIconEn from 'icons/logo/company_logo_en.png'
import PrintPreviewModal from 'components/print/PrintPreviewModal'
import {EMAIL_REGEX_STRING} from 'constants/constants'


const LicenseView = compose(
    withUriParams([
        ['node', String, {values: ['license', 'admins', 'catalogs'], defaultValue: 'license'}],
        ['mode', String, {values: ['edit', 'activate'], optional: true}],
        ['objId', Number, {optional: true}]]),
    queryAdminsWithUsers,
    queryFlatCatalogsAndEntries,
    updateLicense, deleteLicense, activateLicense,
    createAdmin, updateAdmin, deleteAdmin,
    addCatalogToLicense,
    deleteCatalogFromLicense,
    withGeneratorDataSource
)(props => {
    useDebug("LicenseView", props);

    const {mode, node, objId, license, adminsWithUsers, flatCatalogsAndEntries,
        dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate,
        updateLicense, deleteLicense, activateLicense,
        createAdmin, updateAdmin, deleteAdmin,
        addCatalogToLicense, deleteCatalogFromLicense,
        executeWithConfirmation} = props;

    const [errorMessage, setErrorMessage] = useState(null);
    const [showPrintPreviewModal, setShowPrintPreviewModal] = useState(false);

    const {t, i18n} = useTranslation();
    const _t = (value) => t('appLicense.views.LicenseView.' + value);

    const navigate = useSmartNavigate();

    // license object for dataSource
    const licenseSource = useMemo(() =>
        prepareLicenseObjectForEdit(license),
        [license]);

    // admin object for dataSource
    const adminSource = useMemo(() => {
        if (objId > 0) {
            const awu = adminsWithUsers && adminsWithUsers.find(awu => awu.admin.id === objId);
            return awu && awu.admin
        } else if (objId === 0) {
            return {licenseId: license.id};
        } else {
            return null;
        }
    }, [objId, license.id, adminsWithUsers]);

    // catalog object for dataSource
    const catalogSource = useMemo(() => {
        if (objId === 0) {
            return {licenseId: license.id};
        } else {
            return null;
        }
    }, [objId, license]);

    // setting dataSource
    useEffect(() => {
        // we have three datasources here
        if (node === 'license') {
            setDataSource(
                licenseSource,
                (data) => {
                    if (mode === "activate") {
                        data = entityUtils.filterFields(
                            data, [
                                'id',
                                'organizerNameMl',
                                'address',
                                'contacts'
                            ]);
                        activateLicense(data)
                            .then(({data: {activateLicense: {license, error}}}) => {
                                setErrorMessage(error ? parseBackendMessage(error, t): null);
                            });
                    }
                    else {
                        data = prepareLicenseEditObjectForSave(data);
                        data = entityUtils.filterFields(
                            data, [
                                'id',
                                'name',
                                'organizerNameMl',
                                'isSuperlicense',
                                'studentsLimit',
                                'eventsLimit',
                                'number',
                                'priority',
                                'key',
                                'address',
                                'contacts',
                                'comments',
                                'isIssued',
                                'isActivated',
                                'startsAt',
                                'endsAt',
                                'dataLimit'
                            ]);
                        updateLicense(data)
                            .then(({data: {updateLicense: {error}}}) => {
                                setErrorMessage(error ? parseBackendMessage(error, t): null);
                            });
                    }
                });
        } else if (node === 'admins') {
            setDataSource(
                adminSource,
                (data) => {
                    data = entityUtils.filterFields(
                        data, [
                            'id',
                            'licenseId',
                            'email']);
                    const mutationName = objId > 0 ? "updateAdmin": "createAdmin";
                    const mutationFunc = objId > 0 ? updateAdmin: createAdmin;
                    mutationFunc(data)
                        .then(({data: {[mutationName]: {error}}}) => {
                            setErrorMessage(error ? parseBackendMessage(error, t): null);
                        });
                });
        } else if (node === 'catalogs') {
            setDataSource(
                catalogSource,
                (data) => {
                    data = entityUtils.filterFields(
                        data, [
                            'licenseId',
                            'catalogId']);
                    addCatalogToLicense(data)
                        .then(({data: {addCatalogToLicense: {error}}}) => {
                            setErrorMessage(error ? parseBackendMessage(error, t): null);
                        });
                });
        }
    }, [node, objId, licenseSource, adminSource, catalogSource, dataSource, setDataSource,
       activateLicense, updateLicense, updateAdmin, createAdmin, addCatalogToLicense, setErrorMessage, t, mode]);

    // nodes
    const modeUriPart = (mode && `/${mode}`) || '';

    const nodes = [
        {
            id: "license",
            name: _t('basicParams'),
            actions: {
                "select": () => navigate(`${props.uri}/license`)
            }
        },
        {
            id: "admins",
            name: _t('admins'),
            actions: {
                "select": () => navigate(`${props.uri}/admins`)
            }
        },
        {
            id: "catalogs",
            name: _t('catalogs'),
            actions: {
                "select": () => navigate(`${props.uri}/catalogs`)
            }
        }
    ];

    //adminRows
    const adminRows = (adminsWithUsers &&
        adminsWithUsers.map(({admin, user}, i) => ({
            fields: [
                admin.email,
                user && user.name,
                <span className="d-print-none">{!user ? "" : coloredIcon(user.active ? 'yes' : 'no')}</span>,
            ],
            actions: {
                "select": mode === 'edit' && (() => navigate(`${props.uri}/${node}${modeUriPart}/${admin.id}${props.location.search}`)),
                "removeConfirmation": mode === 'edit' && (() => deleteAdmin(admin.id))
            }
        }))) || [];

    adminsWithUsers && mode === 'edit' && adminRows.push({
        fields: [_t('addNewAdmin')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => navigate(`${props.uri}/${node}${modeUriPart}/0${props.location.search}`)
        }
    });

    //catalogRows
    const catalogRows = useMemo(() => {
        const catalogRows = license.catalogs.map(c => ({
            fields: [c.name],
            actions: {
                "removeConfirmation": mode === 'edit' && (() => deleteCatalogFromLicense({licenseId: license.id, catalogId: c.id})
                    .then(({data: {deleteCatalogFromLicense: {error}}}) => {
                        setErrorMessage(error ? parseBackendMessage(error, t): null);
                    }))
            }
        }));
        mode === 'edit' && catalogRows.push({
            fields: [_t('addNewCatalog')],
            className: "text-end",
            notSelectable: true,
            actions: {
                "click": () => navigate(`${props.uri}/${node}${modeUriPart}/0${props.location.search}`)
            }
        });
        return catalogRows;
    }, [license.catalogs, deleteCatalogFromLicense, setErrorMessage, parseBackendMessage, mode,
        navigate, props.uri, node, modeUriPart, props.location.search]);

    // TODO: завести отдельную print-версию прототипа
    const licensePrototypeForPrint = useMemo(() => {
        let licensePrototypeForPrint = JSON.parse(JSON.stringify(licenseStaticPrototypes.license));
        const filterOutFields = ["name", "comments", "prefix", "priority"];
        const twoColumnGroupings = [["isIssued", "isActivated"], ["startsAt", "endsAt"], ["studentsLimit", "eventsLimit"]];
        const recursiveParseItems = (items) => {
            const newItems = [];
            let nextItemIsAllreadyAddedToTwoColumns = false;
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (filterOutFields.includes(item.field)) {
                    continue;
                }
                if (item.type === 'choices') {
                    item.noLabel = true;
                }
                if (item.items) {
                    item.items = recursiveParseItems(item.items);
                }
                // checking if two columns processing needed
                if (nextItemIsAllreadyAddedToTwoColumns) {
                    nextItemIsAllreadyAddedToTwoColumns = false;
                    continue;
                }
                // processing two columns
                const nextItem = items[i + 1];
                const group = twoColumnGroupings.find(group => group[0] === item.field);
                if (group && nextItem && nextItem.field === group[1]) {
                    item.halfWidth = true;
                    nextItem.halfWidth = true;
                    item = {
                        "type": "block",
                        "template": "twoColumn",
                        "items": [item, nextItem]};
                    nextItemIsAllreadyAddedToTwoColumns = true;
                }
                // adding item (or two items if two columns)
                newItems.push(item);
            }
            return newItems;
        }
        licensePrototypeForPrint = recursiveParseItems(licensePrototypeForPrint);
        return licensePrototypeForPrint;
    }, [licenseStaticPrototypes.license]);


    const printContent = useMemo(() => (
        <Container fluid>
            <div className="form-group row g-0 align-items-center">
                <div className="col-sm-4">
                    <img className="img-fluid" src={i18n.resolvedLanguage === "ru" ? logoIconRu : logoIconEn} alt=""/>
                </div>
                <div className="ms-auto text-end col-sm-6">
                    {toArrayWithLineBreaks(_t('print.headerAddress'))}
                    <br/>
                    {toArrayWithLineBreaks(_t('print.headerLinks'), 'a')}
                </div>
            </div>

            {dataSource &&
                <GeneratorBlock data={dataSource} items={licensePrototypeForPrint} disabled wide />}
            <GeneratorBlock data={{accessLink: _t('print.accessLink'), supportInfo: _t('print.supportInfo')}}
                            items={[
                                {field: "accessLink", type: "markdown", name: _t('print.accessLinkField'),
                                    asFormComponent: true},
                                {field: "supportInfo", type: "markdown", name: _t('print.supportInfoField'),
                                    asFormComponent: true}]}
                            disabled wide />

            {!!adminRows.length &&
                <NamedBlock name={_t('admins')} className="mt-3" style={{border: 0}}>
                    <TableBlock rows={adminRows} headers={[_t('email'), _t('name'), <span className="d-print-none" /> ]} />
                </NamedBlock>}

            <NamedBlock name={_t('catalogs')} style={{border: 0}}>
                {catalogRows.length ?
                    <TableBlock rows={catalogRows} />:
                    _t('all')}
            </NamedBlock>

            <div className="form-group row g-0 mt-4 mb-4 justify-content-between">
                <div className="col-sm-3 border-bottom border-2">
                    {dateUtils.dateToPrintDocumentDateString(new Date())}
                </div>
                <div className="col-sm-3 border-bottom border-2" />
                <div className="col-sm-3 border-bottom border-2" />
            </div>
        </Container>),
        [dataSource, licensePrototypeForPrint, adminRows, catalogRows,
            dateUtils.dateToPrintDocumentDateString]);

    //onSave, onCancel
    const onSave = () => {
        saveAndReset();
        navigate(`${props.uri}/${node}${props.location.search}`);
    };
    const onCancel = () => {
        setDataSource(null);
        navigate(`${props.uri}/${node}${props.location.search}`);
    };

    //rendering
    return (
        <div className="flex-grow d-flex flex-row scroll-parent">
            <div className="w-25 d-flex flex-column">
                <div className="scroll">
                    <Tree nodes={nodes} selectedId={node} executeWithLock={props.executeWithLock} />
                </div>
            </div>
            <div className="w-75 d-flex flex-column border border-dark border-start">


                {node === 'license' && dataSource &&
                <>
                    {showPrintPreviewModal && <PrintPreviewModal content={printContent}
                                                                 onClose={() => setShowPrintPreviewModal(false)} />}

                    <SaveButtonsBlock withEditMode
                                      isChanged={isChanged}
                                      hasErrors={hasErrors}
                                      onSave={mode === 'edit' && onSave}
                                      onCancel={mode === 'edit' && onCancel}
                                      actions={[
                                          mode !== 'edit' && dataSource && dataSource.isSuperlicense._type === "false" && {
                                              name: <span><i className="fa fa-print"/>&nbsp;{t('components.print.print')}</span>,
                                              color: "primary",
                                              action: () => setShowPrintPreviewModal(true)
                                          },
                                          mode !== 'edit' && {
                                              name: _t('edit'),
                                              allowOnErrors: true,
                                              action: () => navigate(`${props.uri}/${node}/edit${props.location.search}`)
                                          },
                                          mode !== 'edit' && license.isIssued && !license.isActivated && license.prefix && license.templateDurationMonths && {
                                              name: _t('activate'),
                                              allowOnErrors: true,
                                              action: () => navigate(`${props.uri}/${node}/activate${props.location.search}`)
                                          },
                                          mode !== 'edit' && {
                                              name: _t('delete'),
                                              color: "danger",
                                              allowOnErrors: true,
                                              action: () => executeWithConfirmation(
                                                  () => license.eventsNumber
                                                      ? executeWithConfirmation(() => deleteLicense(license.id)
                                                            .then(({data: {deleteLicense: {id}}}) => navigate(`..`)), _t('deleteConfirmation2'))
                                                      : deleteLicense(license.id)
                                                            .then(({data: {deleteLicense: {id}}}) => navigate(`..`)),
                                                  `${_t('deleteConfirmation.part1')}
                                                   ${license.name}
                                                   " ${license.eventsNumber ?
                                                       `${_t('deleteConfirmation.part2')}
                                                        ${license.eventsNumber}
                                                        ${_t('deleteConfirmation.part3')}` :
                                                        _t('deleteConfirmation.part4')}
                                                   ${_t('deleteConfirmation.part5')}`)
                                          }
                                      ]}/>
                    <Messages error={errorMessage} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} items={licenseStaticPrototypes.license}
                                        onChange={onChange} onValidate={onValidate} disabled={mode !== 'edit'} wide />
                    </div>

                    {mode === "activate" && license.isIssued && !license.isActivated && license.prefix && license.templateDurationMonths &&
                    <PanelModal show title={_t('activate2')} onClose={onCancel}>
                        <SaveButtonsBlock withEditMode isChanged={isChanged} onSave={onSave} onCancel={onCancel} />

                        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                            <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                            items={licenseStaticPrototypes.licenseActivateFields} />
                        </div>
                     </PanelModal>}
                </>}

                {node === 'admins' &&
                <>
                    <SaveButtonsBlock withEditMode
                                      onCancel={mode === 'edit' && (() => {
                                          navigate(`${props.uri}/${node}${props.location.search}`);
                                      })}
                                      actions={[
                                          mode !== 'edit' && {
                                              name: _t('edit'),
                                              allowOnErrors: true,
                                              action: () => navigate(`${props.uri}/${node}/edit${props.location.search}`)
                                          }
                     ]}/>

                    <Messages error={errorMessage} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <NamedBlock name={_t('admins')} className={classnames(mode !== 'edit' && "bg-light")}>
                            <ListWithSort headers={[_t('email'), _t('name'), _t('active')]}
                                          sizes={["100*", "200*", "50*"]}
                                          rows={adminRows} nameIndex="0"
                                          disabled={mode !== 'edit'} />
                        </NamedBlock>
                    </div>

                    {dataSource &&
                    <PanelModal show title={_t('admin')}
                                onClose={() => {
                                        setDataSource(null);
                                        navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                    }
                                }
                                noPadding scrollable>
                        <SaveButtonsBlock withEditMode
                                          isChanged={isChanged}
                                          hasErrors={hasErrors}
                                          onSave={(() => {
                                              saveAndReset();
                                              navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                          })}
                                          onCancel={(() => {
                                              setDataSource(null);
                                              navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                          })} />
                        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                            <GeneratorBlock data={dataSource}
                                            items={[{
                                                name: _t('email'),
                                                type: "string",
                                                field: "email",
                                                pattern: {
                                                    pattern: EMAIL_REGEX_STRING,
                                                    error: _t('invalidEmail'),
                                                },
                                                required: true
                                            }]}
                                            onChange={onChange} onValidate={onValidate} wide />
                        </div>
                    </PanelModal>}
                </>}

               {node === 'catalogs' &&
                <>
                    <SaveButtonsBlock withEditMode
                                      onCancel={mode === 'edit' && (() => {
                                          navigate(`${props.uri}/${node}${props.location.search}`);
                                      })}
                                      actions={[
                                          mode !== 'edit' && {
                                              name: _t('edit'),
                                              allowOnErrors: true,
                                              action: () => navigate(`${props.uri}/${node}/edit${props.location.search}`)
                                          }
                                      ]}/>

                    <Messages error={errorMessage} />

                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <NamedBlock name={_t('catalogs')} className={classnames(mode !== 'edit' && "bg-light")}>
                            <ListWithSort rows={catalogRows} disabled={mode !== 'edit'} />
                        </NamedBlock>
                    </div>

                    {dataSource &&
                    <PanelModal show title={_t('catalogs')}
                                onClose={() => {
                                        setDataSource(null);
                                        navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                    }
                                }
                                noPadding scrollable>
                        <SaveButtonsBlock withEditMode
                                          isChanged={isChanged}
                                          hasErrors={hasErrors}
                                          onSave={(() => {
                                              saveAndReset();
                                              navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                          })}
                                          onCancel={(() => {
                                              setDataSource(null);
                                              navigate(`${props.uri}/${node}${modeUriPart}${props.location.search}`);
                                          })} />
                        <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                            <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                items={[{
                                    type: "select",
                                    // TODO: переименовать в section
                                    nullOption: _t('choosePartition'),
                                    name: _t('partitions'),
                                    field: "catalogId",
                                    required: true,
                                    options: flatCatalogsAndEntries && flatCatalogsAndEntries.catalogs &&
                                             flatCatalogsAndEntries.catalogs.filter(c => !c.parentId)
                                                                         .map(c => ({name: c.name, value: c.id}))
                                }]}/>
                        </div>
                    </PanelModal>}
                </>
               }
            </div>
        </div>);
});

export default LicenseView;
import React from 'react'
import {Col} from 'reactstrap'
import classnames from 'classnames'

import LabelWithInfo from './elements/LabelWithInfo'
import NumberInput from './elements/NumberInput'
import Error from './elements/Error'
import SimulationFieldSelector from './elements/SimulationFieldSelector'

function DoubleVal(props) {

    const {style, wide, isNoInput, name, unit, info, infoImage, disabled, data, field, error} = props;

    const onChange = (e) => {
        let value = e.nativeEvent.target.value;
        value = value !== "" ? Number(value) : null;
        props.onChange(data, field, value);
    };

    let value = data && field && (data[field] || data[field] === 0) ? String(data[field]) : "";

    let placeholder = undefined;

    if (isNoInput) {
        placeholder = value;
        value = "";
    } else if (value === "-Infinity") {
        placeholder = "-∞";
    } else if (value === "Infinity") {
        placeholder = "∞";
    }

    return (
        <Col sm={6} className="form-group row g-0" style={style}>
            <Col sm={wide ? 4 : 6}>
                <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
            </Col>
            {/* padding accounts for 2-column gap */}
            <Col sm={wide ? 8 : 6} className="pe-2">
                <SimulationFieldSelector data={data} field={field} disabled={disabled} onChange={props.onChange}>
                    <NumberInput className={classnames("form-control", error && "is-invalid")} value={value}
                                 placeholder={placeholder} disabled={disabled} onChange={onChange}/>
                </SimulationFieldSelector>
                {error &&  <Error id={field} error={error} />}
            </Col>
        </Col>);
}

export default DoubleVal;
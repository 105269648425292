import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Col} from 'reactstrap'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import LabelWithInfo from './elements/LabelWithInfo'
import {JSONText} from 'components/fields/JSONTextVal'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'

import ConstellationSolutionView from '../../appEvent/views/solutionViews/ConstellationSolutionView'
import {TabBlock} from '../blocks/TabBlock'
import withLocalNavigateContext from 'hocs/withLocalNavigateContext'
import withUriParams from '../../hocs/withUriParams'

import entityUtils from 'utils/entityUtils'
import {extractPrototypeParameters} from './utils/simulationTaskUtils'

import staticPrototypes from 'constants/_@_staticPrototypes.json'
import calculateExpression from 'constants/expressions'
import prototypeUtils from 'utils/prototypeUtils'
import {SIMULATION} from 'constants/constants'

export const PrototypeParametersContext = React.createContext(undefined);

const SimulationTaskEditorVal = (props) => {
    const {
        noLabel, wide, name, unit, info, infoImage, disabled, data, field, error,
        prototypeParametersField} = props;
    let value = data[field] ? data[field]: "";

    const {t} = useTranslation();

    const prototypeParameters = extractPrototypeParameters(entityUtils.safeJSONParse(data[prototypeParametersField] || "{}"));

    const [showEditor, setShowEditor] = useState(false);

    const onChange = (value) => {
        props.onChange(data, field, value);
    };

    const onSaveAndClose = (value) => {
        onChange(value);
        setShowEditor(false);
    };

    const additionalButtons = [{
        name: t('components.fields.SimulationTaskEditorVal.visualEditor'),
        onClick: () => setShowEditor(true),
        blockOnError: true
    }];

    const contents =
        <>
            {showEditor && !error &&
                <SimulationTaskEditorModal title={name} initialJsonString={value} onSave={onSaveAndClose} onCancel={() => setShowEditor(false)}
                                           prototypeParameters={prototypeParameters}
                                           disabled={disabled} />}

            <div className="flex-grow">
                <JSONText additionalButtons={additionalButtons} value={value} disabled={disabled}
                          onChange={onChange} error={error} />
            </div>
        </>;

    if (noLabel) {
        return (
            <div className="form-group row g-0 d-flex align-items-start">
                {contents}
            </div>);
    } else {
        return (
            <div className="form-group row g-0">
                <Col sm={wide ? 2 : 3}>
                    <LabelWithInfo name={name} unit={unit} info={info} infoImage={infoImage}/>
                </Col>
                <Col sm={wide ? 10 : 9} className="d-flex align-items-start">
                    {contents}
                </Col>
            </div>);
    }
};


const SimulationTaskEditorModal = compose(
    withLocalNavigateContext,
    withUriParams([
        ['itemType', String, {values: ["simulation", "solution"], defaultValue: "simulation"}]]),
    withGeneratorDataSource)
((props) => {
    const {
        itemType,
        initialJsonString, title, onSave, onCancel, disabled,
        prototypeParameters,
        onChange, onValidate, hasErrors, dataSource, setDataSource, saveAndReset} = props;
    const [isChanged, setIsChanged] = useState(props.isChanged);

    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    const initialJson = useMemo(() => entityUtils.safeJSONParse(initialJsonString), [initialJsonString]);

    useEffect(() =>
        setDataSource(initialJson, undefined),
        [initialJson, setDataSource]);

    useEffect(() =>
        setIsChanged(props.isChanged),
        [props.isChanged]);

    const itemTypeUriPart = (itemType && `/${itemType}`) || '';

    const setDefaultDataSource = () => {
        const fieldsInfo = prototypeUtils.getFieldsInfo(staticPrototypes[SIMULATION], "simulation");
        const defaultDataSource = {};

        for (const key in fieldsInfo) {
            // skipping prototype keys
            if (!fieldsInfo.hasOwnProperty(key)) {
                continue;
            }
            // skipping all fields do not contain defaultValue or defaultExpression
            if ([null, undefined].includes(fieldsInfo[key].defaultValue) && !fieldsInfo[key].defaultExpression) {
                continue;
            }

            //creating hierarchy object
            const parts = key.split('.');
            let current = defaultDataSource;
            for (let i = 0; i < parts.length - 1; i++) {
                const part = parts[i];
                if (!current[part]) {
                    current[part] = {};
                }
                current = current[part];
            }

            //defaultValue case
            if (![null, undefined].includes(fieldsInfo[key].defaultValue)) {
                current[parts[parts.length - 1]] = fieldsInfo[key].defaultValue;
            }

            //defaultExpression case
            else if (fieldsInfo[key].defaultExpression) {
                current[parts[parts.length - 1]] = calculateExpression(fieldsInfo[key].defaultExpression);
            }
        }

        setDataSource(defaultDataSource);
        setIsChanged(true);
    };

    return (
        <PanelModal show title={title} onClose={onCancel} scrollable noPadding>
            {!disabled &&
            <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors}
                              onSave={() => {
                                  let data = saveAndReset();
                                  data = entityUtils.filterFields(data, ['simulation', 'solution']);
                                  onSave(JSON.stringify(data))
                              }}
                              onCancel={onCancel}
                              actions={[{
                                  disabled: false,
                                  name: t('components.fields.SimulationTaskEditorVal.setDefaultDataSource'),
                                  color: 'danger',
                                  allowOnErrors: true,
                                  action: setDefaultDataSource
                              }]}
                              />}

            <TabBlock selectedTabId={itemType} tabs={[
                {name: t('components.fields.SimulationTaskEditorVal.simulation'), id: "simulation", onSelect: () => navigate(`${props.uri}/simulation${props.location.search}`)},
                {name: t('components.fields.SimulationTaskEditorVal.template'), id: "solution", onSelect: () => navigate(`${props.uri}/solution${props.location.search}`)}]} />

            {itemType === "simulation" &&
            <GeneratorBlock className="flex-grow d-flex"
                            data={dataSource}
                            field="simulation"
                            items={staticPrototypes[SIMULATION]}
                            collectionsDict={{spacecrafts: [], orbits: []}}
                            onChange={onChange}
                            onValidate={onValidate}
                            disabled={disabled}/>}

            {itemType === "solution" &&
            <PrototypeParametersContext.Provider value={prototypeParameters}>
                <ConstellationSolutionView dataSource={dataSource}
                                           field="solution"
                                           scenario={{parameters: {simulation: {"max-spacecrafts": 10, "max-stations": 10}}}}
                                           onChange={onChange}
                                           showSolution
                                           disabled={disabled}
                                           uri={`${props.uri}${itemTypeUriPart}`} uriParams={props.uriParams} location={props.location} />
            </PrototypeParametersContext.Provider>}

        </PanelModal>);
});

export default SimulationTaskEditorVal;
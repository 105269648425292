import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import dateUtils from 'utils/dateUtils'


const useSelectVersion = (catalogEntryVersions, selectedCatalogEntryVersion, createVersionUrl) => {
    const {t, i18n} = useTranslation();
    const getVersionHeaderName = useCallback((vh) => (
        (!vh.publishedAt && t('appCatalog.hooks.useSelectVersionDropdownAction.notPublishedVersion')) ||
        t('appCatalog.hooks.useSelectVersionDropdownAction.publishedVersion', {
            number: catalogEntryVersions.indexOf(vh) + 1,
            name: vh.name || dateUtils.dateToLocaleDateString(vh.publishedAt, i18n)})
        ),
        [t, catalogEntryVersions]);

    const selectedVersionName = useMemo(() => selectedCatalogEntryVersion && getVersionHeaderName(selectedCatalogEntryVersion),
        [getVersionHeaderName, selectedCatalogEntryVersion]);

    const selectVersionDropdownAction = useMemo(() => selectedCatalogEntryVersion && {
        name: selectedVersionName,
        selectItems: catalogEntryVersions.map(vh => ({
            id: vh.id,
            name: getVersionHeaderName(vh),
            to: selectedCatalogEntryVersion.id !== vh.id &&
                createVersionUrl(vh.id) })) },
        [selectedCatalogEntryVersion, catalogEntryVersions, getVersionHeaderName, createVersionUrl]);

    return {selectVersionDropdownAction, selectedVersionName};
}

export default useSelectVersion;
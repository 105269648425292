import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import {Button} from "reactstrap";
import TableBlockWithSort from 'components/blocks/TableBlockWithSort'

import {queryLicenseEvents, queryEventUsersData} from '../data/adminQueries'
import {deleteCompetitionSession} from '../data/adminMutations'
import {breadcrumbsStore} from 'appBase/TopNav'
import dateUtils from 'utils/dateUtils'
import {ADMIN_USERS_PROGRESS_VIEW} from 'constants/constants'


const UsersProgressView = compose(
    withUriParams([['eventId', Number, {optional: true}],
                   ['userId', Number, {optional: true}]]),
    queryLicenseEvents,
    queryEventUsersData,
    deleteCompetitionSession,
    withInitialDataLoadWaiting(['licenseEvents'])
)((props) => {
    const {eventId, userId, licenseEvents, deleteCompetitionSession, executeWithConfirmation} = props;

    const event = eventId && licenseEvents.find(e => e.id === eventId);

    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    // links
    const containerId = ADMIN_USERS_PROGRESS_VIEW;
    breadcrumbsStore.register(containerId);

    const links = [{
        name: t('appAdmin.views.UsersProgressView.usersProgress'),
        to: `${props.uri}${props.location.search}`
    }];
    if (event) {
        links.push({
            name: event.name,
            to: `${props.uri}/${event.id}${props.location.search}`
        });
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = true;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    const headers = [
        t('appAdmin.views.UsersProgressView.name'),
        t('appAdmin.views.UsersProgressView.email'),
        t('appAdmin.views.UsersProgressView.userSolutionsNumber')];
    if (event?.isCompetitionMode) {
        headers.push(t('appAdmin.views.UsersProgressView.competitionSessionStartedAt'));
    }
    if (event?.isOntiMode) {
        headers.push(t('appAdmin.views.UsersProgressView.hasSameTalentId'));
        headers.push(t('appAdmin.views.UsersProgressView.hasOntiProfileAccess'));
    }

    const eventUsersDataRows =
        event && props.eventUsersData && props.eventUsersData
            .filter(u => event.isCompetitionMode
                ? u.eventUserInfo?.competitionSessionStartedAt
                : u.userSolutionsNumber)
            .map(u => {
                const isError = event.isOntiMode &&
                    (!u.hasSameTalentId || !u.hasOntiProfileAccess);
                const fields = [
                    u.name,
                    u.email,
                    u.userSolutionsNumber];
                if (event.isCompetitionMode) {
                    fields.push(dateUtils.dateToLocaleDateTimeString(u.eventUserInfo?.competitionSessionStartedAt, i18n));
                }
                if (event.isOntiMode) {
                    fields.push(u.hasSameTalentId ? t("general.capitalYes") : t("general.capitalNo"));
                    fields.push(u.hasOntiProfileAccess ? t("general.capitalYes") : t("general.capitalNo"));
                }
                return {
                    id: u.id,
                    fields,
                    actions: {
                        "click":  () => navigate(`${props.uri}/${eventId}/${u.id}${props.location.search}`)
                    },
                    isError};
            });

    const selectedIndex = userId && eventUsersDataRows?.indexOf(eventUsersDataRows?.find(r => r.id === userId));

    const eventUserData = userId && props.eventUsersData?.find(u => u.id === userId);

    return (
        <div className={"flex-grow d-flex flex-row scroll-parent"}>
            <div className="w-50 d-flex flex-column scroll">

                <select style={{width: "100%"}} value={eventId || ""} onChange={(e) => navigate(`${props.uri}/${e.nativeEvent.target.value}${props.location.search}`)}>
                    <option value="">{t('appAdmin.views.UsersProgressView.allEvents')}</option>
                    {licenseEvents.map(e => (<option key={e.id} value={e.id}>{e.name}</option>))}
                </select>

                {eventUsersDataRows &&
                <TableBlockWithSort headers={headers}
                                    rows={eventUsersDataRows}
                                    saveable
                                    printable
                                    sortIndex={3}
                                    selectedIndex={selectedIndex}/>}
            </div>

            {eventUserData &&
            <div className="w-50 d-flex flex-column scroll">

                <h5 className="m-3">
                    {eventUserData.name} ({eventUserData.email})
                </h5>

                {event.isCompetitionMode && eventUserData.eventUserInfo &&
                <>
                    <h5 className="text-center mt-3">
                        {t('appAdmin.views.UsersProgressView.deleteCompetitionSessionDescription')}
                    </h5>

                    <div className="text-center mt-2">
                        <Button className="mt-2"
                                onClick={() => executeWithConfirmation(() => {
                                    deleteCompetitionSession(eventUserData.eventUserInfo?.id);
                                    navigate(`${props.uri}/${eventId}${props.location.search}`);
                                })}
                                color="danger">
                            {t('appAdmin.views.UsersProgressView.deleteCompetitionSession')}
                        </Button>
                    </div>
                </>}

            </div>}

        </div>);
});

export default UsersProgressView;
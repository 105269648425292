import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import classnames from 'classnames'

import CollectionItemBlock from './CollectionItemBlock'
import LabelWithInfo from 'components/fields/elements/LabelWithInfo'
import Error from 'components/fields/elements/Error'

const CollectionBlock = (props) => {
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const {t} = useTranslation();

    const {name, collection, itemIdField, collectionItemComponents, className, children,
        disabled, error, data, field, fieldsInfo, onChange, onValidate, collectionsDict, editLanguage,
        namePattern, pendingChildRecursiveReplaces} = props;
    let validations = props.validations || {};

    const toggle = (i) => {
        setExpandedIndex(i !== expandedIndex ? i : -1);
    }

    const onAction = (action, index) => {
        switch (action) {
            case "add":
                if (!Array.isArray(data[field])) {
                    data[field] = [];
                }
                // min is [null, undefined, 0] or length >= min
                const numberOfElementsToAdd =
                    (!validations.min || data[field].length >= validations.min)
                        ? 1
                        : validations.min - data[field].length;

                for(let i = 0; i < numberOfElementsToAdd; i++) {
                    if (itemIdField === '$index') {
                        data[field].push({});
                    } else {
                        data[field].push({[itemIdField]: 1 + Math.max(0, ...data[field].map(e => e[itemIdField]))});
                    }
                }
                setExpandedIndex(data[field].length - 1);
                break;

            case "delete":
                data[field].splice(index, 1);
                if (index === expandedIndex) {
                    setExpandedIndex(-1);
                }
                break;

            default:
                break;
        }

        onChange(data, field, [...data[field]]);
    }


    // render

    let itemHeaders = [];
    if (collection && collectionsDict[collection]) {
        itemHeaders = collectionsDict[collection].map(colItem => {
            if (itemIdField === '$index') {
                return {name: colItem.name};
            } else {
                return {name: colItem.name, [itemIdField]: colItem.itemIdField}
            }
        });
    } else {
        itemHeaders = ((data && field && Array.isArray(data[field]) && data[field]) || [])
            .map((item, i) => {
                const elementNumber = i + 1;
                const elementName =
                    namePattern
                        ? namePattern.replace('*', elementNumber)
                        : t('components.fieldBlocks.CollectionBlock.element') + elementNumber;
                if (itemIdField === '$index') {
                    return {name: elementName};
                } else {
                    return {
                        name: elementName,
                        [itemIdField]: item[itemIdField] || elementNumber
                    };
                }
            });
    }


    let components = [];
    // collecting components

    if (children) {
        components = components.concat(children);
    }

    const fieldsToUpdate = ['field', 'choiceField', 'presetField'];

    itemHeaders.forEach((header, i, arr) => {
        const itemId = itemIdField === '$index' ? i : header[itemIdField];
        const itemBlockChildren = React.Children.map(collectionItemComponents, (c, j) => React.cloneElement(c));
        // creating props for every CollectionItemBlock
        const itemBlockProps = {
            name: header.name,
            field: props.childField,
            className: className,
            // min is [null, undefined, 0] or length > min
            removable: !collection && (!validations.min || (arr.length > validations.min)),
            onDelete: () => onAction("delete", i)
        };
        let el = React.createElement(
            CollectionItemBlock,
            itemBlockProps,
            itemBlockChildren);
        // recursively updating $itemId
        const recursiveCloneWithUpdatedField = (el, itemId) => {
            const updatedProps = {};
            fieldsToUpdate
                .filter(f => el.props[f])
                .forEach(f => updatedProps[f] = el.props[f].replace('$itemId', String(itemId)));
            if (el.type === CollectionBlock) {
                updatedProps.pendingChildRecursiveReplaces = [...(pendingChildRecursiveReplaces || []), itemId];
            }
            return React.cloneElement(
                el, updatedProps,
                React.Children.map(
                    el.props.children,
                    (child, i) => typeof(child.type) !== 'string' ? recursiveCloneWithUpdatedField(child, itemId) : child));
        };
        (pendingChildRecursiveReplaces || []).forEach(itemId => {
            el = recursiveCloneWithUpdatedField(el, itemId);
        });
        el = recursiveCloneWithUpdatedField(el, itemId);
        components.push(el);
    });


    // cloning components with properties

    components =
        React.Children.map(
            components,
            (child, i) => React.cloneElement(
                child,
                {
                    disabled: child.props.disabled || disabled,
                    data: data,
                    fieldsInfo: fieldsInfo,
                    onChange: onChange,
                    onValidate: onValidate,
                    collectionsDict: collectionsDict,
                    editLanguage: editLanguage,
                    index: i,
                    expand: i === expandedIndex,
                    onToggle: toggle
                }));


    if (!collection && validations.max !== undefined && validations.max !== null &&
        (components.length > validations.max))
    {
        components.length = validations.max;
    }


    return (
        // <React.Fragment>
        <div className={classnames("form-group", error && "is-invalid")}>
            {name &&
            <LabelWithInfo className="mb-1" name={name} />}
            {components}
            {/*max is [undefined, null, 0] or length < max*/}
            {!collection && !disabled && (!validations.max || (components.length < validations.max)) &&
                <div className="controlelement d-flex mt-2">
                    <div className="flex-grow">
                        <span className="float-end" onClick={() => onAction("add")}>{t('components.fieldBlocks.CollectionBlock.addNew')}</span>
                    </div>
                </div>}
            {error &&  <Error id={field} error={error}/>}
        </div>
        // </React.Fragment>
    );
}

export default CollectionBlock;
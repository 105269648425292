const staticSpacecraftFieldGroups = [
    {group:"gen", name: "#constants.staticReferenceData.staticSpacecraftFieldGroups.gen#"},
    {group:"lin", name: "#constants.staticReferenceData.staticSpacecraftFieldGroups.lin#"},
    {group:"ang", name: "#constants.staticReferenceData.staticSpacecraftFieldGroups.ang#"},
    {group:"ele", name: "#constants.staticReferenceData.staticSpacecraftFieldGroups.ele#"},
    {group:"the", name: "#constants.staticReferenceData.staticSpacecraftFieldGroups.the#"}
];

const staticEnvironmentResultFields = [
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.sunX#",
      field : "environment.sun.position.index:0",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    },
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.sunY#",
      field : "environment.sun.position.index:1",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    },
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.sunZ#",
      field : "environment.sun.position.index:2",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    },
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.moonX#",
      field: "environment.luna.position.index:0",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    },
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.moonY#",
      field : "environment.luna.position.index:1",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    },
    {
      name: "#constants.staticReferenceData.staticEnvironmentResultFields.moonZ#",
      field : "environment.luna.position.index:2",
      unit: "#constants.staticReferenceData.staticEnvironmentResultFields.unit#"
    }
];

const staticSpacecraftResultFields = [
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.mass#",
        field: "construction.mass",
        group: "gen",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.massUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.latitude#",
        field: "motion.position.latitude",
        group: "gen",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.latitudeUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.longtitude#",
        field: "motion.position.longitude",
        group: "gen",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.longtitudeUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.altitude#",
        field: "motion.position.altitude",
        group: "gen",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.altitudeUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.temperature#",
        field: "thermodynamics.temperature",
        group: "the",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.temperatureUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.power-dissipation#",
        field: "thermodynamics.power-dissipation",
        group: "the",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.power-dissipationUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.positionX#",
        field: "motion.position.index:0",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.positionXUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.positionY#",
        field: "motion.position.index:1",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.positionYUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.positionZ#",
        field: "motion.position.index:2",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.positionZUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocity#",
        field: "motion.linear-velocity-magnitude",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityX#",
        field: "motion.linear-velocity.index:0",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityXUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityY#",
        field: "motion.linear-velocity.index:1",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityYUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityZ#",
        field: "motion.linear-velocity.index:2",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-velocityZUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-acceleration#",
        field: "motion.linear-acceleration-magnitude",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationX#",
        field: "motion.linear-acceleration.index:0",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationXUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationY#",
        field: "motion.linear-acceleration.index:1",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationYUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationZ#",
        field: "motion.linear-acceleration.index:2",
        group: "lin",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.linear-accelerationZUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocity#",
        field: "motion.angular-velocity-magnitude",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocitySourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityX#",
        field: "motion.angular-velocity.index:0",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityXUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityXSourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityY#",
        field: "motion.angular-velocity.index:1",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityYUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityYSourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityZ#",
        field: "motion.angular-velocity.index:2",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityZUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-velocityZSourceUnit#"
    },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-acceleration#",
        field: "motion.angular-acceleration-magnitude",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationSourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationX#",
        field: "motion.angular-acceleration.index:0",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationXUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationXSourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationY#",
        field: "motion.angular-acceleration.index:1",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationYUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationYSourceUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationZ#",
        field: "motion.angular-acceleration.index:2",
        group: "ang",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationZUnit#",
        sourceUnit: "#constants.staticReferenceData.staticSpacecraftResultFields.angular-accelerationZSourceUnit#"
    },

    { name: "#constants.staticReferenceData.staticSpacecraftResultFields.quaternionW#", field: "motion.orientation.index:0", group: "ang" },
    { name: "#constants.staticReferenceData.staticSpacecraftResultFields.quaternionX#", field: "motion.orientation.index:1", group: "ang" },
    { name: "#constants.staticReferenceData.staticSpacecraftResultFields.quaternionY#", field: "motion.orientation.index:2", group: "ang" },
    { name: "#constants.staticReferenceData.staticSpacecraftResultFields.quaternionZ#", field: "motion.orientation.index:3", group: "ang" },

    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.accumulator-charge#",
        field: "electrics.accumulator-charge",
        group: "ele",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.accumulator-chargeUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.accumulator-capacity#",
        field: "electrics.accumulator-capacity",
        group: "ele",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.accumulator-capacityUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.power-production#",
        field: "electrics.power-production",
        group: "ele",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.power-productionUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.power-consumption#",
        field: "electrics.power-consumption",
        group: "ele",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.power-consumptionUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticSpacecraftResultFields.power-balance#",
        field: "electrics.power-balance",
        group: "ele",
        unit: "#constants.staticReferenceData.staticSpacecraftResultFields.power-balanceUnit#"
    }
];

const staticDevicesResultFields = [
    {
        name: "#constants.staticReferenceData.staticDevicesResultFields.enabled#",
        field: "enabled"
    },
    {
        name: "#constants.staticReferenceData.staticDevicesResultFields.failed#",
        field: "failed"
    },
    {
        name: "#constants.staticReferenceData.staticDevicesResultFields.power-consumption#",
        field: "power-consumption",
        unit: "#constants.staticReferenceData.staticDevicesResultFields.power-consumptionUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticDevicesResultFields.power-dissipation#",
        field: "power-dissipation",
        unit: "#constants.staticReferenceData.staticDevicesResultFields.power-dissipationUnit#"
    }
];

const staticFunctionsResultFields = [
    {
        name: "#constants.staticReferenceData.staticFunctionsResultFields.enabled#",
        field: "enabled"
    },
    {
        name: "#constants.staticReferenceData.staticFunctionsResultFields.failed#",
        field: "failed"
    },
    {
        name: "#constants.staticReferenceData.staticFunctionsResultFields.power-consumption#",
        field: "power-consumption",
        unit: "#constants.staticReferenceData.staticFunctionsResultFields.power-consumptionUnit#"
    },
    {
        name: "#constants.staticReferenceData.staticFunctionsResultFields.power-dissipation#",
        field: "power-dissipation",
        unit: "#constants.staticReferenceData.staticFunctionsResultFields.power-dissipationUnit#"
    },

    {
        type: "accumulator",
        name: "#constants.staticReferenceData.staticFunctionsResultFields.capacity#",
        field: "capacity",
        unit: "#constants.staticReferenceData.staticFunctionsResultFields.capacityUnit#"
    },
    {
        type: "accumulator",
        name: "#constants.staticReferenceData.staticFunctionsResultFields.charge#",
        field: "charge",
        unit: "#constants.staticReferenceData.staticFunctionsResultFields.chargeUnit#"
    },
    {
        type: "solar-panel",
        name: "#constants.staticReferenceData.staticFunctionsResultFields.efficiency#",
        field: "efficiency",
        unit: "#constants.staticReferenceData.staticFunctionsResultFields.efficiencyUnit#"
    }
];

export const staticSpacecraftFunctionsTypes = [
    {
        type: "accumulator",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.accumulator#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.accumulatorPlural#"
    },
    {
        type: "solar-panel",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.solar-panel#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.solar-panelPlural#"
    },
    {
        type: "heater",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.heater#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.heaterPlural#"
    },
    {
        type: "antenna",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.antenna#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.antennaPlural#"
    },
    {
        type: "receiver",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.receiver#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.receiverPlural#"
    },
    {
        type: "transmitter",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.transmitter#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.transmitterPlural#"
    },
    {
        type: "reaction-wheel",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.reaction-wheel#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.reaction-wheelPlural#"
    },
    {
        type: "thruster",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.thruster#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.thrusterPlural#"
    },
    {
        type: "angular-velocity-sensor",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.angular-velocity-sensor#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.angular-velocity-sensorPlural#"
    },
    {
        type: "producer",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.producer#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.producerPlural#"
    },
    {
        type: "consumer",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.consumer#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.consumerPlural#"
    },
    {
        type: "propellant-tank",
        name: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.propellant-tank#",
        plural: "#constants.staticReferenceData.staticSpacecraftFunctionsTypes.propellant-tankPlural#"
    }
];

export const staticStationFunctionsTypes = [
    {
        type: "antenna",
        name: "#constants.staticReferenceData.staticStationFunctionsTypes.antenna#",
        plural: "#constants.staticReferenceData.staticStationFunctionsTypes.antennaPlural#"
    },
    {
        type: "receiver",
        name: "#constants.staticReferenceData.staticStationFunctionsTypes.receiver#",
        plural: "#constants.staticReferenceData.staticStationFunctionsTypes.receiverPlural#"
    },
    {
        type: "transmitter",
        name: "#constants.staticReferenceData.staticStationFunctionsTypes.transmitter#",
        plural: "#constants.staticReferenceData.staticStationFunctionsTypes.transmitterPlural#"
    },
    {
        type: "producer",
        name: "#constants.staticReferenceData.staticStationFunctionsTypes.producer#",
        plural: "#constants.staticReferenceData.staticStationFunctionsTypes.producerPlural#"
    },
    {
        type: "consumer",
        name: "#constants.staticReferenceData.staticStationFunctionsTypes.consumer#",
        plural: "#constants.staticReferenceData.staticStationFunctionsTypes.consumerPlural#"
    }
];

export const staticAllFunctionsTypes = staticSpacecraftFunctionsTypes;

export const staticResultsHierarchy = [
    {
        field: "",
        name: "#constants.staticReferenceData.staticResultsHierarchy.environment#",
        fields: staticEnvironmentResultFields
    },
    {
        field: "spacecrafts",
        collection: "spacecrafts",
        index: "id",
        name: "#constants.staticReferenceData.staticResultsHierarchy.spacecraft#",
        plural: "#constants.staticReferenceData.staticResultsHierarchy.spacecraftPlural#",
        items: [
            {
                field: "",
                name: "#constants.staticReferenceData.staticResultsHierarchy.spacecraft#",
                fields: staticSpacecraftResultFields,
                groups: staticSpacecraftFieldGroups
            },
            {
                field: "devices",
                resultsField: "construction.devices",
                index: "index",
                nameField: "$name",
                name: "#constants.staticReferenceData.staticResultsHierarchy.device#",
                plural: "#constants.staticReferenceData.staticResultsHierarchy.devicePlural#",
                items: [
                    {
                        field: "",
                        name: "#constants.staticReferenceData.staticResultsHierarchy.device#",
                        fields: staticDevicesResultFields
                    },
                    {
                        field: "functions",
                        index: "index",
                        nameField: "$name",
                        name: "#constants.staticReferenceData.staticResultsHierarchy.function#",
                        plural: "#constants.staticReferenceData.staticResultsHierarchy.functionPlural#",
                        types: staticSpacecraftFunctionsTypes,
                        typeFieldType: "typeType",
                        items: [
                            {
                                field: "",
                                name: "#constants.staticReferenceData.staticResultsHierarchy.function#",
                                fields: staticFunctionsResultFields,
                                typed: true
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        field: "stations",
        collection: "stations",
        index: "id",
        name: "#constants.staticReferenceData.staticResultsHierarchy.station#",
        plural: "#constants.staticReferenceData.staticResultsHierarchy.stationPlural#",
        items: [
            {
                field: "",
                name: "#constants.staticReferenceData.staticResultsHierarchy.station#",
                fields: []
            },
            {
                field: "devices",
                resultsField: "construction.devices",
                index: "index",
                nameField: "$name",
                name: "#constants.staticReferenceData.staticResultsHierarchy.device#",
                plural: "#constants.staticReferenceData.staticResultsHierarchy.devicePlural#",
                items: [
                    {
                        field: "",
                        name: "#constants.staticReferenceData.staticResultsHierarchy.device#",
                        fields: staticDevicesResultFields
                    },
                    {
                        field: "functions",
                        index: "index",
                        nameField: "$name",
                        name: "#constants.staticReferenceData.staticResultsHierarchy.function#",
                        plural: "#constants.staticReferenceData.staticResultsHierarchy.functionPlural#",
                        types: staticStationFunctionsTypes,
                        typeFieldType: "typeType",
                        items: [
                            {
                                field: "",
                                name: "#constants.staticReferenceData.staticResultsHierarchy.function#",
                                fields: staticFunctionsResultFields,
                                typed: true
                            }
                        ]
                    }
                ]
            }
        ]
    }
];


export const staticFunctionsAggregateFields = [
    {
        type: "accumulator",
        field: "functions.type.accumulator.charge",
        name: "#constants.staticReferenceData.staticFunctionsAggregateFields.charge#",
        unit: "#constants.staticReferenceData.staticFunctionsAggregateFields.chargeUnit#"
    },
    {
        type: "accumulator",
        field: "functions.type.accumulator.capacity",
        name: "#constants.staticReferenceData.staticFunctionsAggregateFields.capacity#",
        unit: "#constants.staticReferenceData.staticFunctionsAggregateFields.capacityUnit#"
    },
    {
        type: "solar-panel",
        field: "functions.type.solar-panel.area",
        name: "#constants.staticReferenceData.staticFunctionsAggregateFields.area#",
        unit: "#constants.staticReferenceData.staticFunctionsAggregateFields.areaUnit#"
    }
];

export const staticPrecisionByUnit = {
    "#constants.staticReferenceData.staticPrecisionByUnit.kilo#": 3,
    "#constants.staticReferenceData.staticPrecisionByUnit.meter#": 2,
    "#constants.staticReferenceData.staticPrecisionByUnit.quadMeter#": 4,
    "#constants.staticReferenceData.staticPrecisionByUnit.cubeMeter#": 6,
    "#constants.staticReferenceData.staticPrecisionByUnit.joule#": 0,
    "#constants.staticReferenceData.staticPrecisionByUnit.watt#": 3     // miliwatts
};

export const staticConversions = [
    {
        unit: "#constants.staticReferenceData.staticConversions.kilometer#",
        sourceUnit: "#constants.staticReferenceData.staticConversions.meter#",
        multiplier: 1000
    },
    {
        unit: "#constants.staticReferenceData.staticConversions.grad#",
        sourceUnit: "#constants.staticReferenceData.staticConversions.rad#",
        multiplier: 57.2957796
    },
    {
        unit: "#constants.staticReferenceData.staticConversions.gradSec#",
        sourceUnit: "#constants.staticReferenceData.staticConversions.radSec#",
        multiplier: 57.2957796
    },
    {
        unit: "#constants.staticReferenceData.staticConversions.gradSecQuad#",
        sourceUnit: "#constants.staticReferenceData.staticConversions.radSecQuad#",
        multiplier: 57.2957796
    }
];


export const staticAllReferenceData = {
    staticSpacecraftFunctionsTypes: staticSpacecraftFunctionsTypes,
    staticStationFunctionsTypes: staticStationFunctionsTypes,
    staticAllFunctionsTypes: staticAllFunctionsTypes
};
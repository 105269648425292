import React, {useMemo, useState, useEffect} from 'react'
import {Alert, Button} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import useSmartNavigate from 'hooks/useSmartNavigate'
import useSimpleGeneratorDataSource from 'hooks/useSimpleGeneratorDataSource'
import useDebug from 'hooks/useDebug'

import {TabBlock} from 'components/blocks/TabBlock'
import CheckBox from 'components/elements/CheckBox'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import LicenseAndEventStatisticsView from './components/LicenseAndEventStatisticsView'

import withLocationStateParam from 'hocs/withLocationStateParam'
import withUriParams from 'hocs/withUriParams'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'

import SimpleSolutionView from 'appEvent/views/solutionViews/SimpleSolutionView'
import ConstellationSolutionView from 'appEvent/views/solutionViews/ConstellationSolutionView'
import SolutionsView from 'appEvent/views/SolutionsView'
import {breadcrumbsStore} from 'appBase/TopNav'

import {queryLicenseScenario} from '../data/adminQueries'
import {
    updateScenario,
    recalcSolutions,
    deleteSolutions,
    deleteSimulationsAndResults
} from '../data/adminMutations'
import {deleteScenarioScenarioUserCustomization} from 'appEvent/data/eventMutations'
import {prepareScenarioScenarioObjectForEdit, prepareScenarioScenarioEditObjectForSave,
        prepareEditScenarioScenarioObjectForSolutionAndResultsView} from '../data/adminScenarioObjects'
import entityUtils from 'utils/entityUtils'
import prototypeUtils from 'utils/prototypeUtils'
import {parseBackendMessage, translateObjOrArray} from 'utils/translationUtils'
import {SIMULATION, FULL_SCREEN, ACCESS_ROLE_EDITOR_TESTER, ADMIN_SCENARIO_VIEW} from 'constants/constants'
import adminPrototypes from '../data/adminStaticPrototypes'
import staticPrototypes from 'constants/_@_staticPrototypes'
import sampleSolutionResults from '../data/sampleSolutionResults'
import useGeneratorDataSource from 'hooks/useGeneratorDataSource'
import MarkdownVal from 'components/fields/MarkdownVal'
import Block from 'components/fieldBlocks/Block'
import {useInterval} from 'usehooks-ts'


const LicenseScenarioView = compose(
    withUriParams([
        ['mode', String, {optional: true, values: ['edit']}],
        ['itemType', String, {optional: true, values: ['scenario', 'descriptions', 'initialParameters', 'results', 'solutions']}],
        ['itemSubType', String, {optional: true, values: ["scenario", "simulation"]}]]),
    queryLicenseScenario,
    updateScenario,
    deleteScenarioScenarioUserCustomization,
    recalcSolutions,
    deleteSimulationsAndResults,
    deleteSolutions,
    withLocationStateParam(FULL_SCREEN, 'isFullScreen'),
    withInitialDataLoadWaiting(['licenseScenario'])
)(props => {
    const {mode, itemType, isFullScreen, licenseId, licenseScenario,
        updateScenario, deleteScenarioScenarioUserCustomization, recalcSolutions, deleteSimulationsAndResults, deleteSolutions,
        user,
        // features
        disabled, enableCatalogPrototypes, enableDeleteStudentSolutions, disableSolutions,
        enableBackLink, showBackLink} = props;
    let {itemSubType} = props;
    const [error, setError] = useState(null);
    const [isDeleteSimulationsAndResultsMutationStarted, setIsDeleteSimulationsAndResultsMutationStarted] = useState(false);

    const {dataSource, setDataSource, saveAndReset, onChange, onValidate, isChanged, hasErrors} = useGeneratorDataSource(props);

    const {t} = useTranslation();

    // links

    const containerId = ADMIN_SCENARIO_VIEW;
    breadcrumbsStore.register(containerId);

    useEffect(() => {
        const breadcrumbs = [];
        breadcrumbs.push({
            name:
                licenseScenario.type === 'page' ? t('appAdmin.views.LicenseScenarioView.page') :
                licenseScenario.type === 'test' ? t('appAdmin.views.LicenseScenarioView.test') :
                licenseScenario.type === 'generative_test' ? t('appAdmin.views.LicenseScenarioView.generativeTest') :
                licenseScenario.type === 'sim' ? t('appAdmin.views.LicenseScenarioView.simulation') :
                licenseScenario.type === 'constellation' ? t('appAdmin.views.LicenseScenarioView.constellation') :
                licenseScenario.type === 'prog' ? t('appAdmin.views.LicenseScenarioView.program') :
                    t('appAdmin.views.LicenseScenarioView.scenario'),
            to: `${props.uri}/scenario`,
            back: enableBackLink && 1 });
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminalBreadcrumbs);
    }, [licenseScenario, itemType, props.uri, props.uriParams, enableBackLink, containerId, t]);

    // include initial params
    const [includeInitialParams, setIncludeInitialParams] = useState(null);
    // effect to reset them
    useEffect(
        () => setIncludeInitialParams(licenseScenario.initialParameters && licenseScenario.initialParameters !== '{}'),
        [licenseScenario]);

    const isScenarioCustomizationRunning =
        licenseScenario.scenarioUserCustomizationStatus &&
        !['finished', 'failed'].includes(licenseScenario.scenarioUserCustomizationStatus) ;
    const isScenarioCustomizationFailed =
        licenseScenario.scenarioUserCustomizationStatus &&
        ['failed'].includes(licenseScenario.scenarioUserCustomizationStatus) ;

    useInterval(() => {
        props.refetch.licenseScenario();
    }, isScenarioCustomizationRunning ? 1000 : null);

    // stable source for data source. does not change
    const scenarioEditObject = useMemo(() =>
        licenseScenario.type !== 'page' ?
            prepareScenarioScenarioObjectForEdit(licenseScenario) :
            licenseScenario,
        [licenseScenario]);

    // set data source
    // we need single dataSource because it manages actionLock
    useEffect(() => {
        if (!scenarioEditObject) {
            return;
        }
        setDataSource(
            scenarioEditObject,
            (data) => {
                data = data.type !== 'page'
                    ? prepareScenarioScenarioEditObjectForSave(data, includeInitialParams, t)
                    : data;
                updateScenario(entityUtils.filterFields(
                    data, [
                        'id',
                        'nameMl',
                        'type',
                        'imageUrl',
                        'descriptionMl',
                        'descriptionShortMl',
                        'descriptionParametersMl',
                        'descriptionScoreMl',
                        'startsAt',
                        'endsAt',
                        'initialParameters',
                        'prototypeParameters',
                        'prototypeResults',
                        'simulationTemplate',
                        'scoringRules',
                        'isEvaluation',
                        'parameters',
                        'isIndividual',
                        'teamMaxSubmitsDifference',
                        'show3dResults',
                        'resultsDecimation',
                        'maxSolutionsNumber']))
                        .then(({data: {updateScenario: {error}}}) => setError(error ? parseBackendMessage(error, t): null));
        });
    }, [scenarioEditObject, dataSource, includeInitialParams, setDataSource, updateScenario, t]);

    // current scenario object based on current scenarioEditObject
    // used to render current solution and to enter initial parameters
    //  - prototype parameters
    //  - prototypeResults
    //  - solutions (just convenient to use this variable)
    //// -- does not work? it reacts on tab change (itemSubMode change)
    let currentScenarioForSolutionAndResultsView = useMemo(() =>
        itemType !== 'scenario' && (
            dataSource && dataSource.type !== 'page'
                ? prepareEditScenarioScenarioObjectForSolutionAndResultsView(dataSource, t)
                : dataSource),
        [itemType, dataSource, t]);

    // sample solution dataSource, used to view initial values only. Resets when dataSource updates, changes or resets
    const sampleSolution = useMemo(() => ({
            parameters: mode === 'edit' && !includeInitialParams
                ? {}
                : JSON.parse(JSON.stringify((dataSource && dataSource.initialParameters) || {}))
        }),
        [mode, includeInitialParams, dataSource]);
    const [sampleSolutionParametersDataSource, onSampleSolutionParametersDataSourceChange] =
        useSimpleGeneratorDataSource(sampleSolution.parameters);

    const navigate = useSmartNavigate();

    useDebug("LicenseScenarioView", {...props,
        "dataSource.initialParameters": dataSource && dataSource.scenario && dataSource.scenario.initialParameters,
        "currentScenarioForSolutionAndResultsView": currentScenarioForSolutionAndResultsView,
        "currentScenarioForSolutionAndResultsView.initialParameters": currentScenarioForSolutionAndResultsView && currentScenarioForSolutionAndResultsView.initialParameters
    });

    //prototypes

    const catalogCompetitionScenarioScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licenseScenarioScenario,
        ['name', 'startsAt', 'endsAt', 'isIndividual', 'teamMaxSubmitsDifference']);

    const catalogCompetitionPageScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licensePageScenario,
        ['name', 'startsAt', 'endsAt']);

    const catalogCourseTestScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licenseScenarioScenario,
        ['startsAt', 'endsAt', 'imageUrl', 'descriptionShortMl', 'isIndividual', 'teamMaxSubmitsDifference']);
    catalogCourseTestScenarioProto.find(x => x.field === 'type').stuff = [{"value": "test", "name": t('appAdmin.views.LicenseScenarioView.test')}];

    const catalogCoursePageScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licensePageScenario,
        ['startsAt', 'endsAt', 'imageUrl', 'descriptionShortMl']);

    const courseTestScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licenseScenarioScenario,
        ['startsAt', 'endsAt', 'imageUrl', 'descriptionShortMl']);
    courseTestScenarioProto.find(x => x.field === 'type').stuff = [{"value": "test", "name": t('appAdmin.views.LicenseScenarioView.test')}];

    const courseGenerativeTestScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licenseScenarioScenario,
        ['startsAt', 'endsAt', 'imageUrl', 'descriptionShortMl']);
    courseGenerativeTestScenarioProto.find(x => x.field === 'type').stuff = [{"value": "generative_test", "name": t('appAdmin.views.LicenseScenarioView.generativeTest')}];

    const coursePageScenarioProto = prototypeUtils.discardFieldsFromPrototype(
        adminPrototypes.licensePageScenario,
        ['startsAt', 'endsAt', 'imageUrl', 'descriptionShortMl']);

    // some calculations

    const currentScenarioType = dataSource && (dataSource.type !== 'page' ? dataSource.type && dataSource.type._type : dataSource.type);

    const modeUriPart = (mode && `/${mode}`) || '';
    const itemTypeUriPart = (itemType && `/${itemType}`) || '';
    const itemSubTypeUriPart = (itemSubType && `/${itemSubType}`) || '';

    // subnavigation - all forward navigations should be local, without navigate!

    if (itemType === 'scenario' && !itemSubType && currentScenarioType === 'constellation') {
        itemSubType = 'scenario';
    } else if (itemSubType && currentScenarioType !== 'constellation') {
        itemSubType = '';
    }

    // rendering

    const {
        eventType, editConfirmationMessage, executeWithLock, executeWithConfirmation} = props;

    const navigateWithLock = (to) => executeWithLock(() => navigate(to));

    const proto =
        enableCatalogPrototypes
            ? (eventType === 'competition'
                ? (currentScenarioType !== 'page'
                    ? catalogCompetitionScenarioScenarioProto
                    : catalogCompetitionPageScenarioProto)
                : (currentScenarioType === 'test'
                    ? catalogCourseTestScenarioProto
                    : catalogCoursePageScenarioProto))
            : (eventType === 'competition'
                ? (currentScenarioType !== 'page'
                    ? adminPrototypes.licenseScenarioScenario
                    : adminPrototypes.licensePageScenario)
                : (currentScenarioType === 'test'
                    ? courseTestScenarioProto
                : currentScenarioType === 'generative_test'
                    ? courseGenerativeTestScenarioProto
                    : coursePageScenarioProto));

    // licenseScenarioComponent
    let licenseScenarioComponent = null;
    let licenseScenarioComponentContents = dataSource &&
        <div className="flex-grow d-flex flex-column workpanel-gray scroll">
            <GeneratorBlock data={dataSource}
                            items={proto}
                            onChange={onChange} onValidate={onValidate}
                            collectionsDict={{licenseId: licenseId, scenarioId: licenseScenario.id}}
                            disabled={mode !== 'edit'}
                            wide />
        </div>;

    if (dataSource && currentScenarioType === 'constellation') {
        licenseScenarioComponent = (
            <>
            <TabBlock selectedTabId={itemSubType} tabs={[
                {name: t('appAdmin.views.LicenseScenarioView.scenario'), id: "scenario", onSelect: () => navigate(`${props.uri}/scenario/scenario${props.location.search}`)},
                {name: t('appAdmin.views.LicenseScenarioView.simulation'), id: "simulation", onSelect: () => navigate(`${props.uri}/scenario/simulation${props.location.search}`)}]} />

            {itemSubType === "scenario" && licenseScenarioComponentContents}

            {itemSubType === "simulation" &&
            <>
            <div className="flex-grow d-flex flex-column scroll">
                <GeneratorBlock className="flex-grow d-flex"
                                data={dataSource}
                                field="parameters.simulation"
                                items={staticPrototypes[SIMULATION]}
                                collectionsDict={{spacecrafts: [], orbits: []}}
                                onChange={onChange}
                                onValidate={onValidate}
                                disabled={mode !== 'edit'}/>
            </div>
            </>}
            </>);
    } else if (dataSource) {
        licenseScenarioComponent = licenseScenarioComponentContents;
    }

    const descriptionsSections = [];
    if (licenseScenario.description) {
        descriptionsSections.push({
            className: "description-tab-icon",
            name: t('appEvent.views.ScenarioView.description'),
            title: t('appEvent.views.ScenarioView.description'),
            key: "description"
        });
    }
    if (licenseScenario.descriptionParameters) {
        descriptionsSections.push({
            className: "init-data-tab-icon",
            name: t('appEvent.views.ScenarioView.descriptionParameters'),
            title: t('appEvent.views.ScenarioView.descriptionParameters'),
            key: "descriptionParameters"
        });
    }
    if (licenseScenario.descriptionScore) {
        descriptionsSections.push({
            className: "criteria-tab-icon",
            name: t('appEvent.views.ScenarioView.descriptionScore'),
            title: t('appEvent.views.ScenarioView.descriptionScore'),
            key: "descriptionScore"
        });
    }

    const isEditInitialParams = mode === 'edit' && includeInitialParams;

    return (
        <>
        {disabled && showBackLink &&
        <SaveButtonsBlock withBack
                          executeWithLock={executeWithLock} />}

        {!disabled &&
        <SaveButtonsBlock withEditMode
                          isChanged={isChanged}
                          hasErrors={hasErrors}
                          onSave={mode === 'edit' && !disabled && (() => {
                              saveAndReset();
                              navigate(`${props.uri}${itemTypeUriPart}${itemSubTypeUriPart}${props.location.search}`);
                          })}
                          onCancel={mode === 'edit' && !disabled && (() => {
                              setDataSource(null);
                              navigate(`${props.uri}${itemTypeUriPart}${itemSubTypeUriPart}${props.location.search}`);
                          })}
                          actions={!disabled && [
                              mode !== 'edit' && ["test", "generative_test", "sim", "constellation", "prog"].includes(licenseScenario.type) && {
                                  disabled: !licenseScenario.hasSuccessfullyCommittedSolutions,
                                  name: t('appAdmin.views.LicenseScenarioView.recalcSolutions'),
                                  color: 'danger',
                                  allowOnErrors: true,
                                  action: () => {
                                      executeWithConfirmation(() => recalcSolutions(licenseScenario.id)
                                                                        .then(({data: {recalcSolutions: {id, error}}}) => setError(error ? parseBackendMessage(error, t): null)),
                                                                    t('appAdmin.views.LicenseScenarioView.recalcSolutionsConfirmation'));
                              }},
                              mode !== 'edit' && ["sim", "constellation", "prog"].includes(licenseScenario.type) && {
                                  disabled: isDeleteSimulationsAndResultsMutationStarted || !licenseScenario.hasSuccessfullyCommittedSimulations,
                                  name: t('appAdmin.views.LicenseScenarioView.deleteSimulationsAndResults'),
                                  color: 'danger',
                                  allowOnErrors: true,
                                  action: () => {
                                      executeWithConfirmation(
                                          () => {
                                              setIsDeleteSimulationsAndResultsMutationStarted(true);
                                              let isDeleteRunning = false;
                                              deleteSimulationsAndResults(null, licenseScenario.id, isDeleteRunning)
                                                  .then(({data: {deleteSimulationsAndResults: {hasRunning, error}}}) => {
                                                      setIsDeleteSimulationsAndResultsMutationStarted(false);
                                                      if (!hasRunning) {
                                                          setError(error ? parseBackendMessage(error, t): null);
                                                          return;
                                                      }
                                                      // another confirmation
                                                      executeWithConfirmation(
                                                          () => {
                                                              setIsDeleteSimulationsAndResultsMutationStarted(true);
                                                              isDeleteRunning = true;
                                                              deleteSimulationsAndResults(null, licenseScenario.id, isDeleteRunning)
                                                                  .then(({data: {deleteSimulationsAndResults: {_, error}}}) => {
                                                                      setIsDeleteSimulationsAndResultsMutationStarted(false);
                                                                      setError(error ? parseBackendMessage(error, t): null);
                                                                  });
                                                          },
                                                          t('appAdmin.views.LicenseScenarioView.deleteSimulationsAndResultsHasRunningConfirmation'));
                                                 });
                                          },
                                          t('appAdmin.views.LicenseScenarioView.deleteSimulationsAndResultsConfirmation'));
                              }},
                              mode !== 'edit' && enableDeleteStudentSolutions && itemType !== "solutions" &&
                              ["test", "generative_test", "sim", "constellation", "prog"].includes(licenseScenario.type) && {
                                  disabled: !licenseScenario.studentSolutionsNumber,
                                  name: `${t('appAdmin.views.LicenseScenarioView.deleteStudentSolutions')} (${licenseScenario.studentSolutionsNumber || 0})`,
                                  color: 'danger',
                                  allowOnErrors: true,
                                  action: () => {
                                      const isTest = false;
                                      executeWithConfirmation(
                                          () => deleteSolutions(null, licenseScenario.id, isTest)
                                              .then(({data: {deleteSolutions: {error}}}) => setError(error ? parseBackendMessage(error, t): null)),
                                          t('appAdmin.views.LicenseScenarioView.deleteStudentSolutionsConfirmation',
                                              {num: licenseScenario.studentSolutionsNumber}));
                                  }},
                              mode !== 'edit' && {
                                  name: t('appAdmin.views.LicenseScenarioView.edit'),
                                  allowOnErrors: true,
                                  action: () => {
                                      const doNavigate = () => navigateWithLock(`${props.uri}/edit${itemTypeUriPart}${itemSubTypeUriPart}${props.location.search}`);
                                      editConfirmationMessage ? executeWithConfirmation(doNavigate, editConfirmationMessage) : doNavigate();
                                  }}
                              ]}
                          executeWithLock={executeWithLock}
                          withBack={showBackLink} />}

        {error &&
        <Alert className="mb-0" color="danger">{error}</Alert>}
        <LicenseAndEventStatisticsView licenseId={licenseId} eventId={licenseScenario.eventId} errors={props.errors} loading={props.loading} withDataSize />
        <TabBlock selectedTabId={itemType} tabs={[
            {name: t('appAdmin.views.LicenseScenarioView.scenario'),
                id: "scenario", onSelect: () => navigate(`${props.uri}${modeUriPart}/scenario${props.location.search}`)},
            {name: t('appAdmin.views.LicenseScenarioView.descriptions'),
                id: "descriptions", onSelect: () => navigate(`${props.uri}${modeUriPart}/descriptions${props.location.search}`)},
            currentScenarioType !== 'page' && {name: mode === 'edit' ? t('appAdmin.views.LicenseScenarioView.initialData') : t('appAdmin.views.LicenseScenarioView.solutionView'),
                id: "initialParameters", onSelect: () => navigate(`${props.uri}${modeUriPart}/initialParameters${props.location.search}`)},
            currentScenarioType !== 'page' && {name: t('appAdmin.views.LicenseScenarioView.resultsView'),
                id: "results", onSelect: () => navigate(`${props.uri}${modeUriPart}/results${props.location.search}`)},
            currentScenarioType !== 'page' && mode !== 'edit' && !disableSolutions && {name: t('appAdmin.views.LicenseScenarioView.solutionsTest'),
                id: "solutions", onSelect: () => navigate(`${props.uri}${modeUriPart}/solutions${props.location.search}`)}]} />

        {itemType === "scenario" &&
        licenseScenarioComponent}

        {itemType === "descriptions" &&
            // BlockFields propagate data to children
            <Block sections={descriptionsSections}>
                {['description', 'descriptionParameters', 'descriptionScore']
                    .filter((field) => licenseScenario[field])
                    .map((field) => (
                        <Block key={field} section={field}>
                            <MarkdownVal value={
                                isScenarioCustomizationRunning ? t('appEvent.views.ScenarioView.customizationIsRunning') :
                                isScenarioCustomizationFailed ? t('appEvent.views.ScenarioView.customizationFailed') :
                                licenseScenario[field]} />

                            {licenseScenario.type === 'generative_test' && field === 'description' &&
                            <>
                                {licenseScenario.scenarioUserCustomizationLog &&
                                <>
                                    <h6>{t('appAdmin.views.LicenseScenarioView.scenarioUserCustomizationLog')}</h6>
                                    <div>{licenseScenario.scenarioUserCustomizationLog}</div>
                                </>}

                                <Button color="danger" className="my-3"
                                        onClick={() => {
                                            deleteScenarioScenarioUserCustomization(licenseScenario.id, ACCESS_ROLE_EDITOR_TESTER)
                                                .then(({data: {deleteScenarioScenarioUserCustomization: {id, error}}}) =>
                                                    setError(error ? parseBackendMessage(error, t) : null))
                                        }}>
                                    {t('appAdmin.views.LicenseScenarioView.resetScenarioUserCustomizationProgram')}
                                </Button>
                            </>}

                        </Block>))}
            </Block>}

        {itemType === "initialParameters" && currentScenarioType !== 'page' &&
        <>

        <div className="flex-grow d-flex flex-column scroll">
            {!isFullScreen &&
            <div className="workpanel-gray">
                {mode === 'edit' &&
                <CheckBox className="mt-2" checked={includeInitialParams}
                          label={t('appAdmin.views.LicenseScenarioView.setInitialData')}
                          onChange={() => {
                              setIncludeInitialParams(!includeInitialParams);
                              onChange(dataSource, "", "");
                          }} />}
                <h6 className="mt-2">{mode === 'edit' && includeInitialParams
                    ? t('appAdmin.views.LicenseScenarioView.initialData2')
                    : t('appAdmin.views.LicenseScenarioView.solutionView2')}</h6>
            </div>}
            {currentScenarioType === 'constellation' && (isEditInitialParams ? dataSource : sampleSolutionParametersDataSource) && currentScenarioForSolutionAndResultsView &&
            <ConstellationSolutionView dataSource={isEditInitialParams ? dataSource : sampleSolutionParametersDataSource}
                                       field={isEditInitialParams ? "initialParameters" : null}
                                       scenario={currentScenarioForSolutionAndResultsView}
                                       onChange={isEditInitialParams ? onChange : onSampleSolutionParametersDataSourceChange}
                                       showSolution
                                       uri={`${props.uri}${modeUriPart}${itemTypeUriPart}${itemSubTypeUriPart}`} uriParams={props.uriParams} location={props.location} />}
            {currentScenarioType !== 'constellation' && (isEditInitialParams ? dataSource : sampleSolutionParametersDataSource) && currentScenarioForSolutionAndResultsView &&
            <SimpleSolutionView dataSource={isEditInitialParams ? dataSource : sampleSolutionParametersDataSource}
                                field={isEditInitialParams ? "initialParameters" : null}
                                scenario={currentScenarioForSolutionAndResultsView}
                                onChange={isEditInitialParams ? onChange : onSampleSolutionParametersDataSourceChange}
                                showSolution
                                uri={`${props.uri}${modeUriPart}${itemTypeUriPart}${itemSubTypeUriPart}`} uriParams={props.uriParams} location={props.location} />}
        </div>
        </>}

        {itemType === "results" && currentScenarioType !== 'page' &&
        <>
        {currentScenarioType === 'constellation' &&
        <SaveButtonsBlock withBack
                          executeWithLock={props.executeWithLock} />}
        <div className="flex-grow d-flex flex-column scroll">
            {currentScenarioType === 'constellation' && currentScenarioForSolutionAndResultsView &&
            <ConstellationSolutionView dataSource={isEditInitialParams ? dataSource : sampleSolutionParametersDataSource}
                                       scenario={currentScenarioForSolutionAndResultsView}
                                       solution={translateObjOrArray(sampleSolutionResults, t)}
                                       showResults
                                       uri={`${props.uri}${modeUriPart}${itemTypeUriPart}${itemSubTypeUriPart}`} uriParams={props.uriParams} location={props.location} />}
            {currentScenarioType !== 'constellation' && currentScenarioForSolutionAndResultsView &&
            <SimpleSolutionView dataSource={isEditInitialParams ? dataSource : sampleSolutionParametersDataSource}
                                scenario={currentScenarioForSolutionAndResultsView}
                                solution={translateObjOrArray(sampleSolutionResults, t)}
                                showResults
                                uri={`${props.uri}${modeUriPart}${itemTypeUriPart}${itemSubTypeUriPart}`} uriParams={props.uriParams} location={props.location} />}
        </div>
        </>}

        {itemType === "solutions" && !disableSolutions && currentScenarioType !== 'page' && currentScenarioForSolutionAndResultsView &&
        <SolutionsView event={null} scenario={currentScenarioForSolutionAndResultsView}
                       scenarioId={licenseScenario.id} // for querySolutions
                       queryAsRole={ACCESS_ROLE_EDITOR_TESTER}
                       disabled={disabled || isScenarioCustomizationRunning || isScenarioCustomizationFailed}
                       enableDeleteTestSolutions
                       isEnded={false}
                       user={user} loading={props.loading} errors={props.errors}
                       setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                       resetActionLock={props.resetActionLock}
                       executeWithLock={props.executeWithLock}
                       executeWithConfirmation={props.executeWithConfirmation}
                       uri={`${props.uri}${modeUriPart}${itemTypeUriPart}`} uriParams={props.uriParams} location={props.location} />}
    </>);
});

export default LicenseScenarioView;
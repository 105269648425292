import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-print-css/css/bootstrap-print.css'
import 'font-awesome/css/font-awesome.css'
import 'react-datetime/css/react-datetime.css'

import 'bootstrap-override.css'
import 'custom.css'
import 'print-override.css'


import React, {useMemo, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {useIsFirstRender} from 'usehooks-ts'
import compose from 'lodash/flowRight'
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client'
import {globalHistory, Router, Redirect, useNavigate, useLocation, useMatch, LocationProvider} from '@reach/router'
import useDebug from 'hooks/useDebug'
import ym, {YMInitializer} from 'react-yandex-metrika'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {CookiesProvider, useCookies} from 'react-cookie'
import i18n from 'i18n'

import Authorization from 'appBase/Authorization'
import ProfileView from 'appBase/ProfileView'
import LicenseApp from 'appLicense/LicenseApp'
import CatalogApp from 'appCatalog/CatalogApp'
import AdminApp from 'appAdmin/AdminApp'
import EventApp from 'appEvent/EventApp'
import ServiceApp from 'appService/ServiceApp'
import withActionLock from 'hocs/withActionLock'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import {queryUser} from 'appBase/data/baseQueries'
import entityUtils from 'utils/entityUtils'

// mobx observer batching: https://github.com/mobxjs/mobx-react-lite/#observer-batching
import 'mobx-react-lite/batchingForReactDom'

// import echartsInstance
// import echartsInstance from 'echartsInstance/lib/echartsInstance';
import echarts from 'echarts/dist/echarts';
import themeDark from 'echarts/theme/dark';
import themeInfographic from 'echarts/theme/infographic';
import themeMacarons from 'echarts/theme/macarons';
import themeRoma from 'echarts/theme/roma';
import themeShine from 'echarts/theme/shine';
import themeVintage from 'echarts/theme/vintage';

// register theme object
echarts.registerTheme('dark', themeDark);
echarts.registerTheme('infographic', themeInfographic);
echarts.registerTheme('macarons', themeMacarons);
echarts.registerTheme('roma', themeRoma);
echarts.registerTheme('shine', themeShine);
echarts.registerTheme('vintage', themeVintage);

// listen to the browser history
globalHistory.listen(({ location, action }) => {
    console.log(`hit: ${location.pathname}${location.search}`);
    ym('hit', `${location.pathname}${location.search}`);
});
// registering first url
window.addEventListener('load', () => {
    console.log(`hit: ${globalHistory.location.pathname}${globalHistory.location.search}`);
    ym('hit', `${globalHistory.location.pathname}${globalHistory.location.search}`);
});


// graphql initialization
const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: '/graphql', credentials: 'same-origin'
});


const BaseRouter = props => {
    useDebug("BaseRouter", props);
    const [, setCookie] = useCookies([]);
    const isFirstRender = useIsFirstRender();
    const uriMatch = useMatch('/:possibleLang/*');
    const location = useLocation();
    const navigate = useNavigate();

    // inform backend about language (first time only)
    if (isFirstRender) {
        // set cookie first time
        setCookie('language', i18n.resolvedLanguage, {path: '/'});
        console.log("cookie set first time: " + i18n.resolvedLanguage);
    }

    // change url if it does not match the languae
    useEffect(() => {
        if (!uriMatch || uriMatch.possibleLang !== i18n.resolvedLanguage) {
            console.log("url does not match language, redirecting:");
            navigate(`/${i18n.resolvedLanguage}${location.pathname}${location.search}`, {replace: true});
        }
    }, [uriMatch, i18n.resolvedLanguage, location.pathname]);

    return (
        <Router className="h-100 w-100" basepath={i18n.resolvedLanguage}>
            <Authorization path="/auth/:mode" apolloClient={client}/>
            <AppRouter default loading={{}} errors={{}} refetch={{}} />
        </Router>);
};


const AppRouter = compose(
    queryUser,
    withActionLock,
    withInitialDataLoadWaiting(['user'])
)(props => {
    useDebug("AppRouter", props);
    const uriMatch = useMatch('/:lang/*');

    // parsing JSON
    const user = useMemo(
        () => props.user && {
            ...props.user,
            parameters: entityUtils.safeJSONParse(props.user.parameters)
        }, [props.user]);

    // router
    return (
        <>
        <Router className="h-100 w-100">
            {/* возможный фикс проблемы, когда почему-то идет запрос "/events/events" */}
            <Redirect noThrow from="/" to={`/${i18n.resolvedLanguage}/events`} default />
            <LicenseApp path="license/*uriParams"
                        user={user}
                        setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                        resetActionLock={props.resetActionLock}
                        executeWithLock={props.executeWithLock}
                        loading={props.loading} errors={props.errors} />
            <CatalogApp path="catalog/*uriParams"
                        asEditableCatalog
                        user={user}
                        setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                        resetActionLock={props.resetActionLock}
                        executeWithLock={props.executeWithLock}
                        loading={props.loading} errors={props.errors} />
            <AdminApp path="admin/*uriParams"
                      user={user}
                      setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                      resetActionLock={props.resetActionLock}
                      executeWithLock={props.executeWithLock}
                      loading={props.loading} errors={props.errors} />
            <EventApp path="events/*uriParams"
                      user={user}
                      setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                      resetActionLock={props.resetActionLock}
                      executeWithLock={props.executeWithLock}
                      loading={props.loading} errors={props.errors} refetch={props.refetch} />
            <CatalogApp path="materials/*uriParams"
                        asReadonlyMaterials
                        user={user}
                        setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                        resetActionLock={props.resetActionLock}
                        executeWithLock={props.executeWithLock}
                        loading={props.loading} errors={props.errors} />
            <ServiceApp path="service/*uriParams"
                        user={user}
                        setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                        resetActionLock={props.resetActionLock}
                        executeWithLock={props.executeWithLock}
                        loading={props.loading} errors={props.errors} />
        </Router>
        <ProfileView user={user}
                     executeWithLock={props.executeWithLock}
                     loading={props.loading} errors={props.errors}
                     location={props.location} />
        </>);
});


console.log('ReactDOM.render:');

ReactDOM.render(
    <>
    <YMInitializer accounts={[67150465]} version="2" />
    <CookiesProvider>
        <ApolloProvider client={client}>
            <DndProvider backend={HTML5Backend}>
                <LocationProvider>
                    <BaseRouter default />
                </LocationProvider>
            </DndProvider>
        </ApolloProvider>
    </CookiesProvider>
    </>,
    document.getElementById('root'));
// registerServiceWorker();

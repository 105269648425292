import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import classnames from "classnames"
import {Button} from 'reactstrap'

import {PrototypeParametersContext} from '../SimulationTaskEditorVal'


function SimulationFieldSelector(props) {
    const {className, onChange, data, field, suffix, disabled, children} = props;
    const {t} = useTranslation();

    const value = data && field && data[field];

    const isProto = value && typeof value === 'string' && value.startsWith('$$solution.');

    const prototypeParameters = useContext(PrototypeParametersContext);

    const wrap = (value) => {
        return value + (suffix || "");
    };

    if (prototypeParameters && prototypeParameters.length > 0) {
        if (isProto) {
            return (
                <>
                    <select value={value}
                            onChange={(e) => onChange(data, field, e.nativeEvent.target.value)}
                            disabled={disabled}>
                        <option value="">{t('components.fields.helpers.SimulationFieldSelector.setConstant')}</option>
                        {prototypeParameters && prototypeParameters.map((pp, i) => (
                            <option key={i} value={wrap(pp)}>{pp}</option>
                        ))}
                    </select>
                </>
            );
        } else if (!disabled) {
            return (
                <div className={classnames("d-flex", className)}>
                    <div className="flex-grow">
                        {children}
                    </div>
                    <Button className="ms-1" color="light"
                            onClick={() => {
                                onChange(data, field, wrap(prototypeParameters[0]));
                            }}
                            disabled={disabled}>
                        <i className="fa fa-magic text-primary"/>
                    </Button>
                </div>);
        }
    }

    return children || null;
}

export default SimulationFieldSelector;